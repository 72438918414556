import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { useMemo } from "react";
import ApolloClientFactory from "../apolloClientFactory";
import { useKeycloak } from "../auth";

function useGqlClient(
  uri: string,
  websocketUri?: string
): ApolloClient<NormalizedCacheObject> {
  const keycloak = useKeycloak();
  return useMemo(() => {
    return ApolloClientFactory.createClient({
      uri,
      websocketUri,
      requestHandler: async (operation) => {
        const token = await keycloak.getTokenSilently();
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : "",
          },
        });
      },
      connectionParams: async () => {
        const token = await keycloak.getTokenSilently();
        return {
          Authorization: token ? `Bearer ${token}` : "",
        };
      },
    });
  }, [keycloak, uri, websocketUri]);
}

export default useGqlClient;
