import { useEffect, useState } from "react";
import { useKeycloak } from "../auth";

export function useKeycloakToken() {
  const [token, setToken] = useState<string>("");
  const keycloak = useKeycloak();

  useEffect(() => {
    keycloak.getTokenSilently().then((token: string) => {
      setToken(token);
    });
  }, [keycloak]);

  return token;
}
