import { useSelector } from "react-redux";
import { getRuleState } from "../ruleManagerSlice";
import RulesService from "../service/RulesService";
import { Trigger } from "../Model/Trigger";
import { Action } from "../Model/Action";
import { RuleSequence } from "../Model/RuleSequence";

export const useInitialValues = (
  id: string | undefined,
  ruleSequence: RuleSequence
) => {
  const ruleInput = useSelector(getRuleState);

  if (id === undefined) {
    return undefined;
  }

  const actionParams = () => {
    return ruleInput.actions?.find((action: Action) => action.type === id)
      ?.params;
  };

  switch (ruleSequence) {
    case RuleSequence.TRIGGER_FORM: {
      return ruleInput.triggers?.find(
        (elem: Trigger) => elem.triggerType === id
      )?.params;
    }
    case RuleSequence.CONDITION_FORM: {
      return RulesService.findCondition(ruleInput.triggers ?? [], id)?.params;
    }
    case RuleSequence.ACTION_FORM: {
      return actionParams();
    }
    case RuleSequence.RULE_FORM: {
      return actionParams();
    }
  }
};
