import { RulesList } from "../Model/RulesList";
import { Rule } from "../Model/Rule";
import { RuleStateMachine } from "../Model/RuleSequence";
import { useSelector } from "react-redux";
import { getRuleInfo } from "../ruleManagerSlice";
import { ReadFieldProperties, WriteFieldProperties } from "../Model/RuleModels";
import RulesService from "../service/RulesService";
import { Catalogue, CatalogueElement } from "../Model/Catalogue";
import { useProgressNavigation } from "./useProgressNavigation";
import { useMemo } from "react";

export const useConstructRule = (
  ruleElements: RulesList | undefined,
  ruleFields: ReadFieldProperties[] | undefined,
  ruleId?: string,
  catalogue?: Catalogue | null,
  fieldType?: string,
  initValues?: WriteFieldProperties
): Rule => {
  const ruleInfo = useSelector(getRuleInfo);

  const fieldsFromCatalogueIfPresent = useMemo(() => {
    const ruleSeq = new RuleStateMachine();
    if (catalogue && fieldType) {
      return catalogue?.elements.find(
        (element: CatalogueElement) =>
          ruleSeq.getState(element.type) === ruleSeq.getState(fieldType)
      )?.fields;
    }

    return ruleFields;
  }, [catalogue, fieldType, ruleFields]);

  const rule: Rule = useMemo(() => {
    return {
      id: ruleId,
      name: catalogue?.rule.name,
      description: catalogue?.rule.description,
      ruleElements: ruleElements,
      ruleFields: RulesService.addRuleInfoToFields(
        ruleInfo,
        fieldsFromCatalogueIfPresent
      ),
      initValues: initValues,
    };
  }, [
    catalogue?.rule.description,
    catalogue?.rule.name,
    fieldsFromCatalogueIfPresent,
    initValues,
    ruleElements,
    ruleId,
    ruleInfo,
  ]);

  useProgressNavigation(rule, catalogue !== undefined);

  return rule;
};
