import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { IAsset } from "../../models/assets/Asset";
import ActionButtons, { Action } from "../../shared/ActionButtons";
import Battery from "../../shared/Battery";
import "../../shared/styles/ListView.scss";
import SortableTable, {
  SortableColumn,
} from "../../shared/Tables/SortableTable";
import { SortModel } from "../../shared/Tables/useSortableColumnsHook";
import { Order } from "../../models/Order";
import { PageCriteria } from "../../models/PageCriteria";
import { useTranslation } from "react-i18next";
import {
  ACTION_TITLE_PL,
  ERROR_NOT_LINKED,
  GENERAL_BATTERY,
  GENERAL_INVENTORY_NUMBER,
  GENERAL_LAST_SEEN,
  GENERAL_OBJECT,
  GENERAL_TECHNICAL_ROOM_NUMBER,
  GENERAL_TEMPERATURE,
  LOCATION_FIXED,
  LOCATION_LAST,
  TAG_ATTRIBUTE_ID,
} from "../../localization";

export const AssetListData: React.FC<IAssetListData> = ({
  name,
  data,
  setIPagination,
  iPagination,
  assetCount: assetsByUsecase,
  onGenerateActions,
}) => {
  const { t } = useTranslation();
  const _sortModelChangeCallback = useCallback(
    (sortModel: SortModel) => {
      setIPagination({
        ...iPagination,
        sortBy: String(sortModel.sortBy),
        direction: sortModel.descending ? Order.DESC : Order.ASC,
      });
    },
    [iPagination, setIPagination]
  );

  const _renderHeader = () => (
    <div className="d-flex">
      <span>{name}</span>
      <span className="ms-auto">({assetsByUsecase})</span>
    </div>
  );

  const hasBattery = useMemo(
    () =>
      data.some((value) =>
        value.tags.some(
          (tag) => !(tag.battery === null || tag.battery === undefined)
        )
      ),
    [data]
  );

  const hasPosition = useMemo(
    () =>
      data.some(
        (value) =>
          !(value.lastPosition === null || value.lastPosition === undefined)
      ),
    [data]
  );
  const hasTemperature = useMemo(
    () =>
      data.some(
        (value) =>
          !(
            value.telemetry?.temperature === undefined ||
            value.telemetry.temperature === null
          )
      ),
    [data]
  );
  const hasFixedLocation = useMemo(
    () =>
      data.some(
        (value) =>
          !(
            value.technicalRoomNumber === undefined ||
            value.technicalRoomNumber === null
          )
      ),
    [data]
  );

  const hasTechnicalRoomNumber = useMemo(
    () =>
      data.some(
        (value) =>
          !(
            value.lastPosition?.technicalRoomNumber === undefined ||
            value.lastPosition.technicalRoomNumber === null
          )
      ),
    [data]
  );

  const hasInventoryNumber = useMemo(
    () =>
      data.some(
        (value) =>
          !(
            value.inventoryNumber === undefined ||
            value.inventoryNumber === null
          )
      ),
    [data]
  );

  const columns = useMemo(() => {
    const columnDefinitions: SortableColumn<IAsset>[] = [
      {
        key: "tags.name",
        label: t(TAG_ATTRIBUTE_ID),
        valueAccessor: (item) => item.tags[0]?.name,
        missingCellOverride: (
          <span className="text-muted">{t(ERROR_NOT_LINKED)}</span>
        ),
      },
      { key: "name", label: t(GENERAL_OBJECT) },
    ];

    if (hasTemperature) {
      columnDefinitions.push({
        key: "telemetry.temperature",
        label: t(GENERAL_TEMPERATURE),
        valueFormatter: (value) => `${value} °C`,
        sortable: false,
      });
    }

    if (hasInventoryNumber) {
      columnDefinitions.push({
        key: "inventoryNumber",
        label: t(GENERAL_INVENTORY_NUMBER),
        sortable: true,
      });
    }

    if (hasPosition) {
      if (hasTechnicalRoomNumber) {
        columnDefinitions.push({
          key: "lastPosition.technicalRoomNumber",
          label: t(GENERAL_TECHNICAL_ROOM_NUMBER),
          sortable: false,
        });
      }
      columnDefinitions.push({
        key: "lastPosition.locatorName",
        label: t(LOCATION_LAST),
        sortable: false,
      });
      columnDefinitions.push({
        key: "lastPosition.lastSeen",
        label: t(GENERAL_LAST_SEEN),
        sortable: false,
        valueFormatter: (value) => moment(value).format("DD.MM.YYYY HH:mm"),
      });
    } else {
      if (hasFixedLocation) {
        columnDefinitions.push({
          key: "technicalRoomNumber",
          label: t(LOCATION_FIXED),
          sortable: false,
        });
      }
    }

    if (hasBattery) {
      columnDefinitions.push({
        key: "tags.battery",
        label: t(GENERAL_BATTERY),
        valueAccessor: (item) => <Battery asset={item} />,
      });
    }

    if (onGenerateActions !== undefined) {
      columnDefinitions.push({
        key: "actions",
        label: t(ACTION_TITLE_PL),
        sortable: false,
        valueAccessor: (item: IAsset) => (
          <ActionButtons size="sm" actions={onGenerateActions(item)} />
        ),
      });
    }

    return columnDefinitions;
  }, [
    t,
    hasTemperature,
    hasInventoryNumber,
    hasPosition,
    hasBattery,
    onGenerateActions,
    hasTechnicalRoomNumber,
    hasFixedLocation,
  ]);
  return (
    <SortableTable
      columns={columns}
      rows={data}
      additionalHeader={_renderHeader()}
      sortModel={{
        sortBy: iPagination.sortBy,
        descending: iPagination.direction === Order.DESC,
      }}
      onSortModelChange={_sortModelChangeCallback}
    />
  );
};

export interface IAssetListData {
  assetCount: number;
  data: IAsset[];
  iPagination: PageCriteria;
  name: string;
  onGenerateActions?: (item: IAsset) => Action[];
  setIPagination(pagination: PageCriteria): void;
}

export default AssetListData;
