import { useEffect, useMemo, useState } from "react";
import useAsset from "./useAsset";
import { useLocationById } from "../../location/hooks/useLocationById";

export function useFetchFallBackPositionAndLevel(
  noData: boolean,
  assetId: string
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const {
    data: assetData,
    error: assetError,
    loading: assetLoading,
  } = useAsset(noData ? assetId : undefined, false);

  const { locationData, locationLoading, locationError } = useLocationById(
    noData ? assetData?.lastPosition?.locationId : undefined
  );

  useEffect(() => {
    setLoading(locationLoading || assetLoading);
  }, [assetLoading, locationLoading]);

  useEffect(() => {
    setIsError(assetError !== undefined || locationError !== undefined);
  }, [assetError, locationError]);

  const level = useMemo(() => {
    return locationData?.location?.availableLevels.find(
      (level) => level.id === assetData?.lastPosition?.levelId
    );
  }, [
    assetData?.lastPosition?.levelId,
    locationData?.location?.availableLevels,
  ]);

  return {
    lastPosition: assetData?.lastPosition,
    level,
    loading,
    isError,
  };
}
