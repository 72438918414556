import { Feature, Point } from "geojson";
import React, { useEffect, useState } from "react";
import ReactMapGL, { ViewState } from "react-map-gl";
import { Position } from "../../models/Position";
import CuGeoJsonFloorplan from "../../catchupMaps/CuGeoJsonFloorplan";
import AssetLayer from "../../map/AssetLayer";
import { Card, CardBody, CardHeader } from "reactstrap";
import "./SingleAssetMap.scss";
import { useTranslation } from "react-i18next";
import { LOCATION_TITLE_SG } from "../../localization";

const SingleAssetMap: React.FC<ISingleAssetMapProps> = ({
  asset,
  viewState,
  locationName,
}) => {
  const [viewport, setViewport] = useState<Partial<ViewState>>();
  const { t } = useTranslation();

  useEffect(() => {
    setViewport(viewState);
  }, [viewState]);
  return (
    <div className="MbMap" id="map-container">
      <Card className="location">
        <CardHeader>
          {t(LOCATION_TITLE_SG)}: {locationName}
        </CardHeader>
        <CardBody id="map">
          <ReactMapGL
            {...viewport}
            reuseMaps
            mapboxAccessToken={process.env.REACT_APP_MB_ACCESS_TOKEN}
            mapStyle={process.env.REACT_APP_MB_STYLE}
            onMove={(evt) => setViewport(evt.viewState)}
          >
            <CuGeoJsonFloorplan
              level={asset.properties.levelName ?? ""}
              use3D
            />
            <AssetLayer
              asset={asset}
              currentLayerId={asset.properties.layerId}
            />
          </ReactMapGL>
        </CardBody>
      </Card>
    </div>
  );
};

interface ISingleAssetMapProps {
  asset: Feature<Point, Position>;
  viewState?: Partial<ViewState>;
  locationName?: string;
}

export default SingleAssetMap;
