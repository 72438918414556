import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, } from "react-pro-sidebar";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { useKeycloak } from "../auth";
import { rolePermission } from "../rolePermission";
import { ROUTES } from "../routes";
import "./style/Navigation.scss";
import UserIcon from "./UserIcon";
import { useTranslation } from "react-i18next";
import {
  ASSET_ACTION_CREATE,
  GENERAL_ADMIN,
  GENERAL_LOGOUT,
  GENERAL_OVERVIEW,
  LOCATION_QR_SCANNER,
  LOCATION_TITLE_PL,
  RULE_ENGINE_TITLE,
} from "../localization";

const Navigation: React.FC<INavigationProps> = ({
                                                  collapsed,
                                                  changeMenuState,
                                                }) => {
  const location = useLocation();
  const keycloak = useKeycloak();
  const {t} = useTranslation();

  const isActive = (path: string, role?: string | string[]) => {
    if (Array.isArray(role)) {
      const roleFound = role.filter((currentRole) => {
        return (
          currentRole === undefined || keycloak.hasResourceRole(currentRole)
        );
      });
      if (roleFound.length === 0) return "hidden";
    } else {
      if (!(role === undefined || keycloak.hasResourceRole(role)))
        return "hidden";
    }

    return matchPath(location.pathname, {path}) ? "active" : "";
  };

  return (
    <ProSidebar collapsed={collapsed} onToggle={() => changeMenuState()}>
      <SidebarHeader>
        <UserIcon/>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            className={isActive(ROUTES.home.childRoutes.useCases.path)}
            icon={<FontAwesomeIcon icon="laptop"/>}
          >
            <NavLink to={ROUTES.home.childRoutes.useCases.path}>
              {t(GENERAL_OVERVIEW)}
            </NavLink>
          </MenuItem>
          <MenuItem
            className={isActive(
              ROUTES.home.childRoutes.assets.childRoutes.create.path,
              rolePermission.ASSET_CREATE
            )}
            icon={<FontAwesomeIcon icon="mobile-alt"/>}
          >
            <NavLink
              to={ROUTES.home.childRoutes.assets.childRoutes.create.path}
            >
              {t(ASSET_ACTION_CREATE)}
            </NavLink>
          </MenuItem>
          <MenuItem
            className={isActive(
              ROUTES.home.childRoutes.locations.path,
              rolePermission.LOCATION_READ
            )}
            icon={<FontAwesomeIcon icon="map-marked-alt"/>}
          >
            <NavLink to={ROUTES.home.childRoutes.locations.path}>
              {t(LOCATION_TITLE_PL)}
            </NavLink>
          </MenuItem>
          <MenuItem
            className={isActive(
              ROUTES.home.childRoutes.ruleEngine.path,
              rolePermission.RULE_ENGINE_READ
            )}
            icon={<FontAwesomeIcon icon="bell"/>}
          >
            <NavLink to={ROUTES.home.childRoutes.ruleEngine.path}>
              {t(RULE_ENGINE_TITLE)}
            </NavLink>
          </MenuItem>
          <MenuItem
            className={isActive(
              process.env.REACT_APP_QR_SCANNER_URI ?? "",
              rolePermission.QR_SCANNER
            )}
            icon={<FontAwesomeIcon icon="qrcode"/>}
          >
            <NavLink to={{pathname: process.env.REACT_APP_QR_SCANNER_URI ?? ""}} target="_parent">
              {t(LOCATION_QR_SCANNER)}
            </NavLink>
          </MenuItem>
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <Menu>
          <MenuItem
            className={isActive(ROUTES.home.childRoutes.admin.path, [
              rolePermission.ADMIN_ASSET_GROUP,
              rolePermission.ADMIN_ICONS,
            ])}
            icon={<FontAwesomeIcon icon={"hammer"}/>}
          >
            <NavLink to={ROUTES.home.childRoutes.admin.path}>
              {t(GENERAL_ADMIN)}
            </NavLink>
          </MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon="sign-out-alt"/>}>
            <NavLink to={ROUTES.home.childRoutes.logout.path}>
              {t(GENERAL_LOGOUT)}
            </NavLink>
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
};

interface INavigationProps {
  collapsed: boolean;

  changeMenuState(): void;
}

export default Navigation;
