import { combineReducers, configureStore } from "@reduxjs/toolkit";
import locatorMangerReducer from "./location/locatorManagerSlice";
import ruleManagerReducer from "./rules-engine/ruleManagerSlice";

const rootReducer = combineReducers({
  locatorManager: locatorMangerReducer,
  ruleManager: ruleManagerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export default store;
