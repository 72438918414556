import { useQuery } from "@apollo/client";
import useGqlClient from "../../hooks/useGqlClient";
import { GET_ALL_ASSETGROUPS } from "../../asset/queries/assetQuery";
import { AssetGroup } from "./useAssetGroupApi";

export const useAssetGroups = () => {
  const assetClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  const { data, loading, error, refetch } = useQuery(GET_ALL_ASSETGROUPS, {
    client: assetClient,
  });

  if (error) {
    console.error(error);
  }

  const refetchAssets = () => {
    try {
      refetch();
    } catch (ex) {
      console.log(ex);
    }
  };

  return {
    assetGroups: data?.assetGroups as AssetGroup[] | undefined,
    assetGroupsLoading: loading,
    assetGroupsError: !!error,
    assetGroupRefetch: refetchAssets,
  };
};
