import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Locator } from "../../models/Locator";

const LOCATOR = gql`
  query Locator($locatorId: ID!) {
    locator(id: $locatorId) {
      viewport {
        zoom
        latitude
        longitude
        pitch
        bearing
      }
    }
  }
`;
export const useViewStateByLocatorId = (locatorId?: string) => {
  const { data, error, loading } = useQuery<LocatorById>(LOCATOR, {
    variables: { locatorId },
    skip: locatorId === undefined || locatorId === null,
  });
  return {
    viewState: data?.locator?.viewport,
    viewStateLoading: loading,
    viewStateError: error,
  };
};

interface LocatorById {
  locator: Locator | null;
}
