import { useMap } from "react-map-gl";
import { useEffect } from "react";
import info from "./imgs/info.svg";
import wcwomanandmen from "./imgs/wcwomenandmen.svg";
import elevator from "./imgs/elevator.svg";
import parking from "./imgs/parkingSpot.svg";
import stairs from "./imgs/stairs.svg";
import wcdisabled from "./imgs/wcdisabled.svg";
import wcmen from "./imgs/wcmen.svg";
import wcwoman from "./imgs/wcwomen.svg";

const mapboxIcons = [
  {
    src: info,
    name: "info",
  },
  {
    src: wcwomanandmen,
    name: "wcwomenandmen",
  },
  {
    src: parking,
    name: "parking",
  },
  {
    src: stairs,
    name: "stairs",
  },
  {
    src: wcdisabled,
    name: "wcdisabled",
  },
  {
    src: elevator,
    name: "elevator",
  },
  {
    src: wcmen,
    name: "wcmen",
  },
  {
    src: wcwoman,
    name: "wcwomen",
  },
];

export default function useMapBoxImages() {
  const { current: map } = useMap();

  useEffect(() => {
    for (const icon of mapboxIcons) {
      let img = new Image(29, 29);
      if (!map?.hasImage(icon.name)) {
        img.onload = () => map?.addImage(icon.name, img, { sdf: true });
      }

      img.src = icon.src;
    }
  }, [map]);
}
