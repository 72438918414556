import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Button from "reactstrap/lib/Button";
import logo from "../logo.svg";
import Search from "./Search";
import "./style/Header.scss";
import BreadcrumbNavigation from "../shared/BreadcrumbNavigation";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  GENERAL_ENGLISH,
  GENERAL_FRENCH,
  GENERAL_GERMAN,
  GENERAL_ITALIAN,
} from "../localization";
import { Language } from "@mui/icons-material";

export default function Header({ changeMenuState }: IHeaderProps) {
  const withSearch = process.env.REACT_APP_FEATURE_SEARCH === "true";
  const [localeDropdown, setLocaleDropdown] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
      <header className="Header d-flex align-items-center justify-content-between">
        <Button
          className="border-right"
          color="primary"
          id="menu-toggle"
          onClick={() => changeMenuState()}
          outline
        >
          <FontAwesomeIcon icon="bars" />
        </Button>
        {withSearch && <Search />}
        <Dropdown
          isOpen={localeDropdown}
          toggle={() => setLocaleDropdown(!localeDropdown)}
        >
          <DropdownToggle caret>
            <Language />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              active={i18n.resolvedLanguage === "de"}
              onClick={() => i18n.changeLanguage("de")}
            >
              {t(GENERAL_GERMAN, { lng: "de" })}
            </DropdownItem>
            <DropdownItem
              active={i18n.resolvedLanguage === "en"}
              onClick={() => i18n.changeLanguage("en")}
            >
              {t(GENERAL_ENGLISH, { lng: "en" })}
            </DropdownItem>
            <DropdownItem
              active={i18n.resolvedLanguage === "fr"}
              onClick={() => i18n.changeLanguage("fr")}
            >
              {t(GENERAL_FRENCH, { lng: "fr" })}
            </DropdownItem>
            <DropdownItem
              active={i18n.resolvedLanguage === "it"}
              onClick={() => i18n.changeLanguage("it")}
            >
              {t(GENERAL_ITALIAN, { lng: "it" })}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <img src={logo} width="150" height="70" className="p-1" alt="header" />
      </header>
      <BreadcrumbNavigation />
    </>
  );
}

interface IHeaderProps {
  changeMenuState(): void;
}
