import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import { useKeycloak } from "./react-keycloak-wrapper";

function Logout(props: WithApolloClient<{}>) {
  const keycloak = useKeycloak();
  keycloak.logout({ redirectUri: window.location.origin });
  props.client?.resetStore();
  return null;
}

export default withApollo(Logout);
