import React from "react";
import { Container } from "reactstrap";
import { ROUTES, SwitchWithSubRoutes } from "../routes";
import "./style/Content.scss";

const Content: React.FC = () => {
  const routes: any = { ...ROUTES.home.childRoutes, home: ROUTES.home };
  return (
    <Container fluid id="page-wrap" className="Content">
      <SwitchWithSubRoutes routes={routes} />
    </Container>
  );
};

export default Content;
