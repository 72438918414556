import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ACTION_CANCEL, ACTION_DELETE } from "../../localization";

const MuiModal: React.FC<DialogTodoProps> = ({
  title,
  children,
  open,
  onAccept,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle title={title}>
        <Box justifyContent={"center"} display={"flex"} fontWeight={"bold"}>
          {title}
        </Box>
      </DialogTitle>
      <DialogContent>
        {children}
        <Box marginTop={"1rem"} display={"flex"} justifyContent={"center"}>
          <DialogActions>
            <Button
              onClick={onCancel}
              sx={{
                textTransform: "none",
              }}
              variant="contained"
              color={"success"}
            >
              {t(ACTION_CANCEL)}
            </Button>
            <Button
              sx={{
                textTransform: "none",
              }}
              color={"error"}
              onClick={onAccept}
              variant="contained"
            >
              {t(ACTION_DELETE)}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

interface DialogTodoProps {
  title: string;
  open: boolean;
  onAccept: () => void;
  onCancel: () => void;
  acceptMessage: string;
  cancelMessage: string;
}

export default MuiModal;
