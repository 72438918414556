import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import {
  ACTION_CANCEL,
  ACTION_DELETE,
  QUESTION_SYMBOL_DELETE,
} from "../../localization";
import { useTranslation } from "react-i18next";

/**
 * Component that contains a {@link Dialog} to prompt the user to confirm the deletion of a {@link CustomIcon}.
 *
 * @param title Title of the {@link Dialog}.
 * @param open Determines whether the {@link Dialog} is currently shown.
 * @param onCancel Function that is invoked when the dialog is cancelled/closed.
 * @param onAccept Function that is invoked when the dialog is accepted/confirmed.
 */
const DeleteCustomIconDialog: React.FC<DeleteCustomIconDialogProps> = ({
  title,
  open,
  onCancel,
  onAccept,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle title={title}>
        <Box justifyContent="center" display="flex" fontWeight="bold">
          {title}
        </Box>
      </DialogTitle>
      <DialogContent>
        {t(QUESTION_SYMBOL_DELETE)}
        <Box marginTop="1rem" display="flex" justifyContent="center">
          <DialogActions>
            <Button
              onClick={onCancel}
              variant="contained"
              sx={{
                textTransform: "none",
              }}
              color={"success"}
            >
              {t(ACTION_CANCEL)}
            </Button>
            <Button
              onClick={onAccept}
              variant="contained"
              sx={{
                textTransform: "none",
              }}
              color={"error"}
            >
              {t(ACTION_DELETE)}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteCustomIconDialog;

interface DeleteCustomIconDialogProps {
  title: string;
  open: boolean;
  onAccept: () => void;
  onCancel: () => void;
}
