import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Input } from "reactstrap";
import ErrorFeedback from "../../forms/components/ErrorFeedback";
import { FieldProps } from "../../rules-engine/components/RulesFormGenerator";
import "./Boundary.scss";
import i18next from "../../i18n";
import { ERROR_INCORRECT_LIMITS, INFO_SELECT_LIMIT } from "../../localization";

type Prop = FieldRenderProps<BoundFieldProps, any>;

function Boundary({ input, meta, ...rest }: Prop) {
  const { lowerBound, upperBound } = input.value;

  return (
    <>
      <div className="boundary">
        <div className="w-50">
          <Input
            name={input.name}
            invalid={
              !!(
                !meta.dirtySinceLastSubmit &&
                meta.touched &&
                (meta.error || meta.submitError)
              )
            }
            value={lowerBound ?? ""}
            onBlur={input.onBlur}
            id={input.name}
            type="number"
            onChange={(event) => {
              if (isNaN(event.target.valueAsNumber)) {
                if (!upperBound || isNaN(upperBound)) {
                  return input.onChange(undefined);
                }
              }
              input.onChange({
                upperBound: input.value.upperBound,
                lowerBound: isNaN(event.target.valueAsNumber)
                  ? undefined
                  : event.target.valueAsNumber,
              });
            }}
          />
        </div>
        <span className="ms-4 w-25">{rest.params.secondLabel}:</span>
        <div className="w-50">
          <Input
            type="number"
            value={upperBound ?? ""}
            onChange={(event) => {
              if (isNaN(event.target.valueAsNumber)) {
                if (!lowerBound || isNaN(lowerBound)) {
                  return input.onChange(undefined);
                }
              }
              input.onChange({
                lowerBound: input.value.lowerBound,
                upperBound: isNaN(event.target.valueAsNumber)
                  ? undefined
                  : event.target.valueAsNumber,
              });
            }}
          />
        </div>
      </div>
      <div>
        <ErrorFeedback name={input.name} />
      </div>
    </>
  );
}

export interface BoundFieldProps extends FieldProps {
  lowerBound?: number;
  upperBound?: number;
  secondLabel?: string;
}

export default Boundary;

export const validateBounds = (boundValues: BoundFieldProps) => {
  if (
    boundValues?.lowerBound !== undefined &&
    boundValues?.upperBound !== undefined &&
    boundValues.upperBound !== null &&
    boundValues.lowerBound !== null
  ) {
    if (boundValues.lowerBound >= boundValues.upperBound!!) {
      console.log(boundValues);
      return i18next.t(ERROR_INCORRECT_LIMITS);
    }
  } else {
    if (boundValues?.lowerBound === null && boundValues?.upperBound === null) {
      return i18next.t(INFO_SELECT_LIMIT);
    }
  }
  return null;
};
