import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "../Information-card/InformationCard.scss";

export default function ProgressItem({
  title,
  itemText,
  isUnselected,
  isHighlighted,
  showGoBackButton,
  onGoBack,
  onClick,
}: ProgressItemProps) {
  const classNames = `d-flex flex-column p-1 ${
    !isHighlighted ? "information-card" : ""
  } ${isHighlighted ? "highlight-background rounded-3" : ""}`;

  const borderStyle = {
    borderStyle: isUnselected ? "dashed" : "",
    borderRadius: isUnselected ? "3px" : "",
    borderColor: isUnselected ? "red" : "",
  };

  return (
    <div style={borderStyle} className={classNames} onClick={onClick}>
      <div className="d-flex">
        <p
          className={`fw-bold mb-1 ${
            isHighlighted ? "text-decoration-underline" : ""
          }`}
        >
          {title}
        </p>
        {showGoBackButton && onGoBack && isHighlighted && (
          <FontAwesomeIcon
            title="Go back"
            icon={faTrash}
            className="ms-auto align-self-center go-back-icon"
            onClick={(event) => {
              event.stopPropagation();
              onGoBack();
            }}
          />
        )}
      </div>
      <p>{itemText}</p>
    </div>
  );
}

export interface ProgressItemProps {
  title: string;
  itemText: string;
  isUnselected: boolean;
  onGoBack?: () => void;
  onClick: () => void;
  isHighlighted: boolean;
  showGoBackButton: boolean;
}
