import { useQuery } from "@apollo/client";
import React from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import { Alert } from "reactstrap";
import useGqlClient from "../../hooks/useGqlClient";
import { SecureRouteProps, SecureRouteWithSubRoutes } from "../../routes";
import Loading from "../../shared/Loading";
import { GetAllLocations } from "../../__generated__/types";
import LocationListTable from "../components/LocationListTable";
import { GET_ALL_LOCATIONS } from "../queries/locationsQuery";
import { useTranslation } from "react-i18next";
import { ERROR_LOADING, LOCATION_TITLE_PL } from "../../localization";

const LocationList: React.FC<
  RouteComponentProps & { routes: Record<string, SecureRouteProps> }
> = ({ match, routes }) => {
  const client = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_LOCATION!);
  const { path } = useRouteMatch();
  const { loading, error, data } = useQuery<GetAllLocations>(
    GET_ALL_LOCATIONS,
    { client }
  );
  const { t } = useTranslation();

  if (loading) return <Loading />;
  if (error) return <Alert color="danger">{t(ERROR_LOADING)}</Alert>;

  return (
    <Switch>
      <Route exact path={path}>
        <div className="LocationList">
          <h1>{t(LOCATION_TITLE_PL)}</h1>
          <LocationListTable locations={data?.locations ?? []} />
        </div>
      </Route>
      {Object.entries(routes).map((value, index) => (
        <SecureRouteWithSubRoutes key={index} {...value[1]} />
      ))}
    </Switch>
  );
};

export default withRouter(LocationList);
