import React from "react";
import { ReactComponent as LoadingSvg } from "../loading.svg";
import "./Loading.scss";

class Loading extends React.Component {
  render() {
    return (
      <div id="Loading">
        <LoadingSvg width="100" height="100" />
      </div>
    );
  }
}

export default Loading;
