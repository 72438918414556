import { generatePath, useLocation } from "react-router-dom";
import { ROUTES, SecureRouteProps } from "../routes";

function useNavdata() {
  const SEPARATOR = "/";
  const ID_PLACEHOLDER = ":id";
  const TAG_PLACEHOLDER = "tags";
  const location = useLocation();
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  let actBreadcrumbs: SecureRouteProps[] = [ROUTES.home];

  const _getRoutes = (
    path: string,
    childRoutes: Record<string, SecureRouteProps> | undefined = ROUTES.home
      .childRoutes
  ) => {
    for (const route of Object.values(childRoutes)) {
      if (path.startsWith(route.path)) {
        actBreadcrumbs.push(route);
        if (path === route.path) break;
        if (
          route.path === ROUTES.home.childRoutes.assets.childRoutes.create.path
        )
          break;
        _getRoutes(path, route.childRoutes);
      }
    }
  };

  let splitPath = location.pathname.split(SEPARATOR);
  let foundId: string | undefined = undefined;
  let foundTags: boolean = false;

  for (const path of splitPath) {
    if (regexExp.test(path)) {
      foundId = path;
      splitPath[splitPath.indexOf(path)] = ID_PLACEHOLDER;
    }
    if (path === TAG_PLACEHOLDER) {
      foundTags = true;
      break;
    }
  }

  if (foundTags) {
    splitPath = splitPath.slice(0, splitPath.indexOf(TAG_PLACEHOLDER));
  }

  const path = splitPath.join(SEPARATOR);
  _getRoutes(path);

  if (foundId) {
    for (let route of actBreadcrumbs) {
      if (route.path.includes(ID_PLACEHOLDER)) {
        const temp = { ...route };
        temp.path = generatePath(temp.path, { id: foundId });
        actBreadcrumbs[actBreadcrumbs.indexOf(route)] = temp;
      }
    }
  }

  return actBreadcrumbs.filter(
    (item) => item.name !== "" && item.name !== undefined
  );
}

export default useNavdata;
