import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { CustomIconTag } from "../../models/CustomIcon";
import { useTranslation } from "react-i18next";
import { GENERAL_CATEGORY_PL } from "../../localization";

const IconPickerNavigation: React.FC<IconNavigationProps> = ({
  items,
  activeItems,
  toggleActiveItem,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <span className="fw-bold text-decoration-underline ">
        {t(GENERAL_CATEGORY_PL)}:
      </span>
      <Nav vertical pills className="me-5 mt-2">
        {items.map((item, index) => {
          return (
            <NavItem
              key={index}
              onClick={() => toggleActiveItem(item)}
              className="mb-2 cursor-pointer"
            >
              <NavLink active={activeItems.includes(item)}>
                {item.tagName}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    </div>
  );
};

interface IconNavigationProps {
  items: CustomIconTag[];
  activeItems: CustomIconTag[];
  toggleActiveItem: (item: CustomIconTag) => void;
}

export default IconPickerNavigation;
