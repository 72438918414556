export type Tag = {
  id: string;
  name: string;
  mac?: string;
  beaconType?: BeaconType;
  isLocked?: boolean;
  offset?: number;
};

export enum BeaconType {
  IBEACON = "IBEACON",
  EDDYSTONE = "EDDYSTONE",
  WLAN = "WLAN",
}
