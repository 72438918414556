import { useSubscription } from "@apollo/client";
import { useMemo } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useGqlClient } from "../../hooks";
import { IAlert } from "../../models/Alert";
import { GET_TLM_SUBSCRIPTION } from "../queries/tlmQueries";

const useTlmAlarms = () => {
  const client = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_TLM!,
    process.env.REACT_APP_GRAPHQL_WEBSOCKET_PATH!
  );
  const { data, error } = useSubscription<{ telemetry: IAlert[] }>(
    GET_TLM_SUBSCRIPTION,
    {
      client,
    }
  );

  if (error) throw error;

  const alarms = useMemo(() => {
    if (!data) return [];

    return data.telemetry.sort((a, b) => {
      if (a.timestamp > b.timestamp) return 1;
      if (a.timestamp < b.timestamp) return -1;
      return 0;
    });
  }, [data]);

  return alarms;
};

export default useTlmAlarms;
