import React from "react";
import { NavLink } from "react-router-dom";
import { Drawer, List, ListItemButton, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";

export interface Item {
  name: string;
  icon: string;
  path: string;
  roles: string[];
}

const AdminNavigation: React.FC<AdminNavigationProps> = ({
  itemList,
  selectedItem,
}) => {
  const useStyles = makeStyles({
    drawer: {
      position: "relative",
      overflowX: "hidden",
      "& .MuiBackdrop-root": {
        display: "none",
      },
      "& .MuiDrawer-paper": {
        position: "relative",
        transition: "none ",
      },
      "& .MuiDrawer-paperAnchorDockedLeft": {
        border: 0,
      },
    },
    icon: {
      minWidth: "2rem",
    },
    list: {
      paddingBottom: 0,
    },
    listItem: {
      "& > div": {
        "&:hover": {
          textDecoration: "none",
        },
        "& > span:hover": {
          textDecoration: "none",
        },
      },
    },
  });
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={true}
    >
      <List className={classes.list}>
        {itemList.map((item, index) => {
          return (
            <ListItemButton
              className={classes.listItem}
              component={NavLink}
              to={item.path}
              key={index}
              selected={item.name === selectedItem}
            >
              <ListItemText>{item.name}</ListItemText>
            </ListItemButton>
          );
        })}
      </List>
    </Drawer>
  );
};

interface AdminNavigationProps {
  itemList: Item[];
  selectedItem?: string;
}
export default AdminNavigation;
