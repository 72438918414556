import {useGqlClient} from "../../hooks";
import {useLazyQuery} from "@apollo/client";
import {GET_LOCATOR_BY_MAC} from "../queries/locationsQuery";

export const useLocatorByMac = () => {
  const locationClient = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_LOCATION!
  );

  const [fetchLocator, {data, loading, error}] = useLazyQuery(
    GET_LOCATOR_BY_MAC,
    {
      client: locationClient,
      fetchPolicy: "no-cache",
    }
  );

  return [fetchLocator, data?.locatorByMac, loading, error] as const;
};
