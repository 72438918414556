import React from "react";
import { FormFeedback, FormGroup, Input, InputProps, Label } from "reactstrap";
import "./BoundsInput.scss";
import { useTranslation } from "react-i18next";
import { GENERAL_MAXIMUM, GENERAL_MINIMUM } from "../../localization";

const BoundsInput: React.FC<InputProps> = ({ input, meta }) => {
  const { t } = useTranslation();
  const { name, onChange } = input;
  const { error, submitError } = meta;
  const inputValue = input.value;

  const minChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const current = { upper: inputValue?.upper, lower: event.target.value };
    return onChange(current);
  };

  const maxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const current = { upper: event.target.value, lower: inputValue?.lower };
    return onChange(current);
  };

  return (
    <div className="bounds-input">
      <FormGroup key={`bounds`} className="d-inline-flex">
        <Label>{t(GENERAL_MINIMUM)}:&nbsp;</Label>
        <Input
          type="number"
          name={`${name}.lower`}
          value={
            inputValue?.lower === undefined || inputValue?.lower == null
              ? ""
              : inputValue?.lower
          }
          onChange={minChanged}
        />
        <Label>&nbsp;&deg;C</Label>
        <div className="mx-3"></div>
        <Label>{t(GENERAL_MAXIMUM)}:&nbsp;</Label>
        <Input
          type="number"
          name={`${name}.upper`}
          value={
            inputValue?.upper === undefined || inputValue?.upper == null
              ? ""
              : inputValue?.upper
          }
          onChange={maxChanged}
        />
        <Label>&nbsp;&deg;C</Label>
      </FormGroup>
      {(error || submitError) && (
        <FormFeedback>{error || submitError}</FormFeedback>
      )}
    </div>
  );
};

export default BoundsInput;
