import gql from "graphql-tag";

export const GET_TLM_SUBSCRIPTION = gql`
  subscription TLM {
    telemetry {
      id
      timestamp
      description
      alertType
      alertId
      patient {
        firstName
        lastName
        birthday
        gender
        assignedLocation
      }
      lastPosition {
        assetId
        locatorName
        technicalRoomNumber
      }
    }
  }
`;
