import moment from "moment";
import React, { memo } from "react";
import { Card, CardBody, Col, Media, Row } from "reactstrap";
import { IAlert } from "../../models/Alert";
import { useTranslation } from "react-i18next";
import {
  ALARM_TITLE_SG,
  GENERAL_FLOOR,
  GENERAL_GENDER,
  GENERAL_LAST_UPDATE,
  GENERAL_PATIENT,
  GENERAL_STATIONED,
  GENERAL_TECHNICAL_ROOM_NUMBER,
  GENERAL_TLM_DEVICE,
  GENERAL_UNKNOWN,
} from "../../localization";

type AlarmInfoProps = {
  alarm: IAlert;
  className?: string;
};

const AlarmInfo: React.FC<AlarmInfoProps> = ({ alarm, className }) => {
  const { firstName, lastName, gender, assignedLocation } = alarm.patient;
  const { t } = useTranslation();

  return (
    <Card className={className}>
      <CardBody>
        <Row className="mb-3 align-items-center">
          <Col>
            <Media>
              <Media object src={`${process.env.PUBLIC_URL}/mitarbeiter.png`} />
            </Media>
          </Col>
          <Col>
            <h2>
              {t(ALARM_TITLE_SG)}:{" "}
              <span className="text-danger">{alarm.description}</span>
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            {t(GENERAL_TLM_DEVICE)}:
            <br />
            {t(GENERAL_PATIENT)}:
            <br />
            {t(GENERAL_GENDER)}:
            <br />
          </Col>
          <Col>
            {alarm.id}
            <br />
            {firstName} {lastName}
            <br />
            {gender}
            <br />
          </Col>
          <Col>
            {t(GENERAL_STATIONED)}:
            <br />
            {t(GENERAL_LAST_UPDATE)}:
            <br />
            {alarm.lastPosition?.technicalRoomNumber ? (
              <>
                {t(GENERAL_TECHNICAL_ROOM_NUMBER)}:
                <br />
              </>
            ) : null}
            {t(GENERAL_FLOOR)}:
          </Col>
          <Col>
            {assignedLocation}
            <br />
            {moment(alarm.lastPosition?.lastSeen).isValid() ? (
              moment(alarm.lastPosition?.lastSeen).format("DD.MM.YY HH:mm:ss")
            ) : (
              <span className="text-muted">{t(GENERAL_UNKNOWN)}</span>
            )}
            <br />
            {alarm.lastPosition?.technicalRoomNumber ? (
              <>
                {alarm.lastPosition.technicalRoomNumber}
                <br />
              </>
            ) : null}
            {alarm.lastPosition?.levelName ? (
              alarm.lastPosition?.levelName
            ) : (
              <span className="text-muted">{t(GENERAL_UNKNOWN)}</span>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default memo(AlarmInfo);
