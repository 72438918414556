import { useEffect } from "react";
import { AnyLayer } from "mapbox-gl";
import { useMap } from "react-map-gl";

export const useSelectCurrentLayer = (currentLayerId?: string) => {
  const mapRef = useMap();

  useEffect(() => {
    const map = mapRef.default?.getMap();
    if (currentLayerId && map) {
      map.on("styledata", (data) => {
        data.style.stylesheet?.layers.forEach((layer: AnyLayer) => {
          if (layer.type === "raster") {
            if (layer.id !== currentLayerId) {
              map.setLayoutProperty(layer.id, "visibility", "none");
            } else {
              map.setLayoutProperty(layer.id, "visibility", "visible");
            }
          }
        });
      });
    }
  }, [currentLayerId, mapRef]);
};
