import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "reactstrap";
import {
  ACTION_APPLY_CHANGES,
  ACTION_LOCK,
  ACTION_RESET_CHANGES,
  GENERAL_DEFAULT_VIEW,
  GENERAL_ILLUMINATION,
  GENERAL_ON_OFF,
  GENERAL_SATELLITE_VIEW,
} from "../../localization";
import { useTranslation } from "react-i18next";

const ActionsControl: React.FC<ActionsControlProps> = ({
  isSaveDisabled,
  onSave,
  isRevertDisabled,
  onRevert,
  isLocked,
  toggleLock,
  toggleSignal,
  isSatelliteViewDisabled,
  toggleSatelliteView,
  showSignalRadius,
}) => {
  const { t } = useTranslation();

  return (
    <div className="button-section">
      <Button
        outline
        color={isLocked ? "primary" : "danger"}
        onClick={() => toggleLock()}
        title={`${t(ACTION_LOCK)} ${t(GENERAL_ON_OFF)}`}
      >
        <FontAwesomeIcon icon={isLocked ? "lock" : "lock-open"} />
      </Button>
      <Button
        outline
        color="primary"
        disabled={isSaveDisabled}
        onClick={() => onSave()}
        title={t(ACTION_APPLY_CHANGES) ?? ""}
      >
        <FontAwesomeIcon icon="save" />
      </Button>
      <Button
        outline
        color="danger"
        disabled={isRevertDisabled}
        onClick={() => onRevert()}
        title={t(ACTION_RESET_CHANGES) ?? ""}
      >
        <FontAwesomeIcon icon="history" />
      </Button>
      <Button
        outline
        color="primary"
        active={showSignalRadius}
        onClick={() => toggleSignal()}
        title={`${t(GENERAL_ILLUMINATION)} ${t(GENERAL_ON_OFF)}`}
      >
        <FontAwesomeIcon icon="signal" />
      </Button>
      {process.env.REACT_APP_MB_STYLE_SATELLITE!! !== "" && (
        <Button
          outline
          color="primary"
          onClick={() => toggleSatelliteView()}
          title={
            (isSatelliteViewDisabled
              ? t(GENERAL_DEFAULT_VIEW)
              : t(GENERAL_SATELLITE_VIEW)) ?? ""
          }
        >
          <FontAwesomeIcon
            icon={isSatelliteViewDisabled ? "road" : "satellite"}
            fixedWidth
          />
        </Button>
      )}
    </div>
  );
};

interface ActionsControlProps {
  isSaveDisabled: boolean;
  isRevertDisabled: boolean;
  isLocked: boolean;
  isSatelliteViewDisabled: boolean;
  showSignalRadius: boolean;
  toggleLock: () => void;
  onSave: () => void;
  onRevert: () => void;
  toggleSignal: () => void;
  toggleSatelliteView: () => void;
}

export default ActionsControl;
