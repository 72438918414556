import _ from "lodash";
import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Form as ReactForm, FormGroup, Label } from "reactstrap";
import { useBuildingpartParents } from "../../asset/hooks/useBuildingpartParents";
import ErrorFeedback from "../../forms/components/ErrorFeedback";
import {
  composeValidators,
  macAddress,
  noScript,
  onlyNumbers,
  required,
  validateBuildingPartLeaf,
} from "../../forms/validation";
import useGqlClient from "../../hooks/useGqlClient";
import {
  removeLocator,
  selectIsLocked,
  updateLocator,
} from "../locatorManagerSlice";
import { Locator } from "./LocatorManager";
import InputField from "../../forms/components/InputField";
import BuildingpartSelectField from "../../forms/components/BuildingpartSelectField";
import SelectField from "../../forms/components/SelectField";
import {
  ACTION_CANCEL,
  ACTION_CONFIRM,
  ACTION_REMOVE,
  GENERAL_CUSTOM_ID,
  GENERAL_LATITUDE,
  GENERAL_LOCATOR_AP,
  GENERAL_LOCATOR_HYPROS,
  GENERAL_LONGITUDE,
  GENERAL_MAC_ADR,
  GENERAL_RADIUS_IN_METERS,
  GENERAL_RANGE,
  GENERAL_ROOM_INFORMATION,
  GENERAL_RSSI_CALIBRATION,
  GENERAL_TYPE,
  INFO_SELECT_LOCATOR,
  LOCATION_FIXED,
  PLACEHOLDER_SELECT,
} from "../../localization";
import { useTranslation } from "react-i18next";

export const GROUPS = {
  AcessPoint: {
    label: GENERAL_LOCATOR_AP,
    value: "Access Point",
  },
  Hypros: {
    label: GENERAL_LOCATOR_HYPROS,
    value: "Hypros Locator",
  },
};

const LocatorForm: React.FC<ILocatorFormProps> = ({ initialValues }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locked = useSelector(selectIsLocked);

  const client = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_BUILDING_DATA!);

  const selectedBuildingparts = useBuildingpartParents(
    initialValues?.buildingPartIDExternal
  );

  const locator: Locator | undefined = initialValues
    ? {
        ...initialValues,
        buildingparts:
          initialValues.buildingparts ??
          selectedBuildingparts?.map((p) => {
            return {
              label: p.name,
              value: p.id,
            };
          }),
      }
    : undefined;

  const _onSubmit = (formValues: Locator) => {
    const locator: Locator = {
      ...(Object.fromEntries(
        Object.entries(formValues).filter(
          (entry) => entry[0] !== "viewport" && entry[0] !== "level"
        )
      ) as unknown as Locator),
      buildingPartIDExternal: _.last(formValues.buildingparts)?.value,
    };
    delete locator.buildingparts;

    dispatch(updateLocator(locator));
  };

  if (!locator) {
    return (
      <Alert color="light" className="text-center shadow-sm rounded">
        {t(INFO_SELECT_LOCATOR)}
      </Alert>
    );
  }

  return (
    <Form initialValues={locator} onSubmit={_onSubmit}>
      {({ handleSubmit, form, pristine, submitting, invalid, validating }) => (
        <ReactForm onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="customerId">{t(GENERAL_CUSTOM_ID)}:</Label>
            <Field
              name="customerId"
              disabled={locked}
              component={InputField}
              validate={noScript}
            />
          </FormGroup>
          <FormGroup>
            <Label for="mac">
              {t(GENERAL_MAC_ADR)}: <span className="text-danger">*</span>
            </Label>
            <Field
              name="mac"
              disabled={locked}
              component={InputField}
              validate={composeValidators(noScript, macAddress, required)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="name">
              {t(GENERAL_ROOM_INFORMATION)}:{" "}
              <span className="text-danger">*</span>
            </Label>
            <Field
              name="name"
              disabled={locked}
              component={InputField}
              validate={composeValidators(noScript, required)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="buildingparts">{t(LOCATION_FIXED)}:</Label>
            <Field
              name="buildingparts"
              placeholder={t(PLACEHOLDER_SELECT)}
              component={BuildingpartSelectField}
              isDisabled={locked}
              validate={validateBuildingPartLeaf(client)}
              selectedBuildingparts={selectedBuildingparts}
            />
            <ErrorFeedback name="buildingparts" />
          </FormGroup>
          <FormGroup>
            <Label for="type">{t(GENERAL_TYPE)}:</Label>
            <Field
              name="type"
              params={{
                options: Object.values(GROUPS).map((group) => {
                  return { label: t(group.label), value: group.value };
                }),
              }}
              disabled={locked}
              component={SelectField}
            />
          </FormGroup>
          <FormGroup>
            <Label for="rssiCalibration">{t(GENERAL_RSSI_CALIBRATION)}:</Label>
            <Field
              name="rssiCalibration"
              disabled={locked}
              component={InputField}
              validate={composeValidators(noScript, onlyNumbers)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="radius">{`${t(GENERAL_RANGE)} (${t(
              GENERAL_RADIUS_IN_METERS
            )}):`}</Label>
            <Field
              name="radius"
              disabled={locked}
              component={InputField}
              validate={composeValidators(noScript, onlyNumbers)}
            />
          </FormGroup>

          <FormGroup>
            <Label for="longitude">{t(GENERAL_LATITUDE)}:</Label>
            <Field name="longitude" plaintext readOnly component={InputField} />
          </FormGroup>

          <FormGroup>
            <Label for="latitude">{t(GENERAL_LONGITUDE)}:</Label>
            <Field name="latitude" plaintext readOnly component={InputField} />
          </FormGroup>

          <div className="d-flex flex-column">
            <Button
              type="submit"
              color="success"
              disabled={
                pristine || locked || submitting || invalid || validating
              }
              block
            >
              {t(ACTION_CONFIRM)}
            </Button>
            <Button
              className="my-2"
              color="warning"
              disabled={pristine || locked}
              onClick={() => form.reset()}
              block
            >
              {t(ACTION_CANCEL)}
            </Button>
            <Button
              color="danger"
              disabled={!initialValues || locked}
              onClick={() => dispatch(removeLocator())}
              block
            >
              {t(ACTION_REMOVE)}
            </Button>
          </div>
        </ReactForm>
      )}
    </Form>
  );
};

export interface ILocatorFormProps {
  initialValues: Locator | undefined;
}

export default LocatorForm;
