export enum Filters {
  GROUPFILTERIDS = "groupFilterIds",
  PAGE = "page",
  PAGESIZE = "pageSize",
  SORT_BY = "sortBy",
  DIRECTION = "direction",
  BUILDINGPARTID = "buildingPartId",
  ACTIVE_STATUS = "activeStatus",
  INTERVAL = "interval",
  UNIT = "unit",
}
