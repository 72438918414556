export function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) {
  return condition ? wrapper(children) : children;
}
interface ConditionalWrapperProps {
  condition: boolean;
  wrapper(children: JSX.Element): JSX.Element;
  children: JSX.Element;
}
