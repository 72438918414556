import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useEffect } from "react";
import useGqlClient from "../../hooks/useGqlClient";
import {
  GET_LAST_MEASUREMENT_BY_TAG_MAC,
  GET_UPDATE_ON_LAST_MEASUREMENT_BY_TAG_MAC,
} from "../queries/AssetHistoryQueries";
const useLastMeasurementByTagMacs = (ids: string[]) => {
  const historyClient = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_HISTORY!,
    process.env.REACT_APP_GRAPHQL_WEBSOCKETURI_HISTORY!
  );

  const { data, loading, error, subscribeToMore } = useQuery(
    GET_LAST_MEASUREMENT_BY_TAG_MAC,
    {
      skip: ids.length === 0,
      variables: {
        tagMacs: ids,
      },
      client: historyClient,
    }
  );

  useEffect(() => {
    if (_.isEmpty(ids)) return;

    return subscribeToMore({
      onError: (error) => {
        console.error(error);
      },
      document: GET_UPDATE_ON_LAST_MEASUREMENT_BY_TAG_MAC,
      variables: {
        tagMacs: ids,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;
        return Object.assign({
          getLastMeasurementByTagMacs: [
            ...subscriptionData.data.getUpdateOnLastMeasurementByTagMacs,
          ],
        });
      },
    });
  }, [subscribeToMore, ids]);

  return { data, loading, error };
};

export default useLastMeasurementByTagMacs;
