import { InputFieldTypes } from "../components/RulesFormGenerator";
import { RuleFields } from "../Model/RuleFields";
import i18next from "../../i18n";
import {
  GENERAL_DESCRIPTION,
  GENERAL_NAME,
  RULE_ENGINE_SAVE,
} from "../../localization";

export const RuleSaveFields: RuleFields = {
  fieldType: "RuleFields",
  type: "",
  title: i18next.t(RULE_ENGINE_SAVE),
  disabled: false,
  description: "",
  shortDescription: "",
  icon: "temperature-high",
  fields: [
    {
      id: "name",
      label: GENERAL_NAME,
      mandatory: true,
      inputFieldTypes: InputFieldTypes.TEXT,
      component: "InputField",
      initialValues: null,
    },
    {
      id: "description",
      label: GENERAL_DESCRIPTION,
      mandatory: true,
      inputFieldTypes: InputFieldTypes.TEXTAREA,
      component: "InputField",
      initialValues: null,
    },
  ],
};
