import React, { useEffect } from "react";
import { FeatureCollection, Point } from "geojson";
import { Position } from "../models/Position";
import { Layer, Marker, Source, useMap } from "react-map-gl";
import { Cluster } from "../hooks/useCluster";
import DonutChartMarker from "../layout/DonutChartMarker";
import { AssetService } from "../asset";

export default function AlarmLayer({
  assets,
  cluster,
}: {
  assets: FeatureCollection<Point, Position>;
  cluster: IterableIterator<Cluster>;
}) {
  const mapRef = useMap();

  useEffect(() => {
    const tmpIcons: Set<string> = new Set();
    for (const feature of assets!.features) {
      if (feature.properties.icon !== undefined) {
        tmpIcons.add(feature.properties.icon);
      }
    }
    for (const icon of Array.from(tmpIcons)) {
      if (icon !== "" && !mapRef.current?.hasImage(icon)) {
        mapRef.current?.loadImage(
          icon,
          (
            err: Error | undefined,
            image: Uint8Array | HTMLImageElement | ImageBitmap | undefined
          ) => {
            if (!err && image) mapRef.current?.addImage(icon, image);
          }
        );
      }
    }
  }, [mapRef, assets]);

  return (
    <>
      {Array.from(cluster).map((value) => (
        <Marker
          anchor="top-left"
          key={value.locatorName}
          latitude={value.latitude}
          longitude={value.longitude}
        >
          <DonutChartMarker
            assetsPerGroup={AssetService.getAssetsPerGroupMap(value.assets)}
          />
        </Marker>
      ))}
      <Source
        id={"assets"}
        type={"geojson"}
        cluster={false}
        clusterRadius={50}
        clusterMaxZoom={23}
        data={assets}
        maxzoom={24}
        clusterProperties={{
          redColor: ["+", ["case", ["==", ["get", "color"], 3], 1, 0]],
          yellowColor: ["+", ["case", ["==", ["get", "color"], 2], 1, 0]],
          clusterRadius: ["max", ["get", "radius"], 0],
        }}
      >
        <Layer
          id={"circle"}
          type={"circle"}
          source={"assets"}
          minzoom={13}
          filter={[
            "all",
            ["==", ["typeof", ["get", "radius"]], "number"],
            ["has", "color"],
          ]}
          paint={{
            "circle-radius": [
              "interpolate",
              ["exponential", 2],
              ["zoom"],
              0,
              0,
              24,
              ["get", "radius"],
            ],
            "circle-color": {
              property: "color",
              stops: [
                [1, "#5698ad"],
                [2, "#E0E632"],
                [3, "#FF6262"],
              ],
            },
            "circle-opacity": 0.5,
          }}
        />
        <Layer
          id={"circle-cluster"}
          type={"circle"}
          source={"assets"}
          minzoom={1}
          filter={["has", "point_count"]}
          paint={{
            "circle-radius": [
              "interpolate",
              ["exponential", 2],
              ["zoom"],
              0,
              0,
              24,
              ["get", "clusterRadius"],
            ],
            "circle-color": [
              "case",
              [">=", ["get", "redColor"], 1],
              "#FF6262",
              "#E0E632",
            ],
            "circle-opacity": 0.5,
          }}
        />
      </Source>
    </>
  );
}
