import { useCatalogue } from "./useCatalogue";
import { useRuleElements } from "./useRuleElements";
import { useSelector } from "react-redux";
import { getRuleInfo, getRuleSequence } from "../ruleManagerSlice";
import { useRuleFields } from "./useRuleFields";
import { useEffect, useState } from "react";
import { useSubmitFields } from "./useSubmitFields";
import { useInitialValues } from "./useInitialValues";
import { RuleApiCalls, useRuleApi } from "./useRuleApi";
import { useConstructRule } from "./useConstructRule";
import { Rule } from "../Model/Rule";
import { WriteFieldProperties } from "../Model/RuleModels";

export const useRule = (
  ruleId?: string
): [
  Rule,
  RuleApiCalls,
  (fieldValues: WriteFieldProperties) => void,
  boolean,
  boolean,
  string | null
] => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const sequence = useSelector(getRuleSequence);
  const ruleInfo = useSelector(getRuleInfo);

  const { catalogueData, catalogueLoading, catalogueError, catalogueIsError } =
    useCatalogue(ruleId);

  const { ruleElements, ruleElementLoading, ruleElementError } =
    useRuleElements(sequence);

  const { ruleFields, ruleFieldLoading, ruleFieldError } = useRuleFields(
    ruleInfo?.fieldType ?? "",
    sequence,
    catalogueData !== undefined
  );

  const submitFields = useSubmitFields(sequence, ruleInfo);
  const initValues = useInitialValues(ruleInfo?.fieldType, sequence);

  const rule = useConstructRule(
    ruleElements,
    ruleFields,
    ruleId,
    catalogueData,
    ruleInfo?.fieldType,
    initValues
  );

  useEffect(() => {
    setIsLoading(catalogueLoading || ruleFieldLoading || ruleElementLoading);
  }, [catalogueLoading, ruleFieldLoading, ruleElementLoading]);

  useEffect(() => {
    setIsError(catalogueIsError || ruleFieldError || ruleElementError);
  }, [catalogueIsError, ruleFieldError, ruleElementError]);

  useEffect(() => {
    setError(catalogueError);
  }, [catalogueError]);

  const { api } = useRuleApi(ruleId);

  return [rule, api, submitFields, isLoading, isError, error];
};
