import { useQuery } from "react-query";
import { RuleSequence, RuleStateMachine } from "../Model/RuleSequence";
import { RulesList } from "../Model/RulesList";

async function fetchRuleElements(type: RuleSequence): Promise<RulesList> {
  const res = await fetch(
    `${process.env.REACT_APP_RULESENGINE_URI!!}/catalogue/${type}`
  );
  return res.json();
}

export const useRuleElements = (type: RuleSequence) => {
  const ruleSeq = new RuleStateMachine();
  const { data, isError, isLoading, error } = useQuery(
    ["ruleElements", type],
    () => fetchRuleElements(type),
    {
      staleTime: 60 * 60 * 1000, // 1 hour
      enabled: ruleSeq.isListState(type),
    }
  );

  if (isError) {
    console.error(error);
  }

  return {
    ruleElements: data,
    ruleElementLoading: isLoading,
    ruleElementError: isError,
  };
};
