import React from "react";
import { Link } from "react-router-dom";
import { Button, ButtonProps } from "reactstrap";

export const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  to,
  ...buttonProps
}) => {
  let tag: React.ElementType<any> = "button";
  let linkProps = null;
  if (to !== undefined) {
    const isExternal = buttonProps.handleAsExternal
      ? buttonProps.handleAsExternal
      : _isExternalLink(to);
    tag = isExternal ? "a" : Link;
    linkProps = isExternal
      ? {
          target: "_blank",
          rel: "noopener noreferrer",
          href: to,
        }
      : {
          to: to,
        };
  }

  return (
    <Button {...buttonProps} {...linkProps} tag={tag}>
      {children}
    </Button>
  );
};

const _isExternalLink = (url?: string) => {
  if (url === undefined) return false;
  return /^https?:\/\//.test(url);
};

export interface LinkButtonProps extends ButtonProps {
  to?: string;
  onClick?: () => void;
  handleAsExternal?: boolean;
}

export default LinkButton;
