import { composeValidators, noScript, required } from "../../forms/validation";
import { validateBounds } from "../../shared/Forms";

import { ComponentNames } from "../Model/ComponentNames";

export const useValidation = (componentName: string, mandatory: boolean) => {
  const validations = [];
  if (componentName === ComponentNames.INPUT_FIELD) {
    validations.push(noScript);
  }

  if (componentName === ComponentNames.BOUNDARY) {
    validations.push(validateBounds);
  }

  if (mandatory) {
    validations.push(required);
  }

  return composeValidators(...validations);
};
