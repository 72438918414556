import { useEffect, useMemo, useState } from "react";
import { AssetGroup, useAssetGroupApi } from "./useAssetGroupApi";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import {
  ASSET_GROUP_NAME_EXISTS,
  ASSET_GROUP_NOT_EMPTY,
} from "../../localization";

export const useAssetGroupsStore = (
  setError: (state: string | null) => void
) => {
  const [cachedAssetGroups, setCachedAssetGroups] = useState<AssetGroup[]>([]);
  const { t } = useTranslation();

  const ERROR_ALREADY_EXISTS = {
    error: "Name already exists",
    message: t(ASSET_GROUP_NAME_EXISTS),
  };
  const ERROR_GROUP_CONTAINS_ASSETS = {
    error: "AssetGroup is not empty",
    message: t(ASSET_GROUP_NOT_EMPTY),
  };
  const {
    assetGroups,
    api: assetGroupApi,
    isError,
    loading,
    ERROR_MESSAGE,
  } = useAssetGroupApi();

  useEffect(() => {
    setError(isError ? ERROR_MESSAGE : null);
  }, [isError, setError, ERROR_MESSAGE]);

  const groups = useMemo(() => {
    return cachedAssetGroups.concat(assetGroups ?? []);
  }, [assetGroups, cachedAssetGroups]);

  const addToCache = () => {
    const createdId = uuidv4();
    setCachedAssetGroups([{ name: "", id: createdId }, ...cachedAssetGroups]);
    return createdId;
  };

  const deleteFromCache = async (assetGroupId: string) => {
    const foundAssetGroup = groups.find(
      (assetGroup) => assetGroup.id === assetGroupId
    );

    if (foundAssetGroup?.id) {
      setCachedAssetGroups(
        cachedAssetGroups.filter(
          (assetGroup) => assetGroup.id === foundAssetGroup.id
        )
      );

      const { errors } = await assetGroupApi.deleteAssetGroup(
        foundAssetGroup.id
      );
      assetGroupApi.assetGroupRefetch();

      if (errors) {
        return {
          errorMessage: errors?.message.includes(
            ERROR_GROUP_CONTAINS_ASSETS.error
          )
            ? ERROR_GROUP_CONTAINS_ASSETS.message
            : ERROR_MESSAGE,
        };
      }
    }

    return { errorMessage: null };
  };

  const createOrUpdateAssetGroup = async (assetGroupInput: AssetGroup) => {
    //remove asset from table if name is empty
    if (assetGroupInput.name === undefined) {
      setCachedAssetGroups(
        cachedAssetGroups.filter(
          (assetGroup) => assetGroup.id !== assetGroupInput.id
        )
      );
      return;
    }

    //Check if assetgroup is not cached.
    const searchForAssetGroup = assetGroups?.find(
      (assetGroup) => assetGroup.id === assetGroupInput.id
    );

    const { data, errors } = await assetGroupApi.createOrUpdateAssetGroup({
      id: searchForAssetGroup ? assetGroupInput.id : undefined,
      name: assetGroupInput.name,
    });

    if (!errors) {
      setCachedAssetGroups(
        cachedAssetGroups.filter(
          (assetGroup) => assetGroup.id !== assetGroupInput.id
        )
      );
      assetGroupApi.assetGroupRefetch();

      return { data: data.createOrUpdateAssetGroup, errorMessage: null };
    }

    return {
      data: null,
      errorMessage: errors.message.includes(ERROR_ALREADY_EXISTS.error)
        ? ERROR_ALREADY_EXISTS.message
        : ERROR_MESSAGE,
    };
  };

  const deleteIfNotFound = (id: string) => {
    const foundAssetGroup = assetGroups?.find(
      (assetGroup) => assetGroup.id === id
    );
    if (foundAssetGroup === undefined) {
      setCachedAssetGroups([
        ...cachedAssetGroups.filter((assetGroup) => assetGroup.id !== id),
      ]);
    }
  };

  const storeApi = {
    createOrUpdateAssetGroup,
    addToCache,
    deleteFromCache,
    deleteIfNotFound,
  };

  return {
    assetGroups: groups,
    loading,
    isError,
    ERROR_MESSAGE,
    storeApi,
  };
};
