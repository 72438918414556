import { GridColumns, GridRowId } from "@mui/x-data-grid";
import React, { Dispatch, SetStateAction, useState } from "react";
import {
  CustomIcon,
  CustomIconInput,
  CustomIconTag,
} from "../../models/CustomIcon";
import GridTable from "../../admin/components/GridTable";
import ModifyCustomIconDialog from "../components/ModifyCustomIconDialog";
import DeleteCustomIconDialog from "../components/DeleteCustomIconDialog";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  ACTION_SYMBOL_ADD,
  ACTION_SYMBOL_DELETE,
  ACTION_SYMBOL_EDIT,
  ACTION_TITLE_PL,
  GENERAL_CATEGORY_PL,
  GENERAL_SYMBOL_SG,
} from "../../localization";
import { makeStyles } from "@mui/styles";

/**
 * Component that contains the content of the Custom Icon implementation.
 * This component contains the main data grid of the custom icons as well as all elements to modify them.
 *
 * @param customIcons List of custom icons displayed by the main data grid.
 * @param customIconTags List of custom icon tags displayed by the main data grid and available in the form.
 * @param loading Indicates whether the content is loading.
 * @param createOrUpdateCustomIcon Function that can be invoked to create/update an icon.
 * @param deleteCustomIcon Function the can be invoked to delete an icon.
 * @param modifyIcon CustomIcon state that contains the currently modified icon.
 * @param setModifyIcon CustomIcon setState action that updates the currently modified icon.
 * @param modifyDialogShown Boolean state that indicates whether the modification dialog is shown.
 * @param setModifyDialogShown Boolean setState action that updates whether the modification dialog is shown.
 */
const CustomIconsContainer: React.FC<CustomIconsContainerProps> = ({
  customIcons,
  customIconTags,
  loading,
  createOrUpdateCustomIcon,
  deleteCustomIcon,
  modifyIcon,
  setModifyIcon,
  modifyDialogShown,
  setModifyDialogShown,
}) => {
  const [deleteDialogShown, setDeleteDialogShown] = useState<boolean>(false);
  const [deleteIcon, setDeleteIcon] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const classes = makeStyles({
    gridIcon: {
      height: "11%",
      maxWidth: "2.6rem",
    },
  })();

  /**
   * Function that creates all actions of the main data grid.
   * The data grid contains two actions:
   * - Edit: Allows the user to modify an existing custom icon
   * - Delete: Allows the user to remove an existing custom icon
   */
  const actions = () => {
    return {
      field: "actions",
      type: "actions",
      headerName: t(ACTION_TITLE_PL),
      flex: 0.2,
      cellClassName: "actions",
      headerClassName: "hideRightSeparator",
      //@ts-ignore
      getActions: ({ ...params }) => {
        return [
          <IconButton onClick={() => handleEditClick(params.id)}>
            <Edit color="secondary" />
          </IconButton>,
          <IconButton
            sx={{ padding: "0.2rem" }}
            onClick={() => handleDeleteClick(params.id)}
          >
            <Delete color="error" />
          </IconButton>,
        ];
      },
    };
  };

  /**
   * Function that is invoked when the edit action of the data grid has been clicked.
   * This function updates the currently modified icon and shows the modification dialog.
   *
   * @param id ID of the icon that is being modified.
   */
  const handleEditClick = (id: GridRowId) => {
    // @ts-ignore
    setModifyIcon(customIcons.find((i) => i.id === id));
    setModifyDialogShown(true);
  };

  /**
   * Function that is invoked when the delete action of the data grid has been clicked.
   * This function shows the deletion dialog.
   *
   * @param id ID of the icon that is being removed.
   */
  const handleDeleteClick = (id: GridRowId) => {
    setDeleteIcon(id.toString());
    setDeleteDialogShown(true);
  };

  const columns: GridColumns = [
    {
      field: "iconCode",
      headerName: t(GENERAL_SYMBOL_SG) ?? "",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      editable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <img
            className={classes.gridIcon}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(params.value)}`}
            alt="icon"
          />
        );
      },
    },
    {
      field: "tags",
      valueGetter: (params) => {
        return params.value.map((i: CustomIconTag) => i.tagName).join(", ");
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
      headerName: t(GENERAL_CATEGORY_PL) ?? "",
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      editable: false,
    },
    actions(),
  ];

  return (
    <>
      <ModifyCustomIconDialog
        title={modifyIcon ? t(ACTION_SYMBOL_EDIT) : t(ACTION_SYMBOL_ADD)}
        open={modifyDialogShown}
        loading={loading}
        tags={customIconTags}
        onCancel={() => {
          setModifyDialogShown(false);
        }}
        onAccept={(customIconInput) => {
          // Save the icon
          createOrUpdateCustomIcon(customIconInput);
          setModifyDialogShown(false);
        }}
        existingIcon={modifyIcon}
      ></ModifyCustomIconDialog>
      <DeleteCustomIconDialog
        title={t(ACTION_SYMBOL_DELETE)}
        open={deleteDialogShown}
        onAccept={() => {
          // Delete the icon
          deleteCustomIcon(deleteIcon ?? "");
          setDeleteDialogShown(false);
        }}
        onCancel={() => {
          setDeleteDialogShown(false);
        }}
      />
      <GridTable
        columns={columns}
        rows={customIcons ?? []}
        rowModels={{}}
        loading={loading}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50, 100]}
      />
    </>
  );
};

export interface CustomIconsContainerProps {
  customIcons: CustomIcon[];
  customIconTags: CustomIconTag[];
  loading: boolean;
  createOrUpdateCustomIcon: (customIconInput: CustomIconInput) => void;
  deleteCustomIcon: (id: string) => void;
  modifyIcon?: CustomIcon | null;
  setModifyIcon: Dispatch<SetStateAction<CustomIcon | null>>;
  modifyDialogShown: boolean;
  setModifyDialogShown: Dispatch<SetStateAction<boolean>>;
}

export default CustomIconsContainer;
