import { ApolloQueryResult } from "@apollo/client";
import { SubmissionErrors } from "final-form";
import _ from "lodash";
import React, { memo, useMemo } from "react";
import { AssetFormData, RangeBounds } from "../../models/assets/Asset";
import { Position } from "../../models/Position";
import AssetForm from "./AssetForm";
import { MarkerProps } from "./GeoCordsModal";
import { CustomIcon, CustomIconTag } from "../../models/CustomIcon";

const AssetTemplate: React.FC<IAssetTemplateProps> = ({
                                                        assetGroups,
                                                        availableTags,
                                                        availableUseCases,
                                                        availableCustomIcons,
                                                        availableCustomIconTags,
                                                        onAccept,
                                                        acceptButtonText,
                                                        beaconLabel,
                                                        selectedGroups,
                                                        selectedDescription,
                                                        selectedAssetName,
                                                        selectedIcon,
                                                        selectedBeacon,
                                                        selectedBuildingparts,
                                                        selectedUseCases,
                                                        selectedInventoryNumber,
                                                        selectedGeoCords,
                                                        selectedTemperatureRange,
                                                        refetchAssetData,
                                                      }) => {
  const memoizedValues: AssetFormData | undefined = useMemo(() => {
    if (selectedGroups === undefined) return undefined;
    if (selectedAssetName === undefined) return undefined;
    if (selectedUseCases === undefined) return undefined;
    if (selectedInventoryNumber === undefined) return undefined;

    const tags = selectedBeacon !== undefined ? mapIdToArray(availableTags, selectedBeacon) : [];

    return {
      assetGroups: selectedGroups,
      description: selectedDescription ?? "",
      temperatureOffset: selectedBeacon?.offset,
      name: selectedAssetName,
      inventoryNumber: selectedInventoryNumber,
      tags: tags ?? [],
      useCaseIds: selectedUseCases.map((useCase) => useCase.id),
      buildingparts: selectedBuildingparts?.map((bp) => {
        return {
          label: bp.name,
          value: bp.id,
        };
      }),
      geocords: selectedGeoCords && {
        latitude: selectedGeoCords.latitude,
        longitude: selectedGeoCords.longitude,
        levelId: selectedGeoCords.levelId,
        locationId: selectedGeoCords.locationId,
      },
      allowedTemperatureRange: selectedTemperatureRange,
      iconId: selectedIcon,
    };
  }, [
    selectedGroups,
    selectedAssetName,
    selectedBeacon,
    selectedUseCases,
    selectedInventoryNumber,
    availableTags,
    selectedDescription,
    selectedBuildingparts,
    selectedGeoCords,
    selectedTemperatureRange,
    selectedIcon,
  ]);

  const handleSubmit = async (formValues: AssetFormData) => {
    const {
      useCaseIds,
      name,
      assetGroups,
      tags,
      description,
      buildingparts,
      inventoryNumber,
      geocords,
      allowedTemperatureRange,
      icon,
      iconId,
      temperatureOffset,
    } = formValues;

    const assetGroupId: string[] = assetGroups.map((value) => value.id);
    const tagIds = tags.map((tag) => tag.id);
    const buildingPartIDExternal =
      Array.isArray(buildingparts) && buildingparts.length > 0
        ? _.last(buildingparts).value
        : null;

    return await onAccept(
      useCaseIds,
      name,
      assetGroupId,
      tagIds,
      description ?? "",
      buildingPartIDExternal,
      inventoryNumber,
      geocords,
      allowedTemperatureRange,
      icon,
      iconId,
      temperatureOffset,
    );
  };

  return (
    <AssetForm
      onSubmit={handleSubmit}
      availableAssetGroups={assetGroups}
      availableTags={availableTags}
      availableUseCases={availableUseCases}
      availableCustomIcons={availableCustomIcons}
      availableCustomIconTags={availableCustomIconTags}
      acceptButtonText={acceptButtonText}
      beaconLabel={beaconLabel}
      initialValues={memoizedValues}
      selectedBuildingparts={selectedBuildingparts}
      refetchAssetData={refetchAssetData}
    />
  );
};

const mapIdToArray: <T extends { id: string }>(
  allItems: T[],
  current: T,
) => T[] = (allItems, current) => {
  const array: any[] = [];

  const item = allItems.find((item) => item.id === current.id);
  if (item !== undefined) {
    array.push(item);
  }

  return array;
};

interface IAssetTemplateProps {
  assetGroups: { name: string; id: string }[];
  availableUseCases: { name: string; id: string }[];
  availableTags: { name: string; id: string, offset?: number; }[];
  availableCustomIcons: CustomIcon[];
  availableCustomIconTags: CustomIconTag[];
  acceptButtonText: string;
  selectedGroups?: { id: string; name: string }[];
  selectedBeacon?: { name: string; id: string, offset?: number };
  selectedBuildingparts?: { id: string; name: string }[];
  selectedDescription?: string;
  selectedAssetName?: string;
  selectedIcon?: string;
  selectedUseCases?: { name: string; id: string }[];
  selectedInventoryNumber?: string;
  selectedGeoCords?: Position;
  selectedTemperatureRange?: RangeBounds | undefined;
  beaconLabel: string;
  refetchAssetData?: () => Promise<ApolloQueryResult<any>>;

  onAccept(
    useCaseIds: string[],
    assetName: string,
    assetGroupIds: string[],
    tagId: string[],
    description: string,
    buildingPartIDExternal: string | null,
    inventoryNumber?: string,
    geocords?: MarkerProps | undefined,
    allowedTemperatureRange?: RangeBounds | undefined,
    icon?: string,
    iconId?: string,
    offset?: number,
  ): Promise<SubmissionErrors | undefined>;
}

export default memo(AssetTemplate);
