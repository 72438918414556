import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Location } from "../../models/Location";
import { rolePermission } from "../../rolePermission";
import ActionButtons from "../../shared/ActionButtons";
import { DataTable } from "../../shared/Tables/DataTable";
import { useTranslation } from "react-i18next";
import {
  ACTION_EDIT,
  ACTION_EXCEL_EXPORT,
  ACTION_TITLE_PL,
  GENERAL_AMOUNT_RECEIVERS,
  GENERAL_NAME,
} from "../../localization";

export const LocationListTable: React.FC<LocationListTableProps> = ({
  locations,
}) => {
  const match = useRouteMatch();
  const { t } = useTranslation();

  return (
    <DataTable
      columns={[
        { key: "name", label: t(GENERAL_NAME) },
        { key: "locatorCount", label: t(GENERAL_AMOUNT_RECEIVERS) },
        {
          key: "actions",
          label: t(ACTION_TITLE_PL),
          valueAccessor: (location) => (
            <ActionButtons
              size="sm"
              actions={[
                {
                  text: t(ACTION_EDIT),
                  to: `${match.url}/${location.id}/edit`,
                  role: rolePermission.LOCATION_EDIT,
                },
                {
                  text: t(ACTION_EXCEL_EXPORT),
                  to:
                    process.env.REACT_APP_REST_URI_DOWNLOAD_LOCATOR! +
                    location.id,
                  handleAsExternal: true,
                  role: rolePermission.LOCATION_READ,
                },
              ]}
            />
          ),
        },
      ]}
      rows={locations}
    />
  );
};

export interface LocationListTableProps {
  locations: Location[];
}

export default LocationListTable;
