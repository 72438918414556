import { gql } from "@apollo/client";

export const searchQuery = gql`
  query search($input: String!) {
    search(input: $input) {
      ... on SearchAsset {
        id
        assetGroupId
        name
      }
    }
  }
`;

export interface ISearchResult {
  search: {
    id: string;
    assetGroupId: string;
    name: string;
    __typename: string;
  }[];
}
