import React, { PropsWithChildren, useCallback } from "react";
import "../styles/DrilldownFilter.scss";

export const TreeContext = React.createContext<{
  selectNode?: (id: string) => void;
  expandChildren?: (id: string) => void;
}>({});

const TreeView: React.FC<TreeViewProps> = ({
  children,
  onExpand,
  onNodeSelect,
}) => {
  const selectNode = useCallback(
    (id: string) => {
      if (onNodeSelect !== undefined) {
        onNodeSelect(id);
      }
    },
    [onNodeSelect]
  );

  const expandChildren = useCallback(
    (id: string) => {
      if (onExpand !== undefined) {
        onExpand(id);
      }
    },
    [onExpand]
  );

  return (
    <TreeContext.Provider
      value={{
        selectNode,
        expandChildren,
      }}
    >
      <ul className="list-unstyled ps-0 mb-0">{children}</ul>
    </TreeContext.Provider>
  );
};

interface TreeViewProps {
  onNodeSelect?: (id: string) => void;
  onExpand?: (id: string) => void;
}

export default React.memo<PropsWithChildren<TreeViewProps>>(TreeView);
