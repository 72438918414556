import {
  ReadFieldProperties,
  RuleInput,
  WriteFieldProperties,
} from "../Model/RuleModels";
import { RuleElement } from "../Model/RuleElement";
import { RuleFields } from "../Model/RuleFields";
import { Trigger, TriggerDto } from "../Model/Trigger";
import { Condition } from "../Model/Condition";
import { Action, ActionDto } from "../Model/Action";
import { RuleState } from "../Model/RuleState";

const RulesService = {
  addRuleInfoToFields(
    ruleInfo: RuleElement | null,
    fieldValues?: ReadFieldProperties[]
  ) {
    if (ruleInfo === null || fieldValues === undefined) {
      return undefined;
    }
    const ruleField: RuleFields = {
      fieldType: ruleInfo.fieldType,
      type: ruleInfo.fieldType,
      icon: ruleInfo.icon,
      title: ruleInfo.title,
      description: ruleInfo.description,
      shortDescription: ruleInfo.shortDescription,
      disabled: ruleInfo.disabled,
      fields: fieldValues,
    };
    return ruleField;
  },

  addOrUpdateTrigger(triggerList: Trigger[] | null, updateTrigger: Trigger) {
    if (triggerList === null) {
      const newTriggerList: Trigger[] = [];
      return [...newTriggerList, updateTrigger];
    }

    const triggerFound = triggerList.find(
      (trigger: Trigger) => trigger.triggerType === updateTrigger.triggerType
    );

    if (triggerFound) {
      return triggerList.map((trigger: Trigger) => {
        if (trigger.triggerType === updateTrigger.triggerType) {
          return {
            ...updateTrigger,
            conditionParams:
              updateTrigger.conditionParams ?? trigger.conditionParams,
          };
        }
        return trigger;
      });
    }

    return [...triggerList, updateTrigger];
  },

  findCondition(triggers: Trigger[], conditionId: string) {
    for (const trigger of triggers) {
      if (trigger.conditionParams?.type === conditionId) {
        return trigger.conditionParams;
      }
    }
    return null;
  },

  //This method is only necessary as long as support for multiple triggers/conditions/actions is not possible
  filterRuleElementFields(
    triggerList: Trigger[],
    triggerInfoId: string,
    actionList: Action[],
    actionInfoId: string
  ) {
    const filteredTriggers = triggerList.filter(
      (trigger) => trigger.triggerType === triggerInfoId
    );

    const filteredActions = actionList.filter(
      (action) => action.type === actionInfoId
    );

    return {
      filteredTriggers: filteredTriggers,
      filteredActions: filteredActions,
    };
  },

  transformAction(actions: Action[], actionId: string): ActionDto[] {
    return actions.map((action) => {
      const dto: ActionDto = {
        ...action.params,
        type: actionId,
      };

      return dto;
    });
  },

  transformTriggerAndCondition(
    triggers: Trigger[],
    triggerId: string,
    conditionId: string
  ) {
    return triggers.map((trigger) => {
      const triggerParams = Object.assign(
        {},
        ...Object.values(trigger.params ?? {})
      );

      const transformedCondition = this.transformCondition(
        trigger.conditionParams,
        conditionId
      );

      const transformedTrigger: TriggerDto = {
        ...triggerParams,
        triggerType: triggerId,
        conditionParams: transformedCondition,
      };

      return transformedTrigger;
    });
  },

  transformCondition(condition?: Condition, conditionId?: string) {
    if (condition?.params === undefined) return null;

    const conditionParams = Object.assign(
      {},
      ...this.transformParams(condition.params)
    );
    return {
      type: conditionId,
      params: conditionParams,
    };
  },

  transformParams(params: WriteFieldProperties) {
    return Object.entries(params).map((param) => {
      if (typeof param[1] === "object" && !Array.isArray(param[1])) {
        return param[1];
      }
      return { [param[0]]: param[1] };
    });
  },

  transformInitValues(elements: ReadFieldProperties[]) {
    return Object.assign(
      {},
      ...elements.map((element) => {
        return { [element.id]: element.initialValues };
      })
    );
  },

  generateRuleInput(ruleState: RuleState, userName: string): RuleInput | null {
    const {
      triggers,
      triggerInfo,
      conditionInfo,
      actions,
      actionInfo,
      name,
      description,
    } = {
      ...ruleState,
    };

    if (
      triggers === null ||
      triggerInfo === null ||
      conditionInfo === null ||
      actions === null ||
      actionInfo === null
    ) {
      return null;
    }

    //This method call is only necessary as long as support for multiple triggers/conditions/actions is not possible
    const filteredElements = this.filterRuleElementFields(
      triggers,
      triggerInfo.fieldType,
      actions,
      actionInfo.fieldType
    );

    const transformedTriggerList = this.transformTriggerAndCondition(
      filteredElements.filteredTriggers,
      triggerInfo.fieldType,
      conditionInfo.fieldType
    );

    const transformedAction = this.transformAction(
      filteredElements.filteredActions,
      actionInfo.fieldType
    );

    return {
      creator: userName,
      name: name ?? "",
      description: description ?? "",
      triggers: transformedTriggerList,
      actions: transformedAction,
    };
  },
};

export default RulesService;
