import { gql } from "@apollo/client";
import {
  eddystoneFragment,
  iBeaconFragment,
} from "../../asset/queries/assetQuery";

export const GET_USE_CASE = gql`
  query GetUseCaseByName($name: String!) {
    useCase(name: $name) {
      id
      name
      icon
      availableGroups {
        name
        id
      }
    }
  }
`;

export const GET_USE_CASES = gql`
  query {
    useCases {
      id
      name
      icon
      path
    }
  }
`;

const position = `
lastPosition {
  lastSeen
  locatorName
  technicalRoomNumber
  assetName
  icon
  latitude
  locatorId
  longitude
}
`;

export const GET_FILTERED_ASSETS = (withPosition: boolean = true) => gql`
  query FilterAssetsBy(
    $filterCriteria: FilterCriteria!
    $pageCriteria: PageCriteria!
    $activeFilter: ActiveFilter
  ) {
    filterAssetsBy(
      filterCriteria: $filterCriteria
      pageCriteria: $pageCriteria
      activeFilter: $activeFilter
    ) {
      assets {
        id
        name
        assetGroups {
          id
          name
        }
        description
        technicalRoomNumber
        inventoryNumber
        ${withPosition ? position : ""}
        tags {
          name
          battery
          __typename
          ...ibeaconFields
          ...eddystoneFields
        }
      }
      totalPages
      assetCount
    }
  }
  ${eddystoneFragment}
  ${iBeaconFragment}
`;

export const GET_UPDATE_ON_POSITION_BY_IDS = gql`
  subscription positionByIds($assetIds: [ID!]!) {
    positionByIds(assetIds: $assetIds) {
      lastSeen
      locatorName
      assetId
      technicalRoomNumber
      assetName
      icon
      latitude
      locatorId
      longitude
    }
  }
`;
