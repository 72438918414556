import useGqlClient from "../../hooks/useGqlClient";
import { useMutation } from "@apollo/client";
import { DELETE_ASSETGROUP } from "../../asset/queries/assetQuery";
import { useCallback } from "react";

export const useDeleteAssetGroup = () => {
  const assetClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  const [deleteAssetGroup, { loading, error }] = useMutation(
    DELETE_ASSETGROUP,
    {
      client: assetClient,
    }
  );

  if (error) {
    console.error(error);
  }

  const deleteAssetGroupById = useCallback(
    async (id: string) => {
      try {
        const { data, errors } = await deleteAssetGroup({
          variables: { id },
        });
        return { data: data, errors: errors?.toString() };
      } catch (ex) {
        return { data: null, errors: ex };
      }
    },
    [deleteAssetGroup]
  );

  return {
    deleteAssetGroup: deleteAssetGroupById,
    assetGroupDeleteLoading: loading,
    assetGroupDeleteError: !!error,
  };
};
