import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
} from "@mui/material";
import CustomIconsContainer from "./CustomIconsContainer";
import { CustomIcon, CustomIconInput } from "../../models/CustomIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCustomIconsApi } from "../hooks/useCustomIconsApi";
import { useTranslation } from "react-i18next";
import { GENERAL_SYMBOL_PL } from "../../localization";
import { makeStyles } from "@mui/styles";

/**
 * Component that contains the admin page of the Custom Icon implementation.
 * This component contains the {@link CustomIconsContainer} component as well as an add button.
 */
const CustomIconsPage = () => {
  const ICON_ADD_BUTTON_COLOR = "#198754";

  const [modifyIcon, setModifyIcon] = useState<CustomIcon | null>(null);
  const [modifyDialogShown, setModifyDialogShown] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const { t } = useTranslation();

  const {
    customIcons,
    customIconTags,
    customIconsLoading,
    customIconsErrorMessage,
    customIconsAPI,
  } = useCustomIconsApi(setShowError);

  const useStyles = makeStyles({
    createButton: {
      backgroundColor: ICON_ADD_BUTTON_COLOR,
      marginLeft: "auto",
      minWidth: "3rem",
      "&:hover": {
        background: ICON_ADD_BUTTON_COLOR,
        opacity: 0.9,
      },
    },
  });

  const classes = useStyles();

  /**
   * Function that is invoked when the create button of the layout has been pressed.
   * This function updates the currently modified icon and shows the modification dialog.
   */
  const handleCreateClick = () => {
    setModifyIcon(null);
    setModifyDialogShown(true);
  };

  /**
   * Function that is invoked to create or update a {@link CustomIcon}.
   * This function invokes the API calls to create/update the icon and refetch the icons and tags.
   *
   * @param customIconInput {@link CustomIconInput} that contains the form data.
   */
  const handleCreateOrUpdateCustomIcon = (customIconInput: CustomIconInput) => {
    customIconsAPI
      .createOrUpdateCustomIcon(customIconInput)
      .then((_queryResult) =>
        customIconsAPI
          .customIconsRefetch()
          .then((_iconsResult) => customIconsAPI.customTagsRefetch())
      );
  };

  /**
   * Function that is invoked to delete a {@link CustomIcon},
   * This function invokes the API calls to delete the icon and refetch the icons and tags.
   *
   * @param id ID of the icon that should be deleted.
   */
  const handleDeleteCustomIcon = (id: string) => {
    customIconsAPI
      .deleteCustomIcon(id)
      .then((_queryResult) =>
        customIconsAPI
          .customIconsRefetch()
          .then((_iconsResult) => customIconsAPI.customTagsRefetch())
      );
  };

  /**
   * Function that renders the header of the admin layout.
   * This header contains the main title as well as the add button for creating new custom icons.
   */
  const renderHeader = () => {
    return (
      <>
        <Box
          sx={{
            "& .MuiButton-root.Mui-disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.26);",
            },
          }}
          display="flex"
          justifyContent="center"
        >
          <span>{t(GENERAL_SYMBOL_PL)}</span>
          <Button
            size="small"
            className={classes.createButton}
            onClick={handleCreateClick}
            disabled={showError || customIconsLoading}
          >
            <FontAwesomeIcon color="white" icon="plus" />
          </Button>
        </Box>
        <Box position="relative"></Box>
      </>
    );
  };

  return (
    <>
      <div>
        <Collapse in={showError}>
          <Alert
            variant="outlined"
            severity="error"
            sx={{ marginBottom: "1rem" }}
            onClose={() => setShowError(false)}
          >
            {customIconsErrorMessage}
          </Alert>
        </Collapse>

        <Card>
          <Box position="relative"></Box>
          <CardHeader title={renderHeader()} />
          <CardContent style={{ padding: 0 }}>
            <Box display="flex" padding={0} width="100%">
              <CustomIconsContainer
                customIcons={customIcons}
                customIconTags={customIconTags}
                loading={customIconsLoading}
                createOrUpdateCustomIcon={handleCreateOrUpdateCustomIcon}
                deleteCustomIcon={handleDeleteCustomIcon}
                modifyIcon={modifyIcon}
                setModifyIcon={setModifyIcon}
                modifyDialogShown={modifyDialogShown}
                setModifyDialogShown={setModifyDialogShown}
              />
            </Box>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default CustomIconsPage;
