import React from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import "./DataTable.scss";
import { useTranslation } from "react-i18next";
import { INFO_NO_ENTRIES } from "../../localization";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

export function DataTable<T extends { id: string }>({
  columns,
  rows,
  additionalHeader,
  styles,
  emptyTable,
}: DataTableProps<T>) {
  const { t } = useTranslation();

  return (
    <Card className="DataTable">
      {additionalHeader && <CardHeader>{additionalHeader}</CardHeader>}
      <CardBody>
        <Table striped>
          <TableHeader columns={columns} />
          <tbody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                item={row}
                columns={columns}
                styles={styles}
              />
            ))}
          </tbody>
        </Table>
        {rows.length <= 0 && (
          <p className="text-muted text-center pt-3">
            {emptyTable ? emptyTable : t(INFO_NO_ENTRIES)}
          </p>
        )}
      </CardBody>
    </Card>
  );
}

export interface DataTableProps<T> {
  columns: DataTableColum<T>[];
  rows: T[];
  additionalHeader?: React.ReactNode;
  styles?: DataTableStyleProps<T>;
  emptyTable?: string;
}

export interface DataTableColum<T> {
  classNames?: string[];
  hidden?: boolean;
  key: keyof T | string;
  label: React.ReactNode;
  missingCellOverride?: React.ReactNode;
  onClick?: () => void;
  valueAccessor?: (item: T) => React.ReactNode;
  valueFormatter?: (value: any) => React.ReactNode;
}

export interface DataTableStyleProps<T> {
  tableRowColor?: (item: T) => string;
}
