import { useHistory, useLocation } from "react-router-dom";
import { Filters } from "../../models/Filters";
import SortCategory from "../../models/SortCategory";
import ChronoUnit from "../../models/ChronoUnit";
import { ActiveFilter } from "../../models/ActiveFilter";
import { PageSize } from "../../models/PageSize";
import { Order } from "../../models/Order";
import { PageCriteria } from "../../models/PageCriteria";
import { FilterCriteria } from "../../models/FilterCriteria";
import { ActiveAssets, ActiveStatus } from "../../models/ActiveStatus";

export default function useFilterData() {
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const history = useHistory();

  function _setHistoryParams(filter?: Filters) {
    if (
      filter === Filters.BUILDINGPARTID ||
      filter === Filters.GROUPFILTERIDS
    ) {
      searchParams.set(Filters.PAGE, "0");
      searchParams.set(Filters.PAGESIZE, PageSize.TEN);
      searchParams.set(Filters.DIRECTION, Order.DESC);
      searchParams.set(Filters.SORT_BY, SortCategory.TAG_NAME);
    }
    history.replace({ pathname, search: searchParams.toString() });
  }

  function setGroupFilter(groupIds?: string[]) {
    if (groupIds !== undefined) {
      searchParams.delete(Filters.GROUPFILTERIDS);
      for (const groupId of groupIds) {
        searchParams.append(Filters.GROUPFILTERIDS, groupId);
      }
    } else {
      searchParams.delete(Filters.GROUPFILTERIDS);
    }
    _setHistoryParams(Filters.GROUPFILTERIDS);
  }

  function setBuildingPart(buildingPartIDExternal: string) {
    const buildingPart = searchParams.get(Filters.BUILDINGPARTID);
    if (
      buildingPartIDExternal !== undefined &&
      buildingPartIDExternal !== buildingPart
    ) {
      searchParams.set(Filters.BUILDINGPARTID, buildingPartIDExternal);
    } else {
      searchParams.delete(Filters.BUILDINGPARTID);
    }
    _setHistoryParams(Filters.BUILDINGPARTID);
  }

  function setActiveStatus(activeAssets?: ActiveAssets) {
    if (activeAssets === undefined) {
      searchParams.delete(Filters.ACTIVE_STATUS);
      searchParams.delete(Filters.UNIT);
      searchParams.delete(Filters.INTERVAL);
    } else {
      searchParams.set(
        Filters.ACTIVE_STATUS,
        ActiveStatus[activeAssets.status]
      );
      searchParams.set(Filters.UNIT, activeAssets.period.unit);
      searchParams.set(
        Filters.INTERVAL,
        activeAssets.period.interval.toString()
      );
    }
    _setHistoryParams(Filters.ACTIVE_STATUS);
    _setHistoryParams(Filters.UNIT);
    _setHistoryParams(Filters.INTERVAL);
  }

  function setPagination(pageCriteria?: PageCriteria) {
    if (pageCriteria !== undefined) {
      const page = searchParams.get(Filters.PAGE);
      const pageSize = searchParams.get(Filters.PAGESIZE);
      const sortBy = searchParams.get(Filters.SORT_BY);
      const orderBy = searchParams.get(Filters.DIRECTION);
      if (page !== pageCriteria.page.toString()) {
        searchParams.set(Filters.PAGE, pageCriteria.page.toString());
      }
      if (pageSize !== pageCriteria.pageSize) {
        searchParams.set(Filters.PAGESIZE, pageCriteria.pageSize);
      }
      if (sortBy !== pageCriteria.sortBy) {
        searchParams.set(Filters.SORT_BY, pageCriteria.sortBy);
      }
      if (orderBy !== pageCriteria.direction) {
        searchParams.set(Filters.DIRECTION, pageCriteria.direction);
      }
    }
    _setHistoryParams();
  }

  function getFilterData(): FilterData {
    const buildingPartID = searchParams.get(Filters.BUILDINGPARTID);
    const groupIds = searchParams.getAll(Filters.GROUPFILTERIDS);
    const page = parseInt(searchParams.get(Filters.PAGE) ?? "0");
    const pageSize =
      (searchParams.get(Filters.PAGESIZE) as PageSize) ?? PageSize.TEN;
    const sortBy = searchParams.get(Filters.SORT_BY) ?? SortCategory.TAG_NAME;
    const direction =
      (searchParams.get(Filters.DIRECTION) as Order) ?? Order.DESC;
    const activeStatus = searchParams.get(Filters.ACTIVE_STATUS);
    const periodInterval = searchParams.get(Filters.INTERVAL);
    const periodUnit = searchParams.get(Filters.UNIT);

    return {
      activeFilter:
        activeStatus === null || periodUnit === null || periodInterval === null
          ? undefined
          : {
              status: ActiveStatus[(activeStatus as unknown) as ActiveStatus],
              interval: Number(periodInterval),
              intervalTimeUnit:
                ChronoUnit[(periodUnit as unknown) as ChronoUnit],
            },
      filterCriteria: {
        groupIds: groupIds.length !== 0 ? groupIds : null,
        buildingPartIDExternal:
          buildingPartID !== null ? buildingPartID : undefined,
      },
      pageCriteria: { page, pageSize, sortBy, direction },
    };
  }

  return {
    filterData: getFilterData(),
    setGroupFilter,
    setBuildingPart,
    setPagination,
    setActiveStatus,
  };
}

interface FilterData {
  filterCriteria?: FilterCriteria;
  pageCriteria: PageCriteria;
  activeFilter?: ActiveFilter;
}
