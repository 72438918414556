import React, { useMemo } from "react";
import "./style/AssetMapMarker.scss";

const DonutChartMarker: React.FC<IDonutChartMarkerProps> = ({
  assetsPerGroup,
}) => {
  const { offsets, total } = useMemo(() => {
    const offsets: number[] = [0];
    for (const assetPerGroup of Array.from(assetsPerGroup.values())) {
      offsets.push(offsets[offsets.length - 1] + assetPerGroup.count);
    }

    const total = Array.from(assetsPerGroup.values()).reduce(
      (a, b) => a + b.count,
      0
    );

    return { offsets, total };
  }, [assetsPerGroup]);

  const createDonutChart = () => {
    const scaleFaktor = 1.2;
    const outerRadius =
      (total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18) *
      scaleFaktor;
    const innerRadius = Math.round(outerRadius * 0.6);
    const diameter = outerRadius * 2;

    return (
      <div>
        <svg
          width={diameter}
          height={diameter}
          className="d-block me-5 mb-5"
          viewBox={`0 0 ${diameter} ${diameter}`}
          textAnchor="middle"
          style={{
            marginLeft: `-${diameter / 2}px`,
            marginTop: `-${diameter / 2}px`,
          }}
        >
          {Array.from(assetsPerGroup).map((numberOfAssetsPerGroup, index) => {
            return donutSegment(
              offsets[index] / total,
              (offsets[index] + numberOfAssetsPerGroup[1].count) / total,
              outerRadius,
              innerRadius,
              index,
              numberOfAssetsPerGroup[1].color
            );
          })}
          <circle
            cx={outerRadius}
            cy={outerRadius}
            r={innerRadius}
            fill="white"
          />
          <text
            dominantBaseline="central"
            transform={`translate(${outerRadius}, ${outerRadius})`}
          >
            {total}
          </text>
        </svg>
      </div>
    );
  };

  const donutSegment = (
    start: number,
    end: number,
    outerRadius: number,
    innerRadius: number,
    index: number,
    color?: string
  ) => {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0),
      y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
      y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    return (
      <path
        key={index}
        d={`M ${outerRadius + innerRadius * x0} ${
          outerRadius + innerRadius * y0
        } L ${outerRadius + outerRadius * x0} ${
          outerRadius + outerRadius * y0
        } A ${outerRadius} ${outerRadius} 0 ${largeArc} 1 ${
          outerRadius + outerRadius * x1
        } ${outerRadius + outerRadius * y1} L ${
          outerRadius + innerRadius * x1
        } ${
          outerRadius + innerRadius * y1
        } A ${innerRadius} ${innerRadius} 0 ${largeArc} 0 ${
          outerRadius + innerRadius * x0
        } ${outerRadius + innerRadius * y0}`}
        fill={color}
      />
    );
  };

  return createDonutChart();
};

export interface IDonutChartMarkerProps {
  assetsPerGroup: Map<string, { count: number; color?: string }>;
}

export default DonutChartMarker;
