import { useMutation } from "react-query";
import { useKeycloakToken } from "../../hooks/useKeycloakToken";

async function callDeleteRuleApi(args: {
  id?: string;
  token?: string;
}): Promise<void> {
  if (args.id === undefined) {
    return;
  }

  await fetch(`${process.env.REACT_APP_RULESENGINE_URI!!}/rule/${args.id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${args.token}`,
    },
  });
}
export const useRuleDelete = () => {
  const token = useKeycloakToken();

  const { mutate, error, isError, isLoading, isSuccess } =
    useMutation(callDeleteRuleApi);

  if (error) {
    console.error(error);
  }

  function deleteRule(id?: string) {
    mutate({ id, token });
  }

  return {
    deleteRule: deleteRule,
    deleteError: isError,
    deleteLoading: isLoading,
    deleteSuccess: isSuccess,
  };
};
