import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./TemplateEditor.css";
import { FieldRenderProps } from "react-final-form";
import { FieldProps } from "../../rules-engine/components/RulesFormGenerator";
import "tinymce/plugins/link";
import { AutocompleterContents } from "../../../public/tinymce/tinymce";
import { useTranslation } from "react-i18next";

type Prop = FieldRenderProps<string[], any>;

function TemplateEditor({ input, ...rest }: Prop) {
  const { i18n } = useTranslation();
  const START_TRIGGER = "$";
  const buildContentItem = (item: SelectableVariable) => {
    return {
      type: "cardmenuitem",
      value: item.value,
      label: item.label,
      items: [
        {
          type: "cardcontainer",
          direction: "vertical",
          items: [
            {
              type: "cardtext",
              text: item.value,
            },
          ],
        },
      ],
    };
  };
  const buildSelectableVariableToolbar = () => {
    return rest.params.options
      ?.map((v: SelectableVariable) => v.label.replaceAll(" ", "&nbsp"))
      .join(" | ");
  };

  return (
    <div className="w-100">
      <Editor
        initialValue={rest.initialValues?.template}
        onEditorChange={(value) => {
          input.onChange(value);
        }}
        onSaveContent={(value: any) => input.onChange(value.content)}
        tinymceScriptSrc="%PUBLIC_URL%/tinymce/tinymce.min.js"
        init={{
          skin: false,
          branding: false,
          menubar: false,
          language: i18n.resolvedLanguage,
          nonbreaking_force_tab: true,
          no_newline_selector: "true",
          content_style: ".mce-offscreen-selection { display: none; }",
          plugins: ["link"],
          relative_urls: false,
          remove_script_host: false,
          link_context_toolbar: true,
          setup: (editor) => {
            const onAction = function (
              autocompleteApi: any,
              rng: any,
              value: any
            ) {
              editor.selection.setRng(rng);
              editor.insertContent(value);
              autocompleteApi.hide();
            };

            rest.params.options?.forEach((v: SelectableVariable) => {
              editor.ui.registry.addButton(v.label.replaceAll(" ", "&nbsp"), {
                text: v.label.replaceAll(" ", "&nbsp"),
                onAction: () => editor.insertContent(v.value),
              });
            });
            editor.ui.registry.addAutocompleter(input.name, {
              ch: START_TRIGGER,
              minChars: 0,
              columns: "auto",
              onAction: onAction,
              fetch: (pattern) => {
                return new Promise((resolve) => {
                  const results: AutocompleterContents[] = rest.params.options
                    ?.filter((option: SelectableVariable) =>
                      option.value.includes(pattern)
                    )
                    .map((option: SelectableVariable) =>
                      buildContentItem(option)
                    );
                  resolve(results);
                });
              },
            });
          },
          toolbar1:
            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link unlink",
          toolbar2: buildSelectableVariableToolbar(),
        }}
      />
    </div>
  );
}

export default TemplateEditor;

export interface TemplateEditorFieldProps extends FieldProps {
  options?: SelectableVariable[];
}

interface SelectableVariable {
  label: string;
  value: string;
}
