import { ApolloError } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, RouteComponentProps, useLocation } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { useKeycloak } from "../../auth";
import { useViewStateByLocatorId } from "../../location/hooks/useViewStateByLocatorId";
import { rolePermission } from "../../rolePermission";
import { ErrorCodes, ROUTES, SecureRouteProps } from "../../routes";
import { Loading } from "../../shared";
import LinkButton from "../../shared/LinkButton";
import AssetMasterData from "../components/AssetMasterData";
import useAsset from "../hooks/useAsset";
import AssetService from "../services/AssetService";
import { IAsset } from "../../models/assets/Asset";
import AssetTelemetryDetail from "../../useCase/components/AssetTelemetryDetail";
import SingleAssetMap from "../maps/SingleAssetMap";
import { useLocationById } from "../../location/hooks/useLocationById";
import { useTranslation } from "react-i18next";
import {
  ACTION_SHARE,
  ASSET_ACTION_EDIT,
  ASSET_DETAIL_VIEW,
  ASSET_ERROR_NOT_FOUND,
  ERROR_UNKNOWN,
  GENERAL_FLOOR,
  GENERAL_RESIDENCE_HISTORY,
} from "../../localization";

type AssetDetailProps = RouteComponentProps<{ id: string }> & {
  routes: Record<string, SecureRouteProps>;
};

export function checkPermission(error: ApolloError | undefined) {
  if (!error) return null;

  if (error.graphQLErrors[0].extensions?.code === ErrorCodes.NOT_AUTHORIZED) {
    return <Redirect to={ROUTES.home.childRoutes.forbidden.path}/>;
  } else return null;
}

const AssetDetail: React.FC<AssetDetailProps> = (props) => {
  const keycloak = useKeycloak();
  const browserLocation = useLocation();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const {
    data: asset,
    loading: assetLoading,
    error: assetError,
    refetch,
  } = useAsset(props.match.params.id);
  const EDIT_PLACEHOLDER = "/edit";
  const EDDYSTONE_PLACEHOLDER = "EddystoneBeacon";

  const { viewState, viewStateError } = useViewStateByLocatorId(
    asset?.lastPosition?.locatorId
  );

  const {locationData, locationLoading, locationError} = useLocationById(
    asset?.lastPosition?.locationId
  );

  const tagMac = asset
    ? AssetService.getDistinctTagMacsFromAssets([asset])[0]
    : undefined;

  const temperatureRange: {
    lower: number | null;
    upper: number | null;
  } = asset?.allowedTemperatureRange ?? {lower: null, upper: null};

  const editRole =
    keycloak.hasResourceRole(rolePermission.ASSET_TRACKING_ASSET_EDIT) ||
    keycloak.hasResourceRole(rolePermission.PHARMACY_ASSET_EDIT) ||
    keycloak.hasResourceRole(
      rolePermission.TEMPERATURE_MONITORING_ASSET_EDIT
    ) ||
    keycloak.hasResourceRole(
      rolePermission.TEMPERATURE_MONITORING_WARD_ASSET_EDIT
    );

  useEffect(() => {
    setLoading(assetLoading || locationLoading);
  }, [assetLoading, locationLoading]);

  useEffect(() => {
    setHasError(
      assetError !== undefined ||
      locationError !== undefined ||
      (viewStateError !== undefined &&
        asset?.technicalRoomNumber === undefined)
    );
  }, [asset?.technicalRoomNumber, assetError, locationError, viewStateError]);

  useEffect(() => {
    if (asset?.tags[0]?.__typename !== "EddystoneBeacon") {
      setLoading(true);
      refetch();
      setLoading(false);
    }
  }, [asset?.tags, browserLocation.pathname, refetch]);

  const getLocationName = (asset: IAsset) => {
    if (asset.lastPosition?.locatorName && asset.lastPosition.levelName) {
      return `${asset.lastPosition.locatorName} - ${t(GENERAL_FLOOR)}: ${
        asset.lastPosition.levelName
      }`;
    }
    return asset.technicalRoomNumber;
  };

  const level = useMemo(() => {
    if (locationData) {
      return locationData.location?.availableLevels.find(
        (lvl) => lvl.id === asset?.lastPosition?.levelId
      );
    }
  }, [asset?.lastPosition?.levelId, locationData]);

  const isFixed = asset?.tags[0]?.__typename === EDDYSTONE_PLACEHOLDER;

  const handleShare = () => {
    const shareData = {
      title: t(ACTION_SHARE) + ": " + t(ASSET_DETAIL_VIEW),
      text: asset?.name,
      url: browserLocation.pathname,
    };
    if (navigator.share) {
      navigator.share(shareData).catch((err) => {
        console.error(err);
      });
    }
  };

  const _renderContent = () => {
    if (asset && isFixed) {
      return (
        <>
          <AssetTelemetryDetail
            tagMac={tagMac}
            temperatureRange={temperatureRange}
            assetName={asset.name}
          />
          {asset.lastPosition && (
            <SingleAssetMap
              asset={AssetService.mapPositionToFeature({
                ...asset.lastPosition,
                layerId: level?.layerId,
                levelName: level?.name ?? asset.lastPosition.levelName,
              })}
              viewState={
                asset.lastPosition.locatorId
                  ? viewState
                  : { ...asset.lastPosition, zoom: 20 }
              }
              locationName={getLocationName(asset)}
            />
          )}
        </>
      );
    }
    return (
      <>
        {asset?.lastPosition && viewState && (
          <SingleAssetMap
            asset={AssetService.mapPositionToFeature({
              ...asset.lastPosition,
              layerId: asset.lastPosition.layerId,
            })}
            viewState={viewState}
            locationName={getLocationName(asset)}
          />
        )}
      </>
    );
  };

  return (
    <>
      {loading && <Loading/>}
      {checkPermission(assetError)}
      {hasError && <Alert color="danger">{t(ERROR_UNKNOWN)}</Alert>}
      {!asset && <Alert color="danger">{t(ASSET_ERROR_NOT_FOUND)}</Alert>}
      {!loading && asset && (
        <>
          <div className="d-flex flex-row-reverse gap-2">
            <Button onClick={handleShare} disabled={!navigator.share} size="sm">
              {t(ACTION_SHARE)}
            </Button>
            {editRole && (
              <LinkButton
                size="sm"
                color="primary"
                to={props.match.url + EDIT_PLACEHOLDER}
              >
                {t(ASSET_ACTION_EDIT)}
              </LinkButton>
            )}
            {!isFixed && (
              <LinkButton
                size="sm"
                color="primary"
                to={props.match.url + "/history"}
              >
                {t(GENERAL_RESIDENCE_HISTORY)}
              </LinkButton>
            )}
          </div>
          <AssetMasterData asset={asset}/>
          {_renderContent()}
        </>
      )}
    </>
  );
};

export default AssetDetail;
