import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./SortIndicator.scss";

export const SortIndicator: React.FC<SortIndicatorProps> = ({
  children,
  descending,
  show,
}) => {
  const _renderIcon = () => {
    const classNames = ["ms-1", "icon"];
    if (show) {
      classNames.push("show");
    }

    return (
      <FontAwesomeIcon
        className={classNames.join(" ")}
        icon={descending ? "chevron-down" : "chevron-up"}
      />
    );
  };

  return (
    <span className="SortIndicator">
      {children}
      {_renderIcon()}
    </span>
  );
};

interface SortIndicatorProps {
  descending: boolean;
  show: boolean;
}

export default SortIndicator;
