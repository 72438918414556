import AssetGroupContainer from "../components/AssetGroupContainer";
import { useAssetGroupsStore } from "../hooks/useAssetGroupsStore";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { GridRowModes, GridRowModesModel } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridRowModesModelProps } from "@mui/x-data-grid/models/api/gridEditingApi";
import { useTranslation } from "react-i18next";
import { ASSET_GROUP_TITLE_PL } from "../../localization";
import { makeStyles } from "@mui/styles";

const AssetGroupPage = () => {
  const { t } = useTranslation();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [error, setError] = useState<string | null>(null);

  const {
    assetGroups: cachedAssetGroups,
    storeApi: assetGroupStore,
    loading,
  } = useAssetGroupsStore(setError);

  const theme: any = useTheme();

  const useStyles = makeStyles({
    createButton: {
      backgroundColor: theme.create.button,
      marginLeft: "auto",
      minWidth: "3rem",
      "&:hover": {
        background: theme.create.button,
        opacity: "0.9",
      },
    },
  });

  const classes = useStyles();

  const handleCreateClick = () => {
    const createdId = assetGroupStore.addToCache();
    setRowModesModel({
      ...rowModesModel,
      [createdId]: { mode: GridRowModes.Edit },
    });
  };

  const renderHeader = () => {
    const gridList: GridRowModesModelProps[] = Object.values(rowModesModel);
    const rowsInEditMode = gridList.filter(
      (model: GridRowModesModelProps) => model.mode === GridRowModes.Edit
    );
    return (
      <>
        <Box
          sx={{
            "& .MuiButton-root.Mui-disabled": {
              backgroundColor: theme.disableColor,
            },
          }}
          display={"flex"}
          justifyContent={"center"}
        >
          <span>{t(ASSET_GROUP_TITLE_PL)}</span>
          <Button
            disabled={error !== null || rowsInEditMode.length > 0}
            size={"small"}
            className={classes.createButton}
            onClick={handleCreateClick}
          >
            <FontAwesomeIcon color={"white"} icon="plus" />
          </Button>
        </Box>
      </>
    );
  };

  return (
    <>
      <Collapse in={error !== null}>
        <Alert
          variant="outlined"
          severity="error"
          sx={{ marginBottom: "1rem" }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      </Collapse>
      <Card>
        <Box position={"relative"}></Box>
        <CardHeader title={renderHeader()} />
        <CardContent style={{ padding: 0 }}>
          <Box display={"flex"} padding={0} width={"100%"}>
            <AssetGroupContainer
              assetGroups={cachedAssetGroups}
              assetGroupApi={assetGroupStore}
              loading={loading}
              rowModesModel={rowModesModel}
              setRowModesModel={setRowModesModel}
              setError={(error) => setError(error)}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default AssetGroupPage;
