import bbox from "@turf/bbox";
import { Feature, FeatureCollection, Point } from "geojson";
import {
  AssetPage,
  IAsset,
  instanceOfEddystone,
  instanceOfIBeacon,
} from "../../models/assets/Asset";
import { FilteredAssetsPosition } from "../../models/FilteredAssetsPosition";
import { Position } from "../../models/Position";
import { Telemetry } from "../../models/Telemetry";

// Refactor to be context provider for use in HOOKS
const AssetService = {
  mapPositionToFeature<T extends Position>(position: T) {
    let icon = position.icon ? this.validateIcon(position.icon) : "";
    let pos = { ...position, icon: icon };
    const feature: Feature<Point, T> = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [pos.longitude, pos.latitude],
      },
      properties: pos,
    };

    return feature;
  },

  mapPositionsToFeatureCollection<T extends Position>(positions: T[]) {
    const featureCollection: FeatureCollection<Point, T> = {
      type: "FeatureCollection",
      features: positions.map((position) =>
        this.mapPositionToFeature(position)
      ),
    };
    const boundingBox = bbox(featureCollection);
    const [minLng, minLat, maxLng, maxLat] = boundingBox;
    if (
      !(
        minLng === Infinity ||
        minLat === Infinity ||
        maxLng === -Infinity ||
        maxLat === -Infinity
      )
    ) {
      featureCollection.bbox = boundingBox;
    }

    return featureCollection;
  },
  mapTelemetryToAssets(
    assetPage: AssetPage,
    telemetries?: Telemetry[]
  ): AssetPage {
    if (!telemetries) return assetPage;
    const telemetryToAssets = assetPage.assets.map((asset) => {
      const macs = asset.tags.map((tag) => {
        if (instanceOfIBeacon(tag) || instanceOfEddystone(tag)) {
          return tag.mac;
        }

        return null;
      });

      const telemetry = telemetries.find((tlm) =>
        macs.some((mac) => mac?.toUpperCase() === tlm.id.toUpperCase())
      );

      return {
        ...asset,
        telemetry,
      };
    });

    return { ...assetPage, assets: [...telemetryToAssets] };
  },

  getDistinctTagMacsFromAssets(assets: IAsset[]): string[] {
    let tagMacs: Set<string> = new Set([]);
    assets.forEach((asset) => {
      asset.tags.forEach((tag) => {
        if (instanceOfIBeacon(tag) || instanceOfEddystone(tag)) {
          tagMacs.add(tag.mac);
        }
      });
    });
    return Array.from(tagMacs);
  },

  mapLocationToAssets(
    assets: IAsset[],
    positions: (FilteredAssetsPosition | null)[]
  ): IAsset[] {
    return assets.map((asset) => {
      const position = positions.find(
        (position) => position?.assetId === asset.id
      );

      return {
        ...asset,
        lastLocation: position?.lastPosition?.locatorName ?? null,
        lastSeen: position?.lastPosition?.lastSeen ?? null,
      };
    });
  },

  _mapLocationToAssets(assets: IAsset[]): IAsset[] {
    return assets.map((asset) => {
      return {
        ...asset,
        lastLocation: asset.lastPosition?.locatorName ?? null,
        lastSeen: asset.lastPosition?.lastSeen ?? null,
      };
    });
  },

  validateIcon(icon?: string) {
    if (icon === null || icon === undefined) {
      return "";
    } else {
      if (!icon.match(new RegExp(/([/|.\w|\s|-])*\.(?:jpg|gif|png)/))) {
        return "";
      } else {
        return icon.startsWith("/") ? icon : `/${icon}`;
      }
    }
  },

  getAssetsPerGroupMap(
    assets: { assetGroupName: string; assetGroupColor?: string }[]
  ) {
    return assets.reduce(
      (arr, obj) =>
        arr.set(obj.assetGroupName, {
          count: (arr.get(obj.assetGroupName)?.count || 0) + 1,
          color: obj.assetGroupColor,
        }),
      new Map<string, { count: number; color?: string }>()
    );
  },
};

export default AssetService;
