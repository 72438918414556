import { Card, CardBody, CardHeader } from "reactstrap";
import { HeatmapColors } from "./HeatmapColors";
import "./HeatmapLegend.scss";
import { GENERAL_DURATION_IN_PERCENT } from "../../localization";
import { useTranslation } from "react-i18next";

export default function HeatmapLegend() {
  const { t } = useTranslation();

  const legend: HeatmapLegendProps = {
    bounds: [
      {
        key: HeatmapColors.LIGHTGREEN,
        value: "0 - 10",
      },
      {
        key: HeatmapColors.GREEN,
        value: "11 - 30",
      },
      {
        key: HeatmapColors.YELLOW,
        value: "31 - 50",
      },
      {
        key: HeatmapColors.ORANGE,
        value: "51 - 70",
      },
      {
        key: HeatmapColors.RED,
        value: "71 - 100",
      },
    ],
  };

  return (
    <Card className="Legend flex-column shadow">
      <CardHeader>{t(GENERAL_DURATION_IN_PERCENT)}</CardHeader>
      <CardBody>
        <div>
          {legend.bounds.map((bound, index) => {
            return (
              <div key={index}>
                <span style={{ backgroundColor: bound.key }}></span>
                {bound.value}
              </div>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
}

interface HeatmapLegendProps {
  bounds: HeatmapBound[];
}
interface HeatmapBound {
  key: string;
  value: string;
}
