import React, { useEffect, useState } from "react";
import {
  Button,
  Modal as ModalReactstrap,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { ACTION_CANCEL } from "../localization";

const Modal: React.FC<IModalProps> = ({
  disableFooter,
  header,
  body,
  acceptButtonText,
  acceptButtonColor,
  show,
  cancel,
  cancelButtonColor,
  onConfirm,
  onCancel,
  onClose,
  children,
  size,
  onCloseButton,
}) => {
  const [modal, setModal] = useState(show);
  const { t } = useTranslation();

  useEffect(() => {
    setModal(show);
  }, [show]);

  return (
    <>
      <ModalReactstrap size={size} isOpen={modal}>
        <div className="modal-header">
          <h5 className="modal-title d-inline-flex">{header}</h5>
          {onCloseButton && (
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            />
          )}
        </div>
        <ModalBody>{children ?? body}</ModalBody>
        {!disableFooter && (
          <ModalFooter>
            <Button color={acceptButtonColor || "primary"} onClick={onConfirm}>
              {acceptButtonText}
            </Button>
            {cancel && (
              <Button
                color={cancelButtonColor || "secondary"}
                onClick={onCancel}
              >
                {t(ACTION_CANCEL)}
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalReactstrap>
    </>
  );
};

interface IModalProps {
  header: string;
  body?: string;
  acceptButtonText: string;
  size?: string;
  show: boolean;
  cancel?: boolean;
  disableFooter?: boolean;
  onCloseButton?: boolean;
  onConfirm?(): void;
  onCancel?(): void;
  onClose?(): void;
  children?: React.ReactNode;
  acceptButtonColor?: string;
  cancelButtonColor?: string;
}

export default Modal;
