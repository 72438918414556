import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Factory, FactoryFields } from "../../forms";
import { TransformedNode } from "../../shared/drilldown-filter/DrilldownFilter";
import InformationButton from "../../shared/InformationButton";
import "./RulesFormGenerator.scss";
import { useTranslation } from "react-i18next";

export default function RulesFormGenerator({
  formItems,
  submit,
  initialValues,
  submitButtonText,
  disableSubmit = false,
}: RuleFormProps) {
  const { t } = useTranslation();
  const isMandatory = useMemo(() => {
    return formItems?.some((field: FieldProps) => field.mandatory);
  }, [formItems]);
  return (
    <Form
      onSubmit={(values) => {
        submit(values);
      }}
      initialValues={initialValues ?? {}}
    >
      {({ handleSubmit, form, hasValidationErrors }) => (
        <form
          name="rulesForm"
          onSubmit={async (event) => {
            handleSubmit(event);
            form.reset();
          }}
        >
          {formItems.map((ruleField: FieldProps) => (
            <div key={ruleField.id} className="mb-3">
              <FormGroup>
                <Row>
                  <Col className="rule-header" sm="2">
                    <span>
                      {ruleField.label !== "" ? `${t(ruleField.label)} :` : ""}
                    </span>
                    <span className="ms-2 me-2">
                      {ruleField.mandatory ? "*" : ""}
                    </span>
                  </Col>
                  <Col className="rule-field" sm={9}>
                    <Factory {...ruleField} />
                  </Col>
                  <Col className="help-button">
                    {ruleField.helpText && (
                      <InformationButton
                        id={ruleField.id}
                        description={ruleField.helpText}
                      />
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </div>
          ))}
          <div className="d-flex mt-auto">
            {isMandatory && <div className="mt-auto">* Pflichtfelder</div>}
            <Button
              className="ms-auto"
              block
              type="submit"
              color="success"
              disabled={hasValidationErrors || disableSubmit}
            >
              {submitButtonText === undefined ? (
                <FontAwesomeIcon
                  className="ms-3 me-3"
                  type="submit"
                  icon="arrow-right"
                />
              ) : (
                submitButtonText
              )}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
}

export interface RuleFormProps {
  formItems: any;
  initialValues?: any;
  submit?: any;
  submitButtonText?: string;
  disableSubmit?: boolean;
}

export interface FieldProps extends FactoryFields {
  label: string;
  helpText?: string;
  mandatory: boolean;
  initialValues: any | null;
  type?: string;
}

export interface InputFieldsProps extends FieldProps {
  value: string;
  inputFieldTypes: InputFieldTypes;
}

export enum InputFieldTypes {
  TEXTAREA = "textarea",
  NUMBER = "number",
  DATE = "date",
  TEXT = "text",
}

export interface BuildingPartsFieldProps extends FieldProps {
  buildingParts: TransformedNode[];
}
