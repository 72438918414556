import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { LevelSelector } from ".";
import { Level } from "../models/Level";

export function LevelSelectorDropdown({
  availableLevels,
  currentLevel,
  onSelect,
}: LevelSelectorDropdownProps) {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const renderItems = () => {
    availableLevels = availableLevels.slice().sort((a, b) => {
      return +b.name - +a.name;
    });

    return availableLevels.map((level) => (
      <DropdownItem
        key={level.id}
        onClick={() => onSelect(level)}
        active={level.id === currentLevel?.id}
      >
        {level.name}
      </DropdownItem>
    ));
  };
  return (
    <LevelSelector
      currentLevel={currentLevel}
      availableLevels={availableLevels}
    >
      <Dropdown
        className="LevelSelector"
        direction="up"
        isOpen={dropdownOpen}
        toggle={toggle}
        active
      >
        <DropdownToggle className="rounded-circle">
          {currentLevel.name}
        </DropdownToggle>
        <DropdownMenu>{renderItems()}</DropdownMenu>
      </Dropdown>
    </LevelSelector>
  );
}

export interface LevelSelectorDropdownProps {
  availableLevels: Level[];
  currentLevel: Level;

  onSelect(level: Level): void;
}
