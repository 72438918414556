import { GridRowId, GridRowModes, GridRowModesModel } from "@mui/x-data-grid";

export const useGridTableActionEvents = (
  api: any,
  actionProps: GridTableActionProps
) => {
  const {
    rowModesModel,
    setRowModesModel,
    deleteClickProps,
    setError,
    saveClickProps,
    cancelProps,
  } = actionProps;

  const filterRowModesModel = (id: GridRowId) => {
    return Object.keys(rowModesModel)
      .filter((key) => key !== id.toString())
      .reduce((obj, key) => {
        return Object.assign(obj, {[key]: rowModesModel[key]});
      }, {});
  };

  const handleDeleteClick = (id: GridRowId) => {
    deleteClickProps.setDeleteId(id.toString());
    deleteClickProps.setShowDialog(true);
  };

  const handleEditClick = (id: GridRowId) => {
    setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
  };

  const handleSaveClick = async (
    id: GridRowId,
    value: string,
    cancel: boolean = false
  ) => {
    if (value === "" || cancel) {
      handleCancelClick(id);
      return;
    }

    const {data, errorMessage} = await saveClickProps.sendRequest({
      id: id,
      name: value,
    });

    if (errorMessage === null && data) {
      const filteredRowModesModel = filterRowModesModel(id);
      setRowModesModel({
        ...filteredRowModesModel,
        [data.id]: {mode: GridRowModes.View},
      });
    }
    
    setError(errorMessage);
  };

  const handleCancelClick = (id: GridRowId) => {
    cancelProps.handelCancel(id.toString());
    const filteredRowModesModel = filterRowModesModel(id);
    setRowModesModel({
      ...filteredRowModesModel,
      [id]: {mode: GridRowModes.View, ignoreModifications: true},
    });

    setError(null);
  };

  return {
    handleSaveClick,
    handleEditClick,
    handleDeleteClick,
    handleCancelClick,
  };
};

export interface GridTableActionProps {
  rowModesModel: GridRowModesModel;
  deleteClickProps: DeleteProps;
  saveClickProps: SaveProps;
  cancelProps: CancelProps;

  setRowModesModel(rowModesModel: GridRowModesModel): void;

  setError(error: string | null): void;
}

export interface DeleteProps {
  setShowDialog: (showDialog: boolean) => void;
  setDeleteId: (id: string) => void;
}

export interface SaveProps {
  sendRequest: (
    ...args: any
  ) => Promise<{ data: Response | null; errorMessage: string }>;
}

export interface CancelProps {
  handelCancel: (id: string) => void;
}

export interface Response {
  id: string;

  [propName: string]: any;
}
