import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledCollapse } from "reactstrap";
import { FieldRenderProps } from "react-final-form";
import "./CustomCollapse.scss";

type Props = FieldRenderProps<string>;
const CustomCollapse: React.FC<CustomCollapseProps & Props> = ({
  input,
  ...rest
}) => {
  const { title, description } = rest.params;
  const [collapsed, setCollapsed] = useState<boolean>(false);
  return (
    <>
      <span
        id={input.name}
        className="fw-bold"
        role="button"
        onClick={() => setCollapsed(!collapsed)}
      >
        <FontAwesomeIcon
          className="me-2"
          icon={collapsed ? "caret-down" : "caret-right"}
        />
        {title}
      </span>

      <UncontrolledCollapse toggler={input.name}>
        <pre className="ms-3 mt-3">{description}</pre>
      </UncontrolledCollapse>
    </>
  );
};

export interface CustomCollapseProps {
  id: string;
  title: string;
  description: string;
}

export default CustomCollapse;
