import moment, { Moment } from "moment";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { Loading } from "../../shared";
import { DatetimeRangePicker } from "../../shared/DatetimeRangePicker";
import useHistoricalPositionData from "../hooks/useHistoricalPositionData";
import { useFetchFallBackPositionAndLevel } from "../hooks/useFetchFallBackPositionAndLevel";
import { useTransformPositionToFeatureCollection } from "../hooks/useTransformPositionToFeatureCollection";
import Heatmap from "../maps/Heatmap";
import { LevelSelector } from "../../map";
import HeatmapLegend from "../maps/HeatmapLegend";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { SelectableList } from "../../shared/SelectableList";
import { HistoricalPosition, Level } from "../../__generated__/history";
import { useTranslation } from "react-i18next";
import {
  ASSET_ERROR_NO_DATA,
  ERROR_INVALID_DATE_SELECTION,
  ERROR_NO_DATA_PERIOD,
  ERROR_UNKNOWN,
  INFO_NO_FLOORS,
} from "../../localization";

export default function AssetHistory() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Moment | null>(
    moment().subtract(1, "week")
  );
  const [endDate, setEndDate] = useState<Moment | null>(moment());
  const dateError = useMemo(() => {
    return startDate !== null && endDate !== null && startDate > endDate;
  }, [startDate, endDate]);

  const {
    levels,
    currentLevel,
    noData,
    loading: historicalLoading,
    error,
    selectLevel,
    positionData,
  } = useHistoricalPositionData(startDate, endDate, id, dateError);

  const {
    lastPosition,
    level: fallBackLevel,
    loading: fallBackLoading,
    isError: fallBackError,
  } = useFetchFallBackPositionAndLevel(noData, id);

  const position: HistoricalPosition[] = useMemo(() => {
    const positions =
      positionData?.historicalPositions ?? Array<HistoricalPosition>();

    if (lastPosition) {
      const historicalFallBackPosition: HistoricalPosition = {
        latitude: lastPosition.latitude,
        longitude: lastPosition.longitude,
        radius: lastPosition.radius,
        duration: 100,
      };
      positions.push(historicalFallBackPosition);
    }
    return positions;
  }, [lastPosition, positionData?.historicalPositions]);

  const selectedLevel = useMemo(() => {
    return currentLevel ?? fallBackLevel;
  }, [currentLevel, fallBackLevel]);

  const { featureCollection } =
    useTransformPositionToFeatureCollection(position);

  return (
    <>
      {(historicalLoading || fallBackLoading) && <Loading />}
      {((error && !noData) || fallBackError) && (
        <Alert color="danger">{t(ERROR_UNKNOWN)}</Alert>
      )}
      {!noData && positionData === undefined && (
        <Alert color="warning">{t(ERROR_NO_DATA_PERIOD)}</Alert>
      )}
      {noData && lastPosition === null && (
        <Alert color="warning">{t(ASSET_ERROR_NO_DATA)}</Alert>
      )}
      {featureCollection && (
        <div className="position-absolute top-0 start-0 w-100 h-100">
          <Heatmap
            key={selectedLevel?.layerId}
            data={featureCollection}
            currentLevel={selectedLevel}
          >
            {selectedLevel && (
              <LevelSelector
                availableLevels={levels ?? Array.of<Level>(selectedLevel)}
                currentLevel={selectedLevel}
              />
            )}
          </Heatmap>
        </div>
      )}
      <div className="d-flex h-100 flex-column align-items-end p-4">
        {dateError && (
          <Alert color="danger">{t(ERROR_INVALID_DATE_SELECTION)}</Alert>
        )}
        <div className="d-flex pe-auto">
          <DatetimeRangePicker
            startDate={startDate}
            endDate={endDate}
            error={dateError}
            onEndDateChange={setEndDate}
            onStartDateChange={setStartDate}
          />
        </div>
        {!noData && selectedLevel && (
          <>
            <div className="d-flex my-4 pe-auto">
              <SelectableList
                items={levels ?? Array.of<Level>(selectedLevel)}
                selectedItem={selectedLevel}
                handleClick={selectLevel}
                header="Ebenen"
                icon={faLayerGroup}
                emptyListOverride={t(INFO_NO_FLOORS) ?? ""}
              />
            </div>
            <div className="d-flex mt-auto pe-auto">
              <HeatmapLegend />
            </div>
          </>
        )}
      </div>
    </>
  );
}
