import React from "react";
import { FormFeedback, FormGroup, Input, InputProps, Label } from "reactstrap";
import "./CheckboxGroup.scss";

const CheckBoxGroup: React.FC<InputProps> = ({ input, meta, options }) => {
  const { name, onChange, onBlur, onFocus } = input;
  const { touched, error } = meta;
  const inputValue = input.value;

  const checkboxes = options.map(
    (option: { label: string; value: string }, index: number) => {
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const arr = [...inputValue];
        if (event.target.checked) {
          arr.push(option.value);
        } else {
          arr.splice(arr.indexOf(option.value), 1);
        }
        onBlur(arr);
        return onChange(arr);
      };
      const checked = inputValue.includes(option.value);
      return (
        <FormGroup check key={`checkbox-${index}`}>
          <Input
            type="checkbox"
            name={`${name}[${index}]`}
            id={`checkbox-${index}`}
            value={option.value}
            checked={checked}
            onChange={handleChange}
            onFocus={onFocus}
            invalid={!!(touched && error)}
          />
          <Label check for={`checkbox-${index}`}>
            {option.label}
          </Label>
        </FormGroup>
      );
    }
  );

  return (
    <div className="checkbox-group">
      {checkboxes}
      {touched && error && <FormFeedback>{error}</FormFeedback>}
    </div>
  );
};

export default CheckBoxGroup;
