import Icon from "./Icon";
import { Modal, ModalBody } from "reactstrap";
import IconPicker from "./IconPicker";
import React, { useEffect, useState } from "react";
import { FieldRenderProps } from "react-final-form";
import { DEFAULT_ICON } from "../../customIcons/hooks/useCustomIcons";
import { CustomIcon, CustomIconTag } from "../../models/CustomIcon";
import { useTranslation } from "react-i18next";
import { GENERAL_SYMBOL_SELECTION } from "../../localization";

type Props = FieldRenderProps<string>;

const IconSelector = ({ input, ...rest }: Props & IconSelectorProps) => {
  const customIconsDefaultIcon = DEFAULT_ICON;
  const customIcons = rest?.customIcons ?? [];
  const customTags = rest?.customTags ?? [];

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentIcon, setCurrentIcon] = useState<string>(
    input.value === "" ? customIconsDefaultIcon.id : input.value
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (input.value !== "") {
      setCurrentIcon(input.value);
    }
  }, [input.value]);

  const handleSubmit = (icon?: CustomIcon) => {
    setModalOpen(false);
    if (icon) {
      setCurrentIcon(icon.id);
      input.onChange(icon.id);
    } else {
      setCurrentIcon(customIconsDefaultIcon.id);
      input.onChange(undefined);
    }
  };

  const obtainCurrentIcon = () => {
    return customIcons
      ? customIcons.find((icon) => icon.id === currentIcon) ??
          customIconsDefaultIcon
      : customIconsDefaultIcon;
  };

  return (
    <>
      <div onClick={() => setModalOpen(!modalOpen)}>
        <Icon className="border-3" icon={obtainCurrentIcon()} active={false} />
      </div>

      <Modal
        isOpen={modalOpen}
        size="xl"
        onClosed={() => setModalOpen(false)}
        toggle={() => setModalOpen(!modalOpen)}
      >
        <div className="bg-light modal-header">
          <span className="modal-title fw-bold d-inline-flex">
            {t(GENERAL_SYMBOL_SELECTION)}
          </span>
          <button
            onClick={() => setModalOpen(!modalOpen)}
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <ModalBody className="mh-100 bg-light">
          <IconPicker
            onSubmit={handleSubmit}
            onCancel={() => setModalOpen(!modalOpen)}
            initialIcon={obtainCurrentIcon()}
            customIcons={customIcons}
            customIconsDefaultIcon={customIconsDefaultIcon}
            customTags={customTags}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

interface IconSelectorProps {
  customIcons: CustomIcon[];
  customTags: CustomIconTag[];
}

export default IconSelector;
