import {
  GridCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid";
import React, { useMemo, useState } from "react";
import MuiModal from "../../admin/components/MuiModal";
import GridTable from "../../admin/components/GridTable";
import { useGridTableColumns } from "../../admin/hooks/useGridTableColumns";
import { AssetGroup } from "../hooks/useAssetGroupApi";
import AssetGroupSaveButton from "./AssetGroupSaveButton";
import { useGridTableActionEvents } from "../../admin/hooks/useGridTableActionEvents";
import { IconButton } from "@mui/material";
import { Cancel, Delete, Edit } from "@mui/icons-material";
import {
  ACTION_CANCEL,
  ACTION_DELETE,
  ACTION_TITLE_PL,
  ASSET_GROUP_DELETE,
  ASSET_GROUP_NO_DATA,
  GENERAL_NAME,
  QUESTION_ASSET_GROUP_DELETE,
} from "../../localization";
import { useTranslation } from "react-i18next";

const AssetGroupContainer: React.FC<AssetGroupContainerProps> = ({
  assetGroups,
  assetGroupApi,
  loading,
  rowModesModel,
  setRowModesModel,
  setError,
}) => {
  const KEY_TO_ADD = "Enter";

  const [dialogShown, setDialogShown] = useState<boolean>(false);
  const [deleteRowId, setDeleteRowId] = useState<GridRowId | null>(null);
  const { t } = useTranslation();

  const {
    handleSaveClick,
    handleEditClick,
    handleDeleteClick,
    handleCancelClick,
  } = useGridTableActionEvents(assetGroupApi, {
    rowModesModel: rowModesModel,
    setRowModesModel: setRowModesModel,
    setError: setError,
    deleteClickProps: {
      setDeleteId: setDeleteRowId,
      setShowDialog: setDialogShown,
    },
    saveClickProps: {
      sendRequest: assetGroupApi.createOrUpdateAssetGroup,
    },
    cancelProps: {
      handelCancel: assetGroupApi.deleteIfNotFound,
    },
  });

  const action = useMemo(() => {
    return {
      field: "actions",
      type: "actions",
      headerName: t(ACTION_TITLE_PL),
      flex: 0.2,
      cellClassName: "actions",
      headerClassName: "hideRightSeparator",
      getActions: ({ ...params }) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <AssetGroupSaveButton
              key={params.row.id}
              id={params.id}
              currentValue={params.row.Name}
              handleSaveClick={handleSaveClick}
            />,
            <IconButton
              key={params.id}
              onClick={() => handleCancelClick(params.id)}
            >
              <Cancel color="error" />
            </IconButton>,
          ];
        }
        return [
          <IconButton
            key={params.id}
            onClick={() => handleEditClick(params.id)}
          >
            <Edit color="secondary" />
          </IconButton>,
          <IconButton
            key={params.row.id}
            onClick={() => handleDeleteClick(params.id)}
          >
            <Delete color="error" />
          </IconButton>,
        ];
      },
    };
  }, [
    handleCancelClick,
    handleDeleteClick,
    handleEditClick,
    handleSaveClick,
    rowModesModel,
    t,
  ]);

  const { columns } = useGridTableColumns(
    [
      {
        name: t(GENERAL_NAME),
        editable: true,
      },
    ],
    action
  );

  const handleDialogCancel = () => {
    setDeleteRowId(null);
    setDialogShown(false);
  };

  const handleDeleteAssetGroup = async () => {
    setDialogShown(false);
    if (deleteRowId) {
      const response = await assetGroupApi.deleteFromCache(
        deleteRowId.toString()
      );
      if (response.errorMessage) {
        setError(response.errorMessage);
      }
    }
  };

  const handleKeyPressEvent = (
    params: GridCellParams,
    event: KeyboardEvent
  ) => {
    const inputEvent = event.target as HTMLInputElement;
    if (event.key === KEY_TO_ADD && inputEvent.value !== "") {
      handleSaveClick(params.id, inputEvent.value);
    }
  };

  return (
    <>
      <MuiModal
        title={t(ASSET_GROUP_DELETE)}
        open={dialogShown}
        onAccept={handleDeleteAssetGroup}
        onCancel={handleDialogCancel}
        acceptMessage={t(ACTION_DELETE)}
        cancelMessage={t(ACTION_CANCEL)}
      >
        <span>{t(QUESTION_ASSET_GROUP_DELETE)}</span>
      </MuiModal>
      {assetGroups && (
        <GridTable
          rows={assetGroups}
          columns={columns}
          rowModels={rowModesModel}
          loading={loading}
          emptyRowMessage={t(ASSET_GROUP_NO_DATA) ?? ""}
          onKeyDown={handleKeyPressEvent}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
        />
      )}
    </>
  );
};

export interface AssetGroupContainerProps {
  assetGroups: AssetGroup[];
  assetGroupApi: any;
  loading: boolean;
  rowModesModel: GridRowModesModel;

  setRowModesModel(rowModesModel: GridRowModesModel): void;

  setError(error: string): void;
}

export default AssetGroupContainer;
