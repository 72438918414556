import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import useNavdata from "../hooks/breadCrumbData";
import "./styles/Breadcrumb.scss";
import { useTranslation } from "react-i18next";

const BreadcrumbNavi: React.FC = () => {
  const data = useNavdata();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Breadcrumb id="breadcrumb" className="shadow-sm">
      {data.map(({ name, path }, key) =>
        name && key + 1 === data.length ? (
          <BreadcrumbItem active key={key}>
            {t(name)}
          </BreadcrumbItem>
        ) : (
          name && (
            <BreadcrumbItem key={key}>
              <Link to={{ pathname: path, state: location?.state }}>
                {t(name)}
              </Link>
            </BreadcrumbItem>
          )
        )
      )}
    </Breadcrumb>
  );
};

export default BreadcrumbNavi;
