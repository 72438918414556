import React from "react";
import { Alert } from "reactstrap";
import { AssetService } from "../../asset";
import SingleAssetMap from "../../asset/maps/SingleAssetMap";
import AlarmInfo from "../components/AlarmInfo";
import { useAlarmBroadcastChannel } from "../hooks";
import { useTranslation } from "react-i18next";
import {
  ALARM_SELECT,
  ERROR_TLM_NO_ASSET,
  ERROR_UNKNOWN,
  INFO_POSITION_UNKNOWN,
} from "../../localization";

const AlarmDetail: React.FC = () => {
  const { data: alarm, error } = useAlarmBroadcastChannel();
  const { t } = useTranslation();

  return (
    <>
      {error && <Alert color="danger">{t(ERROR_UNKNOWN)}</Alert>}
      {!alarm && <Alert color="info">{t(ALARM_SELECT)}</Alert>}
      {alarm && <AlarmInfo alarm={alarm} className="mb-3" />}

      {alarm && !alarm.lastPosition && (
        <Alert color="warning">{t(INFO_POSITION_UNKNOWN)}</Alert>
      )}
      {alarm && alarm.lastPosition && !alarm?.lastPosition?.assetId && (
        <Alert color="danger">{t(ERROR_TLM_NO_ASSET)}</Alert>
      )}
      {alarm && alarm.lastPosition && alarm.lastPosition.assetId && (
        <SingleAssetMap
          asset={AssetService.mapPositionToFeature(alarm.lastPosition)}
        />
      )}
    </>
  );
};

export default AlarmDetail;
