import { useState } from "react";
import { InvalidFeedBackProps } from "../../forms/components/SearchField";
import { macAddress } from "../../forms/validation";
import { useTranslation } from "react-i18next";
import { ERROR_MAC_ADR_INVALID_CHARACTERS } from "../../localization";

export const useValidateMac = (): [
  InvalidFeedBackProps,
  (mac: string) => boolean
] => {
  const checkForNotValidChars = /[g-zG-Z]/;
  const [formFeedBack, setFormFeedBack] = useState<InvalidFeedBackProps>({
    invalid: false,
    message: "",
  });
  const { t } = useTranslation();

  const validationPassed = (mac: string) => {
    const validation = macAddress(mac);

    if (checkForNotValidChars.test(mac)) {
      setFormFeedBack({
        message: t(ERROR_MAC_ADR_INVALID_CHARACTERS),
        invalid: true,
      });
      return false;
    }

    if (validation) {
      setFormFeedBack({ message: validation, invalid: true });
      return false;
    }

    setFormFeedBack({ ...formFeedBack, invalid: false });
    return true;
  };

  return [formFeedBack, validationPassed];
};
