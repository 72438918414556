import useGqlClient from "../../hooks/useGqlClient";
import { useMutation } from "@apollo/client";
import { CREATE_OR_UPDATE_ASSETGROUP } from "../../asset/queries/assetQuery";
import { useCallback } from "react";
import { AssetGroup } from "./useAssetGroupApi";

export const useCreateOrUpdateAssetGroup = () => {
  const assetClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  const [createOrUpdateAssetGroup, { loading, error }] = useMutation(
    CREATE_OR_UPDATE_ASSETGROUP,
    {
      client: assetClient,
    }
  );

  const assetGroupCreateOrUpdate = useCallback(
    async (assetGroupInput: AssetGroup) => {
      try {
        const { data, errors } = await createOrUpdateAssetGroup({
          variables: {
            assetGroupInput: { ...assetGroupInput },
          },
        });
        return { data: data, errors: errors?.toString() };
      } catch (ex) {
        return { data: null, errors: ex };
      }
    },
    [createOrUpdateAssetGroup]
  );

  return {
    createOrUpdateAssetGroup: assetGroupCreateOrUpdate,
    assetGroupsLoading: loading,
    assetGroupsError: !!error,
  };
};
