import { useQuery } from "react-query";
import { RuleSequence, RuleStateMachine } from "../Model/RuleSequence";
import { RuleFields } from "../Model/RuleFields";

export async function fetchRuleFields(
  id: string | null,
  ruleId?: string
): Promise<RuleFields | null> {
  if (id === null) return null;
  const res = await fetch(
    `${process.env.REACT_APP_RULESENGINE_URI!!}/catalogue/type/${id}${
      ruleId ? `/rule/${ruleId}` : ""
    }`
  );
  return res.json();
}

export const useRuleFields = (
  fieldType: string,
  type: RuleSequence,
  catalogue: boolean,
  ruleId?: string
) => {
  const ruleSeq = new RuleStateMachine();
  const { data, isError, isLoading, error } = useQuery(
    ["ruleElements", fieldType],
    () => fetchRuleFields(fieldType, ruleId),
    {
      retryDelay: 60 * 1000, // 1 minute
      retry: 3,
      staleTime: 60 * 60 * 1000, // 1 hour
      enabled:
        !ruleSeq.isListState(type) &&
        RuleSequence.RULE_FORM !== type &&
        ruleId === undefined &&
        !catalogue,
    }
  );

  if (isError) {
    console.error(error);
  }

  return {
    ruleFields: data?.fields,
    ruleFieldError: isError,
    ruleFieldLoading: isLoading,
    type: data?.type,
  };
};
