import UKSHValues from "./mainColors.json";
import { Expression } from "mapbox-gl";
import _ from "lodash";

export const RoomTypes = {
  standardRoom: "standardRoom",
  elevatorRoom: "elevatorRoom",
  stairsRoom: "stairsRoom",
  switchFloor: "switchFloor",
  waitingRoom: "waitingRoom",
  cafeteriaRoom: "cafeteriaRoom",
  wcRoom: "wcRoom",
  entranceRoom: "entranceRoom",
  emergencyRoom: "emergencyRoom",
  hiddenRoom: "hiddenRoom",
  waitingArea: "waitingArea",
  cafeteriaArea: "cafeteriaArea",
  emergencyArea: "emergencyArea",
  mainFloor: "mainFloor",
  sideFloor: "sideFloor",
  restrictedFloor: "restrictedFloor",
  exit: "exit",
};
/**
 * The filters basically mean check if the feature has got the correct type in its properties
 */
export const EXPRESSIONS = {
  roomType: {
    // --- rooms ---
    standardRoom: ["==", ["get", "type"], RoomTypes.standardRoom],
    elevatorRoom: ["==", ["get", "type"], RoomTypes.elevatorRoom],
    stairsRoom: ["==", ["get", "type"], RoomTypes.stairsRoom],
    switchFloorRoom: ["==", ["get", "type"], RoomTypes.switchFloor],
    waitingRoom: ["==", ["get", "type"], RoomTypes.waitingRoom],
    cafeteriaRoom: ["==", ["get", "type"], RoomTypes.cafeteriaRoom],
    wcRoom: ["==", ["get", "type"], RoomTypes.wcRoom],
    entranceRoom: ["==", ["get", "type"], RoomTypes.entranceRoom],
    emergencyRoom: ["==", ["get", "type"], RoomTypes.emergencyRoom],
    // wird zum filtern benutzt
    hiddenRoom: ["==", ["get", "type"], RoomTypes.hiddenRoom],
    // --- Areas ---
    waitingArea: ["==", ["get", "type"], RoomTypes.waitingArea],
    cafeteriaArea: ["==", ["get", "type"], RoomTypes.cafeteriaArea],
    emergencyArea: ["==", ["get", "type"], RoomTypes.emergencyArea],
    // --- floors ---
    mainFloor: ["==", ["get", "type"], RoomTypes.mainFloor],
    sideFloor: ["==", ["get", "type"], RoomTypes.sideFloor],
    restrictedFloor: ["==", ["get", "type"], RoomTypes.restrictedFloor],
    // --- misc ---
    exit: ["==", ["get", "type"], RoomTypes.exit],
    // --- additionals ---
    glass: ["has", "glass"],
    hasHeight: ["has", "height"],
    getHeight: ["get", "height"],
    // --- states ---
    highlight: {
      standard: ["boolean", ["feature-state", "highlighted-standard"], false],
      danger: ["boolean", ["feature-state", "highlighted-danger"], false],
      warning: ["boolean", ["feature-state", "highlighted-warning"], false],
    },
  },
  buildingClass: {
    normal: ["==", ["get", "class"], "normal"],
    main: ["==", ["get", "class"], "main-building"],
    parkingGarage: ["==", ["get", "class"], "parking-garage"],
    parkingLot: ["==", ["get", "class"], "parking-lot"],
  },
  nodeType: {
    elevator: ["==", ["get", "name"], "Fahrstuhl"],
    stairs: ["==", ["get", "name"], "Treppe"],
    toilette: [
      "in",
      ["get", "name"],
      [
        "literal",
        ["WC Herren", "WC Damen", "WC Damen und Herren", "WC barrierefrei"],
      ],
    ],
    information: ["==", ["get", "name"], "Information"],
  },
};

const standard = 2;
/**
 * Für die Ordentlichkeit werden möglichst alle StylingValues für jegliche Layers hier gespeichert,
 * damit man möglichst gleiche Values für alles verwendet und keinen Mischmasch hat.
 */
export const STYLINGVALUES = {
  rooms: {
    height: {
      standard: standard,
      glassStandard: standard + 0.05,
      glassLow: 0.2,
      // bei allen anderen außer normalen räumen sind manchmal noch polygone innendrin
      // die krieseln hässlich (wegen der gleichen höhe und farbunterschied),
      // deshalb ist es nur provisorisch, dass diese räume ein kleines bisschen höher sind
      switchFloorRoom: standard + 0.05,
      elevatorRoom: standard + 0.05,
      stairsRoom: standard + 0.05,
      entranceRoom: 3.5,
      cafeteriaRoom: standard + 0.05,
      waitingRoom: 0.2,
      emergencyRoom: standard + 0.05,
      waitingArea: 0.2,
      cafeteriaArea: 0.2,
      emergencyArea: 0.2,
      mainFloor: 0,
      sideFloor: 0,
      restrictedFloor: 0,
    },
    base: {
      entranceRoom: standard + 0.05,
    },
    opacity: {
      standardRoom: 1,
      switchFloorRoom: 0.5,
      elevatorRoom: 0.5,
      stairsRoom: 0.5,
      waitingRoom: 1,
      mainFloor: 1,
      glass: 0.4,
    },
  },
  lines: {
    opacity: {
      navigation: {
        arrows: {
          ghosted: 0,
        },
        normal: 1,
        ghosted: 0.35,
      },
    },
    blur: {
      navigation: {
        ghosted: 20,
      },
    },
    dash: [0.1, 2],
    thickness: {
      navigation: {
        normal: {
          line: 8,
          halo: 14,
        },
        ghosted: {
          line: 14,
          halo: 14,
        },
      },
    },
  },
  symbols: {
    opacity: {
      navigation: {
        arrows: {
          ghosted: 0,
        },
        normal: 1,
        ghosted: 0.2,
      },
    },
  },
};
// Diese Values können von externen Styles überschrieben werden
const CUSTOMIZABLE_VALUES = {
  // Colors für Features auf Map
  primary: "#234854",
  secondary: "#61942f",
  background: "#757575",
  selected: "#093d73",
  text_selected: "#093d73",
  hovered: "#342854",
  special: "#342854",
  white: "#ffffff",
  shadowDark: "#404040",
  // marker auf der Map (bei selected Stuff)
  childMarker: "#d44e4e",
  childMarker_darker: "#923939",
  childMarker_ghosted: "#923939",
  poiMarker: "#2a2a2a",
  poiMarker_ghosted: "#99999966",
  // Symbol Node Types
  standardSymbol: "#393939",
  unimportantSymbol: "#6f6f6f",
  textHalo: "rgba(255,255,255,0.6)",
  einrichtung: "#07e1be",
  einrichtung_light: "#c2f1ea",
  roomText: "#44556b",
  roomText_selected: "#255fa5",
  room: "#b71e5b",
  room_light: "#404040",
  poi: "#57a418",
  poi_light: "#e8fada",
  parking: "#124b92",
  parking_light: "#c7dcf5",
  notaufnahme: "#cd0000",
  notaufnahme_light: "#ffd4d4",
  bistro: "#ec442d",
  bistro_light: "#fdd0bd",
  information: "#4336fd",
  information_light: "#bad6ff",
  taxi: "#000",
  taxi_light: "#f6e67d",
  bushaltestelle: "#1c7906",
  apotheke: "#cd0000",
  apotheke_light: "#ffd4d4",
  toilette: "#048dbf",
  toilette_light: "#b9edff",
  atm: "#1f983d",
  atm_light: "#cdeed5",
  cashier: "#146088",
  cashier_light: "#9cc9e2",
  virus: "#000000",
  virus_light: "#f3ea22",
  entrance: "#54c47b",
  entrance_light: "#ffffff",
  restricted: "#dea2a2",
  restricted_light: "#ffffff",
  // Farben für Gebäudeklassen
  building_normal: "#80919e",
  building_main: "#5d6b76",
  building_parkingGarage: "#035ca3",
  building_parkingLot: "#516e86",
  // lines der Navigationsrouten
  lineFill: "#07ceae",
  lineFill_inactive: "#b0b0b0",
  userPuck: "#7cbaea",
  userPuck_radar: "#7cbaea",
  userPuck_arc: "#7cbaea",
  userPuck_inactive: "#b0b0b0",
  lineHalo: "#07ceae",
  lineHalo_inactive: "#5d5d5d",
  ghostedHalo: "#999999",
  ghostedArrowHalo: "#7a7a7a",
  // navigation nodes
  start_node: "#333b42",
  transit_node: "#333b42",
  route_node_symbol: "#333b42",
  route_node_bg: "#333b42",
  route_node_marking: "#333b42",
  // polygontypes
  building: "#e76666",
  building_selected: "#07ceae",
  rooms: "#80919e",
  rooms_active: "#07ceae",
  outline: "#ffffff",
  hallways: "#ffffff",
  ground: "#828282",
  levelRoom: "#333b42",
  waitingRoom: "#333b42",
  elevatorRoom: "#333b42",
  stairsRoom: "#333b42",
  wcRoom: "#048dbf",
  cafeteriaRoom: "#e89e77",
  entranceRoom: "#74fca3",
  emergencyRoom: "#e76666",
  emergencyArea: "#e76666",
  cafeteriaArea: "#e89e77",
  waitingArea: "#a5e7ca",
  mainFloor: "#ffffff",
  sideFloor: "#eedfdf",
  restrictedFloor: "#dea2a2",
  exit: "#74fca3",
  mapboxBuildings: "#999999",
  // Information
  error: "#ff6969",
  warning: "#ffec63",
  info: "#8dfadf",
  success: "#06B195",
  gpsAvailable: "#06B195",
  highlight: {
    standard: "#8dfadf",
    warning: "#ffec63",
    danger: "#ff6969",
  },
  // default Font, welches bei allen (nicht explizit hier definierten) layern verwendet wird
  defaultMapFont: ["Open Sans Regular", "Arial Unicode MS Regular"],
  // Einrichtungen Texts
  facilityTextFont: ["Open Sans Semibold", "Arial Unicode MS Regular"],
};

export const VALUES = _.merge(CUSTOMIZABLE_VALUES, UKSHValues);

export const BUNDLED_PROPERTIES: {
  rooms: {
    height: Expression;
    base: Expression;
    opacity: Expression;
    color: Expression;
  };
  nodes: {
    iconColor: Expression;
    circleColor: Expression;
  };
} = {
  rooms: {
    height: [
      "case",
      // wenn explizit eine height angegeben ist, dann muss diese genommen werden
      EXPRESSIONS.roomType.hasHeight,
      EXPRESSIONS.roomType.getHeight,
      EXPRESSIONS.roomType.entranceRoom,
      STYLINGVALUES.rooms.height.entranceRoom,
      // wenn ein Raum glass ist, dann hat er immer einen niedrigen Boden
      // (außer height wurde explizit mitgegeben)
      EXPRESSIONS.roomType.glass,
      STYLINGVALUES.rooms.height.glassLow,
      // rooms ohne glass
      // -- areas ---
      EXPRESSIONS.roomType.waitingArea,
      STYLINGVALUES.rooms.height.waitingArea,
      EXPRESSIONS.roomType.emergencyArea,
      STYLINGVALUES.rooms.height.emergencyArea,
      EXPRESSIONS.roomType.cafeteriaArea,
      STYLINGVALUES.rooms.height.cafeteriaArea,
      // --rooms ---
      EXPRESSIONS.roomType.emergencyRoom,
      STYLINGVALUES.rooms.height.emergencyRoom,
      EXPRESSIONS.roomType.cafeteriaRoom,
      STYLINGVALUES.rooms.height.cafeteriaRoom,
      EXPRESSIONS.roomType.waitingRoom,
      STYLINGVALUES.rooms.height.waitingRoom,
      EXPRESSIONS.roomType.switchFloorRoom,
      STYLINGVALUES.rooms.height.switchFloorRoom,
      EXPRESSIONS.roomType.stairsRoom,
      STYLINGVALUES.rooms.height.stairsRoom,
      EXPRESSIONS.roomType.elevatorRoom,
      STYLINGVALUES.rooms.height.elevatorRoom,
      EXPRESSIONS.roomType.standardRoom,
      STYLINGVALUES.rooms.height.standard,
      // -- floors ---
      EXPRESSIONS.roomType.mainFloor,
      STYLINGVALUES.rooms.height.mainFloor,
      EXPRESSIONS.roomType.sideFloor,
      STYLINGVALUES.rooms.height.sideFloor,
      STYLINGVALUES.rooms.height.standard,
    ],
    base: [
      "case",
      EXPRESSIONS.roomType.entranceRoom,
      STYLINGVALUES.rooms.base.entranceRoom,
      0,
    ],
    opacity: [
      "case",
      EXPRESSIONS.roomType.switchFloorRoom,
      STYLINGVALUES.rooms.opacity.switchFloorRoom,
      EXPRESSIONS.roomType.mainFloor,
      STYLINGVALUES.rooms.opacity.mainFloor,
      EXPRESSIONS.roomType.stairsRoom,
      STYLINGVALUES.rooms.opacity.stairsRoom,
      EXPRESSIONS.roomType.waitingRoom,
      STYLINGVALUES.rooms.opacity.waitingRoom,
      STYLINGVALUES.rooms.opacity.standardRoom,
    ],
    color: [
      "case",
      // highlight color
      EXPRESSIONS.roomType.highlight.standard,
      VALUES.highlight.standard,
      EXPRESSIONS.roomType.highlight.danger,
      VALUES.highlight.danger,
      EXPRESSIONS.roomType.highlight.warning,
      VALUES.highlight.warning,
      // floors
      EXPRESSIONS.roomType.mainFloor,
      VALUES.mainFloor,
      EXPRESSIONS.roomType.sideFloor,
      VALUES.sideFloor,
      EXPRESSIONS.roomType.restrictedFloor,
      VALUES.restrictedFloor,
      // rooms
      EXPRESSIONS.roomType.emergencyRoom,
      VALUES.emergencyRoom,
      EXPRESSIONS.roomType.cafeteriaRoom,
      VALUES.cafeteriaRoom,
      EXPRESSIONS.roomType.exit,
      VALUES.exit,
      EXPRESSIONS.roomType.switchFloorRoom,
      VALUES.levelRoom,
      EXPRESSIONS.roomType.elevatorRoom,
      VALUES.elevatorRoom,
      EXPRESSIONS.roomType.stairsRoom,
      VALUES.stairsRoom,
      EXPRESSIONS.roomType.waitingRoom,
      VALUES.waitingRoom,
      EXPRESSIONS.roomType.wcRoom,
      VALUES.wcRoom,
      EXPRESSIONS.roomType.entranceRoom,
      VALUES.entranceRoom,
      // areas
      EXPRESSIONS.roomType.emergencyArea,
      VALUES.emergencyArea,
      EXPRESSIONS.roomType.cafeteriaArea,
      VALUES.cafeteriaArea,
      EXPRESSIONS.roomType.waitingArea,
      VALUES.waitingArea,
      // fallback value
      VALUES.rooms,
    ],
  },
  nodes: {
    iconColor: [
      "case",
      EXPRESSIONS.nodeType.elevator,
      VALUES.standardSymbol,
      EXPRESSIONS.nodeType.stairs,
      VALUES.standardSymbol,
      EXPRESSIONS.nodeType.toilette,
      VALUES.toilette,
      EXPRESSIONS.nodeType.information,
      VALUES.information,
      VALUES.white,
    ],
    circleColor: [
      "case",
      EXPRESSIONS.nodeType.elevator,
      VALUES.white,
      EXPRESSIONS.nodeType.stairs,
      VALUES.white,
      EXPRESSIONS.nodeType.toilette,
      VALUES.toilette_light,
      EXPRESSIONS.nodeType.information,
      VALUES.information_light,
      VALUES.white,
    ],
  },
};
