import { useCallback, useContext } from "react";
import { levelFields } from "../../__generated__/types";
import { findAndToggleLocator, setCurrentLevel } from "../locatorManagerSlice";
import { ViewState } from "react-map-gl";
import {
  InvalidFeedBackProps,
  SearchFieldProps,
} from "../../forms/components/SearchField";
import { useDispatch } from "react-redux";
import { Locator } from "../components/LocatorManager";
import { useValidateMac } from "./useValidateMac";
import { useLocatorByMac } from "./useLocatorByMac";
import { FlyToInterpolator } from "@deck.gl/core";
import { LocatorRefetchContext, mapToLocators } from "../pages/LocatorEdit";
import {
  ERROR_NO_RECEIVERS_WITH_MAC_ADDRESS,
  PLACEHOLDER_SEARCH_FOR_MAC_ADDRESS,
} from "../../localization";
import { useTranslation } from "react-i18next";

export const useMacSearchField = (
  setViewport: (viewPort: ViewState) => void
): SearchFieldProps => {
  const TRANSITION_SPEED_IN_MILLI = 2000;
  const TRANSITION_DURATION_IN_MILLI = 2500;
  const MAC_ADDRESS_PATTERN = "**:**:**:**:**:**";

  const [formFeedBack, validate] = useValidateMac();
  const [fetchLocatorByMac, locatorData] = useLocatorByMac();
  const locatorsContext = useContext(LocatorRefetchContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setLocator = useCallback(
    (locator) => {
      if (locator) {
        setViewport({
          ...locator.viewport,
          transitionInterpolator: new FlyToInterpolator({
            speed: TRANSITION_SPEED_IN_MILLI,
          }),
          transitionDuration: TRANSITION_DURATION_IN_MILLI,
        });

        dispatch(findAndToggleLocator(locator));
      }
    },
    [dispatch, setViewport]
  );

  const onSubmit = useCallback(
    async (mac: string) => {
      const validation = validate(mac);
      if (validation) {
        const { data } = await fetchLocatorByMac({
          variables: { macAddress: mac },
        });
        if (data.locatorByMac) {
          const levelField: levelFields = data.locatorByMac.level;
          const locator: Locator = {
            ...mapToLocators([data.locatorByMac])[0],
            viewport: data.locatorByMac.viewport,
          };

          dispatch(setCurrentLevel(levelField));

          if (locatorsContext?.refetch) {
            await locatorsContext?.refetch();
            setLocator(locator);
          }
        }
      }
    },
    [locatorsContext, dispatch, fetchLocatorByMac, setLocator, validate]
  );

  const getFormFeedBack = (feedBack: InvalidFeedBackProps) => {
    if (feedBack.invalid) {
      return feedBack;
    }

    if (locatorData === null) {
      return {
        invalid: true,
        message: t(ERROR_NO_RECEIVERS_WITH_MAC_ADDRESS),
      };
    }

    return feedBack;
  };
  return {
    onSubmit,
    inputPattern: MAC_ADDRESS_PATTERN,
    formFeedBack: getFormFeedBack(formFeedBack),
    placeHolder: t(PLACEHOLDER_SEARCH_FOR_MAC_ADDRESS) ?? "",
  };
};
