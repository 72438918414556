import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import useGqlClient from "../../hooks/useGqlClient";
import { UseCase } from "../../models/UseCase";
import { GET_USE_CASES } from "../queries/useCaseQueries";

const useUseCases = (existingUseCases?: UseCase[]) => {
  const [useCases, setUseCases] = useState<UseCase[]>([]);
  const navigationClient = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_NAVIGATION!
  );

  const result = useQuery(GET_USE_CASES, { client: navigationClient });

  useEffect(() => {
    // Match existing useCases from asset service by name.
    // This is needed for a successful migration in case of assets and useCases are already assigned.
    if (existingUseCases) {
      const useCaseNames = result.data?.useCases.map((uc: UseCase) => uc.name);
      const filtered = existingUseCases.filter((value: UseCase) =>
        useCaseNames?.includes(value.name)
      );
      setUseCases(filtered);
    } else if (result?.data?.useCases) {
      setUseCases(result.data.useCases);
    }
  }, [result, existingUseCases]);

  return { ...result, data: { useCases: useCases } };
};

export default useUseCases;
