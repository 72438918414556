import React from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { ROUTES } from "../routes";
import { useTranslation } from "react-i18next";
import {
  ACTION_BACK_TO_HOME,
  ACTION_BACK_TO_PREVIOUS_PAGE,
  ERROR_403,
  ERROR_404,
} from "../localization";

const ErrorDetail: React.FC<{ errorCode: number }> = ({ errorCode }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const codeToText: { [id: number]: string } = {
    403: t(ERROR_403),
    404: t(ERROR_404),
  };
  const code = errorCode === undefined ? 403 : errorCode;
  const displayText = codeToText[code];

  return (
    <>
      <Alert color="danger" className="d-block">
        <p>{displayText}</p>
        <div className="d-flex">
          <Button onClick={history.goBack}>
            {t(ACTION_BACK_TO_PREVIOUS_PAGE)}
          </Button>
          <Button
            className="ms-1"
            onClick={() => history.push(ROUTES.home.childRoutes.useCases.path)}
          >
            {t(ACTION_BACK_TO_HOME)}
          </Button>
        </div>
      </Alert>
    </>
  );
};

export default ErrorDetail;
