import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { FieldRenderProps } from "react-final-form";
import ErrorFeedback from "./ErrorFeedback";
import "./TypeaheadField.scss";

type Prop = FieldRenderProps<string[], any>;

function TypeaheadField({ input, meta, ...rest }: Prop) {
  const { value, onChange, onBlur } = input;

  const handleChange = (selected: string[]) => {
    onBlur(selected as any);
    onChange(selected);
  };

  return (
    <div className="TypeaheadField">
      <Typeahead
        isInvalid={
          !!(
            !meta.dirtySinceLastSubmit &&
            meta.touched &&
            (meta.error || meta.submitError)
          )
        }
        multiple={input.multiple}
        options={rest?.params?.options ?? []}
        onChange={handleChange}
        selected={value ? value : []}
        {...rest}
      />
      <ErrorFeedback name={input.name} />
    </div>
  );
}

export default TypeaheadField;
