import { Meta, Story } from "@storybook/react";
import TemplateEditor, { TemplateEditorFieldProps } from "./TemplateEditor";
import * as React from "react";

export default {
  title: "Components/Form/TemplateEditor",
  component: TemplateEditor,
} as Meta;

const Template: Story<TemplateEditorFieldProps> = (args) => (
  <div>Test</div> //<TemplateEditor {...args} />
);

export const Default = Template.bind({});

Default.args = {
  id: "template",
  label: "Inhalt",
  mandatory: false,
  helpText: "",
  options: [
    { label: "Asset", value: "Asset.Name" },
    { label: "Temperatur", value: "Temperatur-Wert" },
    { label: "Grenzwerte", value: "Unterer-Grenzwert - Oberere Grenzwert" },
  ],
};
