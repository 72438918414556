import _ from "lodash";
import React, { useEffect, useState } from "react";
import Select, {
  ActionMeta,
  GroupTypeBase,
  OptionsType,
  Props as SelectProps,
  StylesConfig,
} from "react-select";

const styles: StylesConfig<any, true> = {
  multiValue: (styles, { data, options }) => {
    return data.isFixed ? { ...styles, backgroundColor: "gray" } : styles;
  },
  multiValueLabel: (styles, { data }) => {
    return data.isFixed
      ? { ...styles, fontWeight: "bold", color: "white", paddingRight: 6 }
      : styles;
  },
  multiValueRemove: (styles, { data }) => {
    return data.isFixed ? { ...styles, display: "none" } : styles;
  },
};

export function FixedMultiSelect<T extends DropdownData>({
  onChange,
  isClearable = true,
  invalid = false,
  value: valueFromProps,
  ...rest
}: FixedMultiSelectProps<T>) {
  const [selection, setSelection] = useState<OptionsType<T>>([]);

  useEffect(() => {
    if (!_.isArray(valueFromProps)) {
      return;
    }

    setSelection(
      valueFromProps.map((val: any, i: number) => {
        return {
          ...val,
          isFixed: i < valueFromProps.length - 1,
        };
      }) || []
    );
  }, [valueFromProps]);

  const handleChange = (value: OptionsType<T>, actionMeta: ActionMeta<T>) => {
    switch (actionMeta.action) {
      case "select-option":
        value = value.map((item) => {
          return {
            ...item,
            isFixed: item.value !== actionMeta.option?.value,
          };
        });
        break;
      case "remove-value":
      case "pop-value":
        if (
          selection.some(
            (item) =>
              item.isFixed && item.value === actionMeta.removedValue?.value
          )
        )
          return;

        if (value.length > 0) {
          const before = value.slice(0, value.length - 1);
          const last = {
            ...value[value.length - 1],
            isFixed: false,
          };
          value = [...before, last];
        }
        break;
    }

    if (onChange !== undefined) {
      onChange(value, actionMeta);
    }

    setSelection(value);
  };

  return (
    <Select
      isMulti
      backspaceRemovesValue={false}
      className={`${invalid ? "is-invalid" : ""} fixed-multiselect`}
      classNamePrefix="fixed-multiselect"
      escapeClearsValue={false}
      isClearable={isClearable}
      styles={styles}
      onChange={handleChange}
      value={selection}
      {...rest}
    />
  );
}

export type FixedMultiSelectProps<T> = SelectProps<T, true, GroupTypeBase<T>>;

export interface DropdownData {
  value: string;
  label: string;
  isFixed?: boolean;
}

export default FixedMultiSelect;
