export enum RuleSequence {
  TRIGGER_LIST = "triggers",
  TRIGGER_FORM = "triggerForm",
  CONDITION_LIST = "conditions",
  CONDITION_FORM = "conditionForm",
  ACTION_LIST = "actions",
  ACTION_FORM = "actionForm",
  RULE_FORM = "ruleForm",
}

export class RuleStateMachine {
  trigger: string = "trigger";
  condition: string = "condition";
  action: string = "action";

  next(sequence: RuleSequence): RuleSequence {
    switch (sequence) {
      case RuleSequence.TRIGGER_LIST:
        return RuleSequence.TRIGGER_FORM;
      case RuleSequence.TRIGGER_FORM:
        return RuleSequence.CONDITION_LIST;
      case RuleSequence.CONDITION_LIST:
        return RuleSequence.CONDITION_FORM;
      case RuleSequence.CONDITION_FORM:
        return RuleSequence.ACTION_LIST;
      case RuleSequence.ACTION_LIST:
        return RuleSequence.ACTION_FORM;
      case RuleSequence.ACTION_FORM:
        return RuleSequence.RULE_FORM;
      default:
        return sequence;
    }
  }

  isListState(sequence: RuleSequence): boolean {
    return (
      sequence === RuleSequence.TRIGGER_LIST ||
      sequence === RuleSequence.CONDITION_LIST ||
      sequence === RuleSequence.ACTION_LIST
    );
  }

  previous(sequence: RuleSequence): RuleSequence {
    switch (sequence) {
      case RuleSequence.RULE_FORM:
        return RuleSequence.ACTION_FORM;
      case RuleSequence.ACTION_FORM:
        return RuleSequence.ACTION_LIST;
      case RuleSequence.ACTION_LIST:
        return RuleSequence.CONDITION_FORM;
      case RuleSequence.CONDITION_FORM:
        return RuleSequence.CONDITION_LIST;
      case RuleSequence.CONDITION_LIST:
        return RuleSequence.TRIGGER_FORM;
      case RuleSequence.TRIGGER_FORM:
        return RuleSequence.TRIGGER_LIST;
      default:
        return sequence;
    }
  }

  getState(state?: string): RuleSequence {
    if (state === undefined) {
      return RuleSequence.TRIGGER_LIST;
    }
    if (state.toLowerCase().includes(this.trigger)) {
      return RuleSequence.TRIGGER_FORM;
    } else if (state.toLowerCase().includes(this.condition)) {
      return RuleSequence.CONDITION_FORM;
    } else if (state.toLowerCase().includes(this.action)) {
      return RuleSequence.ACTION_FORM;
    }
    return RuleSequence.TRIGGER_LIST;
  }
}
