import { useEffect, useMemo, useState } from "react";
import useAsset from "../../asset/hooks/useAsset";
import { ALERT_ASSET_BY_ID } from "../../asset/queries/assetQuery";
import { IAlert } from "../../models/Alert";
import { AlarmBroadcastChannel } from "../AlarmBroadcastChannel";

const useAlarmBroadcastChannel = () => {
  const [alarm, setAlarm] = useState<IAlert>();
  const assetId = alarm?.lastPosition?.assetId;

  const { data, error } = useAsset(assetId, true, ALERT_ASSET_BY_ID);

  useEffect(() => {
    const alarmChannel = new AlarmBroadcastChannel();
    const channel = alarmChannel.getBroadcastChannel();
    channel.onmessage = (ev) => {
      setAlarm(ev.data);
    };
  }, []);

  const alarmWithPosition: IAlert | undefined = useMemo(() => {
    if (!alarm) return undefined;
    if (data == null || data.lastPosition === undefined) {
      const copy = { ...alarm };
      delete copy.lastPosition;
      return copy;
    }

    return {
      ...alarm,
      lastPosition: {
        ...data.lastPosition,
        icon: "/marker.png",
      },
    };
  }, [alarm, data]);

  return { data: alarmWithPosition, error };
};

export default useAlarmBroadcastChannel;
