import React from "react";
import { Col, Row } from "reactstrap";
import InformationCard from "../../shared/Information-card/InformationCard";
import "../../shared/Information-card/InformationCard.scss";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { RuleElement } from "../Model/RuleElement";
import { useDispatch, useSelector } from "react-redux";
import { getRuleSequence, setStateId } from "../ruleManagerSlice";

const RuleElementList: React.FC<IRuleElementList> = ({ ruleElements }) => {
  const dispatch = useDispatch();
  const sequence = useSelector(getRuleSequence);

  return (
    <Row className="rules-list">
      {ruleElements.map((element: RuleElement, index) => (
        <Col
          className="pb-3"
          key={index}
          style={{ fontSize: "0.9rem" }}
          sm={12}
          md={6}
          lg={4}
        >
          <InformationCard
            key={element.fieldType}
            title={element.title}
            informationText={element.description}
            iconName={(element.icon as IconName) ?? undefined}
            onClick={() => {
              dispatch(setStateId({ stateId: sequence, value: element }));
            }}
          />
        </Col>
      ))}
    </Row>
  );
};

interface IRuleElementList {
  ruleElements: RuleElement[];
}

export default RuleElementList;
