import React from "react";
import { FieldRenderProps } from "react-final-form";
import ErrorFeedback from "./ErrorFeedback";
import InputFieldAutoComplete from "./InputFieldAutoComplete";
import { Input } from "reactstrap";
import { InputType } from "reactstrap/es/Input";

type Props = FieldRenderProps<string | number | string[], any>;

function InputField({ input, meta, ...rest }: Props) {
  return (
    <>
      {rest?.params?.options === null || rest?.params?.options === undefined ? (
        <>
          <Input
            {...input}
            {...rest}
            invalid={
              !!(
                !meta.dirtySinceLastSubmit &&
                meta.touched &&
                (meta.error || meta.submitError)
              )
            }
            type={input.type as InputType}
          />
          <ErrorFeedback name={input.name} />
        </>
      ) : (
        <InputFieldAutoComplete
          input={input}
          meta={meta}
          options={rest.params.options}
          {...rest}
        />
      )}
    </>
  );
}

export default InputField;
