import { useEffect, useState } from "react";
import { useAssetGroups } from "./useAssetGroups";
import { useCreateOrUpdateAssetGroup } from "./useCreateOrUpdateAssetGroup";
import { useDeleteAssetGroup } from "./useDeleteAssetGroup";
import { useTranslation } from "react-i18next";
import { ERROR_SIMPLE } from "../../localization";

export const useAssetGroupApi = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const ERROR_MESSAGE = t(ERROR_SIMPLE);

  const {
    assetGroups,
    assetGroupsLoading,
    assetGroupsError,
    assetGroupRefetch,
  } = useAssetGroups();

  const {
    createOrUpdateAssetGroup,
    assetGroupsLoading: assetGroupCreateOrUpdateLoading,
  } = useCreateOrUpdateAssetGroup();
  const { deleteAssetGroup, assetGroupDeleteLoading } = useDeleteAssetGroup();

  useEffect(() => {
    setLoading(
      assetGroupsLoading ||
        assetGroupCreateOrUpdateLoading ||
        assetGroupDeleteLoading
    );
  }, [
    assetGroupCreateOrUpdateLoading,
    assetGroupDeleteLoading,
    assetGroupsLoading,
  ]);

  const api = { createOrUpdateAssetGroup, deleteAssetGroup, assetGroupRefetch };
  return {
    loading,
    isError: assetGroupsError,
    assetGroups,
    ERROR_MESSAGE,
    api,
  };
};

export interface AssetGroup {
  id?: string;
  name: string;
}

export interface AssetGroupApi {
  assetGroups: AssetGroup[];

  createOrUpdate(assetGroupInput: AssetGroup): void;

  deleteById(assetGroupId: string): void;

  addToTable?(): void;
}
