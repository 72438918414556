import _ from "lodash";
import React, { useCallback } from "react";
import { FieldRenderProps } from "react-final-form";
import { OptionsType } from "react-select";
import BuildingpartSelect, {
  BuildingpartSelectProps,
} from "../../shared/Forms/BuildingpartSelect";

function BuildingpartSelectField({
  input,
  meta,
  ...fieldProps
}: BuildingpartSelectFieldProps) {
  const { value, onChange, onBlur, onFocus } = input;
  const { touched, error, submitError } = meta;

  const handleSelect = useCallback(
    (selection: OptionsType<{ label: string; value: string }> | null) => {
      if (!_.isEmpty(selection)) {
        onChange(selection);
      } else {
        onChange(undefined);
      }
    },
    [onChange]
  );

  return (
    <BuildingpartSelect
      {...fieldProps}
      invalid={!!(touched && (error || submitError))}
      onBlur={(ev: any) => onBlur(ev)}
      onFocus={(event) =>
        onFocus(event as React.FocusEvent<HTMLElement, Element>)
      }
      onSelect={handleSelect}
      value={value || []}
      name={input.name}
    />
  );
}

type BuildingpartSelectFieldProps = BuildingpartSelectProps &
  FieldRenderProps<OptionsType<{ label: string; value: string }>>;

export default BuildingpartSelectField;
