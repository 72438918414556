import { useGqlClient } from "../../hooks";
import { useMutation } from "@apollo/client";
import { DELETE_CUSTOM_ICON } from "../../asset/queries/assetQuery";
import { useCallback } from "react";

/**
 * Custom hook that is responsible for deleting custom icons.
 *
 * This hook returns an object containing the following values:
 * - deleteCustomIcon: Action used to delete a custom icon.
 * - customIconDeleteLoading: Indicates whether the action is pending.
 * - customIconDeleteError: Error that might ave occurred during the process.
 *
 * @return An object returning the values as mentioned above.
 */
export const useDeleteCustomIcon = () => {
  const EXCEPTION_DELETE_CONSTRAINT = "ConstraintViolationException";
  const assetClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  const [deleteCustomIcon, {loading, error}] = useMutation(
    DELETE_CUSTOM_ICON,
    {
      client: assetClient,
    },
  );

  const deleteCustomIconById = useCallback(
    async (id: string) => {
      try {
        const {data, errors} = await deleteCustomIcon({
          variables: {id},
        });

        return {
          data,
          errors: errors?.toString(),
        };
      } catch (ex) {
        return {
          data: null,
          errors: ex,
        };
      }
    },
    [deleteCustomIcon]
  );

  return {
    deleteCustomIcon: deleteCustomIconById,
    customIconDeleteLoading: loading,
    customIconDeleteError: !!error,
    customIconDeleteConflict: !!error && error.message.includes(EXCEPTION_DELETE_CONSTRAINT),
  };
};
