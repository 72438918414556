import React from "react";
import _ from "lodash";
import { DataTableColum } from "./DataTable";

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

type HeadColumnProps = Omit<
  Override<DataTableColum<{}>, { key: string | number | symbol }>,
  "valueAccessor"
>;
interface HeadProps {
  columns: HeadColumnProps[];
}
const TableHeader: React.FC<HeadProps> = ({ columns }) => {
  return (
    <thead>
      <tr>
        {columns.map((column) => {
          if (column.hidden === true) return null;
          return (
            <th
              className={column.classNames?.join(" ") ?? ""}
              key={String(column.key)}
              onClick={() => {
                if (!_.isFunction(column.onClick)) return;
                column.onClick();
              }}
            >
              {column.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
