import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { addCatalogue } from "../ruleManagerSlice";
import { Catalogue } from "../Model/Catalogue";
import i18next from "../../i18n";
import { ERROR_RULE_ENGINE_NO_RULE } from "../../localization";
async function fetchCatalogue(id?: string): Promise<Catalogue | null> {
  if (id === undefined) {
    return null;
  }

  const res: Response = await fetch(
    `${process.env.REACT_APP_RULESENGINE_URI!!}/catalogue/rule/${id}`
  );

  if (res.ok) {
    return res.json();
  }

  if (res.status === 404)
    throw new Error(i18next.t(ERROR_RULE_ENGINE_NO_RULE) ?? "");
  return null;
}

export const useCatalogue = (ruleId?: string) => {
  const dispatch = useDispatch();

  const { data, isLoading, error, isError } = useQuery<
    Catalogue | null | undefined
  >(["ruleElements", ruleId], () => fetchCatalogue(ruleId), {
    enabled: ruleId !== undefined,
  });

  useEffect(() => {
    if (data) {
      dispatch(addCatalogue(data));
    }
  }, [data, dispatch]);

  return {
    catalogueData: data,
    catalogueLoading: isLoading,
    catalogueError: error as string,
    catalogueIsError: isError,
  };
};
