import { Field } from "react-final-form";
import React from "react";
import InputField from "./components/InputField";
import SelectField from "./components/SelectField";
import TimeInputField from "./components/TimeInputField";
import AssetMultiSelect from "../shared/Forms/AssetMultiSelect";
import Boundary from "../shared/Forms/Boundary";
import TypeaheadField from "./components/TypeaheadField";
import TemplateEditor from "../shared/Forms/TemplateEditor";
import DrillDownSelect from "../shared/Forms/DrillDownSelect";
import { useValidation } from "../rules-engine/hooks/useValidation";
import BoundsInput from "./components/BoundsInput";
import ReactMultiEmail from "../shared/ReactMultiEmail";
import CustomCollapse from "../shared/CustomCollapse";
import BuildingpartSelectField from "./components/BuildingpartSelectField";
import CheckboxGroup from "./components/CheckboxGroup";

const fields = {
  InputField,
  SelectField,
  TimeInputField,
  AssetMultiSelect,
  Boundary,
  BoundsInput,
  TypeaheadField,
  TemplateEditor,
  DrillDownSelect,
  ReactMultiEmail,
  CustomCollapse,
};

export type FieldTypes = typeof fields;
type FieldProps = {
  [K in keyof FieldTypes]: React.ComponentPropsWithoutRef<FieldTypes[K]>;
};

export interface FactoryFields {
  id: string;
  component: keyof FieldTypes;
}

export function Factory<T extends keyof FieldTypes>({
  component,
  id,
  ...componentProps
}: FactoryFields & FieldProps[T]) {
  const Component = fields[component];
  const validation = useValidation(Component.name, componentProps.mandatory);
  return (
    <>
      {/*// @ts-ignore*/}
      <Field
        name={id}
        component={Component}
        validate={validation}
        {...componentProps}
      />
    </>
  );
}

export {
  InputField,
  SelectField,
  TimeInputField,
  AssetMultiSelect,
  Boundary,
  BoundsInput,
  TypeaheadField,
  TemplateEditor,
  DrillDownSelect,
  ReactMultiEmail,
  CustomCollapse,
  BuildingpartSelectField,
  CheckboxGroup,
};
