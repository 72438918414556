import moment from "moment";
import React, { useState } from "react";
import { RangeModifier } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { Alert, Button, Card, CardBody, CardHeader } from "reactstrap";
import { Loading } from "../../shared";
import useMeasurementsByRange from "../hooks/useMeasurementsByRange";
import "./AssetTelemetryDetail.scss";
import TelemetryDataChart from "./TelemetryDataChart";
import TemperatureFilters, {AggregationFunction, GroupBy} from "./TemperatureFilters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useKeycloak} from "../../auth";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  ACTION_EXPORT,
  ERROR_NO_DATA_AVAILABLE,
  ERROR_UNKNOWN,
  GENERAL_LAST_MEASURED_TEMPERATURE,
} from "../../localization";

interface AssetTelemetryDetailProps {
  tagMac: string | undefined;
  temperatureRange: {
    lower: number | null;
    upper: number | null;
  };
  assetName: string | undefined;
}

export default function AssetTelemetryDetail({
  tagMac,
  temperatureRange,
  assetName,
}: AssetTelemetryDetailProps) {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<RangeModifier>({
    from: new Date(),
    to: new Date(),
  });

  const [selectedFunction, setSelectedFunction] = useState<string>(
    AggregationFunction.MEAN.value
  );
  const [selectedGroupBy, setSelectedGroupBy] = useState<{
    id: string;
    value: string;
    label: string;
  }>(GroupBy.FIVEMINUTES);

  const keycloak = useKeycloak();

  const {
    data: measurementData,
    loading: measurementLoading,
    error: measurementError,
  } = useMeasurementsByRange(
    tagMac,
    selectedDate.from!.toISOString(),
    selectedDate.to!.toISOString(),
    selectedFunction,
    selectedGroupBy.id
  );

  const filteredMeasurements = measurementData
    ? measurementData.getMeasurementsByRange
        .filter((item: any) => item.temperature !== null)
        .map((item: any) => {
          return {
            x: item.timestamp,
            y: item.temperature,
          };
        })
    : undefined;

  const modifiedMeasurementData:
    | {
        id: any;
        color: string;
        data: {
          x: string;
          y: number;
        }[];
      }
    | undefined = filteredMeasurements
    ? {
        id: moment(selectedDate.from).format("YYYY-MM-DD"),
        color: "hsl(259, 70%, 50%)",
        data: filteredMeasurements,
      }
    : undefined;

  const lastMeasurementData: number | undefined =
    filteredMeasurements && filteredMeasurements.length
      ? filteredMeasurements[filteredMeasurements.length - 1].y
      : undefined;

  const onSelectDate = (date: RangeModifier) => {
    if (date.to && date.from) {
      setSelectedDate(date);
    }
  };

  const onSelectGroupBy = (group: {
    id: string;
    value: string;
    label: string;
  }) => {
    setSelectedGroupBy(group);
  };

  const onSelectFunction = (selectFunction: string) => {
    setSelectedFunction(selectFunction);
  };

  const downloadTemperatures = async () => {
    // Fetch the access token and construct the URL
    const token = await keycloak.getTokenSilently();
    const url = new URL(
      process.env.REACT_APP_REST_URI_DOWNLOAD_TEMPERATURE_FILTERED!,
      window.location.origin
    );

    // Fetch the time zone of the browser
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Parse the search params for filtering
    url.searchParams.append("access_token", token);
    url.searchParams.append("start", selectedDate.from!.toISOString());
    url.searchParams.append("end", selectedDate.to!.toISOString());
    url.searchParams.append("tagMac", tagMac!);
    url.searchParams.append("assetName", assetName!);
    url.searchParams.append("groupBy", selectedGroupBy.id);
    url.searchParams.append("function", selectedFunction);
    if (_.isString(browserTimeZone)) {
      url.searchParams.append("timeZone", browserTimeZone);
    }

    // Start the actual download process
    window.open(url.href, "_blank", "noopener");
  };

  return (
    <>
      {measurementError && <Alert color="danger">{t(ERROR_UNKNOWN)}</Alert>}
      <Card id="assetTelemetryDetail">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <span>
            {`${t(GENERAL_LAST_MEASURED_TEMPERATURE)}: `}
            {(lastMeasurementData && `${lastMeasurementData}°C`) ?? "-"}
          </span>
          <span>
            <Button
              className="me-2"
              size="sm"
              color="primary"
              disabled={
                !!(
                  measurementError ||
                  !modifiedMeasurementData ||
                  modifiedMeasurementData?.data?.length === 0
                )
              }
              onClick={downloadTemperatures}
            >
              <FontAwesomeIcon className="me-2" icon="download" />
              {t(ACTION_EXPORT)}
            </Button>
            <TemperatureFilters
              onSelectDate={onSelectDate}
              onSelectGroupBy={onSelectGroupBy}
              onSelectFunction={onSelectFunction}
              selectedGroupBy={selectedGroupBy}
              selectedFunction={selectedFunction}
              selectedDateRange={selectedDate}
              isLoading={measurementLoading}
            />
          </span>
        </CardHeader>
        <CardBody style={{ height: 400 }}>
          {measurementLoading ? (
            <Loading />
          ) : (
            <>
              {measurementError ||
              !modifiedMeasurementData ||
              modifiedMeasurementData?.data?.length === 0 ? (
                <Alert
                  color="warning"
                  className="d-flex justify-content-center"
                >
                  {t(ERROR_NO_DATA_AVAILABLE)}
                </Alert>
              ) : (
                <TelemetryDataChart
                  measurementData={modifiedMeasurementData}
                  range={temperatureRange}
                />
              )}
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
