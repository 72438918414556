import { RefObject, useLayoutEffect, useState } from "react";

function useResize(ref: RefObject<HTMLElement>) {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      if (ref.current) {
        setSize([ref.current.offsetWidth, ref.current.offsetHeight]);
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, [ref]);

  return size;
}

export default useResize;
