import moment from "moment";
import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { IAsset } from "../../models/assets/Asset";
import Battery from "../../shared/Battery";
import Icon from "../../shared/icon-picker/Icon";
import "./AssetMasterData.scss";
import { DEFAULT_ICON } from "../../customIcons/hooks/useCustomIcons";
import { useTranslation } from "react-i18next";
import {
  GENERAL_BATTERY,
  GENERAL_BEACON_MAC,
  GENERAL_DESCRIPTION,
  GENERAL_FLOOR,
  GENERAL_INVENTORY_NUMBER,
  GENERAL_LAST_SEEN,
  GENERAL_TECHNICAL_ROOM_NUMBER,
  GENERAL_TEMPERATURE_LOWER_LIMIT,
  GENERAL_TEMPERATURE_OFFSET,
  GENERAL_TEMPERATURE_UPPER_LIMIT,
  LOCATION_FIXED,
  LOCATION_LAST,
} from "../../localization";

export default function AssetMasterData({asset}: AssetMasterDataProps) {
  const {t} = useTranslation();

  const lastSeenDate =
    asset.lastPosition?.lastSeen === undefined
      ? null
      : moment(asset.lastPosition?.lastSeen);

  const tag = asset.tags.length === 1 ? asset.tags[0] : null;

  const _renderTemperature = (asset: IAsset) => {
    if (!(asset && asset.allowedTemperatureRange)) return;
    else
      return (
        <>
          {asset.allowedTemperatureRange.upper !== null ? (
            <Row>
              <Col sm={4}>
                <span>{t(GENERAL_TEMPERATURE_UPPER_LIMIT)}:</span>
              </Col>
              <Col sm={6}>
                <span>{asset.allowedTemperatureRange.upper.toFixed(1)}°C</span>
              </Col>
            </Row>
          ) : null}
          {asset.allowedTemperatureRange.lower !== null ? (
            <Row>
              <Col sm={4}>
                <span>{t(GENERAL_TEMPERATURE_LOWER_LIMIT)}:</span>
              </Col>
              <Col sm={6}>
                <span>{asset.allowedTemperatureRange.lower.toFixed(1)}°C</span>
              </Col>
            </Row>
          ) : null}
        </>
      );
  };

  function renderField(value?: any, description?: string) {
    if (value === undefined || value === null || value === "") return null;

    return (
      <Row>
        <Col sm={4}>
          <span>{description}:</span>
        </Col>
        <Col sm={6}>
          <span className="attribute-field-value">{value}</span>
        </Col>
      </Row>
    );
  }

  return (
    <Card className="mb-3" id="assetMasterData">
      <CardHeader>{asset.name}</CardHeader>
      <CardBody>
        <Row>
          <Col sm={{size: "auto"}}>
            <Icon
              icon={{
                id: "temp_icon",
                tags: [],
                iconCode: asset.icon ?? DEFAULT_ICON.iconCode,
              }}
            />
          </Col>
          <Col sm={6}>
            {asset.tags.some((value) => value.battery) ? (
              <Row>
                <Col sm={4}>
                  <span>{t(GENERAL_BATTERY)}:</span>
                </Col>
                <Col sm={6}>
                  <span>
                    <Battery asset={asset}/>
                  </span>
                </Col>
              </Row>
            ) : null}
            {renderField(
              tag?.mac,
              t(GENERAL_BEACON_MAC) ?? "",
            )}
            {renderField(
              tag?.offset ? `${tag?.offset}°C` : undefined,
              t(GENERAL_TEMPERATURE_OFFSET) ?? "",
            )}
            {_renderTemperature(asset)}
            {renderField(asset.description, t(GENERAL_DESCRIPTION) ?? "")}
            {renderField(
              asset.inventoryNumber,
              t(GENERAL_INVENTORY_NUMBER) ?? ""
            )}
          </Col>
          <Col>
            {asset.lastPosition?.levelName ? (
              <Row>
                <Col sm={6}>
                  <span>{t(GENERAL_FLOOR)}:</span>
                </Col>
                <Col sm={4}>{asset.lastPosition?.levelName}</Col>
              </Row>
            ) : null}
            {asset.lastPosition?.technicalRoomNumber ? (
              <Row>
                <Col sm={6}>
                  <span>{t(GENERAL_TECHNICAL_ROOM_NUMBER)}:</span>
                </Col>
                <Col>
                  {asset.technicalRoomNumber ??
                    asset.lastPosition?.technicalRoomNumber}
                </Col>
              </Row>
            ) : null}
            {asset.lastPosition?.locatorName ? (
              <Row>
                <Col sm={6}>
                  <span>{t(LOCATION_LAST)}:</span>
                </Col>
                <Col>{asset.lastPosition.locatorName}</Col>
              </Row>
            ) : null}
            {asset.technicalRoomNumber ? (
              <Row>
                <Col sm={6}>
                  <span>{t(LOCATION_FIXED)}:</span>
                </Col>
                <Col>{asset.technicalRoomNumber}</Col>
              </Row>
            ) : null}
            {lastSeenDate?.isValid() && !asset.telemetry?.temperature ? (
              <Row>
                <>
                  <Col sm={6}>
                    <span>{t(GENERAL_LAST_SEEN)}:</span>
                  </Col>
                  <Col sm={4}>{lastSeenDate.format("DD.MM.YYYY HH:mm")}</Col>
                </>
              </Row>
            ) : null}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

interface AssetMasterDataProps {
  asset: IAsset;
  path?: string;
}
