import { useQuery } from "react-query";
import { useKeycloakToken } from "../../hooks/useKeycloakToken";

async function fetchRules(token?: string): Promise<RuleListObject[]> {
  const res = await fetch(`${process.env.REACT_APP_RULESENGINE_URI!!}/rule`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.json();
}

export const useRuleList = () => {
  const token = useKeycloakToken();

  const { data, error, isLoading, isError, refetch } = useQuery(
    ["ruleList", token],
    () => fetchRules(token),
    {
      enabled: !!token,
    }
  );

  if (error) {
    console.error(error);
  }

  return {
    data,
    listError: isError,
    listLoading: isLoading,
    fetchRuleList: refetch,
  };
};

export interface RuleListObject {
  id: string;
  creator: string;
  description: string;
  name: string;
}
