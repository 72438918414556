import useGqlClient from "../../hooks/useGqlClient";
import { useQuery } from "@apollo/client";
import { GET_ALL_CUSTOM_ICON_TAGS } from "../../asset/queries/assetQuery";
import { CustomIconTag } from "../../models/CustomIcon";

/**
 * Custom hook that is responsible for fetching all custom icon tags.
 *
 * This hook return an object containing the following values:
 * - customTags: List of all tags that have been fetched.
 * - customTagsLoading: Indicates whether the content is loading.
 * - customTagsError: Error that might have occurred when fetching the tags.
 * - customTagsRefetch: Action to refetch the custom icon tag data.
 */
export const useCustomIconTags = () => {
  const assetClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  const {data, loading, error, refetch} = useQuery(GET_ALL_CUSTOM_ICON_TAGS, {
    client: assetClient,
  });

  if (error)
    console.error(error);

  return {
    customTags: data?.customIconTags as CustomIconTag[] | [],
    customTagsLoading: loading,
    customTagsError: !!error,
    customTagsRefetch: refetch,
  };
};
