import { gql } from "@apollo/client";

export const GetAssetFormData = gql`
  query assetFormData($id: ID!, $withAsset: Boolean!) {
    asset(id: $id) @include(if: $withAsset) {
      name
      description
      buildingPartIDExternal
      inventoryNumber
      icon
      lastPosition {
        latitude
        longitude
        levelName
        locationId
        levelId
      }
      useCases {
        id
        name
      }

      assetGroups {
        id
        name
      }

      tags {
        id
        name
        offset
        ... on IBeacon {
          mac
        }
        ... on EddystoneBeacon {
          mac
        }
        ... on WLANClient {
          mac
        }
      }

      allowedTemperatureRange {
        upper
        lower
      }

      customIconId
    }

    assetGroups {
      id
      name
    }

    availableTags {
      id
      name
      offset
      ... on IBeacon {
        mac
      }
      ... on EddystoneBeacon {
        mac
      }
      ... on WLANClient {
        mac
      }
    }

    useCases {
      id
      name
    }
  }
`;

export const CreateOrUpdateAssetQuery = gql`
  mutation createOrUpdateAsset($input: AssetInput!) {
    createOrUpdateAsset(input: $input) {
      id
    }
  }
`;
