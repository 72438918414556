import {
  gridEditRowsStateSelector,
  GridRowId,
  useGridApiContext,
} from "@mui/x-data-grid";
import React from "react";
import { IconButton } from "@mui/material";
import { Save } from "@mui/icons-material";

const AssetGroupSaveButton: React.FC<AssetGroupColumnsProps> = ({
  id,
  currentValue,
  handleSaveClick,
}) => {
  const apiRef = useGridApiContext();
  const editState = gridEditRowsStateSelector(apiRef.current.state);
  const nameValue = editState[id]?.Name?.value;
  const valueEquals = currentValue === nameValue;
  return (
    <IconButton onClick={() => handleSaveClick(id, nameValue, valueEquals)}>
      <Save color="secondary" />
    </IconButton>
  );
};

interface AssetGroupColumnsProps {
  id: GridRowId;
  currentValue: string;
  handleSaveClick(id: GridRowId, value: string, cancel: boolean): void;
}

export default AssetGroupSaveButton;
