import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_LEVEL } from "../shared/queries/DrilldownFilterQueries";
import useGqlClient from "./useGqlClient";

function buildRoots(buildingparts: Node[]) {
  return buildingparts
    .filter((node: Node) => !node.parent)
    .map((node: Node) => {
      return new TreeNode(node.id, node.name);
    });
}

function addToTree(buildpartsToAdd: Node[], current?: TreeNode[]) {
  const trees = current ? [...current] : [];
  for (const node of buildpartsToAdd) {
    for (const tree of trees) {
      if (node.parent) {
        const treeNode = tree.findNode(node.parent);
        treeNode?.addChild(new TreeNode(node.id, node.name));
      }
    }
  }

  return trees;
}

const useDrilldownData = (
  buildingPartExternalId?: string,
  initialBuildingparts?: Node[],
  useMaxDepth = true
) => {
  const buildingDataClient = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_BUILDING_DATA!
  );
  const [buildingTree, setBuildingTree] = useState<TreeNode[]>();

  const { data, loading, error } = useQuery<{ getLevel: Node[] }>(GET_LEVEL, {
    variables: {
      buildingPartExternalId: buildingPartExternalId,
      useMaxDepth: useMaxDepth,
    },
    client: buildingDataClient,
  });

  useEffect(() => {
    if (initialBuildingparts !== undefined) {
      const roots = buildRoots(initialBuildingparts);
      setBuildingTree(addToTree(initialBuildingparts, roots));
    }
  }, [initialBuildingparts]);

  useEffect(() => {
    if (data?.getLevel) {
      setBuildingTree((bt) => {
        if(buildingPartExternalId === undefined) bt = undefined; // reset current if field was reset/cleared
        return addToTree(data?.getLevel, bt ?? buildRoots(data.getLevel));
      });
    }
  }, [data, buildingPartExternalId]);

  return { buildingTree: buildingTree ?? [], loading, error };
};

export default useDrilldownData;

export interface Node {
  id: string;
  name: string;
  parent?: string | null;
}

export class TreeNode {
  readonly id: string;
  readonly name: string;
  private _childNodes: TreeNode[] = [];

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  get childNodes() {
    return this._childNodes;
  }

  addChild(child: TreeNode) {
    if (this.findNode(child.id) !== undefined) return false;
    this._childNodes = [...this._childNodes, child];
    return true;
  }

  findNode(id: string): TreeNode | undefined {
    if (this.id === id) return this;
    let maybeChild = this._childNodes.find((child) => child.id === id);
    if (maybeChild !== undefined) return maybeChild;

    for (const child of this._childNodes) {
      maybeChild = child.findNode(id);
      if (maybeChild !== undefined) break;
    }

    return maybeChild;
  }
}
