import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import "./ProgressNavigation.scss";
import { Col, Row } from "reactstrap";
import {
  RuleSequence,
  RuleStateMachine,
} from "../../rules-engine/Model/RuleSequence";
import { setMenuPoint } from "../../rules-engine/ruleManagerSlice";
import { useDispatch } from "react-redux";
import ProgressItem from "./ProgressItem";

const ProgressNavigation: React.FC<ProgressNavigationProps> = ({
  progressPoints,
  highlighted,
}) => {
  const dispatch = useDispatch();
  const ruleSeq = new RuleStateMachine();
  return (
    <>
      {progressPoints.map((progressPoint, i) => (
        <Row id="progress-point" key={i}>
          <Col sm={1}>
            <FontAwesomeIcon
              className="bg-white"
              style={{ zIndex: 1 }}
              icon={progressPoint.icon as IconName}
              fixedWidth
            />
            <div className="vr progress-line" />
          </Col>
          <Col>
            <div className="mb-2">
              <ProgressItem
                title={progressPoint.title}
                itemText={progressPoint.description}
                onClick={() => {
                  if (progressPoint.state) {
                    dispatch(setMenuPoint(progressPoint.state));
                  }
                }}
                isUnselected={ruleSeq.isListState(progressPoint.state)}
                isHighlighted={progressPoint.id === highlighted}
                showGoBackButton={!ruleSeq.isListState(progressPoint.state)}
                onGoBack={() => {
                  if (!ruleSeq.isListState(progressPoint.state)) {
                    dispatch(
                      setMenuPoint(ruleSeq.previous(progressPoint.state))
                    );
                  }
                }}
              />
            </div>
          </Col>
        </Row>
      ))}
      <Row id="progress-point-last">
        <Col sm={1}>
          <FontAwesomeIcon
            className="bg-white"
            style={{ zIndex: 1 }}
            icon={faCircle}
            fixedWidth
          />
        </Col>
      </Row>
    </>
  );
};
export default ProgressNavigation;

export interface ProgressNavigationProps {
  progressPoints: ProgressPoint[];
  highlighted?: string;
}

export interface ProgressPoint {
  id?: string;
  icon: string;
  title: string;
  description: string;
  state: RuleSequence;
}
