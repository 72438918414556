import { gql, useQuery } from "@apollo/client";
import { useGqlClient } from "../../hooks";

const GET_PARENTS = gql`
  query GetParents($id: ID!) {
    getParents(buildingPartExternalId: $id) {
      id
      name
      parent
    }
  }
`;

export function useBuildingpartParents(id?: string) {
  const client = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_BUILDING_DATA!);
  const { data } = useQuery<{
    getParents: [{ id: string; name: string; parent: string }];
  }>(GET_PARENTS, {
    client,
    skip: !id,
    variables: {
      id,
    },
  });

  return data?.getParents;
}
