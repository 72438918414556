import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { WriteFieldProperties } from "./Model/RuleModels";
import { ProgressPoint } from "../shared/progress-navigation/ProgressNavigation";
import RulesService from "./service/RulesService";
import ProgressNavigationService from "./service/ProgressNavigationService";
import { RuleElement } from "./Model/RuleElement";
import { Trigger } from "./Model/Trigger";
import { Condition } from "./Model/Condition";
import { Action } from "./Model/Action";
import { RuleSequence, RuleStateMachine } from "./Model/RuleSequence";
import { RuleState } from "./Model/RuleState";
import { Catalogue } from "./Model/Catalogue";

interface RuleManagerState extends RuleState {
  ruleSequence: RuleSequence;
  progressBar: ProgressPoint[];
}

const initialState: RuleManagerState = {
  creator: null,
  name: null,
  description: null,
  triggerInfo: null,
  triggers: null,
  conditionInfo: null,
  actionInfo: null,
  actions: null,
  ruleSequence: RuleSequence.TRIGGER_LIST,
  progressBar: [],
};

const slice = createSlice({
  name: "RuleManager",
  initialState,
  reducers: {
    setMenuPoint: (
      state: RuleManagerState,
      action: PayloadAction<RuleSequence>
    ) => {
      state.ruleSequence = action.payload;
    },

    setStateId: (
      state: RuleManagerState,
      action: PayloadAction<{
        stateId: RuleSequence;
        value: RuleElement | null;
      }>
    ) => {
      const ruleSeq = new RuleStateMachine();
      switch (action.payload.stateId) {
        case RuleSequence.TRIGGER_LIST:
          state.triggerInfo = action.payload.value;
          break;
        case RuleSequence.CONDITION_LIST:
          state.conditionInfo = action.payload.value;
          break;
        case RuleSequence.ACTION_LIST:
          state.actionInfo = action.payload.value;
          break;
      }
      state.ruleSequence = ruleSeq.next(action.payload.stateId);
    },

    submitFields: (
      state: RuleManagerState,
      newAction: PayloadAction<{
        ruleSequence: string;
        id: string;
        fieldValues: WriteFieldProperties;
      }>
    ) => {
      switch (newAction.payload.ruleSequence) {
        case RuleSequence.TRIGGER_FORM: {
          const changedTrigger: Trigger = {
            triggerType: state.triggerInfo?.fieldType ?? newAction.payload.id,
            params: newAction.payload.fieldValues,
            elementId: state.triggerInfo?.ruleElementId,
          };

          state.triggers = RulesService.addOrUpdateTrigger(
            state.triggers,
            changedTrigger
          );
          break;
        }
        case RuleSequence.CONDITION_FORM: {
          const condition: Condition = {
            type: state.conditionInfo?.fieldType ?? newAction.payload.id,
            params: newAction.payload.fieldValues,
          };
          const foundTrigger = state.triggers?.find(
            (trigger) => trigger.triggerType === state.triggerInfo?.fieldType
          );

          if (foundTrigger) {
            state.triggers = RulesService.addOrUpdateTrigger(
              state.triggers ?? [],
              { ...foundTrigger, conditionParams: condition }
            );
          }

          break;
        }
        case RuleSequence.ACTION_FORM: {
          const actions = state.actions?.map((action: Action) => {
            if (action.type === state.actionInfo?.fieldType) {
              return {
                ...action,
                params: newAction.payload.fieldValues,
              };
            }
            return action;
          });

          state.actions = actions ?? [
            {
              type: state.actionInfo?.fieldType ?? newAction.payload.id,
              params: newAction.payload.fieldValues,
            },
          ];
          break;
        }
      }

      if (state.ruleSequence !== RuleSequence.RULE_FORM) {
        const ruleSeq = new RuleStateMachine();
        if (
          state.progressBar.some(
            (progressPoint) =>
              progressPoint.state ===
              ruleSeq.next(ruleSeq.next(state.ruleSequence))
          )
        ) {
          //skip list state when its already present
          state.ruleSequence = ruleSeq.next(ruleSeq.next(state.ruleSequence));
        } else {
          state.ruleSequence = ruleSeq.next(state.ruleSequence);
        }
      }
    },
    addProgressPoint: (
      state: RuleManagerState,
      action: PayloadAction<ProgressPoint>
    ) => {
      const progressPoint = { ...action.payload };

      if (!progressPoint.state || !progressPoint.id) {
        return;
      }

      //Check if progressPoint already exists
      if (state.progressBar.some((elem) => elem.id === action.payload.id)) {
        return;
      }

      state.progressBar = ProgressNavigationService.replaceProgressPoint(
        state.progressBar,
        progressPoint,
        progressPoint.state
      );
    },
    addCatalogue: (
      state: RuleManagerState,
      action: PayloadAction<Catalogue>
    ) => {
      const ruleSeq = new RuleStateMachine();
      state.progressBar = ProgressNavigationService.generateProgressBar(
        action.payload.navigation.items.map((item) => {
          return { ...item, state: ruleSeq.getState(item.fieldType) };
        })
      );

      action.payload.navigation.items.forEach((item) => {
        switch (ruleSeq.getState(item.fieldType)) {
          case RuleSequence.TRIGGER_FORM:
            state.triggerInfo = item;
            break;
          case RuleSequence.CONDITION_FORM:
            state.conditionInfo = item;
            break;
          case RuleSequence.ACTION_FORM:
            state.actionInfo = item;
            break;
        }
      });

      action.payload.elements.forEach((element) => {
        const initValues: WriteFieldProperties =
          RulesService.transformInitValues(element.fields);

        slice.caseReducers.submitFields(state, {
          type: action.type,
          payload: {
            id: element.type,
            ruleSequence:
              ruleSeq.getState(element.type) ?? RuleSequence.TRIGGER_FORM,
            fieldValues: initValues,
          },
        });
      });
      state.ruleSequence = RuleSequence.TRIGGER_FORM;
    },
    resetState: () => initialState,
  },
});

export const {
  addProgressPoint,
  resetState,
  setStateId,
  setMenuPoint,
  submitFields,
  addCatalogue,
} = slice.actions;

export const getRuleSequence = (state: RootState) =>
  state.ruleManager.ruleSequence;

export const getProgressBar = (state: RootState) =>
  state.ruleManager.progressBar;

export const getRuleInfo = (state: RootState) => {
  switch (state.ruleManager.ruleSequence) {
    case RuleSequence.TRIGGER_LIST:
      return state.ruleManager.triggerInfo;
    case RuleSequence.TRIGGER_FORM:
      return state.ruleManager.triggerInfo;
    case RuleSequence.CONDITION_LIST:
      return state.ruleManager.conditionInfo;
    case RuleSequence.CONDITION_FORM:
      return state.ruleManager.conditionInfo;
    case RuleSequence.ACTION_LIST:
      return state.ruleManager.actionInfo;
    case RuleSequence.ACTION_FORM:
      return state.ruleManager.actionInfo;
    case RuleSequence.RULE_FORM:
      return state.ruleManager.actionInfo;
    default:
      return null;
  }
};

export const getRuleState = (state: RootState) => {
  const ruleState: RuleState = {
    ...state.ruleManager,
  } as RuleState;
  return ruleState;
};

export default slice.reducer;
