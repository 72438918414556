import { ResponsiveLine } from "@nivo/line";
import React, { useMemo } from "react";
import { Card, CardBody } from "reactstrap";
import { CartesianMarkerProps } from "@nivo/core";
import {
  GENERAL_DATE,
  GENERAL_TEMPERATURE,
  GENERAL_TIME,
} from "../../localization";
import { useTranslation } from "react-i18next";

export default function TelemetryDataChart({
  measurementData,
  range,
}: TelemetryDataChartProps) {
  const temperatureRangeColor = "#FF6347";
  const defaultYScale = {
    max: 30.0,
    min: -10.0,
  };
  const defaultOffset = 5;
  const { i18n, t } = useTranslation();

  const minValue = measurementData.data.reduce((previousValue, currentValue) =>
    previousValue.y < currentValue.y ? previousValue : currentValue
  ).y;
  const maxValue = measurementData.data.reduce((previousValue, currentValue) =>
    previousValue.y > currentValue.y ? previousValue : currentValue
  ).y;

  function getMarkers() {
    const markers: CartesianMarkerProps[] = [];
    if (range.lower !== null) {
      markers.push({
        axis: "y",
        value: range.lower,
        legend: `${range.lower}°C`,
        legendPosition: "right",
        lineStyle: { stroke: temperatureRangeColor },
        textStyle: { fill: temperatureRangeColor },
      });
    }
    if (range.upper !== null) {
      markers.push({
        axis: "y",
        value: range.upper,
        legend: `${range.upper}°C`,
        legendPosition: "right",
        lineStyle: { stroke: temperatureRangeColor },
        textStyle: { fill: temperatureRangeColor },
      });
    }
    return markers;
  }

  /**
   * Cannot use {@link CustomLayerProps} as type because of this:
   * https://github.com/plouc/nivo/issues/1947
   */
  function refsLayer({ yScale, innerHeight, innerWidth }: any) {
    // Use the diagram min/max values as fallback if any bound is missing
    const lowerBounds = range.lower ?? calculateScaling.min;
    const upperBounds = range.upper ?? calculateScaling.max;

    // Calculate the rect bounds
    const areaColor = "rgba(191, 191, 191, 1)";
    const inner = Math.abs(yScale(upperBounds) - yScale(lowerBounds));
    const heightLowerArea = innerHeight - yScale(lowerBounds);
    const heightUpperArea = innerHeight - heightLowerArea - inner;

    return (
      <g>
        {heightLowerArea > 0 ? (
          <rect
            y={heightLowerArea > innerHeight ? null : yScale(lowerBounds)}
            width={innerWidth}
            height={
              heightLowerArea > innerHeight ? innerHeight : heightLowerArea
            }
            opacity={0.2}
            fill={areaColor}
          />
        ) : null}
        {heightUpperArea > 0 ? (
          <rect
            width={innerWidth}
            height={
              heightUpperArea > innerHeight ? innerHeight : heightUpperArea
            }
            opacity={0.2}
            fill={areaColor}
          />
        ) : null}
      </g>
    );
  }

  const calculateScaling = useMemo(() => {
    //check default scaling (-10 30) with max and min temperature value of line
    const min = minValue < defaultYScale.min ? minValue : defaultYScale.min;
    const max = maxValue > defaultYScale.max ? maxValue : defaultYScale.max;
    //add offset to boundary of asset
    const lowerWithOffset = range.lower ? range.lower - defaultOffset : null;
    const upperWithOffset = range.upper ? range.upper + defaultOffset : null;
    //check boundary with default scaling
    const lower = lowerWithOffset
      ? lowerWithOffset < min
        ? lowerWithOffset
        : min
      : min;
    const upper = upperWithOffset
      ? upperWithOffset > max
        ? upperWithOffset
        : max
      : max;

    //check if line is under marker
    return {
      min: minValue - defaultOffset < lower ? minValue - defaultOffset : lower,
      max: maxValue + defaultOffset > upper ? maxValue + defaultOffset : upper,
    };
  }, [
    defaultYScale.max,
    defaultYScale.min,
    maxValue,
    minValue,
    range.lower,
    range.upper,
  ]);

  const _handleDateFormat = () => {
    switch (i18n.resolvedLanguage) {
      case "de":
      case "ch":
        return "%d.%m.%Y %H:%M";
      case "fr":
      case "it":
        return "%d/%m/%Y %H:%M";
      case "en":
      default:
        return "%m/%d/%Y %H:%M";
    }
  };

  return (
    <div style={{ position: "absolute", height: "80%", width: "92%" }}>
      {/*Above line is needed to make the responsive line shrink*/}
      <ResponsiveLine
        data={[measurementData]}
        markers={getMarkers()}
        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
        xScale={{
          type: "time",
          format: "%Y-%m-%dT%H:%M:%S%Z",
          precision: "minute",
        }}
        xFormat="time:%Y-%m-%dT%H:%M:%S%Z"
        yScale={{
          type: "linear",
          min: calculateScaling.min,
          max: calculateScaling.max,
          stacked: false,
        }}
        axisBottom={{
          format: _handleDateFormat(),
          legend: `${t(GENERAL_DATE)} & ${t(GENERAL_TIME)}`,
          legendOffset: 36,
          legendPosition: "middle",
        }}
        layers={[
          refsLayer,
          "grid",
          "markers",
          "axes",
          "areas",
          "crosshair",
          "lines",
          "points",
          "slices",
          "mesh",
          "legends",
        ]}
        tooltip={({ point }) => (
          <Card>
            <CardBody>
              <div>
                {t(GENERAL_DATE)}:{" "}
                {(point.data.x as Date).toLocaleDateString(
                  i18n.resolvedLanguage
                )}
              </div>
              <div>
                {t(GENERAL_TIME)}:{" "}
                {(point.data.x as Date).toLocaleTimeString(
                  i18n.resolvedLanguage
                )}
              </div>
              <div>
                {t(GENERAL_TEMPERATURE)}: {point.data.y}°C
              </div>
            </CardBody>
          </Card>
        )}
        axisLeft={{
          legend: t(GENERAL_TEMPERATURE),
          legendOffset: -50,
          legendPosition: "middle",
        }}
        colors={{ scheme: "nivo" }}
        useMesh={true}
        animate
        pointLabelYOffset={0}
      />
    </div>
  );
}

interface TelemetryDataChartProps {
  measurementData: {
    id: any;
    color: string;
    data: {
      x: string;
      y: number;
    }[];
  };
  range: {
    lower: number | null;
    upper: number | null;
  };
}
