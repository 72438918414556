import React from "react";
import Avatar from "react-avatar";
import { useKeycloak } from "../auth";

function UserIcon() {
  const keycloak = useKeycloak();
  const profile = keycloak?.user;
  if (!profile) return null;

  return (
    <div className="Avatar text-center">
      <Avatar
        className="rounded-circle"
        name={`${profile.firstName} ${profile.lastName}`}
        size="50"
      />
    </div>
  );
}

export default UserIcon;
