import { gql } from "@apollo/client";

export const eddystoneFragment = gql`
  fragment eddystoneFields on EddystoneBeacon {
    mac
    nId
    bId
    txPower
    advInterval
  }
`;

export const iBeaconFragment = gql`
  fragment ibeaconFields on IBeacon {
    mac
    uuid
    majorId
    minorId
    txPower
    advInterval
  }
`;

export const WLANClientFragment = gql`
  fragment wlanClientFields on WLANClient {
    mac
  }
`;

export const ASSET_BY_ID = gql`
  query Asset($id: ID!) {
    asset(id: $id) {
      id
      name
      icon
      description
      inventoryNumber
      allowedTemperatureRange {
        lower
        upper
      }
      tags {
        battery
        offset
        __typename
        ...ibeaconFields
        ...eddystoneFields
        ...wlanClientFields
      }
      technicalRoomNumber
      lastPosition {
        latitude
        lastSeen
        layerId
        locatorId
        longitude
        levelId
        locationId
        locatorName
        levelName
        technicalRoomNumber
      }
      customIconId
    }
  }
  ${eddystoneFragment}
  ${iBeaconFragment}
  ${WLANClientFragment}
`;

export const ALERT_ASSET_BY_ID = gql`
  query Asset($id: ID!) {
    asset(id: $id) {
      lastPosition {
        assetId
        assetName
        icon
        latitude
        lastSeen
        layerId
        locatorId
        longitude
        locatorName
        levelName
        technicalRoomNumber
      }
    }
  }
`;

export const GET_UPDATE_ON_POSITION_BY_ID = gql`
  subscription positionById($assetId: ID!) {
    positionById(assetId: $assetId) {
      assetId
      assetName
      icon
      latitude
      lastSeen
      layerId
      locatorId
      locationId
      levelId
      longitude
      locatorName
      levelName
      technicalRoomNumber
    }
  }
`;

export const GET_ALL_ASSETS_AND_ASSETGROUPS = gql`
  query getAllAssets {
    getAllAssets {
      value: id
      label: name
      assetGroups {
        id
      }
    }
    assetGroups {
      value: id
      label: name
    }
  }
`;

export const GET_ALL_ASSETGROUPS = gql`
  query {
    assetGroups {
      id
      Name: name
    }
  }
`;

export const CREATE_OR_UPDATE_ASSETGROUP = gql`
  mutation createOrUpdateAssetGroup($assetGroupInput: AssetGroupInput!) {
    createOrUpdateAssetGroup(assetGroupInput: $assetGroupInput) {
      id
    }
  }
`;

export const DELETE_ASSETGROUP = gql`
  mutation deleteAssetGroup($id: ID!) {
    deleteAssetGroup(id: $id) {
      id
    }
  }
`;

export const GET_ALL_CUSTOM_ICONS = gql`
  query {
    customIcons {
      id
      tags {
        id
        tagName
      }
      iconCode
    }
  }
`;

export const CREATE_OR_UPDATE_CUSTOM_ICON = gql`
  mutation createOrUpdateCustomIcon($customIconInput: CustomIconInput!) {
    createOrUpdateCustomIcon(customIconInput: $customIconInput) {
      id
      tags {
        id
        tagName
      }
      iconCode
    }
  }
`;

export const DELETE_CUSTOM_ICON = gql`
  mutation deleteCustomIcon($id: ID!) {
    deleteCustomIcon(id: $id) {
      id
      tags {
        id
        tagName
      }
      iconCode
    }
  }
`;

export const GET_ALL_CUSTOM_ICON_TAGS = gql`
  query {
    customIconTags {
      id
      tagName
    }
  }
`;
