import React from "react";
import { Layer } from "react-map-gl";
import { HeatmapColors } from "../asset/maps/HeatmapColors";
import { Level } from "../models/Level";

const MAX_ZOOM_LEVEL = 24;
const METERS_P_PXL = 78271.484;
const DEGREE = 180;
const BLUR_RATIO = 0.5;
const CIRCLE_OPACITY = 0.6;

function HeatLayer({ currentLevel }: { currentLevel?: Level | null }) {
  return (
    <Layer
      id={"heatmap"}
      type={"circle"}
      maxzoom={MAX_ZOOM_LEVEL}
      source={"heatmap-source"}
      paint={{
        "circle-radius": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          0,
          0,
          MAX_ZOOM_LEVEL,
          [
            "/",
            [
              "/",
              ["get", "radius"],
              ["/", METERS_P_PXL, ["^", 2, MAX_ZOOM_LEVEL]],
            ],
            ["cos", ["/", ["*", ["get", "latitude"], ["pi"]], DEGREE]],
          ],
        ],
        "circle-opacity": CIRCLE_OPACITY,
        "circle-color": [
          "interpolate",
          ["linear"],
          ["get", "duration"],
          0,
          HeatmapColors.LIGHTGREEN,
          10,
          HeatmapColors.GREEN,
          30,
          HeatmapColors.YELLOW,
          70,
          HeatmapColors.ORANGE,
          100,
          HeatmapColors.RED,
        ],
        "circle-blur": BLUR_RATIO,
      }}
    />
  );
}

export default HeatLayer;
