import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import DayPicker, { DateUtils, RangeModifier } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { Alert, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import ActiveAssetsFilter, {
  useActiveAssets,
} from "../../asset/components/ActiveAssetsFilter";
import useAssetDelete from "../../asset/hooks/useAssetDelete";
import { useKeycloak } from "../../auth";
import useDrilldownData from "../../hooks/drilldownData";
import useAssets from "../../hooks/useAssets";
import useGqlClient from "../../hooks/useGqlClient";
import "../../index.scss";
import { rolePermission } from "../../rolePermission";
import { ROUTES } from "../../routes";
import { Loading, Modal as HyprosModal } from "../../shared";
import AssetTrackingTemplate from "../components/AssetTrackingTemplate";
import { GET_USE_CASE } from "../queries/useCaseQueries";
import {
  ACTION_CANCEL,
  ACTION_DELETE,
  ACTION_EDIT,
  ACTION_EXPORT,
  ACTION_SHOW_MAP,
  ERROR_LOADING,
  GENERAL_SELECTED_DATE_RANGE,
  GENERAL_UNTIL,
  INFO_SELECT_END_DATE,
  INFO_SELECT_START_DATE,
  QUESTION_ASSET_DELETE,
  USE_CASE_ASSET_TRACKING,
} from "../../localization";
import { useTranslation } from "react-i18next";

const initialDateRangeState = {
  from: undefined,
  to: undefined,
};

export default function AssetTracking() {
  const { i18n, t } = useTranslation();
  const locale = i18n.resolvedLanguage;
  const name = "Asset Tracking";
  const alertTime: number = 4000;

  const assetsClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);
  const { state } = useLocation<any>();
  const history = useHistory();
  const [alertShow, setAlertShow] = useState<boolean>(false);
  const [assetDeleteId, setAssetDeleteId] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setModalShow] = useState<boolean>(false);
  const [showDownloadForm, setShowDownloadForm] = useState<boolean>(false);
  const [rootID, setRootID] = useState<string | undefined>(undefined);
  const [alertInput, setAlertInput] = useState<{
    show: boolean;
    color: string;
    message: string;
  }>({
    show: false,
    color: "",
    message: "",
  });
  const keycloak = useKeycloak();

  const [dateRangeState, setDateRangeState] = useState<RangeModifier>(
    initialDateRangeState
  );
  const { from, to }: RangeModifier = dateRangeState;
  const modifiers = { start: from as Date, end: to as Date };

  const {
    data: useCaseData,
    loading: useCaseLoading,
    error: useCaseError,
  } = useQuery(GET_USE_CASE, {
    client: assetsClient,
    variables: {
      name: name,
    },
  });

  const useCaseId = useCaseData?.useCase?.id;
  const { buildingTree, loading: buildingTreeLoading } =
    useDrilldownData(rootID);

  const {
    assetPage,
    loading: assetLoading,
    error: assetError,
    filterData,
    refetch,
    setBuildingPart,
    setGroupFilter,
    setPagination,
    setActiveStatus,
  } = useAssets(useCaseId);

  const activeAssets = useActiveAssets(filterData.activeFilter);

  useEffect(() => {
    setLoading(assetLoading || useCaseLoading);
  }, [assetLoading, useCaseLoading]);

  const {
    _assetDelete,
    loading: deleteLoading,
    error,
    message,
  } = useAssetDelete(assetDeleteId, refetch);

  useEffect(() => {
    setLoading(assetLoading || useCaseLoading || deleteLoading);
  }, [assetLoading, useCaseLoading, deleteLoading]);

  useEffect(() => {
    if (error) {
      setAlertInput({
        show: true,
        color: "danger",
        message,
      });
    } else if (error === false) {
      setAlertInput({
        show: true,
        color: "success",
        message,
      });
      setTimeout(() => {
        setAlertInput((input) => ({ ...input, show: false }));
      }, 5000);
    }
  }, [error, message]);

  useEffect(() => {
    setHasError(assetError !== undefined || useCaseError !== undefined);
  }, [assetError, useCaseError]);

  useEffect(() => {
    let mounted = true;

    const _onShowAlert = () => {
      setAlertShow(true);
      setTimeout(() => {
        setAlertShow(false);
        delete state.renderAlert;
        history.replace(state);
      }, alertTime);
    };

    if (state?.renderAlert && mounted) {
      _onShowAlert();
    }

    return () => {
      mounted = false;
    };
  }, [state, history]);

  const _cancelModal = () => {
    setAssetDeleteId("");
    setModalShow(false);
  };

  const _onAlertDismiss = () => {
    setAlertShow(false);
    const state = { ...(history.location.state as any) };
    delete state.renderAlert;
    history.replace(state);
  };

  const _onDeleteDismiss = () => {
    setAlertInput({ ...alertInput, show: false });
  };

  const _toggleModal = () => {
    setShowDownloadForm(!showDownloadForm);
  };

  const handleDayClick = (day: Date) => {
    const range = DateUtils.addDayToRange(day, { from, to });
    setDateRangeState(range);
  };

  const download = async () => {
    const token = await keycloak.getTokenSilently();
    const url = new URL(
      process.env.REACT_APP_REST_URI_DOWNLOAD_LOCATION_CHANGES!,
      window.location.origin
    );
    from!.setHours(12);
    to!.setHours(12);
    url.searchParams.append("access_token", token);
    url.searchParams.append("useCaseName", "Asset Tracking");
    url.searchParams.append("startDate", from!.toUTCString());
    url.searchParams.append("endDate", to!.toUTCString());

    window.open(url.href, "_blank", "noopener");
  };

  const _renderDownloadModal = () => {
    return (
      <Modal isOpen={showDownloadForm}>
        <div className="modal-header">
          <h5 className="modal-title">Excel Export</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => _toggleModal()}
          />
        </div>
        <ModalBody className="text-center">
          <p>
            {!from && !to && t(INFO_SELECT_START_DATE)}
            {from && !to && t(INFO_SELECT_END_DATE)}
            {from &&
              to &&
              `${t(GENERAL_SELECTED_DATE_RANGE)}: ${from.toLocaleDateString(
                i18n.resolvedLanguage
              )} ${t(GENERAL_UNTIL)}
                ${to.toLocaleDateString(i18n.resolvedLanguage)}`}
            <br />
          </p>
          <DayPicker
            className="Selectable"
            localeUtils={MomentLocaleUtils}
            locale={locale}
            selectedDays={[from as Date, { from, to }]}
            modifiers={modifiers}
            numberOfMonths={1}
            onDayClick={handleDayClick}
            disabledDays={{ after: new Date() }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            outline
            onClick={() => {
              setShowDownloadForm(!showDownloadForm);
            }}
          >
            {t(ACTION_CANCEL)}
          </Button>{" "}
          <Button
            className="btn btn-outline-secondary"
            onClick={() => {
              _toggleModal();
              download();
            }}
            disabled={from === undefined || to === undefined}
          >
            {t(ACTION_EXPORT)}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const _generateActions = useCallback(
    (item) => {
      const onClickDelete = (assetId: string) => {
        setModalShow(true);
        setAssetDeleteId(assetId);
      };

      return [
        {
          text: t(ACTION_SHOW_MAP),
          to: generatePath(
            ROUTES.home.childRoutes.assets.childRoutes.detailEdit.path,
            { id: item.id }
          ),
          role: rolePermission.ASSET_TRACKING_READ,
        },
        {
          text: t(ACTION_EDIT),
          to: generatePath(
            ROUTES.home.childRoutes.assets.childRoutes.detailEdit.childRoutes
              .edit.path,
            { id: item.id }
          ),
          role: rolePermission.ASSET_TRACKING_ASSET_EDIT,
        },
        {
          text: t(ACTION_DELETE),
          onClick: () => onClickDelete(item.id),
          role: rolePermission.ASSET_TRACKING_ASSET_DELETE,
        },
      ];
    },
    [t]
  );

  if (hasError) return <Alert color="danger">{t(ERROR_LOADING)}</Alert>;

  return (
    <>
      {loading && <Loading />}
      {_renderDownloadModal()}

      <HyprosModal
        header={t(ACTION_DELETE)}
        body={t(QUESTION_ASSET_DELETE) ?? ""}
        show={showModal}
        acceptButtonColor="danger"
        acceptButtonText={t(ACTION_DELETE)}
        onConfirm={() => {
          setModalShow(false);
          _assetDelete();
        }}
        cancel={true}
        onCancel={_cancelModal}
      />
      {state ? (
        <Alert color="success" isOpen={alertShow}>
          {state.alertMessage}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => _onAlertDismiss()}
          />
        </Alert>
      ) : null}
      <Alert color={alertInput.color} isOpen={alertInput.show}>
        {alertInput.message}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={() => _onDeleteDismiss()}
        />
      </Alert>

      <div className="mb-1 d-flex justify-content-end align-items-baseline">
        <ActiveAssetsFilter
          onChange={setActiveStatus}
          defaultChecked={activeAssets}
        />
        <Button
          color="light"
          className="ms-3 border-dark"
          onClick={() => {
            _toggleModal();
          }}
        >
          {t(ACTION_EXPORT)}
        </Button>
      </div>
      <AssetTrackingTemplate
        data={assetPage}
        pagination={filterData.pageCriteria}
        onItemSelect={setBuildingPart}
        setIPagination={setPagination}
        onLevelToggle={setRootID}
        onGenerateActions={_generateActions}
        buildingTreeLoading={buildingTreeLoading}
        name={t(USE_CASE_ASSET_TRACKING)}
        tree={buildingTree}
        buildingPartIDExternal={
          filterData.filterCriteria?.buildingPartIDExternal
        }
        assetGroups={useCaseData?.useCase?.availableGroups}
        onClick={setGroupFilter}
        checkedGroups={filterData.filterCriteria?.groupIds ?? []}
      />
    </>
  );
}

export interface MatchParams {
  groupFilter?: string;
  pagination?: string;
  buildingPartId?: string;
}
