import { Typeahead } from "react-bootstrap-typeahead";
import React, { useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";
import { FieldRenderProps } from "react-final-form";
import { InputType } from "reactstrap/es/Input";
import { useAutoCompleteInput } from "../../hooks/useAutoCompleteInput";

interface InputFieldAutoCompleteProps {
  className?: string;
  options: string[];
}

type Props = FieldRenderProps<string | number | string[], any> &
  InputFieldAutoCompleteProps;

const InputFieldAutoComplete: React.FC<Props> = ({ input, options, meta }) => {
  const [currentTypedPlaceholder, setCurrentTypedPlaceholder] =
    useState<string>("");
  const [selectionStart, setSelectionStart] = useState<number>(0);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);

  const typeaheadRef = useRef<Typeahead<string>>(null);
  const innerInputRef = useRef<HTMLInputElement | null>(null);
  const EMPTY_SYMBOL = " ";
  const START_TRIGGER = "$";
  const END_TRIGGER = "}";

  const [splitTextOnCurrentCursorPosition, getTypedValue, filterOptions] =
    useAutoCompleteInput(START_TRIGGER, END_TRIGGER);

  const setPlaceHolder = (placeHolder: string) => {
    const { start, end, indexOfCurrentTrigger } =
      splitTextOnCurrentCursorPosition(input.value.toString(), selectionStart);

    const removedValueAfterTrigger = start.slice(0, indexOfCurrentTrigger);
    const buildOutput = removedValueAfterTrigger + placeHolder + end;

    input.onChange(buildOutput);

    setSelectionStart(buildOutput.length - end.length);
  };

  const resetInvalid = () => {
    setInvalid(false);
  };

  const handleMenu = (typedPlaceholder: string, typedCharacter: string) => {
    if (typedCharacter === START_TRIGGER) {
      if (!menuOpen) setMenuOpen(true);
    }

    if (
      typedPlaceholder.includes(END_TRIGGER) ||
      typedPlaceholder === EMPTY_SYMBOL ||
      typedPlaceholder === ""
    ) {
      if (menuOpen) {
        setMenuOpen(false);
      }
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cursorPosition = event.target.selectionStart ?? 0;
    const currentValue = event.target.value;

    setSelectionStart(cursorPosition);

    const typedValue = getTypedValue(currentValue, cursorPosition);

    setCurrentTypedPlaceholder(typedValue);

    //This part checks if the last typed values in input are equal to the trigger
    const startLength = cursorPosition - START_TRIGGER.length;

    const typedCharacter = currentValue.slice(startLength, cursorPosition);

    handleMenu(typedValue, typedCharacter);

    input.onChange(currentValue);
  };

  //This useEffect handles the cursor position after change of input.
  //Duo modify of the input value react trys to rerender the input and sets cursor to the end.
  //This effect prevents this behavior
  useEffect(() => {
    const currentRef = innerInputRef.current;

    if (currentRef !== null && currentRef.selectionStart !== null) {
      if (currentRef.selectionStart > selectionStart) {
        currentRef.setSelectionRange(selectionStart, selectionStart);
      }
    }
  }, [selectionStart]);

  return (
    <Typeahead
      id={input.name}
      ref={typeaheadRef}
      options={options}
      open={menuOpen}
      selected={[]}
      filterBy={(option: string) => {
        return filterOptions(option, currentTypedPlaceholder);
      }}
      onChange={(values: string[]) => {
        values.forEach((v) => {
          setPlaceHolder(v);
        });
        setMenuOpen(false);
      }}
      renderInput={(
        //Types are wrong from lib. Example uses inputRef and referencedElementRef to control input and
        //to handle popperjs position
        //https://github.com/ericgio/react-bootstrap-typeahead/blob/main/docs/Rendering.md
        // @ts-ignore
        { inputRef, referenceElementRef }
      ) => (
        <>
          <Input
            {...input}
            invalid={
              !!(
                !meta.dirtySinceLastSubmit &&
                meta.touched &&
                (meta.error || meta.submitError)
              ) || invalid
            }
            innerRef={(inputNode: HTMLInputElement | null) => {
              //Same as above mention
              // @ts-ignore
              inputRef(inputNode);
              referenceElementRef(inputNode);
              innerInputRef.current = inputNode;
            }}
            type={input.type as InputType}
            onChange={(event) => {
              if (invalid) resetInvalid();
              handleOnChange(event);
            }}
          />
        </>
      )}
    />
  );
};

export default InputFieldAutoComplete;
