import { FeatureCollection, Point } from "geojson";
import React, { useState } from "react";
import ReactMapGL, { ViewState } from "react-map-gl";
import { Card, CardBody, CardHeader } from "reactstrap";
import AlarmLegend from "../../alarms/components/AlarmLegend";
import useCluster from "../../hooks/useCluster";
import { Position } from "../../models/Position";
import { levelFields } from "../../__generated__/types";
import "./MultiAssetMap.scss";
import AlarmLayer from "../../map/AlarmLayer";
import CuGeoJsonFloorplan from "../../catchupMaps/CuGeoJsonFloorplan";
import { useSelectCurrentLayer } from "../hooks/useSelectCurrentLayer";
import { useTranslation } from "react-i18next";
import { LOCATION_TITLE_SG } from "../../localization";

const MultiAssetMap: React.FC<IMultiAssetMapProps> = ({
  assets,
  viewState,
  locationName,
  currentLevel,
  fullscreen,
}) => {
  const [viewport, setViewport] = useState<ViewState>(viewState);
  const cluster = useCluster(assets);
  const { t } = useTranslation();
  useSelectCurrentLayer(currentLevel.layerId);
  return (
    <div className="MbMap" id="map-container">
      <Card className={fullscreen ? "location fullscreenOn" : "location"}>
        <CardHeader>
          {t(LOCATION_TITLE_SG)}: {locationName}
        </CardHeader>
        <CardBody id="map" className="p-0">
          <ReactMapGL
            {...viewport}
            mapboxAccessToken={process.env.REACT_APP_MB_ACCESS_TOKEN}
            mapStyle={process.env.REACT_APP_MB_STYLE}
            onMove={(evt) => setViewport(evt.viewState)}
          >
            <CuGeoJsonFloorplan level={currentLevel.name} use3D />
            <AlarmLayer assets={assets} cluster={cluster.values()} />
            <AlarmLegend
              assetGroups={Array.from(cluster.values())
                .map((value) => value.assets)
                .flat()}
            />
          </ReactMapGL>
        </CardBody>
      </Card>
    </div>
  );
};

interface IMultiAssetMapProps {
  assets: FeatureCollection<Point, Position>;
  locationName: string;
  viewState: ViewState;
  currentLevel: levelFields;
  fullscreen: boolean;
}

export default MultiAssetMap;
