import { useRuleCreateOrUpdate } from "./useRuleCreateOrUpdate";
import { useCallback, useEffect, useState } from "react";
import { RuleListObject, useRuleList } from "./useRuleList";
import { useRuleDelete } from "./useRuleDelete";

export const useRuleApi = (ruleId?: string): RuleApi => {
  const [data, setData] = useState<RuleListObject[]>([]);
  const [isApiLoading, setIsApiLoading] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);

  const { ruleSave, ruleSaveLoading, ruleSaveError } =
    useRuleCreateOrUpdate(ruleId);
  const {
    data: listData,
    listLoading,
    listError,
    fetchRuleList,
  } = useRuleList();
  const { deleteRule, deleteLoading, deleteError, deleteSuccess } =
    useRuleDelete();

  useEffect(() => {
    if (listData) {
      setData(listData);
    }
  }, [listData]);

  const remove = (deleteId: string) => {
    deleteRule(deleteId);
    setData(data.filter((ruleObject) => ruleObject.id !== deleteId));
  };

  const fetchListData = useCallback(async () => {
    const { data } = await fetchRuleList();
    if (data) {
      setData(data);
    }
  }, [fetchRuleList]);

  useEffect(() => {
    setIsApiLoading(ruleSaveLoading || listLoading || deleteLoading);
  }, [ruleSaveLoading, listLoading, deleteLoading]);

  useEffect(() => {
    setIsApiError(ruleSaveError || listError || deleteError);
  }, [ruleSaveError, listError, deleteError]);

  const api = {
    createOrUpdate: ruleSave,
    rulesList: fetchListData,
    deleteRule: remove,
  };

  return {
    data,
    api,
    isApiLoading,
    isApiError,
    isRuleDeleteSuccess: deleteSuccess,
  };
};

export interface RuleApi {
  data: RuleListObject[];
  api: RuleApiCalls;
  isApiLoading: boolean;
  isApiError: boolean;
  isRuleDeleteSuccess: boolean;
}
export interface RuleApiCalls {
  createOrUpdate: (name: string, description: string) => void;
  rulesList: () => void;
  deleteRule: (ruleId: string) => void;
}
