import { GET_ALL_ASSETS_AND_ASSETGROUPS } from "../queries/assetQuery";
import useGqlClient from "../../hooks/useGqlClient";
import { useQuery } from "@apollo/client";
import { IAsset } from "../../models/assets/Asset";

export const useAssetsAndGroups = () => {
  const assetClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  const { data, loading, error } = useQuery(GET_ALL_ASSETS_AND_ASSETGROUPS, {
    client: assetClient,
  });

  if (error) {
    console.error(error);
  }

  return {
    assets: data?.getAllAssets.map((asset: IAsset) => {
      return {
        ...asset,
        groupIds: asset.assetGroups?.map((group) => {
          return group.id;
        }),
      };
    }),
    assetGroups: data?.assetGroups,
    error: error,
    loading: loading,
  };
};
