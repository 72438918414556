import { RuleInput } from "../Model/RuleModels";
import { useMutation } from "react-query";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useConstructRuleInput } from "./useConstructRuleInput";
import { ROUTES } from "../../routes";
import { useKeycloakToken } from "../../hooks/useKeycloakToken";

async function postRule(args: {
  token: string;
  rule: RuleInput | null;
  ruleId?: string;
}): Promise<boolean> {
  if (args.rule === null) return false;

  const res = await fetch(
    `${process.env.REACT_APP_RULESENGINE_URI!!}/rule${
      args.ruleId ? `/${args.ruleId}` : ""
    }`,
    {
      method: args.ruleId ? "PUT" : "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${args.token}`,
      },
      body: JSON.stringify(args.rule),
    }
  );
  return res.json();
}

export const useRuleCreateOrUpdate = (ruleId?: string) => {
  const token = useKeycloakToken();
  const history = useHistory();
  const ruleObject = useConstructRuleInput();
  const { mutate, isLoading, isError, error, isSuccess } =
    useMutation(postRule);

  if (isError) {
    console.error(error);
  }
  const save = useCallback(
    (name: string, description: string) => {
      if (ruleObject) {
        mutate(
          {
            rule: { ...ruleObject, name: name, description: description },
            ruleId: ruleId,
            token,
          },
          {
            onSuccess: () =>
              history.push(ROUTES.home.childRoutes.ruleEngine.path),
          }
        );
      }
    },
    [ruleObject, mutate, ruleId, token, history]
  );

  return {
    ruleSave: save,
    ruleSaveLoading: isLoading,
    ruleSaveError: isError,
    ruleSaveSuccess: isSuccess,
  };
};
