import { CustomIcon } from "../../models/CustomIcon";
import useGqlClient from "../../hooks/useGqlClient";
import { GET_ALL_CUSTOM_ICONS } from "../../asset/queries/assetQuery";
import { useQuery } from "@apollo/client";
import i18next from "../../i18n";
import { GENERAL_DEFAULT } from "../../localization";

const defaultIconCode =
  '<?xml version="1.0" encoding="iso-8859-1"?>\n' +
  "<!-- Generator: Adobe Illustrator 18.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->\n" +
  '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n' +
  '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\n' +
  '\t viewBox="0 0 432.49 432.49" style="enable-background:new 0 0 432.49 432.49;" xml:space="preserve">\n' +
  "<g>\n" +
  "\t<g>\n" +
  '\t\t<path style="fill:none;" d="M178.568,137.364c0,20.775,16.901,37.677,37.677,37.677s37.677-16.901,37.677-37.677\n' +
  '\t\t\tc0-20.774-16.901-37.677-37.677-37.677S178.568,116.59,178.568,137.364z"/>\n' +
  '\t\t<path style="fill:none;" d="M225.096,370.187c-2.273,2.482-5.484,3.895-8.851,3.895c-3.366,0-6.577-1.413-8.851-3.895\n' +
  "\t\t\tc-0.99-1.082-18.303-20.045-40.335-47.596l-99.61,34.143l148.482,51.069l149.109-51.108l-99.512-34.227\n" +
  '\t\t\tC243.449,350.084,226.088,369.103,225.096,370.187z"/>\n' +
  '\t\t<path style="fill:#73D0F4;" d="M95.855,144.392c0,52.005,80.011,153.768,120.39,199.706\n' +
  "\t\t\tc40.379-45.951,120.392-147.736,120.392-199.706C336.636,78.008,282.629,24,216.245,24S95.855,78.008,95.855,144.392z\n" +
  "\t\t\t M216.245,75.688c34.009,0,61.677,27.668,61.677,61.677c0,34.009-27.668,61.677-61.677,61.677s-61.677-27.668-61.677-61.677\n" +
  '\t\t\tC154.568,103.356,182.236,75.688,216.245,75.688z"/>\n' +
  '\t\t<path style="fill:#3D6889;" d="M405.897,345.366l-124.755-42.908c3.628-4.769,7.303-9.681,10.987-14.721\n' +
  "\t\t\tc45.457-62.194,68.507-110.423,68.507-143.346C360.636,64.773,295.862,0,216.245,0S71.855,64.773,71.855,144.392\n" +
  "\t\t\tc0,32.923,23.05,81.151,68.507,143.346c3.715,5.081,7.42,10.033,11.076,14.837L26.606,345.362\n" +
  "\t\t\tc-4.848,1.662-8.105,6.22-8.108,11.346c-0.003,5.125,3.251,9.687,8.097,11.354l185.428,63.776\n" +
  "\t\t\tc1.265,0.434,2.584,0.652,3.902,0.652c1.315,0,2.631-0.216,3.892-0.648l186.069-63.776c4.848-1.661,8.105-6.22,8.108-11.345\n" +
  "\t\t\tC413.996,351.595,410.743,347.033,405.897,345.366z M336.636,144.392c0,51.97-80.013,153.755-120.392,199.706\n" +
  "\t\t\tc-40.379-45.938-120.39-147.701-120.39-199.706C95.855,78.008,149.861,24,216.245,24S336.636,78.008,336.636,144.392z\n" +
  "\t\t\t M215.932,407.803L67.449,356.733l99.61-34.143c22.032,27.551,39.345,46.514,40.335,47.596c2.274,2.482,5.484,3.895,8.851,3.895\n" +
  '\t\t\tc3.366,0,6.577-1.413,8.851-3.895c0.992-1.084,18.354-20.103,40.434-47.719l99.512,34.227L215.932,407.803z"/>\n' +
  '\t\t<path style="fill:#3D6889;" d="M216.245,199.041c34.009,0,61.677-27.668,61.677-61.677c0-34.009-27.668-61.677-61.677-61.677\n' +
  "\t\t\ts-61.677,27.668-61.677,61.677C154.568,171.373,182.236,199.041,216.245,199.041z M253.922,137.364\n" +
  "\t\t\tc0,20.775-16.901,37.677-37.677,37.677s-37.677-16.901-37.677-37.677c0-20.774,16.901-37.677,37.677-37.677\n" +
  '\t\t\tS253.922,116.59,253.922,137.364z"/>\n' +
  "\t</g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "<g>\n" +
  "</g>\n" +
  "</svg>\n";

export const DEFAULT_ICON = {
  id: "",
  tags: [
    {
      id: "",
      tagName: i18next.t(GENERAL_DEFAULT),
    },
  ],
  iconCode: defaultIconCode,
} as CustomIcon;

/**
 * Custom hook that is responsible for fetching all custom icons.
 *
 * This hook returns an object containing the following values:
 * - customIcons: List of all icons that have been fetched.
 * - customIconsLoading: Indicates whether the content is loading.
 * - customIconsError: Error that might have occurred when fetching the icons.
 * - customIconsDefaultIcon: Default icon used in case of error or icon absence.
 * - customIconsRefetch: Action to refetch the custom icon data.
 *
 * @return An object returning the values as mentioned above.
 */
export const useCustomIcons = () => {
  const assetClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  const { data, loading, error, refetch } = useQuery(GET_ALL_CUSTOM_ICONS, {
    client: assetClient,
  });

  if (error) console.error(error);

  return {
    customIcons: data?.customIcons as CustomIcon[] | [],
    customIconsDefaultIcon: DEFAULT_ICON,
    customIconsLoading: loading,
    customIconsError: !!error,
    customIconsRefetch: refetch,
  };
};
