import _ from "lodash";
import React, { useCallback, useState } from "react";
import { FieldRenderProps } from "react-final-form";
import ErrorFeedback from "../../forms/components/ErrorFeedback";
import DrilldownFilter, {
  TransformedNode,
} from "../drilldown-filter/DrilldownFilter";
import useDrilldownData from "../../hooks/drilldownData";

type Prop = FieldRenderProps<string[], any>;

//This component is not used. It could get deleted or reworked.
function DrillDownSelect({ input, meta, ...rest }: Prop) {
  const [selectedItems, setSelectedItems] = useState<string[]>(
    Array.isArray(input.value) ? input.value : []
  );
  const [rootID, setRootID] = useState<string | undefined>(undefined);

  const { buildingTree: buildingParts, loading } = useDrilldownData(rootID);

  const allChildrenIncluded = (
    parent: TransformedNode,
    selectedList: string[]
  ) => {
    return parent.childNodes.every((child) => selectedList.includes(child.id));
  };

  const addNodes = (item: TransformedNode, selectedIds: string[]) => {
    const selectedList = [...selectedIds];
    let parent = findParent(buildingParts, item.id);

    //Add item to selected list
    selectedList.push(item.id);

    //Find all children and add them to the list of picked ids
    const allChildren = findNestedItems([], item.childNodes);
    allChildren.forEach((children: string) => selectedList.push(children));

    //Check if all children of parents are now included to the list
    //If this is true then add parent to the list and check parent.parent until no parents are left
    while (parent && allChildrenIncluded(parent, selectedList)) {
      selectedList.push(parent.id);
      parent = findParent(buildingParts, parent.id);
    }

    setSelectedItems([...selectedList]);
    input.onChange([...selectedList]);
  };

  const removeNodes = (item: TransformedNode, selectedIds: string[]) => {
    const allChildren = findNestedItems([], item.childNodes);
    let parent = findParent(buildingParts, item.id);

    allChildren.push(item.id);

    let filteredIds = selectedIds.filter(
      (id: string) => !allChildren.includes(id)
    );

    //Remove all parents that are in the list.
    while (parent) {
      const parentId = parent.id;

      filteredIds = filteredIds.filter((id: string) => id !== parentId);
      parent = findParent(buildingParts, parent.id);
    }

    setSelectedItems([...filteredIds]);
    input.onChange([...filteredIds]);
  };

  const handleSelectItem = (id: string) => {
    const selectedNodes = [...selectedItems];
    const treeNode = findItem(id, buildingParts);
    if (!selectedNodes.includes(id)) {
      addNodes(treeNode, selectedNodes);
    } else {
      removeNodes(treeNode, selectedNodes);
    }
  };

  const findNestedItems = useCallback(
    (items: string[], tree?: TransformedNode[]): any => {
      if (tree === undefined) {
        return items;
      }
      for (const node of tree) {
        items.push(node.id);
        if (node.childNodes !== undefined) {
          findNestedItems(items, node.childNodes);
        }
      }
      return items;
    },
    []
  );

  const findParent = (root: TransformedNode[], id: string): any => {
    let node;
    for (const no of root) {
      node = no;
      if (
        node.childNodes.find((n) => n.id === id) ||
        (node.childNodes && (node = findParent(no.childNodes, id)))
      ) {
        return node;
      }
    }
    return null;
  };

  const findItem = (item: string, tree?: TransformedNode[]) => {
    const flatten = function (items: TransformedNode): any {
      return [items, _.flatMapDeep(items.childNodes, flatten)];
    };
    const flatMapDeep = _.flatMapDeep(tree, flatten);
    return flatMapDeep.find((node) => node.id === item);
  };

  const findMatch = useCallback(
    (nodes: TransformedNode[]): null | string => {
      for (const node of nodes) {
        if (selectedItems.includes(node.id)) {
          return node.id;
        }
        if (node.childNodes) {
          return findMatch(node.childNodes);
        }
      }
      return null;
    },
    [selectedItems]
  );
  return (
    <>
      <div
        className={
          !meta.dirtySinceLastSubmit &&
          meta.touched &&
          (meta.error || meta.submitError)
            ? "is-invalid"
            : ""
        }
      >
        <DrilldownFilter
          data={buildingParts}
          selectedItem={""}
          selectedItems={selectedItems}
          onItemSelect={handleSelectItem}
          loading={loading}
          onLevelToggle={setRootID}
          component={true}
        />
        <ErrorFeedback name={input.name} />
      </div>
    </>
  );
}

export default DrillDownSelect;
