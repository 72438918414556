// ACTIONS
export const ACTION_ADD = "actions.general.add";
export const ACTION_APPLY_CHANGES = "actions.general.applyChanges";
export const ACTION_BACK_TO_HOME = "actions.navigation.backToHome";
export const ACTION_BACK_TO_PREVIOUS_PAGE =
  "actions.navigation.backToPreviousPage";
export const ACTION_CANCEL = "actions.general.cancel";
export const ACTION_CHANGE_FLOOR = "actions.navigation.changeFloor";
export const ACTION_CONFIRM = "actions.general.confirm";
export const ACTION_CREATE = "actions.general.create";
export const ACTION_DELETE = "actions.general.delete";
export const ACTION_EXCEL_EXPORT = "actions.general.excelExport";
export const ACTION_EXPORT = "actions.general.export";
export const ACTION_EDIT = "actions.general.edit";
export const ACTION_FILTER_FIRST = "actions.filter.first";
export const ACTION_FILTER_LAST = "actions.filter.last";
export const ACTION_FILTER_MAX = "actions.filter.max";
export const ACTION_FILTER_MEAN = "actions.filter.mean";
export const ACTION_FILTER_MEDIAN = "actions.filter.median";
export const ACTION_FILTER_MIN = "actions.filter.min";
export const ACTION_LOCK = "actions.general.lock";
export const ACTION_HOME = "actions.navigation.home";
export const ACTION_REMOVE = "actions.general.remove";
export const ACTION_RESET_CHANGES = "actions.general.resetChanges";
export const ACTION_SAVE = "actions.general.save";
export const ACTION_SHARE = "actions.general.share";
export const ACTION_SHOW_DETAILS = "actions.general.showDetails";
export const ACTION_SHOW_MAP = "actions.general.showMap";
export const ACTION_SYMBOL_ADD = "actions.symbol.add";
export const ACTION_SYMBOL_DELETE = "actions.symbol.delete";
export const ACTION_SYMBOL_EDIT = "actions.symbol.edit";
export const ACTION_SYMBOL_SELECT = "actions.symbol.selectNew";
export const ACTION_TITLE_SG = "general.action.singular";
export const ACTION_TITLE_PL = "general.action.plural";
export const ACTION_VIEW = "actions.general.view";

// ALARME
export const ALARM_ACTIVATE_SOUND = "actions.alarm.activateSound";
export const ALARM_DETAIL_VIEW = "routes.alarm.detail";
export const ALARM_EXPORT = "actions.alarm.export";
export const ALARM_MANAGEMENT = "routes.alarm.management";
export const ALARM_NO_ALARMS = "infos.alarm.noAlarms";
export const ALARM_SELECT = "infos.alarm.select";
export const ALARM_TITLE_SG = "general.alarm.singular";
export const ALARM_TITLE_SG_CAPS = "general.alarm.singularCaps";
export const ALARM_TITLE_PL = "general.alarm.plural";

// ASSET
export const ASSET_ACTION_CREATE = "actions.asset.create";
export const ASSET_ACTION_EDIT = "actions.asset.edit";
export const ASSET_DETAIL_VIEW = "routes.asset.detail";
export const ASSET_ERROR_DELETE = "errors.asset.delete";
export const ASSET_ERROR_NO_DATA = "errors.asset.noData";
export const ASSET_ERROR_NOT_FOUND = "errors.asset.notFound";
export const ASSET_GROUP_DELETE = "actions.assetGroup.delete";
export const ASSET_GROUP_NAME_EXISTS = "errors.assetGroup.nameExists";
export const ASSET_GROUP_NOT_EMPTY = "errors.assetGroup.notEmpty";
export const ASSET_GROUP_NO_DATA = "errors.assetGroup.noData";
export const ASSET_GROUP_TITLE_SG = "general.assetGroup.singular";
export const ASSET_GROUP_TITLE_PL = "general.assetGroup.plural";
export const ASSET_INFO_CREATE = "infos.asset.create";
export const ASSET_INFO_DELETE = "infos.asset.delete";
export const ASSET_PHARMACY = "routes.asset.pharmacy";
export const ASSET_STATE_ACTIVE = "general.asset.active";
export const ASSET_STATE_DEACTIVATED = "general.asset.deactivated";
export const ASSET_STATE_INACTIVE = "general.asset.inactive";
export const ASSET_TEMPERATURE_MONITORING =
  "routes.asset.temperatureMonitoring";
export const ASSET_TEMPERATURE_MONITORING_STATION =
  "routes.asset.temperatureMonitoringStation";
export const ASSET_TRACKING = "routes.asset.tracking";

// ERROR
export const ERROR_403 = "errors.general.403";
export const ERROR_404 = "errors.general.404";
export const ERROR_CHANGES_NOT_SAVED = "errors.general.changesNotSaved";
export const ERROR_FORM_FORMAT_NOT_ALLOWED = "errors.form.notAllowed";
export const ERROR_FORM_LIMIT_COMPARISON = "errors.form.limitComparison";
export const ERROR_FORM_ONLY_NUMBERS = "errors.form.onlyNumbers";
export const ERROR_FORM_VALID_EMAIL_ADDRESS = "errors.form.validEmail";
export const ERROR_FORM_ONLY_VALID_MAC_ADDRESSES = "errors.form.validMacAdr";
export const ERROR_IMAGE_INVALID_MODE = "errors.image.invalidMode";
export const ERROR_INCORRECT_LIMITS = "errors.general.incorrectLimits";
export const ERROR_INVALID_DATE_SELECTION =
  "errors.general.invalidDateSelection";
export const ERROR_INVALID_DATE_FORMAT = "errors.general.invalidDateFormat";
export const ERROR_LOADING = "errors.general.loading";
export const ERROR_MAC_ADR_IN_USE = "errors.general.macAdrInUse";
export const ERROR_MAC_ADR_INVALID_CHARACTERS =
  "errors.general.macAdrInvalidCharacters";
export const ERROR_NETWORK = "errors.general.network";
export const ERROR_NO_DATA_AVAILABLE = "errors.general.noDataAvailable";
export const ERROR_NO_DATA_PERIOD = "errors.general.noDataPeriod";
export const ERROR_NO_RECEIVERS_WITH_MAC_ADDRESS =
  "errors.general.noReceiversWithMacAdr";
export const ERROR_RULE_ENGINE_NO_RULE = "errors.ruleEngine.noRule";
export const ERROR_NOT_LINKED = "errors.general.notLinked";
export const ERROR_UNKNOWN = "errors.general.unknown";
export const ERROR_SIMPLE = "errors.general.simple";
export const ERROR_SYMBOL_CONFLICT = "errors.symbol.conflict";
export const ERROR_TLM_NO_ASSET = "errors.tlm.noAsset";

// GENERAL
export const GENERAL_ADMIN = "general.admin";
export const GENERAL_ADMIN_MANAGEMENT = "routes.admin.management";
export const GENERAL_AGGREGATED_BY = "general.aggregatedBy";
export const GENERAL_AMOUNT_RECEIVERS = "general.amountReceivers";
export const GENERAL_BATTERY = "general.battery";
export const GENERAL_CATEGORY_SG = "general.category.singular";
export const GENERAL_CATEGORY_PL = "general.category.plural";
export const GENERAL_CHARACTERS_LEFT = "general.charactersLeft";
export const GENERAL_CREATOR = "general.creator";
export const GENERAL_CUSTOM_ID = "general.customId";
export const GENERAL_DATE = "general.date";
export const GENERAL_DEFAULT = "general.default";
export const GENERAL_DEFAULT_VIEW = "general.defaultView";
export const GENERAL_DESCRIPTION = "general.description";
export const GENERAL_DESIGNATION = "general.designation";
export const GENERAL_DURATION_IN_PERCENT = "general.durationInPercent";
export const GENERAL_DURATION_OF_STAY = "general.durationOfStay";
export const GENERAL_EMAIL = "general.email";
export const GENERAL_EMERGENCY_CALLS = "general.emergencyCalls";
export const GENERAL_ENGLISH = "general.english";
export const GENERAL_ESTIMATED_STORAGE_SIZE = "general.estimatedStorageSize";
export const GENERAL_FLOOR = "general.floor";
export const GENERAL_FILTER = "general.filter";
export const GENERAL_FRENCH = "general.french";
export const GENERAL_FROM = "general.from";
export const GENERAL_GENDER = "general.gender";
export const GENERAL_GERMAN = "general.german";
export const GENERAL_GROUP_SG = "general.group.singular";
export const GENERAL_GROUP_PL = "general.group.plural";
export const GENERAL_GROUPED_BY = "general.groupedBy";
export const GENERAL_ILLUMINATION = "general.illumination";
export const GENERAL_INVENTORY_NUMBER = "general.inventoryNumber";
export const GENERAL_TEMPERATURE_OFFSET = "general.temperatureOffset";
export const GENERAL_BEACON_MAC = "general.beaconMac";
export const GENERAL_ITALIAN = "general.italian";
export const GENERAL_LAST_MEASURED_TEMPERATURE =
  "general.lastMeasuredTemperature";
export const GENERAL_LAST_UPDATE = "general.lastUpdate";
export const GENERAL_LAST_SEEN = "general.lastSeen";
export const GENERAL_LATITUDE = "general.latitude";
export const GENERAL_LOCATOR_AP = "general.locator.accessPoint";
export const GENERAL_LOCATOR_HYPROS = "general.locator.hypros";
export const GENERAL_LOGIN = "general.login";
export const GENERAL_LOGOUT = "general.logout";
export const GENERAL_LONGITUDE = "general.longitude";
export const GENERAL_MAC_ADR = "general.macAdr";
export const GENERAL_MAXIMUM = "general.maximum";
export const GENERAL_MENU = "general.menu";
export const GENERAL_MINIMUM = "general.minimum";
export const GENERAL_MODULES = "general.modules";
export const GENERAL_NAME = "general.name";
export const GENERAL_NOW = "general.now";
export const GENERAL_OBJECT = "general.object";
export const GENERAL_ON_OFF = "general.onOff";
export const GENERAL_OVERVIEW = "general.overview";
export const GENERAL_PASSWORD = "general.password";
export const GENERAL_PATIENT = "general.patient";
export const GENERAL_PER_PAGE = "general.perPage";
export const GENERAL_RANGE = "general.range";
export const GENERAL_RADIUS_IN_METERS = "general.radiusInMeters";
export const GENERAL_REMAIN_LOGGED_IN = "general.remainLoggedIn";
export const GENERAL_RESIDENCE_HISTORY = "general.residenceHistory";
export const GENERAL_ROOM_INFORMATION = "general.roomInformation";
export const GENERAL_ROOM_LIST = "general.roomList";
export const GENERAL_RSSI_CALIBRATION = "general.rssiCalibration";
export const GENERAL_RULE_SG = "general.rule.singular";
export const GENERAL_RULE_PL = "general.rule.plural";
export const GENERAL_SATELLITE_VIEW = "general.satelliteView";
export const GENERAL_SELECTED = "general.selected";
export const GENERAL_SELECTED_DATE = "general.selectedDate";
export const GENERAL_SELECTED_DATE_RANGE = "general.selectedDateRange";
export const GENERAL_STATIONED = "general.stationed";
export const GENERAL_STAY = "general.stay";
export const GENERAL_SYMBOL_SG = "general.symbol.singular";
export const GENERAL_SYMBOL_PL = "general.symbol.plural";
export const GENERAL_SYMBOL_SELECTION = "general.symbolSelection";
export const GENERAL_TECHNICAL_ROOM_NUMBER = "general.technicalRoomNumber";
export const GENERAL_TEMPERATURE = "general.temperature";
export const GENERAL_TEMPERATURE_LIMITS = "general.temperatureLimits.title";
export const GENERAL_TEMPERATURE_UPPER_LIMIT =
  "general.temperatureLimits.upperLimit";
export const GENERAL_TEMPERATURE_LOWER_LIMIT =
  "general.temperatureLimits.lowerLimit";
export const GENERAL_TIME = "general.time";
export const GENERAL_TIMESTAMP = "general.timestamp";
export const GENERAL_TLM_DEVICE = "general.tlmDevice";
export const GENERAL_TYPE = "general.type";
export const GENERAL_UNKNOWN = "general.unknown";
export const GENERAL_UNTIL = "general.until";
export const GENERAL_USERNAME = "general.username";

// INFO
export const INFO_BATTERY_CAPACITY_LOW = "infos.battery.capacityLow";
export const INFO_BATTERY_CAPACITY_MIDDLE = "infos.battery.capacityMiddle";
export const INFO_BATTERY_CAPACITY_HIGH = "infos.battery.capacityHigh";
export const INFO_CATEGORY_NO_SPECIAL_CHARACTER =
  "infos.category.noSpecialCharacter";
export const INFO_DATE_IN_FUTURE = "infos.general.dateInFuture";
export const INFO_EXCEL_EXPORT = "infos.general.excelExport";
export const INFO_FLOOR_CHANGE = "infos.location.floorChange";
export const INFO_FORM_AT_LEAST_ONE = "infos.form.atLeastOne";
export const INFO_FORM_REQUIRED_FIELD = "infos.form.requiredField";
export const INFO_LAST_BUILDINGPART = "infos.general.lastBuildingpart";
export const INFO_LOCATION_UNKNOWN = "infos.location.unknown";
export const INFO_NO_ENTRIES = "infos.general.noEntries";
export const INFO_NO_FLOORS = "infos.location.noFloors";
export const INFO_POSITION_UNKNOWN = "infos.general.positionUnknown";
export const INFO_RESET_CHANGES = "infos.general.resetChanges";
export const INFO_SAVED_CHANGES = "infos.general.savedChanges";
export const INFO_SEARCH_NO_RESULT = "infos.general.searchNoResults";
export const INFO_SELECT_END_DATE = "infos.general.selectEndDate";
export const INFO_SELECT_LIMIT = "infos.general.selectLimit";
export const INFO_SELECT_LOCATOR = "infos.location.selectLocator";
export const INFO_SELECT_START_DATE = "infos.general.selectStartDate";
export const INFO_SYMBOL_DUPLICATE_CATEGORY = "infos.symbol.duplicateCategory";
export const INFO_SYMBOL_NO_CATEGORY = "infos.symbol.noCategory";
export const INFO_SYMBOL_NOTHING_FOUND = "infos.symbol.nothingFound";
export const INFO_SYMBOL_NOT_SELECTED = "infos.symbol.notSelected";
export const INFO_USE_CASE_NO_PERMISSION = "infos.navigation.noPermission";

// LOCATION
export const LOCATION_EDIT = "actions.location.edit";
export const LOCATION_FIXED = "general.location.fixed";
export const LOCATION_LAST = "general.location.last";
export const LOCATION_MANAGE_COORDINATES = "general.manageCoordinates";
export const LOCATION_NO_FLOORS = "errors.location.noFloors";
export const LOCATION_NOT_FOUND = "errors.location.notFound";
export const LOCATION_SELECT = "actions.location.select";
export const LOCATION_TITLE_SG = "general.location.singular";
export const LOCATION_TITLE_PL = "general.location.plural";
export const LOCATION_QR_SCANNER = "routes.qrScanner";

// PLACEHOLDER
export const PLACEHOLDER_CATEGORY_ADD = "placeholder.category.add";
export const PLACEHOLDER_LOADING = "placeholder.general.loading";
export const PLACEHOLDER_LOAD_MORE_OPTIONS =
  "placeholder.general.loadMoreOptions";
export const PLACEHOLDER_NO_MORE_OPTIONS = "placeholder.general.noMoreOptions";
export const PLACEHOLDER_NOTHING_FOUND = "placeholder.general.nothingFound";
export const PLACEHOLDER_SEARCH = "placeholder.general.search";
export const PLACEHOLDER_SEARCH_FOR_MAC_ADDRESS =
  "placeholder.general.searchForMacAdr";
export const PLACEHOLDER_SELECT = "placeholder.general.select";

// RULE ENGINE
export const RULE_ENGINE_CREATE = "actions.ruleEngine.create";
export const RULE_ENGINE_DELETE = "actions.ruleEngine.delete";
export const RULE_ENGINE_EDIT = "actions.ruleEngine.edit";
export const RULE_ENGINE_ERROR_DELETE = "errors.ruleEngine.delete";
export const RULE_ENGINE_INFO_NO_RULE_FOUND = "infos.ruleEngine.noRuleFound";
export const RULE_ENGINE_INFO_SAVE = "infos.ruleEngine.save";
export const RULE_ENGINE_SAVE = "actions.ruleEngine.save";
export const RULE_ENGINE_RULES_LIST = "general.ruleList";
export const RULE_ENGINE_TITLE = "general.ruleEngine";

// TAG
export const TAG_ATTRIBUTE_ID = "general.beaconId";
export const TAG_CREATE = "actions.beacon.create";
export const TAG_EDIT = "actions.beacon.edit";
export const TAG_FREE_TAGS = "placeholder.beacon.freeTags";
export const TAG_MANAGE = "actions.beacon.manage";
export const TAG_SELECTED_BEACON = "general.selectedBeacon";

// TIME
export const TIME_SECOND_SG = "time.second.singular";
export const TIME_SECOND_PL = "time.second.plural";
export const TIME_MINUTE_SG = "time.minute.singular";
export const TIME_MINUTE_PL = "time.minute.plural";
export const TIME_HOUR_SG = "time.hour.singular";
export const TIME_HOUR_PL = "time.hour.plural";
export const TIME_DAY_SG = "time.day.singular";
export const TIME_DAY_PL = "time.day.plural";
export const TIME_WEEK_SG = "time.week.singular";
export const TIME_WEEK_PL = "time.week.plural";
export const TIME_MONTH_SG = "time.month.singular";
export const TIME_MONTH_PL = "time.month.plural";

// USECASE
export const USE_CASE_PHARMACY = "useCase.Apothekenkisten";
export const USE_CASE_ASSET_TRACKING = "useCase.Asset Tracking";
export const USE_CASE_ALARM_MANAGEMENT = "useCase.Alarmmanagement";
export const USE_CASE_TELEMETRY = "useCase.Philips Telemetrie";
export const USE_CASE_TEMP_MONITORING = "useCase.Temperaturüberwachung";
export const USE_CASE_TEMP_MONITORING_STATION =
  "useCase.Temperaturüberwachung Station";

// QUESTIONS
export const QUESTION_ASSET_DELETE = "questions.asset.delete";
export const QUESTION_ASSET_GROUP_DELETE = "questions.assetGroup.delete";
export const QUESTION_DISCARD_CHANGES = "questions.general.discardChanges";
export const QUESTION_RULE_ENGINE_DELETE = "questions.ruleEngine.delete";
export const QUESTION_SURE = "questions.general.sure";
export const QUESTION_SYMBOL_DELETE = "questions.symbol.delete";
export const QUESTION_TAG_DELETE = "questions.tag.delete";
