import { IconName } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import ProgressNavigation from "../../shared/progress-navigation/ProgressNavigation";
import InformationCard from "../../shared/Information-card/InformationCard";
import { useSelector } from "react-redux";
import { getProgressBar } from "../ruleManagerSlice";

const RulesContainer: React.FC<IRulesContainer> = ({
  children,
  title,
  description,
  icon,
  highlighted,
  headerLabel,
}) => {
  const progressPoints = useSelector(getProgressBar);

  return (
    <Card>
      <CardHeader>{headerLabel}</CardHeader>
      <CardBody>
        <div className="d-flex">
          <div style={{ fontSize: "0.9rem" }} className="pt-3 w-25">
            <ProgressNavigation
              progressPoints={progressPoints}
              highlighted={highlighted}
            />
          </div>
          <div className="pt-3 ps-4 w-75">
            <InformationCard
              title={title}
              iconName={(icon as IconName) ?? undefined}
              informationText={description}
            >
              {children}
            </InformationCard>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export interface IRulesContainer {
  title: string;
  description: string;
  children?: React.ReactNode;
  icon: string;
  highlighted?: string;
  headerLabel: string;
}

export default RulesContainer;
