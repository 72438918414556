import React, { PropsWithChildren } from "react";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { AlarmDetail, AlarmList } from "./alarms";
import { AssetCreate, AssetDetail, AssetEdit, AssetHistory } from "./asset";
import { Logout, useKeycloak } from "./auth";
import { LocationList, LocatorEdit } from "./location";
import { rolePermission } from "./rolePermission";
import RuleCreateOrUpdate from "./rules-engine/page/RuleCreateOrUpdate";
import RuleListView from "./rules-engine/page/RuleListView";
import ErrorDetail from "./shared/ErrorDetail";
import AlarmManagement from "./useCase/pages/AlarmManagement";
import AssetTracking from "./useCase/pages/AssetTracking";
import Pharmacy from "./useCase/pages/Pharmacy";
import TemperatureMonitoring from "./useCase/pages/TemperatureMonitoring";
import UseCaseList from "./useCase/UseCaseList";
import AdminLayout from "./admin/page/AdminLayout";
import AssetGroupPage from "./assetGroup/page/AssetGroupPage";
import CustomIconsPage from "./customIcons/page/CustomIconsPage";
import {
  ACTION_HOME,
  ALARM_DETAIL_VIEW,
  ALARM_MANAGEMENT,
  ALARM_TITLE_PL,
  ASSET_ACTION_CREATE,
  ASSET_ACTION_EDIT,
  ASSET_DETAIL_VIEW,
  ASSET_GROUP_TITLE_PL,
  ASSET_PHARMACY,
  ASSET_TEMPERATURE_MONITORING,
  ASSET_TEMPERATURE_MONITORING_STATION,
  ASSET_TRACKING,
  GENERAL_ADMIN_MANAGEMENT,
  GENERAL_MODULES,
  GENERAL_RESIDENCE_HISTORY,
  GENERAL_SYMBOL_PL,
  LOCATION_EDIT,
  LOCATION_TITLE_PL,
  RULE_ENGINE_CREATE,
  RULE_ENGINE_EDIT,
  RULE_ENGINE_RULES_LIST,
} from "./localization";

export enum ErrorCodes {
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
}

export const SwitchWithSubRoutes = ({
  routes,
}: Record<string, SecureRouteProps>) => (
  <Switch>
    {Object.entries(routes).map((value, index) => (
      <SecureRouteWithSubRoutes key={index} {...value[1]} />
    ))}
  </Switch>
);

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export const SecureRouteWithSubRoutes: React.FC<SecureRouteProps> = (
  route: SecureRouteProps
) => {
  const keycloak = useKeycloak();

  const authenticated = () => {
    return route.roles?.some((role) => {
      return !!keycloak.hasResourceRole(role);
    });
  };

  const render = route.roles === undefined || authenticated();

  if (!render) return <Redirect to={ROUTES.home.childRoutes.forbidden.path} />;
  return (
    <Route
      path={route.path}
      render={(props) => (
        <route.component {...props} routes={route.childRoutes as any} />
      )}
    />
  );
};

export type SecureRouteProps = RouteProps & {
  name?: string;
  component:
    | React.ComponentType<
        RouteComponentProps<any> & { routes: Record<string, SecureRouteProps> }
      >
    | React.ComponentType<any>;
  path: string;
  roles?: string[];
  childRoutes?: Record<string, SecureRouteProps>;
};

export type RoutesProps = {
  home: SecureRouteProps & {
    childRoutes: {
      admin: SecureRouteProps & {
        childRoutes: {
          assetGroups: SecureRouteProps;
          icons: SecureRouteProps;
        };
      };
      alarmDetail: SecureRouteProps;
      assets: SecureRouteProps & {
        childRoutes: {
          create: SecureRouteProps;
          history: SecureRouteProps;
          detailEdit: SecureRouteProps & {
            childRoutes: {
              edit: SecureRouteProps;
              detail: SecureRouteProps;
            };
          };
        };
      };
      forbidden: SecureRouteProps;
      locations: SecureRouteProps & {
        childRoutes: {
          edit: SecureRouteProps;
        };
      };
      ruleEngine: SecureRouteProps & {
        childRoutes: {
          list: SecureRouteProps;
          edit: SecureRouteProps;
          create: SecureRouteProps;
        };
      };
      logout: SecureRouteProps;
      notFound: SecureRouteProps;
      useCases: SecureRouteProps & {
        childRoutes: {
          alarmManagement: SecureRouteProps;
          alarms: SecureRouteProps;
          assetTracking: SecureRouteProps;
          pharmacy: SecureRouteProps;
          temperatureMonitoring: SecureRouteProps;
          temperatureStationMonitoring: SecureRouteProps;
        };
      };
    };
  };
};

export const ROUTES: RoutesProps = {
  home: {
    component: () => <Redirect to="/use-cases" />,
    name: ACTION_HOME,
    path: "/",
    childRoutes: {
      alarmDetail: {
        component: AlarmDetail,
        name: ALARM_DETAIL_VIEW,
        path: "/alarms/detail",
      },
      assets: {
        component: SwitchWithSubRoutes,
        path: "/assets",
        childRoutes: {
          create: {
            component: AssetCreate,
            name: ASSET_ACTION_CREATE,
            path: "/assets/create",
            roles: [rolePermission.ASSET_CREATE],
          },
          history: {
            component: AssetHistory,
            name: GENERAL_RESIDENCE_HISTORY,
            path: "/assets/:id/history",
          },
          detailEdit: {
            component: SwitchWithSubRoutes,
            path: "/assets/:id",
            name: ASSET_DETAIL_VIEW,
            childRoutes: {
              edit: {
                component: AssetEdit,
                name: ASSET_ACTION_EDIT,
                path: "/assets/:id/edit",
                roles: [
                  rolePermission.ASSET_TRACKING_ASSET_EDIT,
                  rolePermission.TEMPERATURE_MONITORING_ASSET_EDIT,
                  rolePermission.TEMPERATURE_MONITORING_WARD_ASSET_EDIT,
                  rolePermission.PHARMACY_ASSET_EDIT,
                ],
              },
              detail: {
                component: AssetDetail,
                path: "/assets/:id",
                roles: [
                  rolePermission.ASSET_TRACKING_READ,
                  rolePermission.TEMPERATURE_MONITORING_READ,
                  rolePermission.TEMPERATURE_MONITORING_WARD_READ,
                  rolePermission.PHARMACY_READ,
                ],
              },
            },
          },
        },
      },
      forbidden: {
        component: () => <ErrorDetail errorCode={403} />,
        path: "/forbidden",
      },
      locations: {
        component: LocationList,
        name: LOCATION_TITLE_PL,
        path: "/locations",
        roles: [rolePermission.LOCATION_READ],
        childRoutes: {
          edit: {
            component: LocatorEdit,
            name: LOCATION_EDIT,
            path: "/locations/:id/edit",
            roles: [rolePermission.LOCATION_EDIT],
          },
        },
      },
      ruleEngine: {
        component: SwitchWithSubRoutes,
        name: RULE_ENGINE_RULES_LIST,
        path: "/rule-list",
        childRoutes: {
          edit: {
            component: RuleCreateOrUpdate,
            name: RULE_ENGINE_EDIT,
            path: "/rule-list/:id/edit",
            roles: [rolePermission.RULE_ENGINE_EDIT],
          },
          create: {
            component: RuleCreateOrUpdate,
            name: RULE_ENGINE_CREATE,
            path: "/rule-list/create",
            roles: [rolePermission.RULE_ENGINE_CREATE],
          },
          list: {
            component: RuleListView,
            name: RULE_ENGINE_RULES_LIST,
            path: "/rule-list",
            roles: [rolePermission.RULE_ENGINE_READ],
          },
        },
      },
      logout: {
        component: Logout,
        path: "/logout",
      },
      notFound: {
        component: () => <ErrorDetail errorCode={404} />,
        path: "/not-found",
      },
      admin: {
        component: (
          props: PropsWithChildren<RouteComponentProps> & {
            routes: SecureRouteProps;
          }
        ) => (
          <AdminLayout>
            <SwitchWithSubRoutes routes={props.routes} />
          </AdminLayout>
        ),
        name: GENERAL_ADMIN_MANAGEMENT,
        path: "/admin",
        childRoutes: {
          assetGroups: {
            component: AssetGroupPage,
            name: ASSET_GROUP_TITLE_PL,
            path: "/admin/assetGroups",
            roles: [rolePermission.ADMIN_ASSET_GROUP],
          },
          icons: {
            component: CustomIconsPage,
            name: GENERAL_SYMBOL_PL,
            path: "/admin/icons",
            roles: [rolePermission.ADMIN_ICONS],
          },
        },
      },
      useCases: {
        component: UseCaseList,
        name: GENERAL_MODULES,
        path: "/use-cases",
        childRoutes: {
          alarmManagement: {
            component: AlarmManagement,
            name: ALARM_MANAGEMENT,
            path: "/use-cases/alarmManagement",
            roles: [rolePermission.ALARM_MANAGEMENT_READ],
          },
          alarms: {
            component: AlarmList,
            name: ALARM_TITLE_PL,
            path: "/use-cases/alarms",
            roles: [rolePermission.ALARMS_READ],
          },
          assetTracking: {
            component: AssetTracking,
            name: ASSET_TRACKING,
            path: "/use-cases/asset-tracking",
            roles: [rolePermission.ASSET_TRACKING_READ],
          },
          pharmacy: {
            component: Pharmacy,
            name: ASSET_PHARMACY,
            path: "/use-cases/pharmacy",
            roles: [rolePermission.PHARMACY_READ],
          },
          temperatureMonitoring: {
            component: () => (
              <TemperatureMonitoring
                name="Temperaturüberwachung"
                editRole={rolePermission.TEMPERATURE_MONITORING_ASSET_EDIT}
                deleteRole={rolePermission.TEMPERATURE_MONITORING_ASSET_DELETE}
                readRole={rolePermission.TEMPERATURE_MONITORING_READ}
              />
            ),
            name: ASSET_TEMPERATURE_MONITORING,
            path: "/use-cases/temperature-monitoring",
            roles: [rolePermission.TEMPERATURE_MONITORING_READ],
          },
          temperatureStationMonitoring: {
            component: () => (
              <TemperatureMonitoring
                name="Temperaturüberwachung Station"
                editRole={rolePermission.TEMPERATURE_MONITORING_WARD_ASSET_EDIT}
                deleteRole={
                  rolePermission.TEMPERATURE_MONITORING_WARD_ASSET_DELETE
                }
                readRole={rolePermission.TEMPERATURE_MONITORING_WARD_READ}
              />
            ),
            name: ASSET_TEMPERATURE_MONITORING_STATION,
            path: "/use-cases/temperatureStation-monitoring",
            roles: [rolePermission.TEMPERATURE_MONITORING_WARD_READ],
          },
        },
      },
    },
  },
};

export const findRoute = (
  path: string,
  childRoutes: Record<string, SecureRouteProps> = ROUTES.home.childRoutes
): SecureRouteProps | undefined => {
  for (const route of Object.values(childRoutes)) {
    if (path.startsWith(route.path)) {
      if (path === route.path) return route;
      return findRoute(path, route.childRoutes);
    }
  }
  return undefined;
};
