import { useEffect, useState } from "react";
import { useCustomIcons } from "./useCustomIcons";
import { useCreateOrUpdateCustomIcon } from "./useCreateOrUpdateCustomIcon";
import { useDeleteCustomIcon } from "./useDeleteCustomIcon";
import { useCustomIconTags } from "./useCustomIconTags";
import { useTranslation } from "react-i18next";
import { ERROR_SIMPLE, ERROR_SYMBOL_CONFLICT } from "../../localization";

/**
 * Custom hook that unifies the following hooks into a single "API" hook:
 * - useCustomIcons: Fetches all existing custom icons.
 * - useCustomIconTags: Fetches all existing custom icon tags.
 * - useCreateOrUpdateCustomIcon: Used to create or update custom icons.
 * - useDeleteCustomIcon: Used to delete an existing custom icon.
 * Furthermore, this hook exposes two states that represent the loading and error states of all hooks at once.
 *
 * This hook return an object containing the following values:
 * - customIcons: List of all icons that have been fetched.
 * - customIconTags: List of all icon tags that have been fetched.
 * - customIconsAPI: Object that contains the following values
 *    - createOrUpdateCustomIcon: Action used to create or update an icon.
 *    - deleteCustomIcon: Action used to delete an existing icon.
 *    - customIconsRefetch: Action used to refetch the list of custom icons.
 *    - customTagsRefetch: Action used to refetch the list of custom icon tags.
 * - customIconsError: Error that might have occurred during any of these processes.
 * - customIconsLoading: Indicates whether the content is still loading/an action is pending.
 * - customIconsDefaultIcon: Default icon is used in case of error or icon absence.
 * - customIconsErrorMessage: Default error message that is displayed upon error.
 *
 * @param setExtraErrorState: Additional set state action that represents the error state.
 *
 * @return An object returning the values as mentioned above.
 */
export const useCustomIconsApi = (
  setExtraErrorState: (state: boolean) => void
) => {
  const { t } = useTranslation();
  const ERROR_MESSAGE = t(ERROR_SIMPLE) ?? "";
  const CONFLICT_ERROR_MESSAGE = t(ERROR_SYMBOL_CONFLICT) ?? "";

  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(ERROR_MESSAGE);

  // Values of useCustomIcons
  const {
    customIcons,
    customIconsLoading,
    customIconsError,
    customIconsRefetch,
    customIconsDefaultIcon,
  } = useCustomIcons();

  // Values of useCustomIconTags
  const { customTags, customTagsLoading, customTagsError, customTagsRefetch } =
    useCustomIconTags();

  // Values of useCreateOrUpdateCustomIcon
  const {
    createOrUpdateCustomIcon,
    customIconsCreateLoading,
    customIconsCreateError,
  } = useCreateOrUpdateCustomIcon();

  // Values of useDeleteCustomIcon
  const {
    deleteCustomIcon,
    customIconDeleteLoading,
    customIconDeleteError,
    customIconDeleteConflict,
  } = useDeleteCustomIcon();

  // useEffect hook to unify all loading states into a single one
  useEffect(() => {
    setLoading(
      customIconsLoading ||
        customTagsLoading ||
        customIconsCreateLoading ||
        customIconDeleteLoading
    );
  }, [
    customIconsLoading,
    customTagsLoading,
    customIconsCreateLoading,
    customIconDeleteLoading,
  ]);

  // useEffect hook to unify all error states into a single one
  useEffect(() => {
    let updatedError =
      customIconsError ||
      customTagsError ||
      customIconsCreateError ||
      customIconDeleteError;

    setErrorMessage(
      customIconDeleteConflict ? CONFLICT_ERROR_MESSAGE : ERROR_MESSAGE
    );
    setError(updatedError);
    setExtraErrorState(updatedError);
  }, [
    customIconsError,
    customTagsError,
    customIconsCreateError,
    customIconDeleteError,
    customIconDeleteConflict,
    setExtraErrorState,
    CONFLICT_ERROR_MESSAGE,
    ERROR_MESSAGE,
  ]);

  // Create the exposed "API" object
  return {
    customIcons: customIcons,
    customIconTags: customTags,
    customIconsAPI: {
      createOrUpdateCustomIcon,
      deleteCustomIcon,
      customIconsRefetch,
      customTagsRefetch,
    },
    customIconsError: isError,
    customIconsLoading: loading,
    customIconsDefaultIcon,
    customIconsErrorMessage: errorMessage,
  };
};
