import { useTranslation } from "react-i18next";
import _ from "lodash";
import { GENERAL_UNKNOWN } from "../../localization";
import React from "react";
import { DataTableColum, DataTableStyleProps } from "./DataTable";

interface RowProps<T> {
  item: T;
  columns: DataTableColum<T>[];
  styles?: DataTableStyleProps<T>;
}

function TableRow<T>({ item, columns, styles }: RowProps<T>) {
  const { t } = useTranslation();
  const _getValue = (column: DataTableColum<T>) => {
    let value;
    if (column.valueAccessor === undefined) {
      value = _.get(item, column.key);
    } else {
      value = column.valueAccessor(item);
    }

    if (value === undefined || value === null) {
      if (column.missingCellOverride !== undefined) {
        value = column.missingCellOverride;
      } else {
        value = <span className="text-muted">{t(GENERAL_UNKNOWN)}</span>;
      }
    } else if (column.valueFormatter !== undefined) {
      value = column.valueFormatter(value);
    }

    return value;
  };

  return (
    <tr
      className={
        styles?.tableRowColor !== undefined ? styles?.tableRowColor(item) : ""
      }
    >
      {columns.map((column) => {
        if (column.hidden === true) return null;
        return (
          <td
            key={String(column.key)}
            className={column.classNames?.join(" ") ?? ""}
          >
            {_getValue(column)}
          </td>
        );
      })}
    </tr>
  );
}

export default TableRow;
