export const useAutoCompleteInput = (
  startTrigger: string,
  endTrigger: string
): [
  (textValue: string, cursorPosition: number) => SplittedText,
  (value: string, cursorStart: number) => string,
  (option: string, currentTypedPlaceholder: string) => boolean
] => {
  const splitTextOnCurrentCursorPosition = (
    textValue: string,
    cursorPosition: number
  ): SplittedText => {
    const startOfText = textValue.slice(0, cursorPosition);
    const endOfText = textValue.slice(cursorPosition, textValue.length);
    const nextMenuTriggerPosition: number =
      startOfText.lastIndexOf(startTrigger);

    return {
      start: startOfText,
      end: endOfText,
      indexOfCurrentTrigger: nextMenuTriggerPosition,
    };
  };

  const setTypedValue = (value: string, cursorStart: number) => {
    const { start, indexOfCurrentTrigger } = splitTextOnCurrentCursorPosition(
      value,
      cursorStart
    );

    const removedValueBeforeMenuTrigger = start.slice(indexOfCurrentTrigger);

    const searchedValue = removedValueBeforeMenuTrigger.slice(
      0,
      removedValueBeforeMenuTrigger.lastIndexOf(endTrigger) + 1
    );

    return searchedValue === "" ? removedValueBeforeMenuTrigger : searchedValue;
  };

  const filterMethod = (option: string, currentTypedPlaceholder: string) => {
    if (option.includes(currentTypedPlaceholder)) {
      return option !== currentTypedPlaceholder;
    }
    return false;
  };

  return [splitTextOnCurrentCursorPosition, setTypedValue, filterMethod];
};

interface SplittedText {
  start: string;
  end: string;
  indexOfCurrentTrigger: number;
}
