import React from "react";
import { DataTable, DataTableColum, DataTableProps } from "./DataTable";
import "./SortableTable.scss";
import useSortableColumns, { SortModel } from "./useSortableColumnsHook";

export function SortableTable<T extends { id: string }>({
  columns,
  sortModel,
  onSortModelChange,
  ...dataTableProps
}: SortableTableProps<T>) {
  const sortableColumns = useSortableColumns(
    columns,
    sortModel,
    onSortModelChange
  );
  return <DataTable columns={sortableColumns} {...dataTableProps} />;
}

export type SortableTableProps<T> = {
  columns: SortableColumn<T>[];
  sortModel: SortModel;
  onSortModelChange: (sortModel: SortModel) => void;
} & DataTableProps<T>;

export type SortableColumn<T> = {
  sortable?: boolean;
} & DataTableColum<T>;

export default SortableTable;
