import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { Level } from "../models/Level";
import "./LevelSelector.scss";
import { useSelectCurrentLayer } from "../asset/hooks/useSelectCurrentLayer";

export default function LevelSelector({
                                        availableLevels,
  currentLevel,
  children,
}: ILevelSelectorProps) {
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const { default: defaultMap } = useMap();

  const map = defaultMap?.getMap();
  useSelectCurrentLayer(currentLevel.layerId);
  const _changeLayer = useCallback(
    (layerId: string) => {
      if (map === undefined) return;
      for (const layer of availableLevels) {
        const mapboxLayer = map.getLayer(layer.layerId);
        if (mapboxLayer) {
          if (layer.layerId === layerId) {
            map.setLayoutProperty(layer.layerId, "visibility", "visible");
          } else {
            map.setLayoutProperty(layer.layerId, "visibility", "none");
          }
        }
      }
    },
    [availableLevels, map]
  );

  useEffect(() => {
    map?.once("styledataloading", () => {
      setMapIsLoaded(false);
    });
    map?.once("style.load", () => {
      setMapIsLoaded(true);
    });
  }, [map]);

  useEffect(() => {
    if (!mapIsLoaded) return;
    _changeLayer(currentLevel.layerId);
  }, [currentLevel.layerId, _changeLayer, mapIsLoaded]);

  return <>{children}</>;
}

interface ILevelSelectorProps {
  availableLevels: Level[];
  currentLevel: Level;
  children?: ReactNode;
}
