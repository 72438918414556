export interface Position {
  assetId?: string;
  assetName?: string;
  icon?: string;
  latitude: number;
  lastSeen?: string;
  layerId?: string;
  locatorId?: string;
  longitude: number;
  locatorName?: string;
  radius?: number;
  color?: Color;
  levelName?: string;
  isNotClustered?: boolean;
  assetGroupName?: string;
  assetGroupColor?: string;
  technicalRoomNumber?: string;
  locationId?: string;
  levelId?: string;
}

export enum Color {
  blue = 1,
  yellow = 2,
  red = 3,
}
