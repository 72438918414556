import { AssetService } from "../index";
import { HistoricalPosition } from "../../__generated__/history";

export function useTransformPositionToFeatureCollection(
  historicalPositions?: HistoricalPosition[]
) {
  if (historicalPositions === undefined || historicalPositions.length === 0) {
    return { featureCollection: null };
  }

  return {
    featureCollection: AssetService.mapPositionsToFeatureCollection(
      historicalPositions.map((historicalPosition: HistoricalPosition) => {
        return {
          latitude: historicalPosition.latitude ?? 0,
          longitude: historicalPosition.longitude ?? 0,
          radius: historicalPosition.radius ?? 3,
          duration: historicalPosition.duration ?? 100,
        };
      })
    ),
  };
}
