import { useCallback } from "react";
import { RuleElement } from "../Model/RuleElement";
import { RuleSequence } from "../Model/RuleSequence";
import { useDispatch } from "react-redux";
import { submitFields } from "../ruleManagerSlice";
import { WriteFieldProperties } from "../Model/RuleModels";

export const useSubmitFields = (
  ruleSequence: RuleSequence,
  ruleInfo: RuleElement | null
) => {
  const dispatch = useDispatch();

  return useCallback(
    (fieldValues: WriteFieldProperties) => {
      if (ruleInfo === null) {
        return;
      }

      dispatch(
        submitFields({
          ruleSequence: ruleSequence,
          fieldValues: fieldValues,
          id: ruleInfo.fieldType,
        })
      );
    },
    [dispatch, ruleInfo, ruleSequence]
  );
};
