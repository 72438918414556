import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
  order: ["querystring", "localStorage", "navigator"],
  lookupQuerystring: "lng",
  caches: ["localStorage"],
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ["de", "en", "fr", "it"],
    debug: false,
    detection: options,
  });

export default i18n;
