import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import _ from "lodash";
import { OptionsType } from "react-select";
import { IS_LEAF } from "../shared/queries/BuildingPartsQueries";
import { RangeBounds } from "../models/assets/Asset";
import i18next from "../i18n";
import {
  ERROR_FORM_FORMAT_NOT_ALLOWED,
  ERROR_FORM_LIMIT_COMPARISON,
  ERROR_FORM_ONLY_NUMBERS,
  ERROR_FORM_ONLY_VALID_MAC_ADDRESSES,
  ERROR_FORM_VALID_EMAIL_ADDRESS,
  INFO_FORM_AT_LEAST_ONE,
  INFO_FORM_REQUIRED_FIELD,
  INFO_LAST_BUILDINGPART,
} from "../localization";

export const required = (value: any) => {
  const message = i18next.t(INFO_FORM_REQUIRED_FIELD);
  if (Array.isArray(value)) {
    return value.length > 0 ? undefined : message;
  }
  return value ? undefined : message;
};

export const notEmpty = (value: any[]) =>
  Array.isArray(value) && value.length > 0
    ? undefined
    : i18next.t(INFO_FORM_AT_LEAST_ONE);

export const noScript = (value: string) =>
  !RegExp(/<(\/?\w+[^\n>]*\/?)>/gi).test(value)
    ? undefined
    : i18next.t(ERROR_FORM_FORMAT_NOT_ALLOWED);

export const onlyNumbers = (input: any) =>
  isNaN(Number(input)) ? i18next.t(ERROR_FORM_ONLY_NUMBERS) : undefined;

export const numbersOrEmpty = (input: any) => {
  if (!input) {
    return undefined;
  } else return isNaN(Number(input)) ? i18next.t(ERROR_FORM_ONLY_NUMBERS) : undefined;
}

export const macAddress = (input: any) =>
  !RegExp(/^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/).test(input)
    ? `${i18next.t(ERROR_FORM_ONLY_VALID_MAC_ADDRESSES)} (XX:XX:XX:XX:XX:XX).`
    : undefined;

export const composeValidators =
  (...validators: any) =>
    (value: any) =>
      validators.reduce(
        (error: string, validator: any) => error || validator(value),
        undefined
      );

export const validateBuildingPartLeaf = (
  client: ApolloClient<NormalizedCacheObject> | undefined
) => {
  const errorMessage = i18next.t(INFO_LAST_BUILDINGPART);
  if (client === undefined) return undefined;
  return async (
    buildingPartId: OptionsType<{ label: string; value: string }>
  ) => {
    if (
      buildingPartId === null ||
      buildingPartId === undefined ||
      _.isEmpty(buildingPartId)
    )
      return undefined;
    const result = await client.query({
      query: IS_LEAF,
      variables: {
        buildingPartExternalId:
        _.last<{ label: string; value: string }>(buildingPartId)?.value,
      },
    });
    return result.data.isLeaf ? undefined : errorMessage;
  };
};

export const validateBounds = (value: RangeBounds) => {
  if (value === undefined || value == null) return undefined;
  if (value.lower == null || value.upper == null) return undefined;
  if (String(value.lower) === "" || String(value.upper) === "")
    return undefined;

  if (+value.lower >= +value.upper)
    return i18next.t(ERROR_FORM_LIMIT_COMPARISON);

  return undefined;
};
//Regex based on RFC 5322 at emailregex.com
export const isEmailAddress = (address: string) =>
  !RegExp(
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\u{20}\\x21\\x23-\\x5b\\x5d-\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\u{20}\\x21-\\x5a\\x53-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\x7f])+)\])$/
  ).test(address)
    ? i18next.t(ERROR_FORM_VALID_EMAIL_ADDRESS)
    : undefined;
