import { ApolloQueryResult } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { Link, Route, useHistory, useRouteMatch } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { Tag } from "../../models/Tag";
import TagForm from "./TagForm";
import TagList from "./TagList";
import { useTranslation } from "react-i18next";
import { TAG_CREATE, TAG_EDIT, TAG_MANAGE } from "../../localization";

function TagManagerModal({
  isOpen = false,
  onClosed,
  onToggle,
  tags,
  refetchAssetData,
}: TagManagerModalProps) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const handleFormSubmit = useCallback(async () => {
    if (refetchAssetData !== undefined) {
      await refetchAssetData();
    }
    history.push(`${url}/tags`);
  }, [history, url, refetchAssetData]);

  return (
    <Modal isOpen={isOpen} onClosed={onClosed} size="xl" toggle={onToggle}>
      <div className="modal-header">
        <h5 className="modal-title d-inline-flex">
          <Route path={`${url}/tags`} exact>
            {t(TAG_MANAGE)}
          </Route>
          <Route path={`${url}/tags/create`}>
            <Button
              size="sm"
              outline
              color="secondary"
              className="me-2"
              tag={Link}
              to="./"
            >
              <FontAwesomeIcon icon="arrow-left" />
            </Button>
            {t(TAG_CREATE)}
          </Route>
          <Route path={`${url}/tags/edit/:id`}>
            <Button
              size="sm"
              outline
              color="secondary"
              className="mr-2"
              tag={Link}
              to="../"
            >
              <FontAwesomeIcon icon="arrow-left" />
            </Button>
            {t(TAG_EDIT)}
          </Route>
        </h5>
        <button
          onClick={onToggle}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <ModalBody>
        <Route path={`${url}/tags`} exact>
          <TagList items={tags} refetchAssetData={refetchAssetData} />
        </Route>
        <Route path={`${url}/tags/create`}>
          <TagForm onSubmitted={handleFormSubmit} />
        </Route>
        <Route path={`${url}/tags/edit/:id`}>
          <TagForm tags={tags} onSubmitted={handleFormSubmit} />
        </Route>
      </ModalBody>
    </Modal>
  );
}

interface TagManagerModalProps {
  isOpen: boolean;
  onClosed?: () => void;
  onToggle: React.MouseEventHandler<any>;
  tags: Tag[];
  refetchAssetData?: () => Promise<ApolloQueryResult<any>>;
}

export default TagManagerModal;
