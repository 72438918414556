import { ApolloQueryResult } from "@apollo/client";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormApi, SubmissionErrors } from "final-form";
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button, Col, Form as ReactForm, Label, Row } from "reactstrap";
import { BoundsInput, BuildingpartSelectField, CheckboxGroup, InputField, TypeaheadField, } from "../../forms";
import ErrorFeedback from "../../forms/components/ErrorFeedback";
import {
  composeValidators,
  noScript,
  notEmpty,
  numbersOrEmpty,
  required,
  validateBounds,
  validateBuildingPartLeaf,
} from "../../forms/validation";
import { useGqlClient } from "../../hooks";
import { Node } from "../../hooks/drilldownData";
import { AssetFormData } from "../../models/assets/Asset";
import TagManagerModal from "../components/TagManagerModal";
import GeoCordsModal, { MarkerProps } from "./GeoCordsModal";
import IconSelector from "../../shared/icon-picker/IconSelector";
import { CustomIcon, CustomIconTag } from "../../models/CustomIcon";
import { useTranslation } from "react-i18next";
import {
  ACTION_CANCEL,
  GENERAL_CHARACTERS_LEFT,
  GENERAL_DESCRIPTION,
  GENERAL_DESIGNATION,
  GENERAL_GROUP_SG,
  GENERAL_INVENTORY_NUMBER,
  GENERAL_LATITUDE,
  GENERAL_LONGITUDE,
  GENERAL_MODULES,
  GENERAL_SYMBOL_SG,
  GENERAL_TEMPERATURE_LIMITS,
  GENERAL_TEMPERATURE_OFFSET,
  LOCATION_FIXED,
  PLACEHOLDER_SELECT,
} from "../../localization";

const MAX_DESCRIPTION_CHARS = 255;

export const AssetForm: React.FC<IAssetFormProps> = ({
                                                       onSubmit,
                                                       initialValues,
                                                       availableAssetGroups,
                                                       availableUseCases,
                                                       availableTags,
                                                       availableCustomIcons,
                                                       availableCustomIconTags,
                                                       beaconLabel,
                                                       acceptButtonText,
                                                       selectedBuildingparts,
                                                       refetchAssetData,
                                                     }) => {
  const client = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_BUILDING_DATA!);
  const history = useHistory();
  const {url} = useRouteMatch();
  const {pathname} = useLocation();
  const {t} = useTranslation();
  const [modal, setModal] = useState(false);
  const [modalGeoCords, setModalGeoCords] = useState(false);
  const [geocords, setGeocords] = useState<MarkerProps | undefined>(undefined);
  const toggle = () => setModal(!modal);
  const formRef: MutableRefObject<
    FormApi<AssetFormData, Partial<AssetFormData>> | undefined
  > = useRef();

  const initValues = useMemo(() => {
    if (initialValues?.geocords !== geocords) {
      if (geocords) return {...initialValues, geocords};
      return {...initialValues, geocords: undefined};
    }
    return initialValues;
  }, [initialValues, geocords]);

  useEffect(() => {
    setGeocords(initialValues?.geocords);
  }, [initialValues]);

  useEffect(() => {
    if (geocords) {
      formRef.current?.change("geocords", geocords);
    }
  }, [geocords]);

  useEffect(() => {
    if (pathname.includes("tags")) {
      setModal(true);
    } else {
      setModal(false);
    }
  }, [pathname, setModal]);

  const handleClosed = useCallback(() => {
    history.push(url);
  }, [history, url]);

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
      >
        {({
            handleSubmit,
            pristine,
            form,
            submitting,
            values,
            invalid,
            modifiedSinceLastSubmit,
            hasValidationErrors,
            hasSubmitErrors,
          }) => {
          formRef.current = form;
          return (
            <ReactForm name="assetForm" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={2}>
                  {t(GENERAL_MODULES)}: <span className="text-danger">*</span>
                </Col>
                <Col>
                  <Field
                    name="useCaseIds"
                    validate={notEmpty}
                    options={[...availableUseCases]
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((value: { name: string; id: string }) => {
                        return {
                          label: t(`useCase.${value.name}`),
                          value: value.id,
                        };
                      })}
                    component={CheckboxGroup}
                  />
                </Col>
                <Col>
                  <div className="d-flex">
                    <span className="me-4">{t(GENERAL_SYMBOL_SG)}:</span>
                    <Field
                      name="iconId"
                      customIcons={availableCustomIcons}
                      customTags={availableCustomIconTags}
                      component={IconSelector}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2}>
                  {t(GENERAL_GROUP_SG)}: <span className="text-danger">*</span>
                </Col>
                <Col>
                  <Field
                    options={availableAssetGroups}
                    name="assetGroups"
                    id="assetGroups"
                    labelKey="name"
                    placeholder={t(PLACEHOLDER_SELECT)}
                    validate={required}
                    component={TypeaheadField}
                    multiple={true}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2}>
                  {t(GENERAL_DESIGNATION)}:{" "}
                  <span className="text-danger">*</span>
                </Col>
                <Col>
                  <Field
                    name="name"
                    type="text"
                    maxLength="80"
                    validate={composeValidators(required, noScript)}
                    component={InputField}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2}>{t(GENERAL_INVENTORY_NUMBER)}:</Col>
                <Col>
                  <Field
                    name="inventoryNumber"
                    type="text"
                    maxLength="80"
                    validate={noScript}
                    component={InputField}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2} className="d-inline-flex justify-content-between">
                  <span>
                    {beaconLabel} <span className="text-danger">*</span>
                  </span>
                  <Button
                    size="sm"
                    outline
                    onClick={() => history.push(`${url}/tags`)}
                  >
                    <FontAwesomeIcon icon="edit"/>
                  </Button>
                </Col>
                <Col>
                  <Field
                    name="tags"
                    id="tags"
                    options={availableTags}
                    placeholder={t(PLACEHOLDER_SELECT)}
                    labelKey="name"
                    validate={required}
                    component={TypeaheadField}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2}>{t(LOCATION_FIXED)}:</Col>
                <Col>
                  <Field
                    name="buildingparts"
                    placeholder={t(PLACEHOLDER_SELECT)}
                    component={BuildingpartSelectField}
                    selectedBuildingparts={selectedBuildingparts}
                    validate={validateBuildingPartLeaf(client)}
                  />
                  <ErrorFeedback name="buildingparts"/>
                </Col>
                {form.getFieldState("buildingparts")?.value &&
                  form.getFieldState("buildingparts")?.valid &&
                  !form.getFieldState("buildingparts")?.validating && (
                    <Col className="d-inline-flex">
                      <Button
                        size="sm"
                        outline
                        onClick={() => setModalGeoCords(true)}
                      >
                        <FontAwesomeIcon icon="map-marked-alt"/>
                      </Button>
                      <Field
                        className="text-center me-1 ms-2"
                        name="geocords.latitude"
                        component={InputField}
                        placeholder={t(GENERAL_LATITUDE)}
                        disabled
                        isEqual={() =>
                          initialValues?.geocords === values.geocords
                        }
                      />
                      <Field
                        className="text-center me-2 ms-1"
                        name="geocords.longitude"
                        component={InputField}
                        placeholder={t(GENERAL_LONGITUDE)}
                        disabled
                        isEqual={() =>
                          initialValues?.geocords === values.geocords
                        }
                      />
                      <Button
                        size="sm"
                        outline
                        onClick={() => {
                          form.change("geocords", undefined);
                        }}
                        disabled={!values.geocords}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}/>
                      </Button>
                    </Col>
                  )}
              </Row>
              <Row className="mb-3">
                <Col md={2}>{t(GENERAL_TEMPERATURE_OFFSET)}:</Col>
                <Col>
                  <Field
                    name="temperatureOffset"
                    type="number"
                    validate={numbersOrEmpty}
                    component={InputField}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2}>{t(GENERAL_TEMPERATURE_LIMITS)}:</Col>
                <Col md={8}>
                  <Field
                    name="allowedTemperatureRange"
                    id="allowedTemperatureRange"
                    component={BoundsInput}
                    validate={validateBounds}
                  />
                </Col>
              </Row>
              <Row className="mb-1">
                <Col md={2}>{t(GENERAL_DESCRIPTION)}:</Col>
                <Col className="d-flex flex-column">
                  <Field
                    name="description"
                    type="textarea"
                    id="description"
                    maxLength={MAX_DESCRIPTION_CHARS}
                    validate={noScript}
                    component={InputField}
                  />
                  <Label className="ms-auto">
                    {MAX_DESCRIPTION_CHARS - (values?.description?.length ?? 0)}{" "}
                    {t(GENERAL_CHARACTERS_LEFT)}
                  </Label>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={8}/>
                <Col md={2}>
                  <Button
                    block
                    color="danger"
                    onClick={() => {
                      /* Workaround for reset while keepDirtyOnReinizialize is set to true.
                        Reset will no longer take the initial values like expected.
                        https://github.com/final-form/final-form/issues/151#issuecomment-425867172
                        */
                      form.setConfig("keepDirtyOnReinitialize", false);
                      form.reset();
                      form.setConfig("keepDirtyOnReinitialize", true);
                    }}
                    disabled={pristine || submitting}
                    style={{minWidth: "100%"}}
                  >
                    {t(ACTION_CANCEL)}
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    block
                    color="success"
                    type="submit"
                    disabled={
                      (hasValidationErrors && hasSubmitErrors) ||
                      pristine ||
                      (invalid && !modifiedSinceLastSubmit)
                    }
                    style={{minWidth: "100%"}}
                  >
                    {acceptButtonText}
                  </Button>
                </Col>
              </Row>
            </ReactForm>
          );
        }}
      </Form>
      <GeoCordsModal
        key={modalGeoCords ? "open" : "closed"}
        isOpen={modalGeoCords}
        onClosed={(data) => {
          setGeocords(data);
          setModalGeoCords(false);
        }}
        onToggle={() => setModalGeoCords(!modalGeoCords)}
        initialValues={initValues?.geocords}
      />
      <TagManagerModal
        isOpen={modal}
        onClosed={handleClosed}
        onToggle={toggle}
        tags={availableTags}
        refetchAssetData={refetchAssetData}
      />
    </>
  );
};

export interface IAssetFormProps {
  acceptButtonText: string;
  availableAssetGroups: { name: string; id: string }[];
  availableUseCases: { name: string; id: string }[];
  availableTags: { name: string; id: string }[];
  availableCustomIcons: CustomIcon[];
  availableCustomIconTags: CustomIconTag[];
  beaconLabel: string;
  onSubmit: (
    formValues: AssetFormData
  ) => Promise<SubmissionErrors | undefined>;
  initialValues: AssetFormData | undefined;
  selectedBuildingparts?: Node[];
  refetchAssetData?: () => Promise<ApolloQueryResult<any>>;
}

export default AssetForm;
