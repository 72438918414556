import {Layer, Source} from "react-map-gl";
import React, {memo} from "react";
import {useQuery} from "react-query";
import {FeatureCollection} from "geojson";
import {Expression} from "mapbox-gl";
import {BUNDLED_PROPERTIES, EXPRESSIONS, VALUES} from "./Constants";
import useMapBoxImages from "./useMapboxImages";
import i18next from "../i18n";
import { ERROR_NETWORK } from "../localization";

async function fetchFloorplans() {
  const {REACT_APP_URI_BUILDING_DATA} = process.env;
  const response = await fetch(REACT_APP_URI_BUILDING_DATA + "/geojson");
  if (!response.ok) {
    throw new Error(i18next.t(ERROR_NETWORK) ?? "");
  }

  return response.json();
}

function CuGeoJsonFloorplan({level, use3D}: CuGeoJsonFloorplanProps) {
  const {data} = useQuery<Floorplan>("floorplans", fetchFloorplans, {
    enabled: true,
  });

  useMapBoxImages();

  const emptyCollection: FeatureCollection = {
    type: "FeatureCollection",
    features: [],
  };

  const geoJson = {
    ground: data?.["ground.geojson.json"] ?? emptyCollection,
    outline: data?.["outline.geojson.json"] ?? emptyCollection,
    rooms: data?.["rooms.geojson.json"] ?? emptyCollection,
    knoten: data?.["knoten.geojson.json"] ?? emptyCollection,
    floors: data?.["floors.geojson.json"] ?? emptyCollection,
    raum: data?.["raum.geojson.json"] ?? emptyCollection,
    poi: data?.["poi.geojson.json"] ?? emptyCollection,
    einrichtung: data?.["einrichtung.geojson.json"] ?? emptyCollection,
    stockwerk: data?.["stockwerk.geojson.json"] ?? emptyCollection,
  };

  const levelFilter: Expression = ["==", ["get", "level"], parseInt(level)];
  const baseLevelFilter: Expression = [
    "all",
    ["any", ["==", ["get", "class"], "important"]].concat([levelFilter]),
  ];
  const zoomFilters: Expression = [">", ["zoom"], 11];
  const roomsFilters: Expression = [
    "all",
    ["!", EXPRESSIONS.roomType.hiddenRoom],
    zoomFilters,
  ];
  const glassRoomFilters: Expression = [
    "all",
    EXPRESSIONS.roomType.glass,
    zoomFilters,
  ];
  const facilityFilters = ["all", [">", ["zoom"], 17]].concat([levelFilter]);
  const labelFilter = ["all", [">", ["zoom"], 16]].concat([levelFilter]);
  const outlineLayerFilter = baseLevelFilter.concat([zoomFilters]);
  const roomsLayerFilter = baseLevelFilter.concat([roomsFilters]);
  const glassRoomsLayerFilter = baseLevelFilter.concat([glassRoomFilters]);

  return (
    <>
      <Source type={"geojson"} id={"ground"} data={geoJson.ground}>
        <Layer
          type={"fill"}
          id={"ground"}
          paint={{
            "fill-color": VALUES.ground,
          }}
        />
      </Source>
      <Source type={"geojson"} id={"outline"} data={geoJson.outline}>
        <Layer
          type={"fill"}
          id={"outline"}
          filter={outlineLayerFilter}
          paint={{
            "fill-color": VALUES.outline,
          }}
        />
      </Source>
      <Source type={"geojson"} id={"rooms"} data={geoJson.rooms}>
        <Layer
          type={"fill-extrusion"}
          id={"rooms"}
          filter={roomsLayerFilter}
          paint={{
            "fill-extrusion-height": use3D
              ? BUNDLED_PROPERTIES.rooms.height
              : 0,
            "fill-extrusion-color": BUNDLED_PROPERTIES.rooms.color,
            "fill-extrusion-vertical-gradient": false,
            "fill-extrusion-base": BUNDLED_PROPERTIES.rooms.base,
          }}
        />
        <Layer
          type={"fill-extrusion"}
          id={"glassrooms"}
          filter={glassRoomsLayerFilter}
          paint={{
            "fill-extrusion-height": use3D
              ? BUNDLED_PROPERTIES.rooms.height
              : 0,
            "fill-extrusion-color": BUNDLED_PROPERTIES.rooms.color,
            "fill-extrusion-vertical-gradient": false,
            "fill-extrusion-base": BUNDLED_PROPERTIES.rooms.base,
          }}
        />
      </Source>
      <Source type="geojson" id="nodes" data={geoJson.knoten} cluster={false}>
        <Layer
          type="circle"
          id="nodes-circle-out"
          paint={{
            "circle-color": BUNDLED_PROPERTIES.nodes.circleColor,
            "circle-radius": 15,
            "circle-stroke-width": 5,
            "circle-stroke-color": VALUES.white,
          }}
          filter={facilityFilters}
        />
        <Layer
          type="symbol"
          id="nodes-icon"
          layout={{
            "icon-image": ["get", "iconname"],
            "icon-allow-overlap": true,
            "icon-size": 0.8,
          }}
          paint={{
            "icon-color": BUNDLED_PROPERTIES.nodes.iconColor,
          }}
          filter={facilityFilters}
        />
      </Source>
      <Source type="geojson" id="facilities" data={geoJson.einrichtung}>
        <Layer
          type="symbol"
          id="facilities"
          layout={{
            "text-field": ["get", "name"],
            "text-font": VALUES.facilityTextFont,
            "text-allow-overlap": false,
          }}
          paint={{
            "text-halo-color": VALUES.textHalo,
            "text-halo-blur": 1,
            "text-halo-width": 1,
          }}
          filter={labelFilter}
        />
      </Source>
      <Source type="geojson" id="pois" data={geoJson.poi}>
        <Layer
          type="symbol"
          id="poi-labels"
          layout={{
            "text-field": ["get", "name"],
            "text-font": VALUES.facilityTextFont,
            "text-allow-overlap": false,
          }}
          paint={{
            "text-halo-color": VALUES.textHalo,
            "text-halo-blur": 1,
            "text-halo-width": 1,
          }}
          filter={labelFilter}
        />
      </Source>
    </>
  );
}

type Floorplan = {
  "ground.geojson.json": FeatureCollection;
  "outline.geojson.json": FeatureCollection;
  "rooms.geojson.json": FeatureCollection;
  "floors.geojson.json": FeatureCollection;
  "stockwerk.geojson.json": FeatureCollection;
  "einrichtung.geojson.json": FeatureCollection;
  "poi.geojson.json": FeatureCollection;
  "raum.geojson.json": FeatureCollection;
  "knoten.geojson.json": FeatureCollection;
};

type CuGeoJsonFloorplanProps = {
  level: string;
  use3D: boolean;
};

export default memo(CuGeoJsonFloorplan);
