import React, { useMemo, useState } from "react";
import RuleElementList from "./RuleElementList";
import RulesContainer from "./RulesContainer";
import { ProgressPoint } from "../../shared/progress-navigation/ProgressNavigation";
import RulesFormGenerator from "./RulesFormGenerator";
import { useDispatch, useSelector } from "react-redux";
import {
  getProgressBar,
  getRuleSequence,
  setMenuPoint,
} from "../ruleManagerSlice";
import { RuleSaveFields } from "../mock-data/RuleData";
import { RuleSequence, RuleStateMachine } from "../Model/RuleSequence";
import { Rule } from "../Model/Rule";
import { Modal as HyprosModal } from "../../shared";
import { RuleState } from "../Model/RuleState";
import { WriteFieldProperties } from "../Model/RuleModels";

const RulesTemplate: React.FC<IRulesTemplate> = ({
  ruleLabel,
  marked,
  preSelectedListElement,
  rule,
  saveRule,
  submitField,
}) => {
  const { ruleElements, ruleFields } = rule;
  const progressPoints: ProgressPoint[] = useSelector(getProgressBar);
  const ruleSequence = useSelector(getRuleSequence);
  const dispatch = useDispatch();
  const ruleSeq = new RuleStateMachine();
  const [modal, setModal] = useState<boolean>(
    ruleSequence === RuleSequence.RULE_FORM
  );

  const invalidElements = useMemo(() => {
    const ruleState = new RuleStateMachine();
    const anyInvalidElements = progressPoints.some(
      (progressPoint: ProgressPoint) =>
        ruleState.isListState(progressPoint.state)
    );
    setModal(!anyInvalidElements && ruleSequence === RuleSequence.RULE_FORM);
    return anyInvalidElements;
  }, [progressPoints, ruleSequence]);

  const toggleModal = () => {
    if (modal) {
      dispatch(setMenuPoint(ruleSeq.previous(ruleSequence)));
    }
    setModal(!modal);
  };

  return (
    <>
      <HyprosModal
        header={ruleLabel}
        acceptButtonText={ruleLabel}
        show={modal}
        disableFooter
        onCloseButton
        onClose={toggleModal}
        size="lg"
      >
        <RulesFormGenerator
          formItems={RuleSaveFields.fields}
          submit={(fieldValues: RuleState) => {
            if (fieldValues.name && fieldValues.description && saveRule) {
              saveRule(fieldValues.name, fieldValues.description);
            }
            toggleModal();
          }}
          initialValues={rule}
          submitButtonText={ruleLabel}
        />
      </HyprosModal>
      {ruleFields && !ruleSeq.isListState(ruleSequence) ? (
        <RulesContainer
          {...ruleFields}
          highlighted={preSelectedListElement ?? marked}
          headerLabel={ruleLabel}
        >
          <RulesFormGenerator
            formItems={ruleFields?.fields}
            submit={submitField}
            initialValues={rule.initValues}
            disableSubmit={
              invalidElements && RuleSequence.ACTION_FORM === ruleSequence
            }
          />
        </RulesContainer>
      ) : (
        ruleElements &&
        ruleSeq.isListState(ruleSequence) && (
          <RulesContainer
            {...ruleElements}
            highlighted={preSelectedListElement ?? marked}
            headerLabel={ruleLabel}
          >
            <RuleElementList ruleElements={ruleElements?.ruleElements} />
          </RulesContainer>
        )
      )}
    </>
  );
};

interface IRulesTemplate {
  rule: Rule;
  marked?: string;
  preSelectedListElement?: string;
  submitField?: (fieldValues: WriteFieldProperties) => void;
  saveRule?: (name: string, description: string) => void;
  ruleLabel: string;
}

export default RulesTemplate;
