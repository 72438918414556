import React, { CSSProperties, useState } from "react";
import { CustomIcon } from "../../models/CustomIcon";

const Icon: React.FC<IconProps> = ({
                                     icon,
                                     className,
                                     active = false,
                                     setActiveName,
                                     height,
                                     width,
                                   }) => {
  const DEFAULT_HEIGHT = 60;
  const DEFAULT_WIDTH = 60;
  const style: CSSProperties = {
    border: "1px solid lightblue",
  };
  const DEFAULT_CLASSNAME = "m-2 p-2 bg-white shadow-sm rounded";
  const [error, setError] = useState<boolean>(false);

  return (
    <img
      className={
        className ? DEFAULT_CLASSNAME + " " + className : DEFAULT_CLASSNAME
      }
      onError={() => setError(true)}
      style={active ? style : undefined}
      src={error ? "" : `data:image/svg+xml;utf8,${encodeURIComponent(icon.iconCode)}`}
      onClick={() => {
        if (setActiveName) setActiveName(icon);
      }}
      height={height ?? DEFAULT_HEIGHT}
      width={width ?? DEFAULT_WIDTH}
      alt={icon.tags.map(tag => tag.tagName).join(", ")}
    />
  );
};

export interface IconProps {
  icon: CustomIcon;
  className?: string;
  active?: boolean;
  setActiveName?: (name: CustomIcon) => void;
  height?: number;
  width?: number;
}

export default Icon;
