import gql from "graphql-tag";

export const GET_ALARMS_SUBSCRIPTION = gql`
  subscription ALARM {
    alarms {
      tagMac
      createdAt
      deletedAt
      confirmedAt
      lastPosition {
        latitude
        longitude
        locatorName
        radius
        locatorId
      }
    }
  }
`;
