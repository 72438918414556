import React, { useState } from "react";
import Content from "./Content";
import Navigation from "./Navigation";
import "./style/Layout.scss";
import Header from "./Header";

const Layout = () => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className="layout d-flex align-items-stretch">
      <Navigation
        collapsed={collapsed}
        changeMenuState={() => setCollapsed(!collapsed)}
      />
      <main role="main" className="d-flex flex-column">
        <Header changeMenuState={() => setCollapsed(!collapsed)} />
        <Content />
      </main>
    </div>
  );
};

export default Layout;
