import { gql } from "@apollo/client";

export const GET_LEVEL = gql`
  query getLevel($buildingPartExternalId: ID, $useMaxDepth: Boolean) {
    getLevel(
      buildingPartExternalId: $buildingPartExternalId
      useMaxDepth: $useMaxDepth
    ) {
      id
      name
      parent
    }
  }
`;
