import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useKeycloak } from "../auth";
import "./ActionButtons.scss";
import LinkButton from "./LinkButton";

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  actions,
  size,
}) => {
  const keycloak = useKeycloak();
  const renderedActionsCount = actions.filter(
    ({ role }) => role === undefined || keycloak.hasResourceRole(role)
  ).length;

  const _renderButtons = () =>
    actions.map(({ text, color, role, ...rest }, i) => {
      if (renderedActionsCount > 2 && i > 0) return null;
      let isRendered = role === undefined || keycloak.hasResourceRole(role);
      let actualColor = color;
      if (actualColor === undefined) {
        if (i === 0) {
          actualColor = "primary";
        } else {
          actualColor = "secondary";
        }
      }

      return (
        isRendered && (
          <LinkButton
            key={i}
            {...rest}
            size={size}
            color={actualColor}
            role={role}
          >
            {text}
          </LinkButton>
        )
      );
    });

  const _renderDropdown = () => {
    if (renderedActionsCount < 3) return null;

    return (
      <UncontrolledDropdown>
        <DropdownToggle color="secondary" size={size}>
          <FontAwesomeIcon icon="ellipsis-v" size={size} />
        </DropdownToggle>
        <DropdownMenu positionFixed>
          {actions
            .slice(1)
            .filter(
              ({ role }) => role === undefined || keycloak.hasResourceRole(role)
            )
            .map(({ text, role, ...rest }, i) => (
              <DropdownItem key={i} {...rest} size={size} tag={LinkButton}>
                {text}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <div className="ActionButtons">
      {_renderButtons()}
      {_renderDropdown()}
    </div>
  );
};

export interface ActionButtonsProps {
  actions: Action[];
  size?: "sm" | "lg";
}

export interface Action {
  text: string;
  to?: string;
  onClick?: () => void;
  role?: string;
  color?: string;
  handleAsExternal?: boolean;
}

export default ActionButtons;
