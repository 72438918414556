import { useMutation } from "@apollo/client";
import { SubmissionErrors } from "final-form";
import { useCallback, useState } from "react";
import { useGqlClient } from "../../hooks";
import { RangeBounds } from "../../models/assets/Asset";
import { MarkerProps } from "../components/GeoCordsModal";
import { CreateOrUpdateAssetQuery } from "../queries/assetFormQueries";
import { INFO_LAST_BUILDINGPART } from "../../localization";
import { useTranslation } from "react-i18next";

export function useAssetCreateOrUpdate(
  onSuccess: () => void,
  assetId?: string
) {
  const [errors, setErrors] = useState<boolean>(false);
  const assetsClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);
  const {t} = useTranslation();

  const [createOrUpdateAssetMutation, {loading}] = useMutation(
    CreateOrUpdateAssetQuery,
    {
      client: assetsClient,
    }
  );

  const createOrUpdate = useCallback(
    async (
      useCaseIds: string[],
      name: string,
      assetGroupIds: string[],
      tagIds: string[],
      description: string,
      buildingPartIDExternal: string | null,
      inventoryNumber?: string,
      geocords?: MarkerProps | undefined,
      allowedTemperatureRange?: RangeBounds | undefined,
      icon?: string,
      iconId?: string,
      offset?: number,
    ): Promise<SubmissionErrors | undefined> => {
      try {
        const {errors} = await createOrUpdateAssetMutation({
          variables: {
            input: {
              id: assetId,
              useCaseIds,
              name,
              tagIds,
              description,
              assetGroupIds,
              buildingPartIDExternal,
              inventoryNumber,
              icon,
              iconId,
              offset,
              fixedPosition:
                geocords &&
                !Object.values(geocords).every((ele) => ele === undefined)
                  ? geocords
                  : undefined,
              temperatureBound:
                allowedTemperatureRange !== undefined
                  ? {
                    upper: allowedTemperatureRange?.upper || null,
                    lower: allowedTemperatureRange?.lower || null,
                  }
                  : null,
            },
          },
        });
        if (errors !== undefined) {
          setErrors(true);
        } else {
          setErrors(false);
          onSuccess();
        }
      } catch (error) {
        setErrors(true);
        if (error.message.includes("NO_LEAF")) {
          return {
            buildingparts: t(INFO_LAST_BUILDINGPART),
          };
        }
      }
    },
    [createOrUpdateAssetMutation, assetId, onSuccess, t]
  );

  return {createOrUpdate, loading, errors};
}
