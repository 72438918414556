import React from "react";
import { useField } from "react-final-form";
import { FormFeedback } from "reactstrap";

type Props = { name: string; errorMsg?: string };

const ErrorFeedback = ({ name, errorMsg }: Props) => {
  const {
    meta: { touched, error, submitError, dirtySinceLastSubmit },
  } = useField(name, {
    subscription: {
      touched: true,
      error: true,
      submitError: true,
      dirtySinceLastSubmit: true,
    },
  });
  if (errorMsg) return <FormFeedback>{errorMsg}</FormFeedback>;
  return !dirtySinceLastSubmit && touched && (error || submitError) ? (
    <FormFeedback>{error || submitError}</FormFeedback>
  ) : null;
};

export default ErrorFeedback;
