export class GenericBroadcastChannel<T> {
  private broadcastChannelIdentifier: string;

  constructor(channelIdentifier: string) {
    this.broadcastChannelIdentifier = channelIdentifier;
  }

  send(message: T) {
    const channel = new BroadcastChannel(this.broadcastChannelIdentifier);
    channel.postMessage(message);
    channel.close();
  }

  getBroadcastChannel() {
    return new BroadcastChannel(this.broadcastChannelIdentifier);
  }

}

