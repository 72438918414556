import { gql } from "@apollo/client";

export const deleteAssetQuery = gql`
  mutation deleteAsset($id: ID!) {
    deleteAsset(assetId: $id) {
      id
      name
      tags {
        name
      }
    }
  }
`;
