import React, { useState } from "react";
import {
  DataGrid,
  deDE,
  enUS,
  frFR,
  itIT,
  GridCallbackDetails,
  GridCell,
  GridCellParams,
  GridColumns,
  GridRowModesModel,
  GridRowsProp,
  MuiBaseEvent,
} from "@mui/x-data-grid";
import { Box, Tooltip, useTheme } from "@mui/material";
import { WarningAmber } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { INFO_NO_ENTRIES } from "../../localization";

const GridTable: React.FC<GridTableProps> = ({
  rows,
  columns,
  loading,
  rowModels,
  emptyRowMessage,
  pageSize,
  rowsPerPageOptions,
  onKeyDown,
  gridError,
}) => {
  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_ROWS_PER_PAGE_OPTIONS: number[] = [];

  const [pageSizeState, setPageSizeState] = useState<number>(
    pageSize ?? DEFAULT_PAGE_SIZE
  );

  const theme: any = useTheme();
  const { i18n, t } = useTranslation();

  const renderEmptyMessage = (message: string) => {
    return (
      <Box marginTop={"1rem"} fontWeight={"bold"} textAlign={"center"}>
        {message}
      </Box>
    );
  };

  const renderCell = (params: any, gridError?: GridErrorCell | null) => {
    return (
      <>
        {params.field === gridError?.fieldName &&
        gridError?.rowId === params.rowId &&
        gridError?.rowId !== undefined ? (
          <Box position={"relative"} display={"flex"}>
            <GridCell {...params} />
            <Box
              alignItems={"center"}
              position={"absolute"}
              right={0}
              top={"50%"}
              style={{
                transform: "translate(-50%,-50%)",
                backgroundColor: "transparent",
              }}
            >
              {gridError?.message ? (
                <Tooltip
                  title={gridError?.message}
                  placement={"left"}
                  disableFocusListener
                  disableTouchListener
                  arrow
                >
                  <WarningAmber color="error" />
                </Tooltip>
              ) : (
                <WarningAmber color="error" />
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <GridCell {...params} />
          </Box>
        )}
      </>
    );
  };

  const _handleDataGridLocaleText = () => {
    switch (i18n.resolvedLanguage) {
      case "fr":
        return frFR.components.MuiDataGrid.defaultProps.localeText;
      case "en":
        return enUS.components.MuiDataGrid.defaultProps.localeText;
      case "it":
        return itIT.components.MuiDataGrid.defaultProps.localeText;
      case "de":
      default:
        return deDE.components.MuiDataGrid.defaultProps.localeText;
    }
  };

  return (
    <DataGrid
      localeText={_handleDataGridLocaleText()}
      sx={{
        border: "none",
        "& .MuiDataGrid-row--editing > div > .MuiDataGrid-cell": {
          backgroundColor: `${theme.strippedColor}`,
        },
        "& .MuiTablePagination-selectLabel": {
          marginTop: "1rem",
          marginBottom: "1rem",
        },
        "& .MuiTablePagination-displayedRows": {
          marginTop: "1rem",
          marginBottom: "1rem",
        },
        "& .hideRightSeparator > .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      }}
      autoHeight
      editMode="row"
      rows={rows}
      columns={columns}
      rowModesModel={rowModels}
      pageSize={pageSizeState}
      onPageSizeChange={(newPageSize) => setPageSizeState(newPageSize)}
      rowsPerPageOptions={rowsPerPageOptions ?? DEFAULT_ROWS_PER_PAGE_OPTIONS}
      components={{
        NoRowsOverlay: () =>
          renderEmptyMessage(emptyRowMessage ?? t(INFO_NO_ENTRIES)),
        Cell: ({ ...params }) => renderCell(params, gridError),
      }}
      onCellKeyDown={onKeyDown}
      loading={loading}
      selectionModel={[]}
      experimentalFeatures={{ newEditingApi: true }}
    />
  );
};

interface GridTableProps {
  columns: GridColumns;
  rows: GridRowsProp;
  rowModels: GridRowModesModel;
  loading?: boolean;
  emptyRowMessage?: string;
  pageSize?: number;
  rowsPerPageOptions?: number[];
  gridError?: GridErrorCell | null;

  onKeyDown?(
    params: GridCellParams,
    event: MuiBaseEvent,
    details: GridCallbackDetails
  ): void;
}

export interface GridErrorCell {
  rowId: string;
  fieldName: string;
  message: string;
}

export default GridTable;
