import React, { useCallback, useMemo } from "react";
import { SortableColumn } from "./SortableTable";
import SortIndicator from "./SortIndicator";

export default function useSortableColumns<T>(
  columns: SortableColumn<T>[],
  sortModel: SortModel,
  sortModelChangeCalback: (sortModel: SortModel) => void
) {
  const handleColumnClick = useCallback(
    (column: SortableColumn<T>) => {
      let order = sortModel.descending;

      if (column.key === sortModel.sortBy) {
        order = !order;
      }

      sortModelChangeCalback({
        sortBy: column.key,
        descending: order,
      });
    },
    [sortModel, sortModelChangeCalback]
  );

  const sortableColumns = useMemo(
    () =>
      columns.map((column) => {
        const sortableColumn = { ...column };
        if (column.sortable !== false) {
          sortableColumn.label = (
            <SortIndicator
              show={sortModel.sortBy === column.key}
              descending={sortModel.descending}
            >
              {column.label}
            </SortIndicator>
          );

          if (sortableColumn.classNames !== undefined) {
            sortableColumn.classNames.push("sortable");
          } else {
            sortableColumn.classNames = ["sortable"];
          }

          sortableColumn.onClick = () => handleColumnClick(column);
        }

        return sortableColumn;
      }),
    [columns, handleColumnClick, sortModel]
  );

  return sortableColumns;
}

export interface SortModel {
  sortBy: number | string | symbol;
  descending: boolean;
}
