import { useQuery } from "@apollo/client";
import { useGqlClient } from ".";
import { GET_ALL_POSITIONS } from "../location/queries/locationsQuery";
import { Position } from "../models/Position";

export const useAllPositions = () => {
  const client = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_LOCATION!);

  const { data, loading, error } = useQuery<PositionsResult>(
    GET_ALL_POSITIONS,
    { client: client, pollInterval: 1000 }
  );

  return { data, loading, error };
};

export interface PositionsResult {
  positions: Position[];
}
