import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Card, CardBody, CardHeader } from "reactstrap";
import { ROUTES } from "../../routes";
import { Loading } from "../../shared";
import AssetTemplate from "../components/AssetTemplate";
import { useAssetCreateOrUpdate } from "../hooks/useAssetCreateOrUpdate";
import { useAssetFormData } from "../hooks/useAssetFormData";
import { useCustomIcons } from "../../customIcons/hooks/useCustomIcons";
import { useCustomIconTags } from "../../customIcons/hooks/useCustomIconTags";
import { useTranslation } from "react-i18next";
import {
  ACTION_CREATE,
  ASSET_ACTION_CREATE,
  ASSET_INFO_CREATE,
  ERROR_UNKNOWN,
  TAG_FREE_TAGS,
} from "../../localization";

function AssetCreate() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const handleSuccess = useCallback(() => {
    history.push({
      pathname: ROUTES.home.path,
      state: {
        renderAlert: true,
        alertMessage: t(ASSET_INFO_CREATE),
      },
    });
  }, [history, t]);

  const { customIcons, customIconsLoading, customIconsError } =
    useCustomIcons();

  const { customTags, customTagsLoading, customTagsError } =
    useCustomIconTags();

  const {
    assetGroups,
    availableTags,
    availableUseCases,
    errors: formDataErrors,
    loading: formDataLoading,
    refetchAssetData,
  } = useAssetFormData();

  const {
    createOrUpdate,
    loading: mutationLoading,
    errors: mutationErrors,
  } = useAssetCreateOrUpdate(handleSuccess);

  useEffect(() => {
    setLoading(
      formDataLoading ||
        mutationLoading ||
        customIconsLoading ||
        customTagsLoading
    );
  }, [formDataLoading, mutationLoading, customIconsLoading, customTagsLoading]);

  useEffect(() => {
    setError(
      formDataErrors || mutationErrors || customIconsError || customTagsError
    );
  }, [formDataErrors, mutationErrors, customIconsError, customTagsError]);

  return (
    <>
      {loading && <Loading />}
      <Alert color="danger" isOpen={error}>
        {t(ERROR_UNKNOWN)}
      </Alert>
      <Card>
        <CardHeader>{t(ASSET_ACTION_CREATE)}</CardHeader>
        <CardBody>
          <AssetTemplate
            acceptButtonText={t(ACTION_CREATE)}
            assetGroups={assetGroups}
            availableTags={availableTags}
            availableUseCases={availableUseCases}
            availableCustomIcons={customIcons}
            availableCustomIconTags={customTags}
            beaconLabel={`${t(TAG_FREE_TAGS)}:`}
            onAccept={createOrUpdate}
            refetchAssetData={refetchAssetData}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default AssetCreate;
