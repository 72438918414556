import { CustomIconTag } from "../../models/CustomIcon";
import { FieldRenderProps } from "react-final-form";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ACTION_ADD,
  GENERAL_CATEGORY_PL,
  INFO_CATEGORY_NO_SPECIAL_CHARACTER,
  INFO_SYMBOL_DUPLICATE_CATEGORY,
  INFO_SYMBOL_NO_CATEGORY,
  PLACEHOLDER_CATEGORY_ADD,
} from "../../localization";

type Props = FieldRenderProps<CustomIconTagFieldInput[], any>;

/**
 * Component that represents a modified {@link Autocomplete} field used to manage custom icon tags.
 * This component allows the user to create new tags by entering a new tag name.
 * All tags are represented as material chips inside the layout.
 * Additionally, the user can use existing tags from other icons.
 * Furthermore, this component validates the created tags by utilizing the following rules:
 * - A single tag is a single word (without whitespaces, etc.).
 * - A single tag only consists of letters and numbers.
 * - A single tag can only occur once per icon.
 *
 * @param input Nested input component to communicate with react-final-form.
 * @param _meta Meta information that can contain e.g. a validation error.
 * @param rest Remaining attributes and props of the {@link FieldRenderProps}.
 */
export const CustomIconTagField = ({ input, _meta, ...rest }: Props) => {
  const { t } = useTranslation();
  /**
   * Function that maps a given list of {@link CustomIconTag} objects to a list of {@link CustomIconTagInput} objects.
   *
   * @param tags List of {@link CustomIconTag} objects that should be mapped.
   *
   * @return Returns the mapped list of {@link CustomIconTagInput} objects.
   */
  const mapToInput = (tags: CustomIconTag[]) => {
    return tags.map((i) => {
      return {
        backendID: i.id,
        label: i.tagName,
      };
    });
  };

  /**
   * Function that validates the current input.
   * This function uses a Regex expression to validate the current input.
   * Furthermore, this function checks whether the entered tag already exists.
   *
   * @param rawInput Input string that should be validated.
   */
  const validateInput = (rawInput: string) => {
    if (/^[a-zA-Z0-9\u00C0-\u017F]+$/.test(rawInput)) {
      return selectedTags.some((i) => i.label === rawInput)
        ? VALIDATION_ERROR_DUPLICATE
        : null;
    } else {
      return VALIDATION_ERROR_FORMAT;
    }
  };

  const filter = createFilterOptions<CustomIconTagFieldInput>();

  const VALIDATION_ERROR_EMPTY = t(INFO_SYMBOL_NO_CATEGORY);
  const VALIDATION_ERROR_FORMAT = t(INFO_CATEGORY_NO_SPECIAL_CHARACTER);
  const VALIDATION_ERROR_DUPLICATE = t(INFO_SYMBOL_DUPLICATE_CATEGORY);

  const [selectedTags, setSelectedTags] = useState<CustomIconTagFieldInput[]>(
    input.value
  );
  const [tagOptions, setTagOptions] = useState<CustomIconTagFieldInput[]>(
    mapToInput(rest?.existingTags)
  );

  const [fieldInputValue, setFieldInputValue] = useState<string>("");
  const [inputError, setInputError] = useState<string | null>(
    selectedTags.length === 0 ? VALIDATION_ERROR_EMPTY : null
  );

  return (
    <Autocomplete
      multiple
      onChange={(_event: any, newValue: CustomIconTagFieldInput[]) => {
        setSelectedTags(
          newValue.map((i) => {
            if (i.appendValue) {
              // Convert the placeholder to an actual instance
              const createdInstance = {
                id: i.backendID,
                backendID: i.backendID,
                label: i.appendValue,
              };

              // Add the created option to the autocomplete
              setTagOptions([createdInstance, ...tagOptions]);

              // Return the created instance
              return createdInstance;
            } else {
              return i;
            }
          })
        );

        // Publish the changes and update the validity
        input.onChange(
          newValue.map((i) => {
            if (i.appendValue) {
              return {
                backendID: i.backendID,
                label: i.appendValue,
              };
            } else return i;
          })
        );

        setFieldInputValue("");
        setInputError(newValue.length > 0 ? null : VALIDATION_ERROR_EMPTY);
      }}
      isOptionEqualToValue={(option, value) => {
        // Compare an option and a value
        return (
          option.backendID === value.backendID && option.label === value.label
        );
      }}
      value={selectedTags}
      options={tagOptions}
      fullWidth
      handleHomeEndKeys
      selectOnFocus
      clearOnBlur
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        if (
          !validateInput(inputValue) &&
          !tagOptions.find((i) => i.label === inputValue)
        ) {
          filtered.push({
            appendValue: inputValue,
            backendID: `new_${Date.now()}`,
            label: `${t(ACTION_ADD)}: "${inputValue}"`,
          });
        }

        return filtered;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            label={t(GENERAL_CATEGORY_PL)}
            margin="dense"
            placeholder={t(PLACEHOLDER_CATEGORY_ADD) ?? ""}
            value={fieldInputValue}
            error={inputError != null}
            helperText={inputError}
            onBlur={(_) =>
              setInputError(
                selectedTags.length > 0 ? null : VALIDATION_ERROR_EMPTY
              )
            }
            onChange={(e) => {
              setFieldInputValue(e.target.value);
              setInputError(validateInput(e.target.value));
            }}
          />
        );
      }}
    />
  );
};

export default CustomIconTagField;

export interface CustomIconTagFieldInput {
  appendValue?: string;
  backendID: string;
  label: string;
}
