import React from "react";
import "./styles/Sidebar.scss";

const Sidebar: React.FC<SidebarProps> = ({ children, className }) => {
  const classes = className !== undefined ? className : "";
  return <aside className={`Sidebar ${classes}`}>{children}</aside>;
};

interface SidebarProps {
  children: React.ReactNode;
  className?: string;
}

export default Sidebar;
