import { Rule } from "../Model/Rule";
import { ProgressPoint } from "../../shared/progress-navigation/ProgressNavigation";
import { useDispatch, useSelector } from "react-redux";
import { addProgressPoint, getRuleSequence } from "../ruleManagerSlice";
import { RuleStateMachine } from "../Model/RuleSequence";
import { useEffect, useMemo } from "react";

export const useProgressNavigation = (rule: Rule, catalogue: boolean) => {
  const dispatch = useDispatch();
  const sequence = useSelector(getRuleSequence);

  const progressPoint: ProgressPoint | null = useMemo(() => {
    const ruleSeq = new RuleStateMachine();
    if (ruleSeq.isListState(sequence) && rule.ruleElements) {
      return {
        id: rule.ruleElements.fieldType ?? rule.ruleElements.title,
        title: rule.ruleElements.title,
        icon: rule.ruleElements.icon,
        description: rule.ruleElements.shortDescription,
        state: sequence,
      };
    }
    if (!ruleSeq.isListState(sequence) && rule.ruleFields) {
      return {
        id: rule.ruleFields.type,
        title: rule.ruleFields.title,
        icon: rule.ruleFields.icon,
        description: rule.ruleFields.shortDescription,
        state: sequence,
      };
    }
    return null;
  }, [rule.ruleElements, rule.ruleFields, sequence]);

  useEffect(() => {
    if (progressPoint !== null) {
      dispatch(addProgressPoint(progressPoint));
    }
  }, [dispatch, progressPoint, catalogue]);
};
