import MomentUtils from "@date-io/moment";

import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment, { Moment } from "moment";
import { ConditionalWrapper } from "./ConditionalWrapper";

import { useTranslation } from "react-i18next";
import {
  ACTION_CANCEL,
  ACTION_SAVE,
  ERROR_INVALID_DATE_FORMAT,
  GENERAL_FROM,
  GENERAL_NOW,
  GENERAL_STAY,
  GENERAL_UNTIL,
  INFO_DATE_IN_FUTURE,
} from "../localization";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const DATE_FORMAT = "DD.MM.YYYY HH:mm";

export function DatetimeRangePicker({
  startDate,
  endDate,
  error,
  onEndDateChange,
  onStartDateChange,
}: DatetimeRangePickerProps) {
  const { t } = useTranslation();
  const minDate = moment().subtract(45, "days");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#08546b",
      },
    },
    /*overrides: {
      MuiInputBase: {
        input: {
          cursor: "pointer !important",
        },
      },
    },*/
  });

  return (
    <div className="position-relative btn bg-white shadow rounded-pill cursor-text">
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
        <span className="d-inline-flex align-items-baseline">
          <div className="text-primary">{`${t(GENERAL_STAY)} ${t(
            GENERAL_FROM
          )}:`}</div>
          <ConditionalWrapper
            condition={theme !== undefined}
            wrapper={(children) => (
              <ThemeProvider theme={theme!!}>{children}</ThemeProvider>
            )}
          >
            <DateTimePicker
              className="ms-1"
              ampm={false}
              cancelLabel={t(ACTION_CANCEL)}
              format={DATE_FORMAT}
              invalidDateMessage={t(ERROR_INVALID_DATE_FORMAT)}
              maxDateMessage={t(INFO_DATE_IN_FUTURE)}
              okLabel={t(ACTION_SAVE)}
              onChange={(date: Moment | null) => {
                onStartDateChange(date);
              }}
              minDate={minDate}
              value={startDate}
              disableFuture
              showTodayButton
              todayLabel={t(GENERAL_NOW)}
            />
          </ConditionalWrapper>
        </span>
        <span className="d-inline-flex align-items-baseline ms-2">
          <div className="text-primary">{t(GENERAL_UNTIL)}:</div>
          <ConditionalWrapper
            condition={theme !== undefined}
            wrapper={(children) => (
              <ThemeProvider theme={theme!!}>{children}</ThemeProvider>
            )}
          >
            <DateTimePicker
              className="ms-1"
              ampm={false}
              cancelLabel={t(ACTION_CANCEL)}
              format={DATE_FORMAT}
              invalidDateMessage={t(ERROR_INVALID_DATE_FORMAT)}
              maxDateMessage={t(INFO_DATE_IN_FUTURE)}
              okLabel={t(ACTION_SAVE)}
              onChange={(date: any) => {
                onEndDateChange(date);
              }}
              minDate={minDate}
              value={endDate}
              disableFuture
              error={error}
              showTodayButton
              todayLabel={t(GENERAL_NOW)}
            />
          </ConditionalWrapper>
        </span>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export interface DatetimeRangePickerProps {
  startDate: Moment | null;
  endDate: Moment | null;
  error: boolean;
  onEndDateChange(endDate: Moment | null): void;
  onStartDateChange(startDate: Moment | null): void;
}
