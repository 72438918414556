import React, { useEffect } from "react";
import { FieldRenderProps } from "react-final-form";
import { Input } from "reactstrap";
import { FieldProps } from "../../rules-engine/components/RulesFormGenerator";

type Props = FieldRenderProps<string | number | string[], any>;

function SelectField({ input, meta, ...rest }: Props) {
  useEffect(() => {
    if (input.value === "") {
      input.onChange(rest.params?.options[0].value);
    }
  }, [input, rest.params?.options]);

  return (
    <Input
      {...input}
      type="select"
      invalid={!!(meta.touched && meta.error)}
      value={input.value}
      onChange={(event) => input.onChange(event.target.value)}
      disabled={rest.disabled}
    >
      {rest.params?.placeholder && !input.value && (
        <option disabled>{rest?.placeholder}</option>
      )}
      {rest.params?.options.map(
        (option: { label: string; value: string }, index: number) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        )
      )}
    </Input>
  );
}

export interface SelectFieldProps extends FieldProps {
  options: { label: string; value: string }[];
}

export default SelectField;
