import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { Alert } from "reactstrap";
import ActiveAssetsFilter, {
  useActiveAssets,
} from "../../asset/components/ActiveAssetsFilter";
import useAssetDelete from "../../asset/hooks/useAssetDelete";
import useDrilldownData from "../../hooks/drilldownData";
import useAssets from "../../hooks/useAssets";
import useGqlClient from "../../hooks/useGqlClient";
import "../../index.scss";
import { rolePermission } from "../../rolePermission";
import { ROUTES } from "../../routes";
import { Loading, Modal } from "../../shared";
import AssetTrackingTemplate from "../components/AssetTrackingTemplate";
import { GET_USE_CASE } from "../queries/useCaseQueries";
import {
  ACTION_DELETE,
  ACTION_EDIT,
  ACTION_SHOW_MAP,
  ERROR_LOADING,
  QUESTION_ASSET_DELETE,
  USE_CASE_PHARMACY,
} from "../../localization";
import { useTranslation } from "react-i18next";

export default function Pharmacy() {
  const name = "Apothekenkisten";
  const alertTime: number = 4000;
  const assetsClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  const { state } = useLocation<any>();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<boolean>(false);
  const [alertShow, setAlertShow] = useState<boolean>(false);
  const [rootID, setRootID] = useState<string | undefined>(undefined);
  const [assetDeleteId, setAssetDeleteId] = useState<string>("");
  const [showModal, setModalShow] = useState<boolean>(false);
  const [alertInput, setAlertInput] = useState<{
    show: boolean;
    color: string;
    message: string;
  }>({
    show: false,
    color: "",
    message: "",
  });

  const {
    data: useCaseData,
    loading: useCaseLoading,
    error: useCaseError,
  } = useQuery(GET_USE_CASE, {
    client: assetsClient,
    variables: {
      name: name,
    },
  });
  const useCaseId = useCaseData?.useCase?.id;

  const { buildingTree, loading: buildingTreeLoading } =
    useDrilldownData(rootID);

  const {
    assetPage,
    loading: assetLoading,
    error: assetError,
    filterData,
    refetch,
    setBuildingPart,
    setGroupFilter,
    setPagination,
    setActiveStatus,
  } = useAssets(useCaseId);

  const activeAssets = useActiveAssets(filterData.activeFilter);

  const {
    _assetDelete,
    loading: deleteLoading,
    error,
    message,
  } = useAssetDelete(assetDeleteId, refetch);

  useEffect(() => {
    setLoading(assetLoading || useCaseLoading || deleteLoading);
  }, [assetLoading, useCaseLoading, deleteLoading]);

  useEffect(() => {
    if (error) {
      setAlertInput({
        show: true,
        color: "danger",
        message,
      });
    } else if (error === false) {
      setAlertInput({
        show: true,
        color: "success",
        message,
      });
      setTimeout(() => {
        setAlertInput((input) => ({ ...input, show: false }));
      }, 5000);
    }
  }, [error, message]);

  useEffect(() => {
    setErrors(assetError !== undefined || useCaseError !== undefined);
  }, [assetError, useCaseError]);

  useEffect(() => {
    let mounted = true;

    const _onShowAlert = () => {
      setAlertShow(true);
      setTimeout(() => {
        setAlertShow(false);
        delete state.renderAlert;
        history.replace(state);
      }, alertTime);
    };

    if (state?.renderAlert && mounted) {
      _onShowAlert();
    }

    return () => {
      mounted = false;
    };
  }, [state, history]);

  const _cancelModal = () => {
    setAssetDeleteId("");
    setModalShow(false);
  };

  const _onAlertDismiss = () => {
    setAlertShow(false);
    const state = { ...(history.location.state as any) };
    delete state.renderAlert;
    history.replace(state);
  };

  const _onDeleteDismiss = () => {
    setAlertInput({ ...alertInput, show: false });
  };

  const _generateActions = useCallback(
    (item) => {
      const onClickDelete = (assetId: string) => {
        setModalShow(true);
        setAssetDeleteId(assetId);
      };
      return [
        {
          text: t(ACTION_SHOW_MAP),
          to: generatePath(
            ROUTES.home.childRoutes.assets.childRoutes.detailEdit.path,
            { id: item.id }
          ),
          role: rolePermission.PHARMACY_READ,
        },
        {
          text: t(ACTION_EDIT),
          to: generatePath(
            ROUTES.home.childRoutes.assets.childRoutes.detailEdit.childRoutes
              .edit.path,
            { id: item.id }
          ),
          role: rolePermission.PHARMACY_ASSET_EDIT,
        },
        {
          text: t(ACTION_DELETE),
          onClick: () => onClickDelete(item.id),
          role: rolePermission.PHARMACY_ASSET_DELETE,
        },
      ];
    },
    [t]
  );

  if (errors) return <Alert color="danger">{t(ERROR_LOADING)}</Alert>;

  return (
    <>
      {loading && <Loading />}
      <Modal
        header={t(ACTION_DELETE)}
        body={t(QUESTION_ASSET_DELETE) ?? ""}
        show={showModal}
        acceptButtonColor="danger"
        acceptButtonText={t(ACTION_DELETE)}
        onConfirm={() => {
          setModalShow(false);
          _assetDelete();
        }}
        cancel={true}
        onCancel={_cancelModal}
      />
      {state ? (
        <Alert color="success" isOpen={alertShow}>
          {state.alertMessage}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => _onAlertDismiss()}
          />
        </Alert>
      ) : null}
      <Alert color={alertInput.color} isOpen={alertInput.show}>
        {alertInput.message}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={() => _onDeleteDismiss()}
        />
      </Alert>

      {useCaseData?.useCase?.availableGroups && (
        <div className="mb-1 d-flex justify-content-end align-items-baseline">
          <ActiveAssetsFilter
            onChange={setActiveStatus}
            defaultChecked={activeAssets}
          />
        </div>
      )}
      <AssetTrackingTemplate
        data={assetPage}
        pagination={filterData.pageCriteria}
        onItemSelect={setBuildingPart}
        setIPagination={setPagination}
        onLevelToggle={setRootID}
        onGenerateActions={_generateActions}
        buildingTreeLoading={buildingTreeLoading}
        name={t(USE_CASE_PHARMACY)}
        tree={buildingTree}
        buildingPartIDExternal={
          filterData.filterCriteria?.buildingPartIDExternal
        }
        assetGroups={useCaseData?.useCase?.availableGroups}
        onClick={setGroupFilter}
        checkedGroups={filterData.filterCriteria?.groupIds ?? []}
      />
    </>
  );
}
