import React, { useEffect, useRef, useState } from "react";
import { Alert } from "reactstrap";

const AlertBox: React.FC<AlertBoxProps> = ({
  show,
  message,
  displayedTime,
  color,
}) => {
  const [open, setOpen] = useState<boolean>(show);
  const timer = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (displayedTime && show) {
      timer.current = setTimeout(() => setOpen(false), displayedTime);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };
  }, [displayedTime, show]);

  const onAbort = () => {
    setOpen(false);
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };

  return (
    <Alert isOpen={open} fade={true} color={color}>
      {message}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={onAbort}
      />
    </Alert>
  );
};

export default AlertBox;

export interface AlertBoxProps {
  message: string;
  show: boolean;
  color?: string;
  displayedTime?: number | null;
}
