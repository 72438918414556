import { useSubscription } from "@apollo/client";
import { useMemo } from "react";
import { GET_ALARMS_SUBSCRIPTION } from "../../alarms/queries/alarmQueries";
import { useGqlClient } from "../../hooks";
import { IAlarm } from "../../models/Alarm";
import { Color } from "../../models/Position";

export const useAlarms = () => {
  const client = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_TELEMETRY!,
    process.env.REACT_APP_GRAPHQL_WEBSOCKETURI_TELEMETRY!
  );

  const { data } = useSubscription<AlarmResult>(GET_ALARMS_SUBSCRIPTION, {
    client,
  });

  const filteredPosition = useMemo(() => {
    if (data?.alarms === undefined) return [];

    return data.alarms
      .filter((alarm) => alarm.lastPosition !== undefined)
      .map((alarm) => {
        let color = Color.red;
        if (alarm.confirmedAt) {
          color = Color.yellow;
        }
        return { ...alarm.lastPosition!, color };
      });
  }, [data]);

  return {
    alarms:
      data?.alarms.filter((alarm) => alarm.lastPosition !== undefined) ?? [],
    positions: filteredPosition,
  };
};

interface AlarmResult {
  alarms: IAlarm[];
}
