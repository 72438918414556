import { GridColumns } from "@mui/x-data-grid";

export const useGridTableColumns = (keys: ColumnDefinition[], actions: any) => {
  const generateColumns = () => {
    const columns: GridColumns = keys.map((key: ColumnDefinition) => {
      return {
        field: key.name,
        headerName: key.name,
        headerAlign: "center",
        align: "center",
        flex: 0.3,
        editable: key.editable,
      };
    });

    columns.push(actions);
    return columns;
  };

  return { columns: generateColumns() };
};

interface ColumnDefinition {
  name: string;
  editable: boolean;
}
