import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useEffect, useState } from "react";
import { AssetPage, IAsset } from "../models/assets/Asset";
import { Position } from "../models/Position";
import useFilterData from "../useCase/hooks/useFilterData";
import {
  GET_FILTERED_ASSETS,
  GET_UPDATE_ON_POSITION_BY_IDS,
} from "../useCase/queries/useCaseQueries";
import useGqlClient from "./useGqlClient";

export default function useAssets(useCaseId: string, fetchPosition = true) {
  const {
    filterData,
    setBuildingPart,
    setGroupFilter,
    setPagination,
    setActiveStatus,
  } = useFilterData();

  const { filterCriteria, pageCriteria, activeFilter } = filterData;

  const assetClient = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_ASSETS!,
    fetchPosition
      ? process.env.REACT_APP_GRAPHQL_WEBSOCKETURI_ASSETS!
      : undefined
  );

  const {
    data,
    loading,
    error,
    refetch,
    subscribeToMore,
  } = useQuery<AssetFilterResult>(GET_FILTERED_ASSETS(fetchPosition), {
    variables: {
      filterCriteria: {
        useCaseId,
        ...filterCriteria,
      },
      pageCriteria: {
        ...pageCriteria,
      },
      activeFilter:
        activeFilter === undefined
          ? null
          : {
              ...activeFilter,
            },
    },
    client: assetClient,
    skip: useCaseId === undefined,
  });
  const [assetIds, setAssetIds] = useState<string[]>([]);

  useEffect(() => {
    const ids =
      data?.filterAssetsBy?.assets?.map((asset: IAsset) => asset.id) ?? [];
    if (!_.isEqual(assetIds, ids)) setAssetIds(ids);
  }, [assetIds, data?.filterAssetsBy?.assets]);

  useEffect(() => {
    if (_.isEmpty(assetIds)) return;

    return subscribeToMore<PositionByIds>({
      onError: (error) => {
        console.error(error);
      },
      document: GET_UPDATE_ON_POSITION_BY_IDS,
      variables: {
        assetIds: assetIds,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;
        const newPosition: Position = subscriptionData.data.positionByIds;
        const assets = prev.filterAssetsBy?.assets?.map((asset) =>
          asset.id === newPosition.assetId
            ? {
                ...asset,
                lastPosition: newPosition,
              }
            : asset
        );
        return {
          filterAssetsBy: {
            ...prev.filterAssetsBy,
            assets,
          },
        };
      },
    });
  }, [assetIds, subscribeToMore]);

  const assetPage = {
    assets: data?.filterAssetsBy.assets ?? [],
    assetCount: data?.filterAssetsBy.assetCount ?? 0,
    totalPages: data?.filterAssetsBy.totalPages ?? 0,
  };

  return {
    assetPage,
    loading,
    error,
    filterData,
    refetch,
    setGroupFilter,
    setBuildingPart,
    setPagination,
    setActiveStatus,
  };
}

export interface AssetFilterResult {
  filterAssetsBy: AssetPage;
}

interface PositionByIds {
  positionByIds: Position;
}
