type RolePermissionType =
  | "TEMPERATURE_MONITORING_READ"
  | "TEMPERATURE_MONITORING_ASSET_EDIT"
  | "TEMPERATURE_MONITORING_ASSET_DELETE"
  | "TEMPERATURE_MONITORING_WARD_READ"
  | "TEMPERATURE_MONITORING_WARD_ASSET_EDIT"
  | "TEMPERATURE_MONITORING_WARD_ASSET_DELETE"
  | "PHARMACY_READ"
  | "PHARMACY_ASSET_EDIT"
  | "PHARMACY_ASSET_DELETE"
  | "ASSET_TRACKING_READ"
  | "ASSET_TRACKING_ASSET_EDIT"
  | "ASSET_TRACKING_ASSET_DELETE"
  | "ALARM_MANAGEMENT_READ"
  | "ALARMS_READ"
  | "ASSET_CREATE"
  | "LOCATION_READ"
  | "LOCATION_EDIT"
  | "RULE_ENGINE_CREATE"
  | "RULE_ENGINE_READ"
  | "RULE_ENGINE_EDIT"
  | "RULE_ENGINE_DELETE"
  | "ADMIN_ASSET_GROUP"
  | "ADMIN_ICONS"
  | "QR_SCANNER";

/**
 * Key: RolePermissionType for preventing typos
 * Value: HAS to be EQUAL with the role name in Keycloak
 */
export const rolePermission: Record<RolePermissionType, string> = {
  TEMPERATURE_MONITORING_READ: "temperatureMonitoring:read",
  TEMPERATURE_MONITORING_ASSET_EDIT: "temperatureMonitoring:asset:edit",
  TEMPERATURE_MONITORING_ASSET_DELETE: "temperatureMonitoring:asset:delete",

  TEMPERATURE_MONITORING_WARD_READ: "temperatureMonitoringWard:read",
  TEMPERATURE_MONITORING_WARD_ASSET_EDIT:
    "temperatureMonitoringWard:asset:edit",
  TEMPERATURE_MONITORING_WARD_ASSET_DELETE:
    "temperatureMonitoringWard:asset:delete",

  PHARMACY_READ: "pharmacy:read",
  PHARMACY_ASSET_DELETE: "pharmacy:asset:delete",
  PHARMACY_ASSET_EDIT: "pharmacy:asset:edit",

  ASSET_TRACKING_READ: "assetTracking:read",
  ASSET_TRACKING_ASSET_EDIT: "assetTracking:asset:edit",
  ASSET_TRACKING_ASSET_DELETE: "assetTracking:asset:delete",

  ALARM_MANAGEMENT_READ: "alarmManagement:read",
  ALARMS_READ: "alarms:read",

  ASSET_CREATE: "asset:create",

  LOCATION_READ: "location:read",
  LOCATION_EDIT: "location:edit",

  RULE_ENGINE_CREATE: "ruleEngine:create",
  RULE_ENGINE_READ: "ruleEngine:read",
  RULE_ENGINE_EDIT: "ruleEngine:edit",
  RULE_ENGINE_DELETE: "ruleEngine:delete",

  ADMIN_ASSET_GROUP: "adminManagement:assetGroup",
  ADMIN_ICONS: "adminManagement:icons",

  QR_SCANNER: "qrScanner",
};
