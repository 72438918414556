import Keycloak from "keycloak-js";
import React, { useContext, useEffect, useState } from "react";

export const KeycloakContext = React.createContext();
export const useKeycloak = () => useContext(KeycloakContext);
export const KeycloakProvider = ({ children, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [keycloakClient, setKeycloak] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initKeycloak = async () => {
      const keycloak = Keycloak({
        ...initOptions,
      });
      setKeycloak(keycloak);

      try {
        const isAuthenticated = await keycloak.init({});
        setIsAuthenticated(isAuthenticated);
        if (isAuthenticated) {
          const user = await keycloak.loadUserProfile();
          setUser(user);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    initKeycloak();
    // eslint-disable-next-line
  }, []);

  const _getToken = async () => {
    try {
      // value 30 is proposed from keycloak documentation
      await keycloakClient.updateToken(30);
    } catch (err) {
      keycloakClient.logout({ redirectUri: window.location.href });
    } finally {
      return keycloakClient.token;
    }
  };

  return (
    <KeycloakContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        login: (...p) => keycloakClient.login(...p),
        getTokenSilently: _getToken,
        logout: (...p) => keycloakClient.logout(...p),
        hasResourceRole: (role) => keycloakClient.hasResourceRole(role),
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};
