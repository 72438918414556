import {useMemo} from "react";
import {Location} from "../../location/hooks/useLocations";
import {levelFields} from "../../__generated__/types";

export const useLevel = (locations?: Location[]) => {
  const DEFAULT_START_LEVEL = "0";
  const level = useMemo(() => {
    if (locations === undefined
      || locations.length === 0
      || locations[0].availableLevels === undefined
      || locations[0].availableLevels.length === 0) return null;

    return (
      locations[0].availableLevels.find(
        (level: levelFields) => level.name === DEFAULT_START_LEVEL
      ) ?? locations[0].availableLevels[0]
    );
  }, [locations]);
  return level;
};
