import {useQuery} from "@apollo/client";
import {ViewState} from "react-map-gl";
import {useGqlClient} from "../../hooks";
import {levelFields} from "../../__generated__/types";
import {GET_ALL_LOCATIONS_WITH_LEVEL} from "../queries/locationsQuery";

export const useLocations = (skipping?: boolean) => {
  const locationClient = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_LOCATION!
  );

  const {data, error, loading} = useQuery<Locations>(
    GET_ALL_LOCATIONS_WITH_LEVEL,
    {
      client: locationClient,
      skip: skipping,
    }
  );
  return {
    locationData: data?.locations,
    locationError: error,
    locationLoading: loading,
  };
};

export interface Location {
  id: string;
  name: string;
  availableLevels: levelFields[];
  viewport: ViewState;
}

export interface Locations {
  locations: Location[];
}
