import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IAlert } from "../../models/Alert";
import { AlarmBroadcastChannel } from "../AlarmBroadcastChannel";
import AlarmToastContent from "../components/AlarmToastContent";

const useAlarmApproval = (alarms: IAlert[]) => {
  const sessionStorageIdentifier = "TLM_";
  const [, reRender] = useState<number>();

  function sendAlarmDetail(alarmDetail: IAlert): void {
    const channel = new AlarmBroadcastChannel();
    channel.send(alarmDetail);
  }

  function isAlarmApproved(alarm: IAlert): boolean {
    const item = sessionStorage.getItem(
      sessionStorageIdentifier + alarm.alertId
    );
    return item !== null && item === "true";
  }

  function removeOldAlertsFromStorage(alertIds: number[]): void {
    for (var i = 0; i < sessionStorage.length; i++) {
      const key: string | null = sessionStorage.key(i);
      if (key !== null && key.startsWith(sessionStorageIdentifier)) {
        const id: number = parseInt(key.replace(sessionStorageIdentifier, ""));
        if (!alertIds.includes(id)) {
          sessionStorage.removeItem(key);
        }
      }
    }
  }

  const approveAlarm = useCallback((alarm: IAlert) => {
    if (
      sessionStorage.getItem(sessionStorageIdentifier + alarm.alertId) !== null
    ) {
      sessionStorage.setItem(sessionStorageIdentifier + alarm.alertId, "true");
    }
    sendAlarmDetail(alarm);
    toast.dismiss(alarm.alertId);
    reRender(alarm.alertId);
  }, []);

  useEffect(() => {
    for (const alarm of alarms) {
      // ensure item in sessionstorage exists
      if (
        sessionStorage.getItem(sessionStorageIdentifier + alarm.alertId) ===
        null
      ) {
        sessionStorage.setItem(
          sessionStorageIdentifier + alarm.alertId,
          "false"
        );
      }
      if (
        sessionStorage.getItem(sessionStorageIdentifier + alarm.alertId) !==
          null &&
        sessionStorage.getItem(sessionStorageIdentifier + alarm.alertId) ===
          "false"
      ) {
        if (!toast.isActive(alarm.alertId)) {
          toast.error(
            <AlarmToastContent
              alarm={alarm}
              onApprove={(alarm) => approveAlarm(alarm)}
            />,
            {
              toastId: alarm.alertId,
            }
          );
        }
      }
      if (isAlarmApproved(alarm)) {
        sendAlarmDetail(alarm);
      }
    }
    removeOldAlertsFromStorage(alarms.map((alarm) => alarm.alertId));
  }, [alarms, approveAlarm]);

  return { approveAlarm, isAlarmApproved };
};

export default useAlarmApproval;
