import { FieldRenderProps } from "react-final-form";
import { isEmail, ReactMultiEmail as MultiEmail } from "react-multi-email";
import React from "react";
import "./ReactMultiEmail.scss";

type Props = FieldRenderProps<string[]>;

const ReactMultiEmail: React.FC<Props> = ({ input, ...rest }) => {
  return (
    <MultiEmail
      emails={rest.initialValues}
      onChange={(_emails: string[]) => {
        input.onChange(_emails);
      }}
      validateEmail={(email) => {
        return isEmail(email);
      }}
      getLabel={(
        email: string,
        index: number,
        removeEmail: (index: number) => void
      ) => {
        return (
          <div onBlur={input.onBlur} data-tag key={index}>
            {email}
            <span data-tag-handle onClick={() => removeEmail(index)}>
              ×
            </span>
          </div>
        );
      }}
    />
  );
};

export default ReactMultiEmail;
