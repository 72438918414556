import useGqlClient from "../../hooks/useGqlClient";
import { useMutation } from "@apollo/client";
import { CREATE_OR_UPDATE_CUSTOM_ICON } from "../../asset/queries/assetQuery";
import { useCallback } from "react";
import { CustomIconInput } from "../../models/CustomIcon";

/**
 * Custom hook that is responsible for creating/updating custom icons.
 *
 * This hook returns an object containing the following values:
 * - createOrUpdateCustomIcon: Action used to create or update a custom icon.
 * - customIconsCreateLoading: Indicates whether the action is pending.
 * - customIconsCreateError: Error that might have occurred during the process.
 *
 * @return An object returning the values as mentioned above.
 */
export const useCreateOrUpdateCustomIcon = () => {
  const assetClient = useGqlClient(process.env.REACT_APP_GRAPHQL_URI_ASSETS!);

  const [createOrUpdateCustomIcon, {loading, error}] = useMutation(
    CREATE_OR_UPDATE_CUSTOM_ICON,
    {
      client: assetClient,
    },
  );

  const customIconCreateOrUpdate = useCallback(
    async (customIconInput: CustomIconInput) => {
      try {
        const {data, errors} = await createOrUpdateCustomIcon({
          variables: {
            customIconInput: {...customIconInput},
          },
        });

        return {
          data,
          errors: errors?.toString(),
        };
      } catch (ex) {
        return {
          data: null,
          errors: ex,
        };
      }
    },
    [createOrUpdateCustomIcon]
  );

  return {
    createOrUpdateCustomIcon: customIconCreateOrUpdate,
    customIconsCreateLoading: loading,
    customIconsCreateError: !!error,
  };
}
