import moment from "moment";
import React from "react";
import { IAlarm } from "../../models/Alarm";
import { useTranslation } from "react-i18next";
import { INFO_LOCATION_UNKNOWN } from "../../localization";

const AlarmCard: React.FC<IAlarmCardProps> = ({ alarm }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`alarm-card-body bg-${
        alarm.confirmedAt ? "warning" : "danger"
      } m-2 p-2`}
    >
      <div className="text-dark">
        {moment(alarm.createdAt).format("DD.MM.YYYY - HH:mm:ss")} Uhr
      </div>
      {(alarm.lastPosition && (
        <div className="text-dark">{alarm.lastPosition.locatorName}</div>
      )) ?? <div className="text-dark">{t(INFO_LOCATION_UNKNOWN)}</div>}
    </div>
  );
};

interface IAlarmCardProps {
  alarm: IAlarm;
}

export default AlarmCard;
