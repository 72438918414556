import React from "react";
import "./InformationCard.scss";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InformationCard: React.FC<InformationCardProps> = ({
  iconName,
  title,
  onClick,
  children,
  informationText,
}) => {
  const classNames = `d-flex flex-column p-1 ${
    onClick ? "information-card" : ""
  }`;

  return (
    <div className={classNames} onClick={onClick}>
      <div className="d-flex">
        {iconName && (
          <div className="me-2">
            <FontAwesomeIcon icon={iconName} />
          </div>
        )}
        <p className="fw-bold mb-1">{title}</p>
      </div>
      <p>{informationText}</p>
      {children}
    </div>
  );
};

export interface InformationCardProps {
  iconName?: IconName;
  title: string;
  informationText: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export default InformationCard;
