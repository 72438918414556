import { FieldRenderProps } from "react-final-form";
import { Box, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  ACTION_SYMBOL_SELECT,
  INFO_SYMBOL_NOT_SELECTED,
} from "../../localization";

type Props = FieldRenderProps<string, any>;

/**
 * Component that is used to select a custom vector file and display the preview of the image.
 * This component allows the user to select the vector code for the icon.
 *
 * @param input Nested input component to communicate with react-final-form.
 * @param _meta Meta information that can contain e.g. a validation error.
 * @param _rest Remaining attributes and props of the {@link FieldRenderProps}.
 */
export const CustomIconSelector = ({ input, _meta, ..._rest }: Props) => {
  const { t } = useTranslation();
  /**
   * Function that is invoked when the user selected a new icon file.
   * This function validates the selected data and loads a preview of the icon.
   *
   * @param e Event that contains the file information.
   */
  const handleIconFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      let imageFile = e.target.files[0];
      let reader = new FileReader();
      reader.readAsText(imageFile, "utf-8");
      reader.onloadend = (_) => {
        let rawResult = reader.result as unknown as string;
        input.onChange(rawResult.replace(/\s+/, " "));
      };
    }
  };

  /**
   * Function that builds the vector preview component.
   * This component either displays a placeholder or the selected vector image.
   */
  const _buildVectorPreview = () => {
    return input.value.length > 0 ? (
      <img
        width="45%"
        height="45%"
        src={`data:image/svg+xml;utf8,${encodeURIComponent(input.value)}`}
        alt="icon"
      />
    ) : (
      <>
        <FontAwesomeIcon color="gray" size="8x" icon="image" />
        <p style={{ color: "#d32f2f" }}>{t(INFO_SYMBOL_NOT_SELECTED)}</p>
      </>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      {_buildVectorPreview()}
      <Button
        sx={{ marginTop: "2rem", marginBottom: "1rem" }}
        variant="contained"
        startIcon={<FontAwesomeIcon icon="folder-open" />}
        component="label"
      >
        {t(ACTION_SYMBOL_SELECT)} (*.svg)
        <input
          hidden
          accept="image/svg+xml"
          onChange={(e) => handleIconFileSelected(e)}
          type="file"
        />
      </Button>
    </Box>
  );
};

export default CustomIconSelector;
