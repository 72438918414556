import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RulesTemplate from "../components/RulesTemplate";
import { getRuleInfo, resetState } from "../ruleManagerSlice";
import AlertBox from "../../shared/AlertBox";
import { Loading } from "../../shared";
import { useRule } from "../hooks/useRule";
import { useTranslation } from "react-i18next";
import {
  ERROR_SIMPLE,
  RULE_ENGINE_CREATE,
  RULE_ENGINE_EDIT,
} from "../../localization";

const RuleCreateOrUpdate = () => {
  const { t } = useTranslation();
  //param for edit case
  const { id } = useParams<{ id: string | undefined }>();
  const edit = id === undefined;
  const ruleLabel = edit ? t(RULE_ENGINE_CREATE) : t(RULE_ENGINE_EDIT);

  const dispatch = useDispatch();
  const [rule, api, submitFields, isLoading, isError, error] = useRule(id);

  const ruleInfo = useSelector(getRuleInfo);

  //Remove when bug got fixed where component does not get unmounted when route changes
  //Ticket: HYP-2265
  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  return (
    <>
      {isLoading && <Loading />}
      {isError && (
        <AlertBox
          message={error?.toString() ?? t(ERROR_SIMPLE)}
          show={isError}
          color="danger"
        />
      )}

      {!isError && (
        <RulesTemplate
          submitField={submitFields}
          saveRule={api.createOrUpdate}
          marked={ruleInfo?.fieldType}
          ruleLabel={ruleLabel}
          preSelectedListElement={rule.ruleElements?.title}
          rule={rule}
        />
      )}
    </>
  );
};

export default RuleCreateOrUpdate;
