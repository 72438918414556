import moment from "moment";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IAlert } from "../../models/Alert";
import ActionButtons from "../../shared/ActionButtons";
import {
  DataTable,
  DataTableColum,
  DataTableStyleProps,
} from "../../shared/Tables/DataTable";
import { useAlarmApproval, useTlmAlarms } from "../hooks";
import { useTranslation } from "react-i18next";
import {
  ACTION_TITLE_PL,
  ACTION_VIEW,
  ALARM_TITLE_SG,
  GENERAL_GENDER,
  GENERAL_PATIENT,
  GENERAL_TECHNICAL_ROOM_NUMBER,
  GENERAL_TIMESTAMP,
  GENERAL_TLM_DEVICE,
  LOCATION_LAST,
} from "../../localization";
const AlarmList: React.FC<{}> = () => {
  const alarms = useTlmAlarms();
  const { isAlarmApproved, approveAlarm } = useAlarmApproval(alarms);
  const { t } = useTranslation();

  const styles: DataTableStyleProps<IAlert> = {
    tableRowColor: (item: IAlert) => {
      return isAlarmApproved(item) ? "" : "table-danger";
    },
  };

  const renderHeading = (approveAlarm: (alarm: IAlert) => void) => {
    const dataTable: DataTableColum<IAlert>[] = [
      {
        key: "id",
        label: t(GENERAL_TLM_DEVICE),
      },
      {
        key: "patient.lastName",
        label: t(GENERAL_PATIENT),
        valueAccessor: (item: IAlert) => (
          <span> {`${item.patient.firstName} ${item.patient.lastName}`}</span>
        ),
      },
      { key: "patient.gender", label: t(GENERAL_GENDER) },
      {
        key: "timestamp",
        label: t(GENERAL_TIMESTAMP),
        valueFormatter: (item: string) =>
          moment(item).format("DD.MM.YYYY HH:mm:ss"),
      },
      { key: "description", label: t(ALARM_TITLE_SG) },
      {
        key: "lastPosition.technicalRoomNumber",
        label: t(GENERAL_TECHNICAL_ROOM_NUMBER),
      },
      { key: "lastPosition.locatorName", label: t(LOCATION_LAST) },
      {
        key: "actions",
        label: t(ACTION_TITLE_PL),
        valueAccessor: (item: IAlert) => (
          <ActionButtons
            actions={[
              {
                text: t(ACTION_VIEW),
                onClick: () => approveAlarm(item),
              },
            ]}
          />
        ),
      },
    ];
    return dataTable;
  };

  return (
    <>
      <DataTable
        rows={alarms}
        columns={renderHeading(approveAlarm)}
        styles={styles}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        closeButton={false}
        closeOnClick={false}
      />
    </>
  );
};

export default AlarmList;
