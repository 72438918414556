import { FeatureCollection, Point } from "geojson";
import { useMemo } from "react";
import { Position } from "../models/Position";

function useCluster(assets: FeatureCollection<Point, Position>) {
  const cluster = useMemo(() => {
    const tmpCluster = new Map<string, Cluster>();
    assets.features.forEach((feature) => {
      if (
        feature.properties.locatorId &&
        !tmpCluster.has(feature.properties.locatorId)
      ) {
        tmpCluster.set(feature.properties.locatorId, {
          longitude: feature.properties.longitude,
          latitude: feature.properties.latitude,
          locatorName: feature.properties.locatorName,
          assets: [
            {
              icon: feature.properties.icon ?? "",
              assetId: feature.properties.assetId,
              assetGroupName: feature.properties.assetGroupName ?? "Alarme",
              assetGroupColor: generateColor(
                feature.properties.assetGroupName ?? "Alarme"
              ),
            },
          ],
        });
      } else {
        if (feature.properties.locatorId)
          tmpCluster.get(feature.properties.locatorId)?.assets.push({
            icon: feature.properties.icon ?? "",
            assetId: feature.properties.assetId,
            assetGroupName: feature.properties.assetGroupName ?? "Alarme",
            assetGroupColor: generateColor(
              feature.properties.assetGroupName ?? "Alarme"
            ),
          });
      }
    });
    return tmpCluster;
  }, [assets]);

  function generateColor(name: string) {
    if (name === "Alarme") return "#FF0000";
    let hash: number = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = ((hash * 1000) & 0x00ffffff).toString(16).toUpperCase();
    let colorHexValue = "#" + c.substring(0, 6);
    while (colorHexValue.length < 7) {
      colorHexValue = colorHexValue + "0";
    }
    return colorHexValue;
  }

  return cluster;
}
export default useCluster;

export interface Cluster {
  latitude: number;
  longitude: number;
  locatorName?: string;
  assets: {
    assetId?: string;
    icon: string;
    assetGroupName: string;
    assetGroupColor?: string;
  }[];
}
