import AdminNavigation, { Item } from "../components/AdminNavigation";
import React, { useEffect } from "react";
import { ROUTES } from "../../routes";
import { useHistory, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green, red } from "@mui/material/colors";
import { useKeycloak } from "../../auth";
import { useTranslation } from "react-i18next";
import { GENERAL_MENU } from "../../localization";
import { Box, Card, CardContent, CardHeader } from "@mui/material";

const AdminLayout: React.FC = ({ children }) => {
  const theme = createTheme({
    status: {
      danger: red[600],
      success: green[600],
    },
    palette: {
      primary: { main: "#08546b" },
      secondary: { main: "#5698ad" },
      error: { main: red[600] },
    },
    create: {
      button: green[800],
    },
    strippedColor: "rgba(0,0,0,0.04)",
    tableHover: "rgba(0,0,0,0.06)",
    disableColor: "rgba(0, 0, 0, 0.26)",
  } as any);

  const keycloak = useKeycloak();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const isActive = (path: string, role?: string) => {
    return role === undefined || keycloak.hasResourceRole(role);
  };

  const generateMenuPoints = () => {
    const menuPoints = Object.values(ROUTES.home.childRoutes.admin.childRoutes);
    return menuPoints
      .filter((point) => {
        if (point.roles) {
          return isActive(point.path, point.roles[0]);
        }
        return true;
      })
      .map((point) => {
        return {
          name: t(point.name!!),
          icon: point.name,
          path: point.path,
          roles: point.roles,
        } as Item;
      });
  };

  const itemList = generateMenuPoints();

  useEffect(() => {
    if (location.pathname === ROUTES.home.childRoutes.admin.path) {
      history.push(itemList[0]?.path);
    }
  }, [history, itemList, location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Box marginTop={"2rem"} display={"flex"} padding={0}>
        <Box marginRight={"1rem"}>
          <Card>
            <CardHeader title={t(GENERAL_MENU)} />
            <CardContent style={{ padding: 0 }}>
              <AdminNavigation
                itemList={itemList}
                selectedItem={
                  itemList.find((item) => item.path === location.pathname)?.name
                }
              />
            </CardContent>
          </Card>
        </Box>
        <Box flexGrow={1}>{children}</Box>
      </Box>
    </ThemeProvider>
  );
};

export default AdminLayout;
