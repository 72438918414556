import moment from "moment";
import React, { FC } from "react";
import { Button } from "reactstrap";
import { IAlert } from "../../models/Alert";
import "../Alarm.scss";
import {
  ACTION_VIEW,
  ALARM_TITLE_SG_CAPS,
  GENERAL_UNKNOWN,
  LOCATION_TITLE_SG,
} from "../../localization";
import { useTranslation } from "react-i18next";

export interface IAlarmToastContent {
  alarm: IAlert;
  onApprove(alarm: IAlert): void;
}

const AlarmToastContent: FC<IAlarmToastContent> = ({ alarm, onApprove }) => {
  const { t } = useTranslation();
  return (
    <>
      {t(ALARM_TITLE_SG_CAPS)}
      <br />
      <div className={"margin-5-20"}>
        {alarm.patient.firstName} {alarm.patient.lastName}
        <br />
        {alarm.description}
        <br />
        {moment(alarm.timestamp).format("DD.MM.YY HH:mm:ss")}
        <br />
        {t(LOCATION_TITLE_SG)}:{" "}
        {alarm.lastPosition?.locatorName ?? t(GENERAL_UNKNOWN)}
        <br />
      </div>
      <Button
        outline
        className={"alarm-btn"}
        size={"sm"}
        block
        onClick={() => onApprove(alarm)}
      >
        {t(ACTION_VIEW)}
      </Button>
    </>
  );
};

export default AlarmToastContent;
