import React, { useEffect, useState } from "react";
import { ViewState } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { OptionsType } from "react-select";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import {
  initializeLocators,
  selectCreatedLocators,
  selectDeletedLocatorIds,
  selectHasChanges,
  selectIsLocked,
  selectIsSatelliteViewActive,
  selectSignalRadius,
  selectUpdatedLocators,
  toggleLock,
  toggleSatelliteView,
  toggleSignalRadius,
} from "../locatorManagerSlice";
import "../styles/LocatorManager.scss";
import ActionsControl from "./ActionsControl";
import MarkerOverlay from "./MarkerOverlay";
import {
  ACTION_CANCEL,
  ACTION_RESET_CHANGES,
  INFO_RESET_CHANGES,
  QUESTION_DISCARD_CHANGES,
} from "../../localization";
import { useTranslation } from "react-i18next";

const LocatorManager: React.FC<LocatorManagerProps> = ({
  children,
  initialLocators,
  initialViewport,
  onSave,
}) => {
  const showSignalRadius = useSelector(selectSignalRadius);
  const [modal, setModal] = useState(false);
  const createdLocators = useSelector(selectCreatedLocators);
  const updatedLocators = useSelector(selectUpdatedLocators);
  const deletedLocatorIds = useSelector(selectDeletedLocatorIds);
  const hasChanges = useSelector(selectHasChanges);
  const isLocked = useSelector(selectIsLocked);
  const isSatelliteViewActive = useSelector(selectIsSatelliteViewActive);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(initializeLocators(initialLocators));
  }, [initialLocators, dispatch]);

  const _onSave = () => {
    if (onSave) {
      onSave(createdLocators, updatedLocators, deletedLocatorIds);
    }
  };
  const _onRevert = () => {
    _toggleModal();
    dispatch(initializeLocators(initialLocators));
  };

  const _toggleModal = () => setModal(!modal);

  const _toggleLock = () => dispatch(toggleLock());

  const _toggleSignal = () => dispatch(toggleSignalRadius());

  const _toggleSatelliteView = () => dispatch(toggleSatelliteView());

  return (
    <>
      <MarkerOverlay
        draggable={!isLocked}
        initialViewport={initialViewport}
        showSignalRadius={showSignalRadius}
      >
        <>{children}</>
      </MarkerOverlay>

      <ActionsControl
        isSaveDisabled={!hasChanges}
        isRevertDisabled={!hasChanges}
        isLocked={isLocked}
        isSatelliteViewDisabled={isSatelliteViewActive}
        showSignalRadius={showSignalRadius}
        onSave={_onSave}
        onRevert={_toggleModal}
        toggleLock={_toggleLock}
        toggleSignal={_toggleSignal}
        toggleSatelliteView={_toggleSatelliteView}
      />

      <Modal isOpen={modal}>
        <div className="modal-header">
          <h5 className="modal-title">{t(QUESTION_DISCARD_CHANGES)}</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => _toggleModal()}
          />
        </div>
        <ModalBody>{t(INFO_RESET_CHANGES)}</ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={_onRevert}>
            {t(ACTION_RESET_CHANGES)}
          </Button>{" "}
          <Button color="secondary" outline onClick={_toggleModal}>
            {t(ACTION_CANCEL)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export interface LocatorManagerProps {
  children?: React.ReactChild;
  initialLocators: Locator[];
  onSave?: (
    createdLocators: Locator[],
    updatedLocators: Locator[],
    deletedLocatorIds: string[]
  ) => void;
  initialViewport: Partial<ViewState>;
}

export interface Locator {
  id?: string;
  customerId?: string;
  levelId?: string;
  layerId?: string;
  mac?: string;
  name?: string;
  type?: string;
  radius?: string;
  longitude: number;
  latitude: number;
  rssiCalibration?: number;
  buildingPartIDExternal?: string;
  buildingparts?: OptionsType<{ label: string; value: string }>;
  viewport?: {
    latitude?: number;
    longitude?: number;
    pitch?: number;
    bearing?: number;
    zoom?: number;
  };
}

export default LocatorManager;
