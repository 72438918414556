import React, { useEffect } from "react";
import { FieldRenderProps } from "react-final-form";
import { Input } from "reactstrap";
import ErrorFeedback from "./ErrorFeedback";
import { FieldProps } from "../../rules-engine/components/RulesFormGenerator";

type Props = FieldRenderProps<TimeIntervalFieldProps, any, TimeIntervalValues>;

function TimeInputField({ input, meta, ...rest }: Props) {
  const invalidChars = ["-", "+", "e", "E"];
  const minimumTimeValue = 0;
  useEffect(() => {
    if (input.value.type === undefined) {
      input.onChange({ ...input.value, type: input.type });
    }
  }, [input]);

  return (
    <div className="d-flex align-items-center gap-2">
      <span>{rest.params.initialDurationLabel}:</span>
      <Input
        id="initialDuration"
        type="number"
        value={input.value.initialDuration?.duration ?? minimumTimeValue}
        invalid={
          !!(
            !meta.dirtySinceLastSubmit &&
            meta.touched &&
            (meta.error || meta.submitError)
          )
        }
        onKeyDown={(evt) =>
          invalidChars.includes(evt.key) && evt.preventDefault()
        }
        min={minimumTimeValue}
        onBlur={input.onBlur}
        className="form-control mr-2"
        onChange={(event) => {
          input.onChange({
            ...input.value,
            initialDuration: {
              timeUnit:
                input.value.initialDuration?.timeUnit ??
                rest.params.initialDuration.timeUnits.options[0].value,
              duration: event.target.value,
            },
          });
        }}
      />
      <ErrorFeedback name={input.name} />
      <Input
        type="select"
        className="form-select"
        onChange={(event) => {
          input.onChange({
            ...input.value,
            initialDuration: {
              duration:
                input.value.initialDuration?.duration ?? minimumTimeValue,
              timeUnit: event.target.value,
            },
          });
        }}
        value={input.value.initialDuration?.timeUnit}
      >
        {rest.params.initialDuration.timeUnits.options.map(
          (value: { label: string; value: string }) => (
            <option key={value.label} value={value.value}>
              {value.label}
            </option>
          )
        )}
      </Input>
      <span>{rest.params.repetitionLabel}:</span>
      <Input
        type="number"
        value={input.value.repetitionDuration?.duration ?? 0}
        invalid={
          !!(
            !meta.dirtySinceLastSubmit &&
            meta.touched &&
            (meta.error || meta.submitError)
          )
        }
        onKeyDown={(evt) =>
          invalidChars.includes(evt.key) && evt.preventDefault()
        }
        min={minimumTimeValue}
        onBlur={input.onBlur}
        className="form-control"
        onChange={(event) => {
          input.onChange({
            ...input.value,
            repetitionDuration: {
              timeUnit:
                input.value.repetitionDuration?.timeUnit ??
                rest.params.repetitionDuration.timeUnits.options[0].value,
              duration: event.target.value,
            },
          });
        }}
      />
      <ErrorFeedback name={input.name} />
      <Input
        type="select"
        className="form-select"
        onChange={(event) => {
          input.onChange({
            ...input.value,
            repetitionDuration: {
              duration:
                input.value.repetitionDuration?.duration ?? minimumTimeValue,
              timeUnit: event.target.value,
            },
          });
        }}
        value={input.value.repetitionDuration?.timeUnit}
      >
        {rest.params.repetitionDuration.timeUnits.options.map(
          (value: { label: string; value: string }) => (
            <option key={value.label} value={value.value}>
              {value.label}
            </option>
          )
        )}
      </Input>
    </div>
  );
}

export interface TimeIntervalValues {
  initialDuration?: TimeInterval;
  repetitionDuration?: TimeInterval;
  type?: string;
}

export interface TimeInterval {
  duration: number;
  timeUnit: string;
}

export interface TimeIntervalFieldProps extends FieldProps {
  type: string;
  params: TimeIntervalField;
}

export interface TimeIntervalField {
  initialDuration: {
    timeValue: number;
    timeUnits: { options: { label: string; value: string }[] };
  };
  repetitionDuration: {
    timeValue: number;
    timeUnits: { options: { label: string; value: string }[] };
  };
}

export default TimeInputField;
