import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { levelFields } from "../__generated__/types";
import { Locator } from "./components/LocatorManager";

interface LocatorManagerState {
  locators: Locator[];
  currentLevel: levelFields | null;
  createdLocators: Locator[];
  updatedLocators: { [id: string]: Locator };
  deletedLocators: string[];
  selectedLocator: { locator: Locator | null; index: number };
  isLocked: boolean;
  isDragging: boolean;
  showSignalRadius: boolean;
  isSatelliteViewActive: boolean;
}

const initialState: LocatorManagerState = {
  locators: [],
  currentLevel: null,
  createdLocators: [],
  updatedLocators: {},
  deletedLocators: [],
  selectedLocator: { locator: null, index: -1 },
  isLocked: true,
  isDragging: false,
  showSignalRadius: false,
  isSatelliteViewActive: false,
};

const slice = createSlice({
  name: "locatorManager",
  initialState,
  reducers: {
    addLocator: (
      state: LocatorManagerState,
      action: PayloadAction<Locator>
    ) => {
      const locator: Locator = {
        ...action.payload,
        type: action.payload.type ? action.payload.type : "Access Point",
        radius: "0.0",
        rssiCalibration: 0,
      };
      state.locators.push(locator);
      state.createdLocators.push(locator);
      state.selectedLocator = {
        locator,
        index: state.locators.length - 1,
      };
    },
    setCurrentLevel: (
      state: LocatorManagerState,
      action: PayloadAction<levelFields>
    ) => {
      state.currentLevel = action.payload;
    },
    initializeLocators: (
      state: LocatorManagerState,
      action: PayloadAction<Locator[]>
    ) => {
      state.locators = action.payload;
      state.selectedLocator = { locator: null, index: -1 };
      state.createdLocators = [];
      state.updatedLocators = {};
      state.deletedLocators = [];
    },
    removeLocator: (state: LocatorManagerState) => {
      const selectedLocator = state.selectedLocator.locator;
      // invalid state
      if (!selectedLocator) return;

      if (selectedLocator.id) {
        // updatedLocator
        delete state.updatedLocators[selectedLocator.id];
        // deletedLocator
        state.deletedLocators.push(selectedLocator.id);
      } else {
        // createdLocator
        const index = state.createdLocators.findIndex(
          (l) =>
            l.latitude === selectedLocator.latitude &&
            l.longitude === selectedLocator.longitude
        );

        if (index >= 0) {
          state.createdLocators.splice(index, 1);
        }
      }

      // locator on map
      state.locators.splice(state.selectedLocator.index, 1);
      state.selectedLocator = { locator: null, index: -1 };
    },
    setIsDragging: (
      state: LocatorManagerState,
      action: PayloadAction<boolean>
    ) => {
      state.isDragging = action.payload;
    },
    toggleSignalRadius: (state: LocatorManagerState) => {
      state.showSignalRadius = !state.showSignalRadius;
    },
    toggleLocator: (
      state: LocatorManagerState,
      action: PayloadAction<{ locator: Locator | null; index: number }>
    ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const { locator } = action.payload;
      const index = locator ? action.payload.index : -1;

      state.selectedLocator = { locator, index };
    },
    toggleLock: (state: LocatorManagerState) => {
      state.isLocked = !state.isLocked;
    },
    toggleSatelliteView: (state: LocatorManagerState) => {
      state.isSatelliteViewActive = !state.isSatelliteViewActive;
    },
    updateCoordinate: (
      state: LocatorManagerState,
      action: PayloadAction<{ coordinate: Locator; index: number }>
    ) => {
      const { locators } = state;
      const { coordinate, index } = action.payload;

      const locator = locators[index];
      if (locator) {
        if (locator.id) {
          // updatedLocator
          state.updatedLocators[locator.id] = {
            ...locator,
            longitude: coordinate.longitude,
            latitude: coordinate.latitude,
          };
        } else {
          // createdLocator
          const createdLocator = state.createdLocators.find(
            (l) =>
              l.longitude === locator.longitude &&
              l.latitude === locator.latitude
          );
          if (createdLocator) {
            createdLocator.longitude = coordinate.longitude;
            createdLocator.latitude = coordinate.latitude;
          }
        }

        //locator on map
        locator.longitude = coordinate.longitude;
        locator.latitude = coordinate.latitude;
      }
    },
    updateLocator: (
      state: LocatorManagerState,
      action: PayloadAction<Locator>
    ) => {
      const locator = action.payload;
      if (locator.id) {
        // updatedLocator
        state.updatedLocators[locator.id] = locator;
      } else {
        // createdLocator
        const createdIndex = state.createdLocators.findIndex(
          (l) =>
            l.longitude === locator.longitude && l.latitude === locator.latitude
        );
        if (createdIndex >= 0) {
          state.createdLocators.splice(createdIndex, 1);
          state.createdLocators.push(locator);
        }
      }

      // locator on map
      const { index } = state.selectedLocator;
      state.locators[index] = locator;
      state.selectedLocator.locator = locator;
    },
    findAndToggleLocator(
      state: LocatorManagerState,
      action: PayloadAction<Locator>
    ) {
      if (action.payload.id !== undefined) {
        const index = state.locators.findIndex(
          (locator) => locator.id === action.payload.id
        );
        if (index >= 0) {
          state.selectedLocator = { locator: action.payload, index };
        } else {
          state.selectedLocator = { locator: null, index };
        }
      }
    },
  },
});

export const {
  addLocator,
  initializeLocators,
  toggleLocator,
  removeLocator,
  toggleLock,
  updateCoordinate,
  updateLocator,
  setCurrentLevel,
  setIsDragging,
  toggleSignalRadius,
  toggleSatelliteView,
  findAndToggleLocator,
} = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectActiveLocator = (state: RootState) =>
  state.locatorManager.selectedLocator;

export const selectCurrentLevel = (state: RootState) =>
  state.locatorManager.currentLevel;

export const selectLocators = (state: RootState) =>
  state.locatorManager.locators;

export const selectCreatedLocators = (state: RootState) =>
  state.locatorManager.createdLocators;

export const selectDeletedLocatorIds = (state: RootState) =>
  state.locatorManager.deletedLocators;

export const selectUpdatedLocators = (state: RootState) => {
  const arr = [];
  for (const key in state.locatorManager.updatedLocators) {
    arr.push(state.locatorManager.updatedLocators[key]);
  }
  return arr;
};

export const selectIsDragging = (state: RootState) =>
  state.locatorManager.isDragging;

export const selectSignalRadius = (state: RootState) =>
  state.locatorManager.showSignalRadius;

export const selectIsLocked = (state: RootState) =>
  state.locatorManager.isLocked;

export const selectHasChanges = (state: RootState) =>
  state.locatorManager.createdLocators.length > 0 ||
  state.locatorManager.deletedLocators.length > 0 ||
  selectUpdatedLocators(state).length > 0;

export const selectIsSatelliteViewActive = (state: RootState) =>
  state.locatorManager.isSatelliteViewActive;

export default slice.reducer;
