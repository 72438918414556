import { gql } from "@apollo/client";

const levelFragment = gql`
  fragment levelFields on Level {
    id
    name
    layerId
  }
`;

const viewportFragment = gql`
  fragment viewportFields on Viewport {
    bearing
    latitude
    longitude
    pitch
    zoom
  }
`;

export const GET_ALL_LOCATIONS = gql`
  query GetAllLocations {
    locations {
      id
      name
      locatorCount
    }
  }
`;

export const GET_ALL_LOCATIONS_WITH_LEVEL = gql`
  query GetAllLocations {
    locations {
      id
      name
      availableLevels {
        ...levelFields
      }
      viewport {
        ...viewportFields
      }
    }
  }
  ${levelFragment}
  ${viewportFragment}
`;

export const GET_LOCATION = gql`
  query GetLocationById($id: ID!) {
    location(id: $id) {
      id
      name
      availableLevels {
        ...levelFields
      }
      viewport {
        ...viewportFields
      }
    }
  }

  ${levelFragment}
  ${viewportFragment}
`;

export const GET_LOCATION_LEVEL = gql`
  query GetPositionsByLevel($levelId: ID!) {
    positionsByLevel(levelId: $levelId) {
      assetId
      assetName
      icon
      latitude
      locatorName
      longitude
    }
  }
`;

export const GET_ALL_POSITIONS = gql`
  query GetAllPositions {
    positions {
      assetId
      assetName
      icon
      latitude
      locatorName
      locatorId
      longitude
      assetGroupName
    }
  }
`;

export const GET_LOCATOR_BY_MAC = gql`
  query LocatorByMac($macAddress: String) {
    locatorByMac(mac: $macAddress) {
      id
      customerId
      mac
      name
      type
      radius
      rssiCalibration
      buildingPartExternalId
      level {
        id
        name
        layerId
      }
      viewport {
        latitude
        longitude
        zoom
        pitch
        bearing
      }
    }
  }
`;

/*export class LocationsQuery extends Query<GetAllLocations> {}
export class LocationQuery extends Query<
  GetLocationById,
  GetLocationByIdVariables
  > { }
export class LevelQuery extends Query<
  GetTrackingsByLocationAndLevel,
  GetTrackingsByLocationAndLevelVariables
> {}*/
