import RulesService from "../service/RulesService";
import { useSelector } from "react-redux";
import { getRuleState } from "../ruleManagerSlice";
import { useKeycloak } from "../../auth";
import { useMemo } from "react";

export const useConstructRuleInput = () => {
  const ruleState = useSelector(getRuleState);
  const keycloak = useKeycloak();

  return useMemo(() => {
    const userName = `${keycloak.user.firstName}  ${keycloak.user.lastName}`;
    return RulesService.generateRuleInput(ruleState, userName);
  }, [keycloak.user.firstName, keycloak.user.lastName, ruleState]);
};
