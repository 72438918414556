import React from "react";
import {
  CustomIcon,
  CustomIconInput,
  CustomIconTag,
} from "../../models/CustomIcon";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Form as ReactForm } from "reactstrap";
import { Field, Form } from "react-final-form";
import { notEmpty, required } from "../../forms/validation";
import CustomIconTagField, {
  CustomIconTagFieldInput,
} from "./CustomIconTagField";
import CustomIconSelector from "./CustomIconSelector";
import { useTranslation } from "react-i18next";
import { ACTION_CANCEL, ACTION_SAVE } from "../../localization";

/**
 * Component that contains a {@link Dialog} that allows the user to modify an existing {@link CustomIcon}.
 * Moreover, this {@link Dialog} can also be used to add a new {@link CustomIcon} to the instance.
 *
 * @param title Title of the {@link Dialog}.
 * @param open Determines whether the {@link Dialog} is currently shown.
 * @param loading Determines whether the API has any pending action.
 * @param tags List of {@link CustomIconTag} instance the user can choose from.
 * @param existingIcon Existing {@link CustomIcon} that is modified (null when creating a new one).
 * @param onCancel Function that is invoked when the dialog is cancelled/closed.
 * @param onAccept Function that is invoked when the dialog is accepted/confirmed.
 */
const ModifyCustomIconDialog: React.FC<ModifyCustomIconDialogProps> = ({
  title,
  open,
  loading,
  tags,
  existingIcon,
  onCancel,
  onAccept,
}) => {
  const { t } = useTranslation();
  /**
   * Wrapper function that is called when the dialog is accepted.
   * This function calls the onAccept prop function and removes the selected vector icon.
   */
  const _handleAccept = (values: CustomIconFormValues) => {
    onAccept({
      id: existingIcon ? existingIcon.id : undefined,
      tags: values.tags.map((i) => {
        return {
          id: i.backendID.includes("new") ? undefined : i.backendID,
          tagName: i.label,
        };
      }),
      iconCode: values.iconCode,
    });
  };

  /**
   * Function that renders the main form of the dialog.
   */
  const _renderForm = () => {
    return (
      <>
        <Form
          initialValues={{
            ...existingIcon,
            tags:
              existingIcon != null
                ? existingIcon.tags.map((i) => {
                    return {
                      backendID: i.id,
                      label: i.tagName,
                    };
                  })
                : [],
          }}
          onSubmit={() => {}}
        >
          {({ handleSubmit, hasValidationErrors, values }) => {
            return (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="100%"
                  marginTop="1rem"
                >
                  <ReactForm name="customIconForm" onSubmit={handleSubmit}>
                    <Field
                      name="iconCode"
                      validate={required}
                      component={CustomIconSelector}
                    />
                    <Field
                      name="tags"
                      validate={notEmpty}
                      existingTags={tags}
                      component={CustomIconTagField}
                    />
                  </ReactForm>
                </Box>
                <Box marginTop="1rem" display="flex" justifyContent="center">
                  <DialogActions>
                    <Button
                      onClick={onCancel}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                      }}
                      color={"error"}
                    >
                      {t(ACTION_CANCEL)}
                    </Button>
                    <Button
                      onClick={() =>
                        _handleAccept(values as CustomIconFormValues)
                      }
                      disabled={hasValidationErrors}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                      }}
                      color={"success"}
                    >
                      {t(ACTION_SAVE)}
                    </Button>
                  </DialogActions>
                </Box>
              </>
            );
          }}
        </Form>
      </>
    );
  };

  /**
   * Function that renders the pending view of the dialog.
   */
  const _renderPending = () => {
    return <div>Loading</div>;
  };

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle title={title}>
        <Box justifyContent="center" display="flex" fontWeight="bold">
          {title}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginTop="1rem"
        >
          {loading ? _renderPending() : _renderForm()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyCustomIconDialog;

interface CustomIconFormValues {
  id: string | undefined;
  tags: CustomIconTagFieldInput[];
  iconCode: string;
}

interface ModifyCustomIconDialogProps {
  title: string;
  open: boolean;
  loading: boolean;
  tags: CustomIconTag[];
  onCancel: () => void;
  onAccept: (customIconInput: CustomIconInput) => void;
  existingIcon?: CustomIcon | null;
}
