import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum GroupBy {
  Fiveminutes = 'FIVEMINUTES',
  Tenminutes = 'TENMINUTES',
  Thirtyminutes = 'THIRTYMINUTES',
  Onehour = 'ONEHOUR',
  Sixhours = 'SIXHOURS'
}

export type HistoricalPosition = {
  __typename?: 'HistoricalPosition';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Int']>;
  radius?: Maybe<Scalars['Float']>;
};

export type Level = {
  __typename?: 'Level';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  layerId?: Maybe<Scalars['String']>;
};

export type PositionInfluxFilter = {
  assetId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  levelId?: Maybe<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  getMeasurementsByDay: Array<Maybe<Tlm>>;
  getLastMeasurementByTagMacs: Array<Maybe<Tlm>>;
  getMeasurementsByPeriod: Array<Maybe<TlMbyTagMac>>;
  historicalPositions: Array<HistoricalPosition>;
  levelsByAssetIdAndPeriod: Array<Level>;
};


export type QueryGetMeasurementsByDayArgs = {
  tagMac: Scalars['ID'];
  day: Scalars['String'];
  function?: Maybe<QueryFunction>;
  groupBy?: Maybe<GroupBy>;
};


export type QueryGetLastMeasurementByTagMacsArgs = {
  tagMacs: Array<Scalars['ID']>;
};


export type QueryGetMeasurementsByPeriodArgs = {
  tagMacs: Array<Scalars['ID']>;
  start: Scalars['String'];
  end: Scalars['String'];
};


export type QueryHistoricalPositionsArgs = {
  filter: PositionInfluxFilter;
};


export type QueryLevelsByAssetIdAndPeriodArgs = {
  filter: PositionInfluxFilter;
};

export enum QueryFunction {
  Mean = 'MEAN',
  Median = 'MEDIAN',
  First = 'FIRST',
  Last = 'LAST',
  Max = 'MAX',
  Min = 'MIN'
}

export type Subscription = {
  __typename?: 'Subscription';
  getUpdateOnMeasurementsByDay: Array<Maybe<Tlm>>;
  getUpdateOnLastMeasurementByTagMacs: Array<Maybe<Tlm>>;
};


export type SubscriptionGetUpdateOnMeasurementsByDayArgs = {
  tagMac: Scalars['ID'];
  day: Scalars['String'];
  function?: Maybe<QueryFunction>;
  groupBy?: Maybe<GroupBy>;
};


export type SubscriptionGetUpdateOnLastMeasurementByTagMacsArgs = {
  tagMacs: Array<Scalars['ID']>;
};

export type Tlm = {
  __typename?: 'TLM';
  id: Scalars['ID'];
  humidity?: Maybe<Scalars['Float']>;
  humidityMean?: Maybe<Scalars['Float']>;
  humidityMax?: Maybe<Scalars['Float']>;
  humidityMin?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  temperatureMean?: Maybe<Scalars['Float']>;
  temperatureMax?: Maybe<Scalars['Float']>;
  temperatureMin?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type TlMbyTagMac = {
  __typename?: 'TLMbyTagMac';
  tagMac: Scalars['ID'];
  tlm: Array<Maybe<Tlm>>;
};

export type HistoricalPositionsQueryVariables = Exact<{
  filter: PositionInfluxFilter;
}>;


export type HistoricalPositionsQuery = { __typename?: 'Query', historicalPositions: Array<{ __typename?: 'HistoricalPosition', latitude?: Maybe<number>, longitude?: Maybe<number>, duration?: Maybe<number>, radius?: Maybe<number> }> };

export type LevelsByAssetIdAndPeriodQueryVariables = Exact<{
  filter: PositionInfluxFilter;
}>;


export type LevelsByAssetIdAndPeriodQuery = { __typename?: 'Query', levelsByAssetIdAndPeriod: Array<{ __typename?: 'Level', id: string, name?: Maybe<string>, layerId?: Maybe<string> }> };


export const HistoricalPositionsDocument = gql`
    query HistoricalPositions($filter: PositionInfluxFilter!) {
  historicalPositions(filter: $filter) {
    latitude
    longitude
    duration
    radius
  }
}
    `;

/**
 * __useHistoricalPositionsQuery__
 *
 * To run a query within a React component, call `useHistoricalPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricalPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricalPositionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHistoricalPositionsQuery(baseOptions: Apollo.QueryHookOptions<HistoricalPositionsQuery, HistoricalPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoricalPositionsQuery, HistoricalPositionsQueryVariables>(HistoricalPositionsDocument, options);
      }
export function useHistoricalPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoricalPositionsQuery, HistoricalPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoricalPositionsQuery, HistoricalPositionsQueryVariables>(HistoricalPositionsDocument, options);
        }
export type HistoricalPositionsQueryHookResult = ReturnType<typeof useHistoricalPositionsQuery>;
export type HistoricalPositionsLazyQueryHookResult = ReturnType<typeof useHistoricalPositionsLazyQuery>;
export type HistoricalPositionsQueryResult = Apollo.QueryResult<HistoricalPositionsQuery, HistoricalPositionsQueryVariables>;
export const LevelsByAssetIdAndPeriodDocument = gql`
    query LevelsByAssetIdAndPeriod($filter: PositionInfluxFilter!) {
  levelsByAssetIdAndPeriod(filter: $filter) {
    id
    name
    layerId
  }
}
    `;

/**
 * __useLevelsByAssetIdAndPeriodQuery__
 *
 * To run a query within a React component, call `useLevelsByAssetIdAndPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useLevelsByAssetIdAndPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLevelsByAssetIdAndPeriodQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLevelsByAssetIdAndPeriodQuery(baseOptions: Apollo.QueryHookOptions<LevelsByAssetIdAndPeriodQuery, LevelsByAssetIdAndPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LevelsByAssetIdAndPeriodQuery, LevelsByAssetIdAndPeriodQueryVariables>(LevelsByAssetIdAndPeriodDocument, options);
      }
export function useLevelsByAssetIdAndPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LevelsByAssetIdAndPeriodQuery, LevelsByAssetIdAndPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LevelsByAssetIdAndPeriodQuery, LevelsByAssetIdAndPeriodQueryVariables>(LevelsByAssetIdAndPeriodDocument, options);
        }
export type LevelsByAssetIdAndPeriodQueryHookResult = ReturnType<typeof useLevelsByAssetIdAndPeriodQuery>;
export type LevelsByAssetIdAndPeriodLazyQueryHookResult = ReturnType<typeof useLevelsByAssetIdAndPeriodLazyQuery>;
export type LevelsByAssetIdAndPeriodQueryResult = Apollo.QueryResult<LevelsByAssetIdAndPeriodQuery, LevelsByAssetIdAndPeriodQueryVariables>;