import React, { useMemo } from "react";
import { useAssetsAndGroups } from "../../asset/hooks/useAssetsAndGroups";
import { FieldRenderProps } from "react-final-form";
import { FieldProps } from "../../rules-engine/components/RulesFormGenerator";
import ErrorFeedback from "../../forms/components/ErrorFeedback";
import Select from "react-select";
import _ from "lodash";
import i18next from "../../i18n";
import { ERROR_NO_DATA_AVAILABLE, ERROR_SIMPLE } from "../../localization";

type Props = FieldRenderProps<AssetsFieldProps, any>;

const AssetMultiSelect: React.FC<Props> = ({ input, _meta }) => {
  const { assets, assetGroups, error, loading } = useAssetsAndGroups();

  const initialAssetGroups = useMemo(
    () =>
      input.value?.assetGroups
        ? assetGroups?.filter((group: any) =>
            input.value.assetGroups?.some((v) => v === group.value)
          )
        : [],
    [assetGroups, input.value.assetGroups]
  );

  const initialAssets = input.value.assets
    ? assets?.filter((asset: any) =>
        input.value.assets?.some((v) => v === asset.value)
      )
    : [];

  const filteredAssets = useMemo(() => {
    if (!initialAssetGroups || initialAssetGroups.length < 1) return assets;
    return assets?.filter(
      (asset: any) =>
        !asset?.groupIds.some((g: any) =>
          initialAssetGroups?.some((group: any) => group.value === g)
        )
    );
  }, [assets, initialAssetGroups]);

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      background: "#ecf2f6",
    }),
  };

  return (
    <>
      <Select
        isMulti
        styles={customStyles}
        value={initialAssetGroups}
        options={assetGroups}
        isLoading={loading}
        onChange={(event) => {
          input.onChange({
            assets: input.value.assets ?? [],
            assetGroups: [...event.map((val: any) => val.value)],
          });
        }}
      />
      <Select
        className="mt-3"
        isMulti
        styles={customStyles}
        value={initialAssets}
        options={filteredAssets}
        isLoading={loading}
        onChange={(event) => {
          input.onChange({
            assets: [...event.map((val: any) => val.value)],
            assetGroups: input.value.assetGroups ?? [],
          });
        }}
      />
      <div>
        <ErrorFeedback
          name={input.name}
          errorMsg={validateAssetAndAssetGroups(
            assets,
            assetGroups,
            !!error,
            loading
          )}
        />
      </div>
    </>
  );
};

export default AssetMultiSelect;

export interface AssetsFieldProps extends FieldProps {
  assets: string[];
  assetGroups: string[];
}

export const validateAssetAndAssetGroups = (
  assets: string[] | undefined,
  assetGroups: string[] | undefined,
  error: boolean,
  loading: boolean
) => {
  if (loading) return undefined;
  if (error) return i18next.t(ERROR_SIMPLE);
  if (_.isEmpty(assets) && _.isEmpty(assetGroups))
    return i18next.t(ERROR_NO_DATA_AVAILABLE);
  return undefined;
};
