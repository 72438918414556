import React, { useMemo, useState } from "react";
import { Button } from "reactstrap";
import IconPickerNavigation from "./IconPickerNavigation";
import Icon from "./Icon";
import { CustomIcon, CustomIconTag } from "../../models/CustomIcon";
import { DEFAULT_ICON } from "../../customIcons/hooks/useCustomIcons";
import { useTranslation } from "react-i18next";
import {
  ACTION_CANCEL,
  ACTION_CONFIRM,
  GENERAL_SELECTED,
  INFO_SYMBOL_NOTHING_FOUND,
} from "../../localization";

const IconPicker: React.FC<IconPickerProps> = ({
  onSubmit,
  onCancel,
  initialIcon,
  customIcons,
  customIconsDefaultIcon,
  customTags,
}) => {
  const [activeTags, setActiveTags] = useState<CustomIconTag[]>([]);
  const [pickedIcon, setPickedIcon] = useState<CustomIcon | undefined>(
    initialIcon
  );
  const { t } = useTranslation();

  const setIcon = (icon: CustomIcon) => {
    if (icon !== pickedIcon) {
      setPickedIcon(icon);
    }
  };

  const toggleActiveIcon = (tag: CustomIconTag) => {
    if (activeTags.includes(tag))
      setActiveTags(activeTags.filter((i) => i !== tag));
    else setActiveTags([tag, ...activeTags]);
  };

  const filteredIcons = useMemo(() => {
    return activeTags.length === 0
      ? [DEFAULT_ICON, ...customIcons]
      : customIcons.filter((i) => {
          return activeTags.some((c) => i.tags.map((e) => e.id).includes(c.id));
        });
  }, [activeTags, customIcons]);

  return (
    <>
      <div className="d-flex">
        <div>
          <div className="mb-auto">
            <IconPickerNavigation
              items={customTags}
              activeItems={activeTags}
              toggleActiveItem={toggleActiveIcon}
            />
          </div>
          {pickedIcon && (
            <div>
              <span className="fw-bold">{t(GENERAL_SELECTED)}</span>
              <Icon
                icon={
                  customIcons.find((i) => i.id === pickedIcon?.id) ??
                  customIconsDefaultIcon
                }
              />
            </div>
          )}
        </div>
        <div className="w-100">
          <div className="overflow-auto" style={{ maxHeight: "75vh" }}>
            {filteredIcons.length === 0 ? (
              <span>{t(INFO_SYMBOL_NOTHING_FOUND)}</span>
            ) : (
              filteredIcons.map((icon, index) => {
                return (
                  <Icon
                    key={index}
                    className="cursor-pointer"
                    icon={icon}
                    active={icon.id === pickedIcon?.id}
                    setActiveName={setIcon}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end ">
        <Button className="me-3" color="danger" onClick={onCancel}>
          {t(ACTION_CANCEL)}
        </Button>
        <Button onClick={() => onSubmit(pickedIcon)} color="success">
          {t(ACTION_CONFIRM)}
        </Button>
      </div>
    </>
  );
};

export interface IconPickerProps {
  onSubmit: (icon?: CustomIcon) => void;
  onCancel: () => void;
  initialIcon?: CustomIcon;
  customIcons: CustomIcon[];
  customIconsDefaultIcon: CustomIcon;
  customTags: CustomIconTag[];
}

export default IconPicker;
