import { useQuery } from "@apollo/client";
import { useGqlClient } from "../../hooks";
import { GetLocationById } from "../../__generated__/types";
import { GET_LOCATION } from "../queries/locationsQuery";

export const useLocationById = (locationId?: string) => {
  const locationClient = useGqlClient(
    process.env.REACT_APP_GRAPHQL_URI_LOCATION!
  );

  const { data, error, loading } = useQuery<GetLocationById>(GET_LOCATION, {
    client: locationClient,
    variables: {
      id: locationId,
    },
    skip: locationId === undefined || locationId === null,
  });

  return {
    locationData: data,
    locationError: error,
    locationLoading: loading,
  };
};
