import React, { useCallback, useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { ROUTES } from "../../routes";
import ActionButtons from "../../shared/ActionButtons";
import LinkButton from "../../shared/LinkButton";
import { DataTable } from "../../shared/Tables/DataTable";
import { SortableColumn } from "../../shared/Tables/SortableTable";
import { RuleData } from "../Model/RuleModels";
import { RuleListObject } from "../hooks/useRuleList";
import { Loading } from "../../shared";
import AlertBox from "../../shared/AlertBox";
import { useRuleApi } from "../hooks/useRuleApi";
import { rolePermission } from "../../rolePermission";
import { useKeycloak } from "../../auth";
import { useTranslation } from "react-i18next";
import {
  ACTION_CANCEL,
  ACTION_DELETE,
  ACTION_EDIT,
  ACTION_TITLE_PL,
  ERROR_NOT_LINKED,
  GENERAL_CREATOR,
  GENERAL_DESCRIPTION,
  GENERAL_NAME,
  GENERAL_RULE_PL,
  QUESTION_RULE_ENGINE_DELETE,
  RULE_ENGINE_CREATE,
  RULE_ENGINE_DELETE,
  RULE_ENGINE_ERROR_DELETE,
  RULE_ENGINE_INFO_NO_RULE_FOUND,
  RULE_ENGINE_INFO_SAVE,
} from "../../localization";

function RuleListView() {
  //time in ms
  const ALERT_DISPLAY_TIME = 5000;
  const [modal, setModal] = useState<boolean>();
  const [ruleDelete, setRuleDelete] = useState<RuleData | null>(null);
  const keycloak = useKeycloak();
  const { t } = useTranslation();

  const {
    data,
    api: { deleteRule },
    isApiLoading,
    isApiError,
    isRuleDeleteSuccess,
  } = useRuleApi();

  const toggleModal = () => setModal(!modal);

  const alertMessage = (isSuccess: boolean) => {
    const DELETE_SUCCESS = `${t(RULE_ENGINE_INFO_SAVE)}: ${ruleDelete?.name}`;
    return isSuccess ? DELETE_SUCCESS : t(RULE_ENGINE_ERROR_DELETE);
  };

  const _generateActions = useCallback(
    (item) => {
      return [
        {
          text: t(ACTION_EDIT),
          to: generatePath(
            ROUTES.home.childRoutes.ruleEngine.childRoutes.edit.path,
            { id: item.id }
          ),
          role: rolePermission.RULE_ENGINE_EDIT,
        },
        {
          text: t(ACTION_DELETE),
          onClick: () => {
            setModal(!modal);
            setRuleDelete(item);
          },
          role: rolePermission.RULE_ENGINE_DELETE,
        },
      ];
    },
    [modal, t]
  );

  const columns = useMemo(() => {
    const columnDefinitions: SortableColumn<RuleListObject>[] = [
      {
        key: "creator",
        label: t(GENERAL_CREATOR),
        valueAccessor: (item) => item.creator,
        missingCellOverride: (
          <span className="text-muted">{t(ERROR_NOT_LINKED)}</span>
        ),
      },
      {
        key: "name",
        label: t(GENERAL_NAME),
        valueAccessor: (item) => item.name,
        missingCellOverride: (
          <span className="text-muted">{t(ERROR_NOT_LINKED)}</span>
        ),
      },
      {
        key: "description",
        label: t(GENERAL_DESCRIPTION),
        valueAccessor: (item) => item.description,
        missingCellOverride: (
          <span className="text-muted">{t(ERROR_NOT_LINKED)}</span>
        ),
      },
    ];
    if (
      keycloak.hasResourceRole(rolePermission.RULE_ENGINE_EDIT) ||
      keycloak.hasResourceRole(rolePermission.RULE_ENGINE_DELETE)
    ) {
      columnDefinitions.push({
        key: "actions",
        label: t(ACTION_TITLE_PL),
        sortable: false,
        valueAccessor: (item) => (
          <ActionButtons size="sm" actions={_generateActions(item)} />
        ),
      });
    }
    return columnDefinitions;
  }, [_generateActions, keycloak, t]);

  const generateHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <span className="fw-bold">{t(GENERAL_RULE_PL)}</span>
        {keycloak.hasResourceRole(rolePermission.RULE_ENGINE_CREATE) && (
          <LinkButton
            className="ms-auto"
            size="sm"
            color="primary"
            to={generatePath(
              ROUTES.home.childRoutes.ruleEngine.childRoutes.create.path
            )}
          >
            {t(RULE_ENGINE_CREATE)}
          </LinkButton>
        )}
      </div>
    );
  };

  return (
    <>
      {isApiLoading && <Loading />}
      {(isApiError || isRuleDeleteSuccess) && (
        <AlertBox
          displayedTime={isRuleDeleteSuccess ? ALERT_DISPLAY_TIME : null}
          message={alertMessage(isRuleDeleteSuccess)}
          color={isRuleDeleteSuccess ? "success" : "danger"}
          show={isApiError || isRuleDeleteSuccess}
        />
      )}
      <Modal isOpen={modal} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title d-inline-flex">{t(RULE_ENGINE_DELETE)}</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => toggleModal()}
          />
        </div>
        <ModalBody>
          <Row>
            <p>{t(QUESTION_RULE_ENGINE_DELETE)}</p>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col sm="auto">
              <Button
                color="danger"
                block
                onClick={() => {
                  if (ruleDelete) {
                    deleteRule(ruleDelete.id);
                  }
                  toggleModal();
                }}
              >
                {t(ACTION_DELETE)}
              </Button>
            </Col>
            <Col sm="auto">
              <Button
                color="success"
                block
                onClick={() => {
                  toggleModal();
                  setRuleDelete(null);
                }}
              >
                {t(ACTION_CANCEL)}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <DataTable
        columns={columns}
        rows={data ?? []}
        additionalHeader={generateHeader()}
        emptyTable={t(RULE_ENGINE_INFO_NO_RULE_FOUND) ?? ""}
      />
    </>
  );
}

export default RuleListView;
