import React, { useCallback, useMemo } from "react";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import "../../_variables.scss";
import "../styles/DrilldownFilter.scss";
import TreeItem from "./TreeItem";
import TreeView from "./TreeView";
import { useTranslation } from "react-i18next";
import { GENERAL_ROOM_LIST } from "../../localization";

export const DrilldownFilter: React.FC<DrilldownFilterProps> = ({
  data,
  onLevelToggle,
  onItemSelect,
  loading,
  selectedItem,
  selectedItems,
  className,
  component,
}) => {
  const { t } = useTranslation();

  const handleExpand = useCallback(
    (id: string) => {
      if (onLevelToggle === undefined) return;
      onLevelToggle(id);
    },
    [onLevelToggle]
  );

  const handleNodeSelect = useCallback(
    (id: string) => {
      if (onItemSelect === undefined) return;
      onItemSelect(id);
    },
    [onItemSelect]
  );

  const handleExpandItem = useCallback(
    (id: string) => {
      return !!selectedItems?.includes(id);
    },
    [selectedItems]
  );

  const memoizedTrees = useMemo(() => {
    const renderTree = (
      { id, name, childNodes }: TransformedNode,
      depth: number = 0,
      lastRootNode: boolean = false,
      index: number,
      size: number
    ) => {
      return (
        <TreeItem
          key={id}
          id={id}
          index={index}
          label={name}
          lastRootNode={lastRootNode}
          selectedItem={
            selectedItems?.find((item) => item === id) ?? selectedItem
          }
          size={size}
          depth={depth}
          listItem={component}
          expanded={handleExpandItem(id)}
        >
          {Array.isArray(childNodes) && childNodes.length > 0
            ? childNodes
                .filter((node) => node !== null)
                .map((node, index) =>
                  renderTree(
                    node,
                    depth + 1,
                    data.length === 1 && index < childNodes.length - 1
                      ? false
                      : lastRootNode,
                    index,
                    childNodes.length
                  )
                )
            : null}
        </TreeItem>
      );
    };

    return data.map((tree: TransformedNode, index: number) => {
      return renderTree(tree, 0, index === data.length - 1, index, data.length);
    });
  }, [data, selectedItem, selectedItems, component, handleExpandItem]);

  if (component) {
    return (
      <Card>
        <CardBody>
          <TreeView onExpand={handleExpand} onNodeSelect={handleNodeSelect}>
            {memoizedTrees}
          </TreeView>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className={`px-0 ${className}`}>
      <CardHeader className="d-flex align-items-center justify-content-between">
        {t(GENERAL_ROOM_LIST)}
        <Spinner
          size="sm"
          type="grow"
          className={`ms-3 ${loading ? "visible" : "invisible"}`}
        />
      </CardHeader>
      <CardBody className="p-0">
        <TreeView onExpand={handleExpand} onNodeSelect={handleNodeSelect}>
          {memoizedTrees}
        </TreeView>
      </CardBody>
    </Card>
  );
};

export interface DrilldownFilterProps {
  data: TransformedNode[];
  onLevelToggle?(id?: string): void;
  onItemSelect?(id: string): void;
  loading: boolean;
  selectedItem: string;
  selectedItems?: string[];
  className?: string;
  component?: boolean;
}

export interface TransformedNode {
  id: string;
  name: string;
  childNodes: TransformedNode[];
}

export default DrilldownFilter;
