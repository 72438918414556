import { FeatureCollection } from "geojson";
import mapboxgl from "mapbox-gl";
import { useCallback, useMemo, useState } from "react";
import { MapRef, ViewState } from "react-map-gl";

export function useBboxViewport(
  features?: FeatureCollection
): [Partial<ViewState>, (node: MapRef) => void] {
  const [map, setMap] = useState<mapboxgl.Map>();

  const ref = useCallback((node: MapRef) => {
    if (node !== null) {
      setMap(node.getMap() as mapboxgl.Map);
    }
  }, []);

  const viewport = useMemo(() => {
    if (features?.bbox === undefined || map === undefined)
      return { longitude: 0, latitude: 0, zoom: 20 };

    const [minLng, minLat, maxLng, maxLat] = features.bbox;

    const result = map.cameraForBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 40, maxZoom: 20 }
    );

    if (result) {
      return {
        longitude: result?.center.lng,
        latitude: result?.center.lat,
        zoom: result?.zoom,
      };
    }

    return { longitude: 0, latitude: 0, zoom: 20 };
  }, [features?.bbox, map]);

  return [viewport, ref];
}
