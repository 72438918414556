import { FeatureCollection, Point } from "geojson";
import { ReactNode, useEffect, useState } from "react";
import ReactMapGL, {
  NavigationControl,
  Source,
  useMap,
  ViewState,
} from "react-map-gl";
import CuGeoJsonFloorplan from "../../catchupMaps/CuGeoJsonFloorplan";
import HeatLayer from "../../map/HeatLayer";
import { Level } from "../../models/Level";
import { useBboxViewport } from "./hooks/useBoxViewport";

export default function HeatMap({
  children,
  currentLevel,
  data,
}: HeatmapProps) {
  const [initialViewState, setMapRef] = useBboxViewport(data);
  const { default: defaultMap } = useMap();

  const [viewState, setViewState] = useState<Partial<ViewState> | ViewState>();

  useEffect(() => {
    setViewState(initialViewState);
  }, [initialViewState]);

  useEffect(() => {
    if (defaultMap !== undefined) {
      setMapRef(defaultMap);
    }
  }, [defaultMap, setMapRef]);

  return (
    <ReactMapGL
      {...viewState}
      mapStyle={process.env.REACT_APP_MB_STYLE}
      mapboxAccessToken={process.env.REACT_APP_MB_ACCESS_TOKEN}
      onMove={(evt) => setViewState(evt.viewState)}
    >
      <CuGeoJsonFloorplan level={currentLevel?.name ?? ""} use3D />
      <NavigationControl position={"top-left"} />
      {data && (
        <Source type="geojson" data={data} id="heatmap-source">
          <HeatLayer currentLevel={currentLevel} />
        </Source>
      )}
      {children}
    </ReactMapGL>
  );
}

interface HeatmapProps {
  children?: ReactNode;
  currentLevel?: Level | null;
  data?: FeatureCollection<Point, { duration: number }>;
}
