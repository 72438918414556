import { ProgressPoint } from "../../shared/progress-navigation/ProgressNavigation";
import { RuleElement } from "../Model/RuleElement";
import { RuleSequence, RuleStateMachine } from "../Model/RuleSequence";

const ProgressNavigationService = {
  generateProgressPoint(
    ruleElement: RuleElement,
    state: RuleSequence
  ): ProgressPoint {
    return {
      id: ruleElement.fieldType ?? ruleElement.title,
      title: ruleElement.title,
      icon: ruleElement.icon,
      description: ruleElement.shortDescription,
      state: state,
    };
  },

  generateProgressBar(ruleElements: RuleElement[]): ProgressPoint[] {
    const ruleSeq = new RuleStateMachine();
    return ruleElements.map((ruleElement) =>
      this.generateProgressPoint(
        ruleElement,
        ruleSeq.getState(ruleElement.fieldType)
      )
    );
  },

  replaceProgressPoint(
    progressBar: ProgressPoint[],
    progressPoint: ProgressPoint,
    state: RuleSequence
  ) {
    const ruleSeq = new RuleStateMachine();
    const isListState = ruleSeq.isListState(state);
    let findProgressPoint: number = -1;

    if (isListState) {
      findProgressPoint = progressBar.findIndex(
        (pPoint) =>
          pPoint.state === state || pPoint.state === ruleSeq.next(state)
      );
    } else {
      findProgressPoint = progressBar.findIndex(
        (pPoint) =>
          pPoint.state === state || pPoint.state === ruleSeq.previous(state)
      );
    }

    if (findProgressPoint !== -1) {
      progressBar[findProgressPoint] = progressPoint;
      return progressBar;
    }

    return [...progressBar, progressPoint];
  },
};

export default ProgressNavigationService;
