export enum ComponentNames {
  BOUNDARY = "Boundary",
  INPUT_FIELD = "InputField",
  SELECT_FIELD = "SelectField",
  TIME_INPUT_FIELD = "TimeInputField",
  ASSET_MULTI_SELECT = "AssetMultiSelect",
  BOUNDS_INPUT = "BoundsInput",
  TYPEAHEAD_FIELD = "TypeaheadField",
  TEMPLATE_EDITOR = "TemplateEditor",
  DRILL_DOWN_SELECT = "DrillDownSelect",
  REACT_MULTI_EMAIL = "ReactMultiEmail",
}
