import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./InformationButton.scss";

const InformationButton: React.FC<InformationButtonProps> = ({
  description,
  id,
}) => {
  return (
    <>
      <FontAwesomeIcon
        className="grow-on-hover"
        icon={faQuestionCircle}
        id={id + "-tooltip"}
      />
      <UncontrolledTooltip
        innerClassName="bg-secondary"
        placement="top-start"
        fade
        target={id + "-tooltip"}
      >
        {description}
      </UncontrolledTooltip>
    </>
  );
};

export default InformationButton;

export interface InformationButtonProps {
  id: string;
  description: string;
}
