import {ApolloClient, ApolloLink, Observable, Operation, split,} from "@apollo/client";
import {InMemoryCache} from "@apollo/client/cache";
import {onError} from "@apollo/client/link/error";
import {HttpLink} from "@apollo/client/link/http";
import {WebSocketLink} from "@apollo/client/link/ws";
import {getMainDefinition} from "@apollo/client/utilities";
import {OperationDefinitionNode} from "graphql";
import {ConnectionParamsOptions} from "subscriptions-transport-ws";
import introspectionQueryResultData from "./schema.json";

class ApolloClientFactory {
  static buildWsUri(path: string) {
    const expression = /^ws?s?:\/\/(?:www\.)?[-a-zA-Z\d@:%._+~#=]{1,256}\.[a-zA-Z\d()]{1,6}\b[-a-zA-Z\d()@:%_+.~#?&/=]*$/
    const regex = new RegExp(expression);
    if (path.match(regex)) {
      return path
    }

    const protocol = process.env.REACT_APP_GRAPHQL_WEBSOCKET_PROTOCOL!;

    let host = window.location.host;

    if (process.env.REACT_APP_GRAPHQL_WEBSOCKET_HOST) {
      host = process.env.REACT_APP_GRAPHQL_WEBSOCKET_HOST;
    }

    return protocol + "://" + host + path;
  }

  static createClient(options: ClientOptions) {
    /*const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    });*/
    const possibleTypes = introspectionQueryResultData.__schema.types.map(
      (item) => item.name
    );
    const requestHandler = options.requestHandler
      ? new ApolloLink(
        (operation, forward) =>
          new Observable((observer: any) => {
            let handle: any;
            Promise.resolve(operation)
              .then(
                (oper) =>
                  options.requestHandler && options.requestHandler(oper)
              )
              .then(() => {
                handle =
                  forward &&
                  forward(operation).subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  });
              })
              .catch(observer.error.bind(observer));

            return () => {
              if (handle) handle.unsubscribe();
            };
          })
      )
      : false;

    const errorLink = onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors)
        graphQLErrors.map(({message, locations, path}) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const httpLink = new HttpLink({
      uri: options.uri,
      credentials: "same-origin",
    });

    let link: ApolloLink;

    if (options.websocketUri && options.connectionParams) {
      const wsLink = new WebSocketLink({
        uri: ApolloClientFactory.buildWsUri(options.websocketUri),
        options: {
          lazy: true,
          connectionParams: options.connectionParams,
        },
      });
      link = split(
        ({query}) => {
          const definition = getMainDefinition(
            query
          ) as OperationDefinitionNode;
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        ApolloLink.from(
          [errorLink, requestHandler, httpLink].filter(
            (x) => !!x
          ) as ApolloLink[]
        )
      );
    } else {
      link = ApolloLink.from(
        [errorLink, requestHandler, httpLink].filter((x) => !!x) as ApolloLink[]
      );
    }

    return new ApolloClient({
      link,
      cache: new InMemoryCache({
        possibleTypes: {possibleTypes},
      }),
    });
  }
}

interface ClientOptions {
  uri: string;
  websocketUri?: string;
  token?: string;

  requestHandler?(operation: Operation): Promise<void>;

  connectionParams?(): ConnectionParamsOptions;
}

export default ApolloClientFactory;
