import ChronoUnit from "./ChronoUnit";

export enum ActiveStatus {
  ACTIVE = "ACTIVE",
  DEACTIVE = "DEACTIVE",
  INACTIVE = "INACTIVE",
}

export interface ActiveAssets {
  status: ActiveStatus;
  period: Period;
}

export interface Period {
  interval: number;
  unit: ChronoUnit;
}
